import React from 'react';

export interface IAudioPlayerContext {
  focusedPlayerId: number | null;
  focusingEnabled: boolean;
  requestPlayerId(): number;
  focus(id: number): void;
}

const AudioPlayerContext = React.createContext<IAudioPlayerContext>({
  focusedPlayerId: null,
  focusingEnabled: false,
  requestPlayerId() {
    return -1;
  },
  focus(id: number) {}
});

export interface IFocusedAudioPlayerProviderProps {}

export const FocusedAudioPlayerProvider: React.FC<IFocusedAudioPlayerProviderProps> = (props) => {
  const lastPlayerIdRef = React.useRef(-1);
  const [focusedPlayerId, setFocusedPlayerId] = React.useState<number | null>(null);

  return (
    <AudioPlayerContext.Provider
      value={{
        focusedPlayerId,
        focusingEnabled: true,
        requestPlayerId(): number {
          const id = lastPlayerIdRef.current + 1;
          lastPlayerIdRef.current = id;
          return id;
        },
        focus(id: number) {
          setFocusedPlayerId(id);
        }
      }}
    >
      {props.children}
    </AudioPlayerContext.Provider>
  );
};

export const useFocusedAudioPlayer = () => {
  const [playerId, setPlayerId] = React.useState<number | null>(null);
  const { focusedPlayerId, focusingEnabled, requestPlayerId, focus } = React.useContext(
    AudioPlayerContext
  );

  React.useEffect(() => {
    if (playerId === null) {
      const id = requestPlayerId();
      setPlayerId(id);
    }
  }, [requestPlayerId, playerId]);

  return {
    isFocused: !focusingEnabled || focusedPlayerId === playerId,
    focus: () => {
      if (playerId !== null) {
        focus(playerId);
      }
    }
  };
};
