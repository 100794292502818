import React from 'react';
import EnterpriseTemplate, {
  EnterpriseStateProvider,
  IEnterpriseTemplateProps
} from "../../components/templates/enterprise";
import {
  useAcceptTOSMutation,
  useAppContextQuery,
  useCreateAccountMutation
} from "../../graphql/generated";
import FullPageLoader from "../shared/loader";
import GenericErrorBoundary from "../../components/molecules/generic-error-boundary";
import { useSavedState } from "../../utils/hooks/saved-state";
import { FeatureLockContext, FeatureLockedState, FeatureLockProvider } from "../../utils/feature-locking";
import { useHistory, matchPath, useLocation } from "react-router-dom";
import RenderCountWarningBanner from "../../components/organisms/render-count-warning-banner";

export interface IEnterpriseTemplateContainerProps {
  exemptedRoutesFromAllFeatureLock?: string[];
}

const EnterpriseTemplateContainer: React.FC<IEnterpriseTemplateContainerProps> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [selectedAccount, setSelectedAccount] = React.useState<string | null>(null);
  const [savedAccountId, setSavedAccountId] = useSavedState<string | null>("account", null)

  const { data: appContextData, loading: appContextDataLoading, refetch } = useAppContextQuery();

  const [acceptTOS] = useAcceptTOSMutation()

  const appContextFirstAccount = appContextData?.viewer?.user.accounts[0]?.account?.id;

  React.useEffect(
    () => {
      const firstAccount = savedAccountId ?? appContextFirstAccount;

      if (!!firstAccount && !selectedAccount) {
        setSelectedAccount(firstAccount)
        setSavedAccountId(firstAccount)
      } else if (selectedAccount && appContextFirstAccount && !appContextData?.viewer?.user.accounts.find(a => a.account.id === selectedAccount)) {
        setSelectedAccount(appContextFirstAccount)
        setSavedAccountId(appContextFirstAccount)
      }
    }, [appContextData?.viewer?.user.accounts]
  )

  if (appContextDataLoading || !appContextData?.viewer) return <FullPageLoader />

  const selectedAccountAssoc = appContextData.viewer.user.accounts.find(a => a.account.id === selectedAccount)

  const subscription = selectedAccountAssoc?.account.pricingPlanSubscription

  const user = appContextData.viewer.user

  const data: IEnterpriseTemplateProps["data"] = {
    selectedCompanyId: selectedAccount,
    companies: appContextData?.viewer.user.accounts.map(a => ({
      id: a.account.id,
      title: a.account.name,
      imageUrl: a.account.logo
    })) ?? [],
    user: {
      firstName: user.firstName,
      lastName: user.lastName,
      emailAddress: user.emailAddress,
      emailVerified: user.emailVerified
    },
    pricingPlan: subscription ? {
      inactive: !subscription.isActive
    } : null,
    hasAssociatedTalentAccounts: (selectedAccountAssoc?.account.talentEntitlementsCount ?? 0) > 0,
    signedAgreementDate: selectedAccountAssoc?.account.latestUserTOSAcceptance ? new Date(selectedAccountAssoc?.account.latestUserTOSAcceptance.acceptanceDate) : null
  }

  const disabledFeatures: FeatureLockContext = appContextData?.viewer.user.accounts.reduce((allAccountFlags, account) => {
    return ({
      accounts: {
        ...allAccountFlags.accounts,
        [account.account.id]: account.account.allRoleRestrictions.featureFlags.reduce((acc, curr) => {
          let lockedState: FeatureLockedState = curr.locked;
          if (curr.locked && curr.lockedReason) {
            lockedState = {
              lockedReason: curr.lockedReason
            }
          }
          if (curr.locked && curr.hidden) {
            lockedState = 'hidden';
          }

          return ({
            ...acc,
            [curr.key]: lockedState
          });
        }, {})
      }
    });
  }, { accounts: {} });

  return (
    <FeatureLockProvider value={disabledFeatures}>
      <EnterpriseStateProvider value={{
        state: {
          selectedAccount
        }
      }}>
        <EnterpriseTemplate
          data={data}
          exemptedFromAllFeatureLock={props.exemptedRoutesFromAllFeatureLock ? props.exemptedRoutesFromAllFeatureLock.find(r => matchPath(location.pathname, {
            path: r,
            exact: true,
            strict: false
          }) != null) != null : false}
          onBeforeSignOut={() => {
            setSavedAccountId(null)
          }}
          onAgreeToTOSPdf={async () => {
            if (selectedAccountAssoc) {
              await acceptTOS({
                variables: {
                  account: selectedAccountAssoc.account.id
                }
              })
            }
          }}
          onSignOutReturnTo={`${window.location.origin}/enterprise/signed-out`}
          onSelectCompany={(account) => {
            setSelectedAccount(account);
            setSavedAccountId(account)
            history.push("/enterprise")
          }}
          onAccountCreated={() => refetch()}
        >
          { !matchPath(location.pathname, {
            path: "/enterprise/account",
            exact: true,
            strict: false
          }) && <RenderCountWarningBanner totalRenders={subscription?.billingPeriodUsage.renders ?? 0} maxRenders={selectedAccountAssoc?.account?.allRoleRestrictions.synthesisMaxRequests ?? null} /> }
          <GenericErrorBoundary>
            {props.children}
          </GenericErrorBoundary>
        </EnterpriseTemplate >
      </EnterpriseStateProvider>
    </FeatureLockProvider>
  );
};

export default EnterpriseTemplateContainer;
