import React from 'react';
import { VictoryBar, VictoryChart, VictoryAxis } from "victory";
import { useTheme } from '../../../theme';
import Select from '../select';

export interface IUsageChartProps {
  heading?: React.ReactNode;
  data: {
    points: Array<{ date: Date; value: number }>;
    keyValues: Array<{
      key: string;
      label: string;
      value: React.ReactNode;
    }>;
  };
  ranges: Array<string>;
  selectedRange: {
    key: string;
    from: Date;
    to: Date;
  };
  setSelectedRange(newRange: string): void;

  hideDay?: boolean;
  hideYear?: boolean;
  className?: string;
}

const UsageChart: React.FC<IUsageChartProps> = (props) => {
  const theme = useTheme();

  const data = props.data.points;
  const maxIsZero = data.reduce((acc, curr) => curr.value > acc ? curr.value : acc, 0) === 0

  return (
    <div className={props.className}>
      <div className={'flex justify-between vocalid-primary-text'}>
        <div>
          {props.heading}
          <Select value={props.selectedRange.key} placeholder={'Time Period'} onChange={newValue => props.setSelectedRange(newValue as string)}>
            {props.ranges.map(r => ({ value: r, label: r }))}
          </Select>
        </div>
        <div className={'vocalid-h3 ttn'}>
          <div className={"mb1"}>{props.selectedRange.from.toLocaleDateString()} - {props.selectedRange.to.toLocaleDateString()}</div>
          <table>
            <tbody>
              {props.data.keyValues.map(keyValue => (
                <tr key={keyValue.key}>
                  <td className={'pr3 vocalid-primary-text pb1'}>{keyValue.label}:</td>
                  <td className={'tr b pb1'}>{keyValue.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <VictoryChart
        domainPadding={{ x: 15 }}
        padding={{ top: 10, bottom: 25, left: 30, right: 30 }}
        height={120}
        scale={{ x: 'time' }}
      >
        <VictoryBar data={data} x="date" y="value" style={{ data: { fill: theme.colors.blue, filter: 'drop-shadow(0px 0px 3px rgba(29, 153, 203, 0.15))' } }} />
        <VictoryAxis
          scale="time"
          tickCount={10}
          tickFormat={(x: Date) => {
            return `${x.getMonth() + 1}${ !props.hideDay ? "/" + x.getDate() : ""}${ !props.hideYear ? "/" + x.getFullYear().toString().substr(-2) : ""}`;
          }}
          style={{
            axis: {
              stroke: theme.colors.tertiaryText
            },
            tickLabels: {
              fill: theme.colors.tertiaryText,
              fontSize: 5,
              fontFamily: theme.typography.body.fontFamily
            }
          }}
        />
        <VictoryAxis
          dependentAxis
          tickFormat={(tick) => maxIsZero || tick !== Math.round(tick) ? '' : tick}
          style={{
            axis: {
              stroke: theme.colors.tertiaryText
            },
            axisLabel: {},
            tickLabels: {
              fill: theme.colors.tertiaryText,
              fontSize: 5,
              fontFamily: theme.typography.body.fontFamily
            }
          }}
        />
      </VictoryChart>
    </div>
  );
};

export default UsageChart;
