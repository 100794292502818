import React from "react";
import classNames from "classnames";
import { css } from "emotion";
import Checkbox from "../../atoms/checkbox";
import Icon, { EIconName } from "../../atoms/icon";
import AudioPlayer from "../../molecules/audio-player";
import Tooltip from "../../atoms/tooltip";
import { useTheme } from "../../../theme";
import SsmlContentView from "./ssml";
import Input from "../../atoms/input";
import { PrimaryButton } from "../../atoms/button";
import { RecordingApprovalFlag } from "../../../graphql/generated";

export interface IRecordingsReviewTableProps {
  talentId: string;
  loadedRecordings: Array<{
    id: string;
    contentLineId: string | null;
    num: number;
    sentence: string;
    contentSentence: string | null;
    error: {
      sentence: string;
      message: string | null;
    } | null;
    assignment: string;
    audioUrl: string | null;
    timestamp: Date;
    status: RecordingApprovalFlag;
    comments: string | null;
    reported: boolean;
    session: {
      id: string;
      startedAt: Date;
      recordingDeviceName: string | null;
      browser: string | null;
      os: string | null;
      userAgent: string | null;
    };
  }>;
  commentWritingAllowed: boolean;

  selectedRecordingIds: string[];
  onRecordingSelected(recordingId: string, selected: boolean): void;
  // onSelectedAllRecordings(): void;
  // onClearedSelection(): void;

  onRecordingStatusChange(recordingId: string, status: RecordingApprovalFlag): void;
  onUpdateRecordingComments(recordingId: string, comments: string | null): void;
  onRecordingSentenceChanged(recordingId: string, sentence: string): void;
  onReportContentLine(contentLineId: string, reason: string): void;

  className?: string;
}

const numberOfRecordingsToPreload = 10;

const RecordingsReviewTable = React.forwardRef<HTMLDivElement, IRecordingsReviewTableProps>((props, ref) => {
  const shownRecordings = props.loadedRecordings

  return (
    <div className={props.className}>
      <table className={'w-100'} style={{ borderSpacing: 0 }}>
        <thead>
          <tr className={'ttu vocalid-h3 vocalid-secondary-text'}>
            <td className={'v-top ph2 tc'}>
              {/*<Checkbox
                checked={allSelected}
                indeterminate={someSelected && !allSelected}
                onChange={() =>
                  allSelected
                    ? props.onClearedSelection()
                    : props.onSelectedAllRecordings()
                }
              />*/}
            </td>
            <td className={'v-mid pb3'}>No.</td>
            <td className={'v-mid pb3'} style={{ width: '40%' }}>Sentence</td>
            <td className={'v-mid pb3'} style={{ width: '17%' }}>Training Script</td>
            <td className={'v-mid pb3'} style={{ width: '17%' }}>Date</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {shownRecordings.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime()).map((recording, index) => {
            return (
              <RecordingTableItem
                recording={{
                  ...recording,
                  status: recording.status
                }}
                selected={props.selectedRecordingIds.includes(recording.id)}
                preloadAudio={false}
                commentWritingAllowed={props.commentWritingAllowed}
                onStatusChange={newStatus => props.onRecordingStatusChange(recording.id, newStatus)}
                onUpdateComments={newComments => props.onUpdateRecordingComments(recording.id, newComments)}
                onSentenceChanged={newSentence => props.onRecordingSentenceChanged(recording.id, newSentence)}
                onReportContentLine={reason => recording.contentLineId && props.onReportContentLine(recording.contentLineId, reason)}
                onSelected={selected => props.onRecordingSelected(recording.id, selected)}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
});

export default RecordingsReviewTable;


export interface RecordingTableItemProps {
  recording: IRecordingsReviewTableProps["loadedRecordings"][number];
  selected: boolean;
  preloadAudio: boolean;
  commentWritingAllowed: boolean;
  onSelected(selected: boolean): void;
  onStatusChange(status: RecordingApprovalFlag): void;
  onUpdateComments(comments: string | null): void;
  onSentenceChanged(sentence: string): void;
  onReportContentLine(reason: string): void;
}

export const RecordingTableItem: React.FC<RecordingTableItemProps> = props => {
  const { colors: themeColors } = useTheme();
  const recordedSentenceInputRef = React.useRef<HTMLInputElement | null>(null);

  const [comments, setComments] = React.useState<string | null>(null);
  React.useEffect(() => {
    setComments(props.recording.comments);
  }, [props.recording.comments]);

  const [isEditingSentence, setIsEditingSentence] = React.useState(false);
  const [prevRecordedSentence, setPrevRecordedSentence] = React.useState<string>(props.recording.sentence);
  const [recordedSentence, setRecordedSentence] = React.useState(props.recording.sentence);
  React.useEffect(() => {
    setRecordedSentence(props.recording.sentence);
  }, [props.recording.sentence]);

  const [reportReason, setReportReason] = React.useState<string>("");
  const [reported, setReported] = React.useState(props.recording.reported);
  React.useEffect(() => {
    setReported(props.recording.reported);
  }, [props.recording.reported]);

  const tdCss = css({
      backgroundColor:
        props.recording.status === RecordingApprovalFlag.Approved ? '#24706A' :
          props.recording.status === RecordingApprovalFlag.Rejected ? '#664553' :
            props.recording.error !== null && recordedSentence === props.recording.error.sentence ? '#665645' :
              undefined
    });

  const leftBorderCss = css({
    borderLeft: '1.5px solid #3C6575'
  });

  return (
    <tr
      key={props.recording.id}
      className={classNames(
        "bg-vocalid-tile vocalid-secondary-text",
        css({
          "&:nth-child(odd)": {
            backgroundColor: "#2A404A"
          }
        })
      )}
    >
      <td className={"pa2 tc"}>
        <Checkbox
          checked={props.selected}
          onChange={({ target }) => {
            const checkbox = target as HTMLInputElement;
            const checked = checkbox.checked;

            props.onSelected(checked);
          }}
        />
      </td>
      <td
        className={classNames(tdCss, "tr")}><Tooltip interactive tooltipContent={<div><span>ID: </span><span>{props.recording.id}</span></div>}><div className={"pa2"}>{props.recording.num}</div></Tooltip></td>
      <td className={classNames(tdCss, leftBorderCss, "pa2")}>
        <span className={"flex items-center"}>
          {props.recording.error !== null && !isEditingSentence && recordedSentence === props.recording.error?.sentence &&
            <Tooltip
              tooltipContent={<div>
                <div>
                  <div>This content line:</div>
                  <blockquote>{props.recording.contentSentence}</blockquote>
                  <div>was reported to contain errors{props.recording.error.message ? ":" : ""}</div>
                </div>
                {props.recording.error.message && <ul>
                  <li>{props.recording.error.message}</li>
                </ul>
                }
              </div>}
            >
              <Icon name={EIconName.Warning} color={themeColors.secondaryText} size={20} className={"mr2"} />
            </Tooltip>}
          {!isEditingSentence ? <>
            <SsmlContentView ssml={recordedSentence} />
            <span className={"flex-grow-1 flex-shrink-1"} />
            <Icon name={EIconName.Edit} className={"ml2 mr1 flex-shrink-0"} onClick={() => {
              setPrevRecordedSentence(recordedSentence);
              setIsEditingSentence(true);
              setTimeout(() => {
                recordedSentenceInputRef.current?.focus();
              }, 100);
            }} />
            { (props.recording.error === null || recordedSentence !== props.recording.error?.sentence) && <Tooltip interactive={recordedSentence === props.recording.contentSentence && !reported} tooltipContent={
              <div className={"flex flex-column items-center"}>
                { recordedSentence !== props.recording.contentSentence ? <>
                  <div className={"tc mh3"}>This sentence has been edited. Its original script cannot be reported for errors.</div>
                </> : !reported ? <>
                  <div className={"tc mb2 mh3"}>Does this sentence script<br/>contain an error?</div>
                  <Input white value={reportReason} placeholder={"Typo"} onChange={setReportReason} className={"w-100 mb2"} />
                  <PrimaryButton disabled={reportReason === ""} size={"small"} onClick={async () => {
                    await props.onReportContentLine(reportReason);
                    setReportReason("");
                    setReported(true);
                  }}>Report</PrimaryButton>
                </> : <>
                  <div className={"tc"}>You have reported this sentence script to be containing errors.</div>
                </> }
              </div>
            }>
              <Icon name={reported && recordedSentence === props.recording.contentSentence ? EIconName.ReportActive : EIconName.ReportInactive} size={28} className={classNames("mr1 flex-shrink-0", recordedSentence !== props.recording.contentSentence && css({ opacity: 0.3 }))} />
            </Tooltip> }
          </> : <>
            <Input ref={recordedSentenceInputRef} value={recordedSentence} onChange={setRecordedSentence} onKeyDown={key => {
              if (key === "Enter") {
                props.onSentenceChanged(recordedSentence);
                setIsEditingSentence(false);
              } else if (key === "Escape") {
                setRecordedSentence(prevRecordedSentence);
                setIsEditingSentence(false);
              }
            }} className={"flex-grow-1"} />
            <Icon name={EIconName.X} size={25} className={"ml2 mr1 flex-shrink-0"} onClick={() => {
              setRecordedSentence(prevRecordedSentence);
              setIsEditingSentence(false);
            }} />
            <Icon name={EIconName.Checkmark} size={18} className={"ml1 mr2 flex-shrink-0"} onClick={() => {
              props.onSentenceChanged(recordedSentence);
              setIsEditingSentence(false);
            }} />
          </>}
        </span>
      </td>
      <td className={classNames(tdCss, leftBorderCss, "pa2")}>
        {props.recording.assignment}
      </td>
      <td className={classNames(tdCss, leftBorderCss, "pa2")}>
        {props.recording.timestamp.toLocaleString()}
      </td>
      <td className={classNames(leftBorderCss, "pa2 tr")}>
        <Tooltip tooltipContent={
          <dl className={"tl ma0"}>
            <dt className={"di mr2"}>Device:</dt>
            <dd className={"di ml0 b"}>{props.recording.session.recordingDeviceName ?? "Undefined"}</dd>
            <br />
            <dt className={"di mr2"}>Browser:</dt>
            <dd className={"di ml0 b"}>{props.recording.session.browser ?? "Undefined"}</dd>
            <br />
            <dt className={"di mr2"}>Operating System:</dt>
            <dd className={"di ml0 b"}>{props.recording.session.os ?? "Undefined"}</dd>
          </dl>
        }>
          <Icon name={EIconName.Info} className={"o-50"} />
        </Tooltip>
        <Tooltip
          interactive
          tooltipContent={
            <div className={"w5"}>
              <textarea placeholder={props.commentWritingAllowed ? "Add comments here..." : "No comments"} value={comments ?? ""} onChange={({ target: { value: newValue } }) => {
                const newComments = newValue === "" ? null : newValue;
                if (props.commentWritingAllowed) {
                  setComments(newComments);
                }
              }} onBlur={() => props.onUpdateComments(comments)} rows={6} className={classNames("w-100 bg-transparent", props.commentWritingAllowed ? "vocalid-primary-text" : "vocalid-dark-text", css({ resize: 'none', outline: 'none', border: 'none' }))} />
            </div>
          }
          color={props.commentWritingAllowed ? themeColors.clipInactive : undefined}
        >
          <Icon name={EIconName.Comments} className={classNames("ml1", comments !== null ? 'o-100' : 'o-50')} />
        </Tooltip>
        {props.recording.audioUrl ? (<AudioPlayer
          src={props.recording.audioUrl}
          preload={props.preloadAudio}
          buttonOnly
          className={"ml1 mr2"}
        />) : <span />}
        <Icon
          name={EIconName.Reject}
          onClick={() => props.onStatusChange(RecordingApprovalFlag.Rejected)}
          active={(props.recording.status) === RecordingApprovalFlag.Rejected}
          activeColor={"red"}
          className={"mr2"}
        />
        <Icon
          name={EIconName.Approve}
          active={(props.recording.status) === RecordingApprovalFlag.Approved}
          onClick={() => props.onStatusChange(RecordingApprovalFlag.Approved)}
          activeColor={"green"}
          className={"mr2"}
        />
        <Icon
          name={EIconName.Download}
          onClick={() => {
            if (props.recording.audioUrl) window.location.href = props.recording.audioUrl;
          }}
        />
      </td>
    </tr>
  );
};
