import React from 'react';
import classNames from 'classnames';
import { css } from 'emotion';
import Checkbox from '../../atoms/checkbox';
import {PrimaryButton} from "../../atoms/button";

export interface IAgreementPdfViewProps {
  headerText?: string;
  agreeText?: string;
  file: string;
  onAgree(): void;
  className?: string;
}

const AgreementPdfView: React.FC<IAgreementPdfViewProps> = (props) => {
  const [agreed, setAgreed] = React.useState(false);

  return (
    <div className={props.className}>
      <div className={'vocalid-h3 mb4'}>
        {props.headerText}
      </div>
      <div className={classNames("mb2", css({
        minWidth: 700,
        height: 500,
      }))}>
        <object data={props.file} type="application/pdf" className={"w-100 h-100"}>
          <embed src={props.file} type="application/pdf" />
        </object>
      </div>
      <div className={'flex justify-between items-center mt3'}>
        <div className={'ml2 mr3 pointer'} onClick={() => {
          setAgreed((o) => !o);
        }}>
          <Checkbox onChange={e => {
            setAgreed((o) => !o);
          }} checked={agreed} className={'v-mid mr2'} /> {props.agreeText ?? 'I have read and understood.'}
        </div>
        <div>
          <PrimaryButton
            onClick={() => props.onAgree()}
            disabled={!agreed}
            className={'db'}
          >
            I AGREE
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default AgreementPdfView;
