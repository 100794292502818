import {
  AdMixContents,
  IProjectMixData,
  MixContents,
  MixType,
  PlaylistItemData,
  PlaylistMixContents
} from "../../../components/organisms/project-sidebar/tabs/mix";
import React from "react";
import {
  AdMixContentFragment,
  AdMixContentInput,
  MixContentFragment,
  MixContentInput,
  MixFormat,
  MixFragment,
  PlaylistItemFragment,
  PlaylistItemInput,
  PlaylistMixContentFragment,
  PlaylistMixContentInput,
  useChangeMixFormatMutation,
  useCreateMixMutation,
  useCreateMixTakeItemMutation,
  useCreateMixTakeMutation,
  useDeleteMixMutation,
  useDeleteMixTakeMutation,
  useUpdateMixMutation
} from "../../../graphql/generated";

export function useManagedMixes(projectId: string, mixesData: MixFragment[], refetchProjectQuery: () => Promise<any>) {
  // mutations
  const [createMixMutation] = useCreateMixMutation()
  const createMix = async () => {
    await createMixMutation({
      variables: {
        project: projectId
      }
    });
    await refetchProjectQuery();
  }

  const [deleteMixMutation] = useDeleteMixMutation()
  const deleteMix = async (mixId: string) => {
    await deleteMixMutation({
      variables: {
        mix: mixId
      }
    });
    await refetchProjectQuery();
  }

  const [deleteMixTakeMutation] = useDeleteMixTakeMutation()
  const deleteMixTake = async (mixId: string, takeId: string) => {
    await deleteMixTakeMutation({
      variables: {
        mixTake: takeId
      }
    });
    await refetchProjectQuery();
  }

  const [createMixTakeItemMutation] = useCreateMixTakeItemMutation()
  const createMixTakeItem = async (mixId: string, takeId: string) => {
    await createMixTakeItemMutation({
      variables: {
        mix: mixId,
        takeId
      }
    })
    await refetchProjectQuery();
  }

  const [updateMixMutation] = useUpdateMixMutation();

  const persist = async (mixId: string, content: MixContents) => {
    await updateMixMutation({
      variables: {
        id: mixId,
        content: convertMixContentToGQL(content)
      }
    })
    await refetchProjectQuery()
  }

  const [generatingTake, setGeneratingTake] = React.useState(false)

  const [createMixTakeMutation] = useCreateMixTakeMutation()
  const generateTake = async (mixId: string) => {
    setGeneratingTake(true)
    await createMixTakeMutation({
      variables: {
        mix: mixId
      }
    })
    await refetchProjectQuery()

    setGeneratingTake(false)
  }

  const [changeMixFormatMutation] = useChangeMixFormatMutation()
  const changeMixFormat = async (mixId: string, type: MixType) => {
    await changeMixFormatMutation({
      variables: {
        input: {
          id: mixId,
          format: convertMixTypeToGQL(type)
        }
      }
    })
    await refetchProjectQuery()
  }

  const mixes: Array<IProjectMixData> = React.useMemo(() => [...mixesData].sort((a, b) => a.created - b.created).map(mix => ({
    id: mix.id,
    contents: convertMixContentFromGQL(mix.content),
    takes: mix.takes.map((take, i) => ({
      id: take.id,
      number: i + 1,
      createdAt: new Date(take.created),
      rendering: take.rendered === null,
      contents: convertMixContentFromGQL(take.content),
      renderedUrl: take.rendered?.url ?? null,
      error: null // take.error FIXME
    }))
  })), [mixesData])

  return {
    mixes,
    addMix: createMix,
    deleteMix: deleteMix,
    deleteMixTake: deleteMixTake,
    addTakeItem: async (mixId: string, takeId: string) => {
      await createMixTakeItem(mixId, takeId);
    },
    update: async (mixId: string, contents: MixContents) => {
      await persist(mixId, contents);
    },
    addPauseItem: async (mixId: string) => {
      const oldContent = mixes.find(p => p.id === mixId)!.contents

      switch (oldContent.type) {
        case "Playlist":
          await persist(mixId, {
            ...oldContent,
            items: [
              {
                type: "pause" as const,
                id: `pause ${new Date()}`,
                duration: 1000
              },
              ...oldContent.items
            ]
          });
          break;
        case "Ad":
          throw new Error("Adding pauses to Ad content is not yet implemented")
      }
    },
    generateTake,
    changeFormat: changeMixFormat
  }
}

function convertMixTypeToGQL(type: MixType): MixFormat {
  switch (type) {
    case "Playlist":
      return MixFormat.PLAYLIST
    case "Ad":
      return MixFormat.AD
  }
}

function convertMixContentFromGQL(content: MixContentFragment): MixContents {
  switch (content.__typename) {
    case "PlaylistMixContent":
      return convertPlaylistContentFromGQL(content)
    case "AdMixContent":
      return convertAdContentFromGQL(content)
  }
}

function convertPlaylistContentFromGQL(content: PlaylistMixContentFragment): PlaylistMixContents {
  return {
    type: 'Playlist',
    items: content.items.map(convertPlaylistItemFromGQL)
  };
}

function convertAdContentFromGQL(content: AdMixContentFragment): AdMixContents {
  return {
    type: 'Ad',
    sections: content.sections.map(section => ({
      key: section.key,
      items: section.items.map(convertPlaylistItemFromGQL)
    })),
    soundTemplateId: content.soundTemplate?.id ?? null,
    maxDurationSeconds: content.maxDurationSeconds
  };
}

function convertPlaylistItemFromGQL(item: PlaylistItemFragment): PlaylistItemData {
  switch (item.__typename) {
    case "PlaylistPauseItem":
      return {
        type: "pause",
        id: item.id,
        duration: item.duration
      };
    case "PlaylistTakeItem":
      return {
        type: "take",
        id: item.id,
        takeId: item.take.id,
        num: item.take.clip?.takes.findIndex(take => take.id === item.take.id) ?? 0,
        clipName: item.take.clip ? item.take.clip.title ?? "Unnamed Clip" : "Deleted Clip",
        audioUrl: item.take.__typename === "GeneratedTake" ? item.take.rendered!.url : item.take.recorded!.downloadUrl!,
        voiceName: item.take.__typename === "GeneratedTake" ? item.take.voice.title : item.take.voice.talent!.firstName + " " + item.take.voice.talent!.lastName,
        talentRecording: item.take.__typename === "TalentRecordedTake"
      };
  }
}

function convertPlaylistItemToGQL(item: PlaylistItemData): PlaylistItemInput {
  switch (item.type) {
    case "pause":
      return {
        pause: {
          id: item.id,
          duration: item.duration
        }
      };
    case "take":
      return {
        take: {
          id: item.id,
          takeId: item.takeId
        }
      };
  }
}

function convertMixContentToGQL(content: MixContents): MixContentInput {
  switch (content.type) {
    case "Playlist":
      return {
        playlist: convertPlaylistContentToGQL(content)
      }
    case "Ad":
      return {
        ad: convertAdContentToGQL(content)
      }
  }
}

function convertPlaylistContentToGQL(content: PlaylistMixContents): PlaylistMixContentInput {
  return {
    items: content.items.map(item => convertPlaylistItemToGQL(item))
  };
}

function convertAdContentToGQL(content: AdMixContents): AdMixContentInput {
  return {
    sections: content.sections.map(section => ({
      key: section.key,
      items: section.items.map(item => convertPlaylistItemToGQL(item))
    })),
    soundTemplateId: content.soundTemplateId,
    maxDurationSeconds: content.maxDurationSeconds
  };
}
