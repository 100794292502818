import React from "react";
import Tooltip from "../../atoms/tooltip";
import { css } from "emotion";
import classNames from "classnames";
import { useTheme } from "../../../theme";
import Select from "../select";

export interface PhoneticKeyboardProps {
  visible: boolean;
  lastKey: string | undefined;
  onPressKey(key: string): void;
  onModifyLastKey(modifiedKey: string): void;
  onClickInside?(): void;
  className?: string;
}

const languageKeys = ["English", "Spanish"] as const;
type LanguageExamples = [SymbolExample | null, SymbolExample | null];

const ___ = null

const symbolLayout = [
  ["v", ___, ___, ___, ___, ___, ___, ___, ___, ___, ___, "ʁ"],
  ["f", ___, ___, ___, ___, ___, ___, ___, ___, ___, ___, "χ"],
  ["β", "ð", "z", "ɾ", ___, ___, ___, ___, ___, ___, "ɣ", "ʕ"],
  ["ɸ", "θ", "s", "r", ___, ___, ___, ___, ___, ___, "x", "ħ"],
  ["m", "n", "l", "ɹ", "i", "y", ___, ___, "u", "ɲ", "ŋ", "q"],
  ["b", "d", "ʒ", "d͡ʒ","ɪ", "ʏ", ___, "ʊ", "ɯ", "ʎ", "g", "ʔ"],
  ["p", "t", "ʃ", "t͡ʃ","e", "ø", "ə", "ɵ", "o", "c", "k", "h"],
  [___, ___, ___, "j", "ɛ", "œ", "ɜ", "ʌ", "ɔ", "ʝ", ___, ___],
  [___, ___, ___, "w", "æ", "a", "ɐ", "ɑ", "ɒ", "ç", ___, ___],
  [___, ___, ___, ___, "aɪ","eɪ","ɔɪ","aʊ","oʊ",___, ___, ___],
  [___, ___, ___, ___, ___, "˞", ":", "~", ___, ___, ___, ___],
  [___, ___, ___, ___, ___, ___, "'", ___, ___, ___, ___, ___]
] as const;

const vowels: Array<KeyboardSymbol> = [
  "j", "u", "ʊ", "o", "e", "ə", "ʌ", "ɔ", "ɛ", "æ", "ɑ", "aɪ", "eɪ", "ɔɪ", "aʊ",
  "oʊ", "i", "ɪ", "a", "ɒ", "ɜ", "ʎ", "ʝ"
];

// noinspection NonAsciiCharacters, JSNonASCIINames
const symbolMap: SymbolMap = {
  "v": ["_v_est",    "_v_amos"],
  "j": ["_y_es",     "t_i_erra"],
  "f": ["_f_ive",    "_f_oca"],
  "l": ["_l_eft",    "_l_oco"],
  "ɾ": ["bu_tt_er",  "ca_r_a"],
  "w": ["_w_e",      "f_u_ego"],
  "ð": ["_th_en",    "lo_d_o"],
  "z": ["_z_ero",    "ra_s_go"],
  "u": ["b_oo_t",    "p_u_so"],
  "ɹ": ["_r_ed",     null],
  "ŋ": ["th_ing_",   null],
  "θ": ["_th_in",    "distin_c_ión"],
  "s": ["_s_eem",    "ca_s_a"],
  "ʊ": ["b_oo_k",    null],
  "d͡ʒ": ["_j_ump",   null],
  "g": ["_g_ame",    "_g_oma"],
  "m": ["_m_ouse",   "ca_m_a"],
  "n": ["_n_o",      "_n_ada"],
  "o": ["h_o_me",    "p_o_so"],
  "t͡ʃ": ["_ch_air",  "_ch_ico"],
  "k": ["_c_at",     "_c_asa"],
  "b": ["_b_ed",     "_b_eso"],
  "d": ["_d_ig",     "_d_ama"],
  "e": ["m_ay_",     "p_e_so"],
  "ə": ["aren_a_",   null],
  "ʌ": ["b_u_s",     null],
  "ɔ": ["cl_o_th",   null],
  "ʒ": ["vi_s_ion",  null],
  "ʔ": [undefined,   null],
  "p": ["_p_it",     "_p_elo"],
  "t": ["_t_in",     "_t_oro"],
  "ɛ": ["dr_e_ss",   null],
  "æ": ["tr_a_p",    null],
  // "ɝ": ["n_ur_se", undefined],
  // "ɚ": ["read_er_", undefined],
  "ɑ": ["f_a_ther",  null],
  "ʃ": ["_sh_eep",   null],
  "h": ["_h_ouse",   null],
  "aɪ": ["pr_i_ce",  null],
  "eɪ": ["f_a_ce",   null],
  "ɔɪ": ["ch_oi_ce", null],
  "aʊ": ["m_ou_th",  null],
  "oʊ": ["g_oa_t",   null],
  "i": ["b_ea_m",    "p_i_so"],
  "ɪ": ["p_i_nk",    null],
  "y": [null,        null],
  "x": [null,        "me_x_icano"],
  "a": [null,        "p_a_so"],
  "c": [null,        null],
  "q": [null,        null],
  "r": [null,        "ca_rr_o"],
  "ç": [null,        null],
  "ø": [null,        null],
  "ħ": [null,        null],
  "œ": [null,        null],
  "ɐ": [null,        null],
  "ɒ": ["th_ough_t", null],
  "ɜ": ["b_i_rd",    null],
  "ɣ": [null,        "la_g_o"],
  "ɯ": [null,        null],
  "ɲ": [null,        "a_ñ_o"],
  "ɵ": [null,        null],
  "ɸ": [null,        null],
  "ʁ": [null,        null],
  "ʎ": [null,        "va_ll_a"],
  "ʏ": [null,        null],
  "ʕ": [null,        null],
  "ʝ": [null,        "va_y_a"],
  "β": [null,        "sa_b_e"],
  "χ": [null,        null],
  "˞": { ex:
       ["ə -> ɚ",    "ə -> ɚ"],
       modify: symbol => symbol + "\u02de",
       symbolWhitelist: vowels },
  "~": { ex:
      ["ɑ -> ɑ̃",     "ɑ -> ɑ̃"],
      modify: symbol => symbol + "\u0303",
      symbolWhitelist: vowels },
  ":": { ex:
      [undefined,    undefined],
      modify: symbol => symbol + "\u02D0",
      symbolWhitelist: vowels },
  "'": [undefined, undefined]
}

const PhoneticKeyboard: React.FC<PhoneticKeyboardProps> = props => {
  const { colors: themeColors } = useTheme();

  const [hoveringSymbol, setHoveringSymbol] = React.useState<KeyboardSymbol | null>(null);
  const [languageKey, setLanguageKey] = React.useState<number>(0);

  return (
    <Tooltip
      visible={props.visible}
      hideArrow
      placement={"left"}
      interactive={true}
      tooltipContent={
        <div>
          <div className={"relative"}>
            <Select value={languageKey.toString()} menuPosition={"absolute"} onChange={newKey => setLanguageKey(parseInt(newKey as string))}>
              {languageKeys.map((language, i) => ({
                label: language,
                value: i.toString()
              }))}
            </Select>
          </div>
          <div className={"relative"} onClick={props.onClickInside}>
            <div className={css({
              position: 'absolute',
              top: 40,
              left: 0,
              right: 0,
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'left',
              pointerEvents: 'none'
            })}>
              <span className={"vocalid-primary-text"} style={{ fontSize: 16, minWidth: 100, paddingLeft: 10 }}>
                {renderExample(hoveringSymbol ? resolveSymbol(symbolMap[hoveringSymbol as keyof SymbolMap], languageKey) ?? undefined : undefined)}
              </span>
            </div>
            <table cellSpacing="0" cellPadding="0" className={classNames("mv1 mh2", css({
              borderSpacing: 0,
              borderCollapse: 'collapse',
            }))}>
              <tbody>
                {symbolLayout.map((section, sectionI) => (
                  <tr key={sectionI} className={"pa0"}>
                    {(section as unknown as Array<KeyboardSymbol | null>).map((symbol, rowI) => {
                      const mapping = symbol ? symbolMap[symbol] : undefined;
                      const disabled = mapping ?
                        resolveSymbol(mapping, languageKey) === null ||
                        (isModifier(mapping) && props.lastKey !== null && (
                          (mapping.symbolWhitelist !== undefined && !mapping.symbolWhitelist.includes(props.lastKey as any)) ||
                          (mapping.symbolBlacklist !== undefined && mapping.symbolBlacklist.includes(props.lastKey as any))
                        )) : false

                      return (
                        <td key={rowI} className={css({
                          padding: 1.5
                        })}>
                          <KeyboardBtn
                            disabled={disabled}
                            color={sectionI > 9 ? themeColors.purple : sectionI === 9 ? themeColors.pink : rowI > 3 && rowI < 9 ? themeColors.darkGreen : themeColors.blue}
                            symbol={symbol}
                            onClick={() => symbol && !disabled && (isModifier(mapping!) ? props.lastKey && props.onModifyLastKey(mapping!.modify(props.lastKey)) : props.onPressKey?.(symbol))}
                            onHover={() => setHoveringSymbol(symbol)}
                          />
                        </td>
                      );
                    }) }
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      }
      className={props.className}
      tooltipElStyle={{
        maxWidth: 'unset',
        backgroundColor: themeColors.tile,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 8px 24px 10px',
        marginLeft: 20,
        marginRight: 20
      }}
    >
      {props.children}
    </Tooltip>
  );
};

export default PhoneticKeyboard;

export interface KeyboardBtnProps {
  color: string;
  symbol: string | null;
  disabled?: boolean;
  onClick?(): void;
  onHover?(): void;
}

const KeyboardBtn: React.FC<KeyboardBtnProps> = props => {
  const { colors: themeColors } = useTheme()

  const baseStyle = css({
    width: 38,
    height: 38,
    margin: 0,
    fontSize: 16
  })

  const buttonStyle = css({
    background: props.color,
    color: themeColors.primaryText,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
    cursor: 'pointer',
    opacity: props.disabled ? 0.25 : 1,
    '&:hover': {
      background: themeColors.secondaryText,
    },
    '&:active': {
      background: themeColors.iconActive,
    }
  })

  return props.symbol ? (
    <span className={classNames(baseStyle, buttonStyle)} onClick={props.onClick} onMouseEnter={props.onHover}>
      {props.symbol}
    </span>
  ) : <span onMouseEnter={props.onHover} className={classNames("db", baseStyle)}>{" "}</span>;
};

function resolveSymbol(symbolMapping: SymbolMapping, languageIndex: number): string | undefined | null {
  return isModifier(symbolMapping) ? symbolMapping.ex[languageIndex] : symbolMapping[languageIndex];
}

function renderExample(ex: string | undefined) {
  if (ex !== undefined) {
    let arr = [];
    let emphasizedText = ""
    for (let i = 0; i < ex.length; i++) {
      if (ex[i] === "_") {
        if (emphasizedText.length === 0) {
          // open emphasis
          i++;
          emphasizedText += ex[i];
        } else {
          // close emphasis
          arr.push(<span style={{
            display: 'inline-block',
            borderBottom: '1px solid white'
          }}>{emphasizedText}</span>);
          emphasizedText = "";
        }
      } else if (emphasizedText.length !== 0) {
        emphasizedText += ex[i];
      } else {
        arr.push(ex[i]);
      }
    }

    return <span><span className={"vocalid-tertiary-text"}>ex.</span> {arr}</span>
  }

  return ex;
}

type KeyboardSymbol = NonNullable<typeof symbolLayout[number][number]>
type SymbolExample = string | undefined;
type Modifier = { ex: LanguageExamples; modify: (symbol: string) => string; symbolWhitelist?: Array<KeyboardSymbol>; symbolBlacklist?: Array<KeyboardSymbol>; };
function isModifier(symbolMapping: SymbolMapping): symbolMapping is Modifier {
  return (symbolMapping as Modifier).ex !== undefined;
}
type SymbolMapping = LanguageExamples | Modifier
type SymbolMap = {
  [key in KeyboardSymbol]: SymbolMapping;
};
