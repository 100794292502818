import React from 'react';
import { css } from 'emotion';
import { useTheme } from '../../../theme';
import classNames from 'classnames';
import Icon, { EIconName } from '../../atoms/icon';
import Tooltip from '../../atoms/tooltip';
import Separator from '../../atoms/separator';

export interface IDropdownProps {
  color?: string;
  label: React.ReactNode;
  disabled?: boolean;
  roundedCorners?: boolean;
  noOutline?: boolean;
  menuColor?: string;
  icon?: EIconName;
  iconSize?: number;

  className?: string;
  style?: React.CSSProperties;

  menuClassName?: string;
  menuStyle?: React.CSSProperties;
}

const Dropdown: React.FC<IDropdownProps> = ({ className, style, ...props }) => {
  const { colors: themeColors } = useTheme();
  const color = props.disabled
    ? themeColors.iconInactive
    : props.color
    ? (themeColors as any)[props.color] ?? props.color
    : themeColors.secondaryText;

  const menuColor = props.menuColor
    ? (themeColors as any)[props.menuColor] ?? props.menuColor
    : themeColors.tile;

  const [expanded, setExpanded] = React.useState(false);

  return (
    <Tooltip
      visible={expanded}
      onClickOutside={() => setExpanded(false)}
      interactive
      placement={'bottom-start'}
      hideArrow
      disabled={props.disabled}
      tooltipElStyle={{
        color: themeColors.primaryText,
        padding: '.5rem 0',
        backgroundColor: menuColor,
        borderRadius: props.roundedCorners ? 4 : 0
      }}
      tooltipContent={
        <div className={props.menuClassName} style={props.menuStyle}>
          {props.children}
        </div>
      }
    >
      <div className={classNames('dib relative primary', className)} style={style}>
        <div className={css({
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginTop: 8,
          paddingRight: 10
        })}>
          <Icon
            name={props.icon ?? EIconName.DropdownArrowDown}
            color={color}
            size={props.iconSize}
            className={css({
              verticalAlign: 'middle',
              pointerEvents: 'none'
            })}
          />
        </div>
        <button
          className={css({
            display: 'inline-block',
            backgroundColor: 'transparent',
            color: color,
            borderRadius: 4,
            borderColor: !props.noOutline ? color : 'transparent',
            appearance: 'none',
            cursor: 'pointer',
            lineHeight: 1.15,
            padding: '8px 40px 8px 10px',
            borderStyle: 'solid',
            borderWidth: 1,
            minHeight: 40
          })}
          onClick={() => setExpanded((o) => !o)}
        >
          {props.label}
        </button>
      </div>
    </Tooltip>
  );
};

export default Dropdown;

export interface IDropdownOptionProps {
  selected?: boolean;
  onSelect(): void;
  className?: string;
}

export const DropdownOption: React.FC<IDropdownOptionProps> = (props) => {
  return (
    <div className={classNames('pointer pv2 ph3', props.selected && css({
      backgroundColor: 'rgba(0, 0, 0, 0.2)'
    }), props.className)} onClick={props.onSelect}>
      {props.children}
    </div>
  );
};

export interface IDropdownSeparatorProps {
  className?: string;
}

export const DropdownSeparator: React.FC<IDropdownSeparatorProps> = (props) => {
  const { colors: themeColors } = useTheme();

  return (
    <Separator
      className={props.className}
      style={{
        borderColor: themeColors.secondaryText,
        margin: '4px 0'
      }}
    />
  );
};
