import React from 'react';
import ReactModal from 'react-modal';
import { css } from 'emotion';
import classNames from 'classnames';
import { useTheme } from '../../../theme';

ReactModal.setAppElement('#root');

export interface IModalProps {
  isOpen: boolean;
  onAfterOpen?(): void;
  onClose?(): void;
  parentClassName?: string;
  containerClassName?: string;
  className?: string;
  style?: React.CSSProperties;
}

const Modal: React.FC<IModalProps> = (props) => {
  const { colors: themeColors } = useTheme();

  const overlayCss = css({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 900
  })

  const wrapperCss = classNames(
    'vocalid-body',
    css({
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 20,
      left: 20,
      right: 20,
      bottom: 20,
      outline: 'none',
      pointerEvents: 'none',
      zIndex: 999
    })
  )

  const content = (
    <div
      className={classNames(
        css({
          minWidth: 0,
          minHeight: 0,
          maxWidth: '100%',
          overflowY: 'auto',
          WebkitOverflowScrolling: 'touch',
          maxHeight: '100%',
          color: themeColors.darkText,
          pointerEvents: 'initial'
        }),
        props.containerClassName
      )}
    >
      <div className={props.className} style={props.style}>
        {props.children}
      </div>
    </div>
  )

  return (
    <ReactModal
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onClose}
      shouldFocusAfterRender={false}
      portalClassName={props.parentClassName}
      className={wrapperCss}
      overlayClassName={overlayCss}
    >
      {content}
    </ReactModal>
  );
};

export default Modal;
