import React from 'react';
import RCSlider, { Range as RCRange } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useTheme } from '../../../theme';

interface ISliderProps {
  value: number;
  min?: number;
  max?: number;
  step?: number;
  markers?: boolean;
  startPoint?: number;

  disabled?: boolean;

  minLabel?: React.ReactNode;
  maxLabel?: React.ReactNode;

  color?: string;
  trackOpacity?: number;

  className?: string;
  style?: React.CSSProperties;

  onChange?(newValue: number): void;
}

const Slider: React.FC<ISliderProps> = (props) => {
  const { colors: themeColors } = useTheme();

  const trackColor = (props.color && (themeColors as any)[props.color]) || themeColors.blue;
  const railColor = themeColors.sliderGroove;
  const handleStroke = themeColors.sliderHandleStroke;
  const handleColor = themeColors.sliderHandle;

  return (
    <div className={props.className} style={props.style}>
      <div className={'flex justify-between items-end tertiary-text f4'}>
        <div>{props.minLabel}</div>
        <div>{props.maxLabel}</div>
      </div>
      <RCSlider
        value={props.value}
        min={props.min}
        max={props.max}
        step={props.step}
        dots={props.markers}
        startPoint={props.startPoint}
        handleStyle={{
          boxSizing: 'content-box',
          height: 6,
          width: 6,
          marginTop: !props.disabled ? -7 : 0,
          backgroundColor: !props.disabled ? handleColor : trackColor,
          borderColor: handleStroke,
          borderWidth: !props.disabled ? 7 : 0
        }}
        trackStyle={{
          backgroundColor: trackColor,
          height: 6,
          opacity: props.trackOpacity ?? 1
        }}
        railStyle={{
          backgroundColor: railColor,
          height: 6
        }}
        dotStyle={{
          height: 6,
          width: 1,
          top: 16,
          marginLeft: 0.5,
          backgroundColor: themeColors.tertiaryText,
          border: 'none',
          borderRadius: 0
        }}
        style={{
          marginTop: 7.5,
          marginBottom: 7.5 + (props.markers ? 25 : 0)
        }}
        onChange={newValue => !props.disabled && props.onChange?.(newValue)}
      />
    </div>
  );
};

export default Slider;

export interface RangeSliderProps {
  value: number[];
  min?: number;
  max?: number;
  step?: number;
  markers?: boolean;

  disabled?: boolean;

  minLabel?: React.ReactNode;
  maxLabel?: React.ReactNode;

  color?: string;
  trackOpacity?: number;

  className?: string;
  style?: React.CSSProperties;

  onChange?(newValue: number[]): void;
}

export const RangeSlider: React.FC<RangeSliderProps> = props => {
  const { colors: themeColors } = useTheme();

  const trackColor = (props.color && (themeColors as any)[props.color]) || themeColors.blue;
  const railColor = themeColors.sliderGroove;
  const handleStroke = themeColors.sliderHandleStroke;
  const handleColor = themeColors.sliderHandle;

  return (
    <div className={props.className} style={props.style}>
      <div className={'flex justify-between items-end tertiary-text f4'}>
        <div>{props.minLabel}</div>
        <div>{props.maxLabel}</div>
      </div>
      <RCRange
        value={props.value}
        min={props.min}
        max={props.max}
        step={props.step}
        dots={props.markers}
        handleStyle={props.value.map(v => ({
          boxSizing: 'content-box',
          height: 6,
          width: 6,
          marginTop: !props.disabled ? -7 : 0,
          backgroundColor: !props.disabled ? handleColor : trackColor,
          borderColor: handleStroke,
          borderWidth: !props.disabled ? 7 : 0
        }))}
        trackStyle={props.value.map(v => ({
          backgroundColor: trackColor,
          height: 6,
          opacity: props.trackOpacity ?? 1
        }))}
        railStyle={{
          backgroundColor: railColor,
          height: 6
        }}
        dotStyle={{
          height: 6,
          width: 1,
          top: 16,
          marginLeft: 0.5,
          backgroundColor: themeColors.tertiaryText,
          border: 'none',
          borderRadius: 0
        }}
        style={{
          marginTop: 7.5,
          marginBottom: 7.5 + (props.markers ? 25 : 0)
        }}
        onChange={newValue => !props.disabled && props.onChange?.(newValue)}
      />
    </div>
  );
};
