import React from "react";
import { CircularProgress } from "@material-ui/core";
import classNames from "classnames";

export const Loader: React.FC = props => {
  return (
    <span className={"vocalid-primary-text"}>
      <CircularProgress color={"inherit"}/>
    </span>
  );
};

const FullPageLoader: React.FC<{ className?: string }> = props => {
  return (
    <div className={classNames("flex h-75 justify-center items-center", props.className)}>
      <Loader />
    </div>
  );
};

export default FullPageLoader;
