import React from "react";
import classNames from "classnames";
import { CircularProgress } from "@material-ui/core";
import { css } from "emotion";
import { OutlineButton, PrimaryButton } from "../../../components/atoms/button";
import { useTheme } from "../../../theme";
import { useAuth } from "../../../auth";
import * as queryString from "querystring";
import { useAccountPricingPlanQuery, usePricingPlanQuery } from "../../../graphql/generated";
import { NO_PLAN_ID } from "../pricing-plan-selection/pricing-plans-list";

export interface PricingPlanListingProps {
  id: string;
  hideFeatures?: boolean;
  onSelect?(trial: boolean): void;
  isSelected?: { trial: boolean } | false;
  disabled?: boolean;
  show?: 'plan-and-trial' | 'plan' | 'trial';
  className?: string;
}

export const PricingPlanListingWidth = 300

const moreInfoLink = <a href={"https://vocalid.ai/parrot-studio?utm_source=parrot&utm_medium=redirect&utm_campaign=parrot_launch"} target="_blank" rel="noopener noreferrer" className={"vocalid-link no-underline"}>More Info</a>

const PricingPlanListing: React.FC<PricingPlanListingProps> = props => {
  const { colors: themeColors } = useTheme();
  const { data, loading } = usePricingPlanQuery({ variables: { id: props.id } });

  if (!data?.pricingPlan || loading) return <span className={"vocalid-primary-text"}><CircularProgress color={"inherit"} /></span>

  const planSelected = props.isSelected !== undefined ? props.isSelected && !props.isSelected.trial : false;
  const trialSelected = props.isSelected !== undefined ? props.isSelected && props.isSelected.trial : false;

  const features = data.pricingPlan.featuresCopy.split("\n").filter(i => i !== "")

  const trial = data.pricingPlan.trial
  const trialFeatures = trial?.featuresCopy?.split("\n").filter(i => i !== "") ?? []

  const show = props.show ?? 'plan-and-trial';

  return (
    <div className={props.className}>
      <div className={"flex justify-end h-100"}>
        { trial && (show === 'plan-and-trial' || show === 'trial') &&
          <StatelessPricingPlanListing
            data={{
              name: `${data.pricingPlan.name} Trial`,
              cost: <div style={{
                marginTop: -12,
                marginBottom: -18
              }}><div>Free</div><div className={"vocalid-h3"} style={{ paddingBottom: '7px' }}>For {trial.periodDays} Days</div></div>,
              description: trial.description ?? "",
              highlightedFeatures: !props.hideFeatures ? [...trialFeatures, moreInfoLink] : []
            }}
            backgroundColor={themeColors.tileStroke}
            {...(!props.onSelect ? { onSelect: null } :
                trialSelected ?
                {
                  selectText: 'Selected',
                  selectDisabled: true,
                  onSelect: () => {}
                } :
                  props.disabled ? {
                    selectText: 'Disabled',
                    selectDisabled: true,
                    onSelect: () => {}
                  }
                :
                {
                  selectText: 'Start Trial',
                  selectOutlined: true,
                  onSelect: () => {
                    // LogRocket.track('PricingPlanSelected', {
                    //   plan: pricingPlanId,
                    //   trial: true
                    // });
                    props.onSelect?.(true);
                  }
                })
            }
            className={"h-100"}
          />
        }
        {(show === 'plan-and-trial' || show === 'plan') &&
          <StatelessPricingPlanListing
            data={{
              name: data.pricingPlan.name,
              cost: {
                monthly: data.pricingPlan.monthlyPrice! / 100
              },
              description: data.pricingPlan.description,
              highlightedFeatures: !props.hideFeatures ? [...features, moreInfoLink] : [],
            }}
            {...(!props.onSelect ? { onSelect: null } :
              planSelected ?
                {
                  selectText: 'Selected',
                  selectDisabled: true,
                  onSelect: () => {}
                }
                :
                props.disabled ? {
                    selectText: 'Unavailable',
                    selectOutlined: true,
                    selectDisabled: true,
                    onSelect: () => {}
                  }
                  :
                {
                  selectText: 'Select',
                  onSelect: () => {
                    props.onSelect?.(false);
                  }
                })
            }
            className={"h-100"}
          />
        }
      </div>
    </div>
  );
};

export default PricingPlanListing;

const CustomDetail = {
  name: "Custom",
  description: "Whether it’s a bespoke voice, a high volume use case, or a moonshot idea, we’re in — let’s talk.",
  features: [
    "Select voice personas",
    "Volume licensing",
    "Unlimited projects",
    "Unlimited playlists",
    "Commercial use",
    "API access",
    "Account manager",
    "Creative training kickoff",
    "Enterprise SLA"
  ],
  selectLink: `mailto:sales@vocalid.ai?${queryString.stringify({
    subject: "Custom Parrot Studio Plan Request",
    body: ""
  })}`
}

export interface CustomPricingPlanListingProps {
  isSelected?: boolean;
  hideFeatures?: boolean;
  hideSelect?: boolean;
  className?: string;
}

export const CustomPricingPlanListing: React.FC<CustomPricingPlanListingProps> = props => {
  const isSelected = props.isSelected !== undefined ? props.isSelected : false;

  return (
    <StatelessPricingPlanListing
      key={"custom"}
      data={{
        name: CustomDetail.name,
        cost: <span className={"vocalid-h1 dib v-mid"}>Custom</span>,
        description: CustomDetail.description,
        highlightedFeatures: !props.hideFeatures ? [...CustomDetail.features, moreInfoLink] : []
      }}
      {...(props.hideSelect ? { onSelect: null } :
        isSelected ? {
          selectText: 'Selected',
          selectDisabled: true,
          onSelect: () => {
          }
        } :
        {
          selectOutlined: true,
          selectText: "Contact Us",
          onSelect: () => {
            window.location.href = CustomDetail.selectLink
          }
        }
      )}
      className={props.className}
    />
  );
};

export const TrialDetail = {
  name: "Basic Plan",
  description: "Enjoy getting to know our AI voices and tools, for free!",
  features: [
    "100 renders",
    "1 project",
    "1 mix",
    "Personal Use"
  ]
}

export interface TrialPricingPlanListingProps {
  isSelected?: boolean;
  disabled?: boolean;
  hideFeatures?: boolean;
  onSelect?(): void;
  className?: string;
}

export const TrialPricingPlanListing: React.FC<TrialPricingPlanListingProps> = props => {
  const { colors: themeColors } = useTheme()
  const isSelected = props.isSelected !== undefined ? props.isSelected : false;

  return (
    <StatelessPricingPlanListing
      key={"trial"}
      data={{
        name: TrialDetail.name,
        cost: <div style={{
          marginTop: -6,
          marginBottom: 36
        }}><div>Free</div></div>,
        description: TrialDetail.description,
        highlightedFeatures: !props.hideFeatures ? [...TrialDetail.features, moreInfoLink] : []
      }}
      backgroundColor={themeColors.tileStroke}
      {...(!props.onSelect ? { onSelect: null } :
            props.disabled ? {
              selectText: 'Unavailable',
              selectOutlined: true,
              selectDisabled: true,
              onSelect: () => {}
            } :
            isSelected ? {
              selectText: 'Selected',
              selectDisabled: true,
              onSelect: () => {
              }
            } :
            {
              selectText: 'Start',
              selectOutlined: true,
              onSelect: () => {
                props.onSelect?.();
              }
            }
      )}
      className={props.className}
    />
  );
};

export interface CustomPricingPlanBannerProps {
  isSelected?: boolean;
  className?: string;
}

export const CustomPricingPlanBanner: React.FC<CustomPricingPlanBannerProps> = props => {
  const { colors: themeColors } = useTheme()
  const isSelected = props.isSelected !== undefined ? props.isSelected : false;

  return (
    <div className={props.className}>
      <div className={classNames("tl vocalid-secondary-text br2", css({
        backgroundColor: themeColors.tile
      }))}>
        <div className={"flex"}>
          <div>
            <h1 className={"vocalid-primary-text vocalid-body pt2 ph3 ttu"}>{CustomDetail.name}</h1>
            <div className={classNames("vocalid-primary-text pv2 ph3 mh4", css({
              maxWidth: PricingPlanListingWidth
            }))}>
              <h2 className={"vocalid-pricing-number tc mb4"}>
                <span className={"vocalid-h1 dib v-mid"}>Custom</span>
              </h2>
              <div className={"mb3"}>
                <h4 className={"vocalid-h3 mt0 tc mb0"} style={{
                  minHeight: 64
                }}>{CustomDetail.description}<br /></h4>
                {/*<h5 className={"vocalid-h3 tc vocalid-secondary-text mt2 mb0"}>{props.data.tagline}</h5>*/}
              </div>
              <div className={"tc"}>{moreInfoLink}</div>
            </div>
          </div>
          <div className={"ph3"}>
            <ul className={"ph3 mb4"}>
              {CustomDetail.features.map((feature, i) => (
                <li key={i} className={"mb2 vocalid-h3"}>{feature}</li>
              ))}
            </ul>
            <div className={"mb3"}>
              {isSelected ?
                <PrimaryButton disabled={true} className={"w-100"}>Selected</PrimaryButton>
                : <OutlineButton size={"medium"} className={"w-100"}
                                 onClick={() => {
                                   window.location.href = CustomDetail.selectLink
                                 }}>Contact Us</OutlineButton>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface SmartPricingPlanListingProps {
  id: string;
  hideFeatures?: boolean;
  show?: 'plan' | 'trial';
  // isSelected: boolean;

  onSelect?(): void;
  className?: string;
}

export const SmartPricingPlanListing: React.FC<SmartPricingPlanListingProps> = props => {
  if (props.id === NO_PLAN_ID) {
    return <TrialPricingPlanListing {...props} />
  } else {
    return <PricingPlanListing {...props} />
  }
};

export interface StatelessPricingPlanListingProps {
  data: {
    name: string;
    cost: {
      monthly: number;
    } | React.ReactNode;
    description: string;
    highlightedFeatures: Array<React.ReactNode>;
  };
  selectOutlined?: boolean;
  selectText?: string;
  selectDisabled?: boolean;
  backgroundColor?: string;
  onSelect: ((trial: boolean) => void) | null;
  className?: string;
}

export const StatelessPricingPlanListing: React.FC<StatelessPricingPlanListingProps> = props => {
  const { colors: themeColors } = useTheme();

  return (
    <div className={props.className}>
      <div className={classNames("tl flex flex-column h-100 vocalid-secondary-text br2", css({
        minHeight: props.data.highlightedFeatures.length !== 0 ? 600 : undefined,
        width: PricingPlanListingWidth,
        backgroundColor: props.backgroundColor ?? themeColors.tile
      }))}>
        <div style={{ backgroundColor: themeColors.blue, height: 10 }} />
        <div className={"vocalid-primary-text pv2 ph3 br2 br--top"}>
          <h1 className={"vocalid-body ttu"}>{props.data.name}</h1>
          <h2
            className={"vocalid-pricing-number tc mb4"}>{!props.data.cost?.hasOwnProperty("monthly") ? props.data.cost : <>${(props.data.cost as any).monthly}<span
            className={"vocalid-body"}>/mo</span></>}</h2>
          <div className={"mb3"}>
            <h4 className={"vocalid-h3 tc mt0 mb0"} style={{
              minHeight: 64
            }}>{props.data.description}<br /></h4>
            {/*<h5 className={"vocalid-h3 tc vocalid-secondary-text mt2 mb0"}>{props.data.tagline}</h5>*/}
          </div>
        </div>
        <div className={"pb3 ph3"}>
          <ul className={"ph3 mb4"}>
            {props.data.highlightedFeatures.map((feature, i) => (
              <li key={i} className={"mb2 vocalid-h3"}>{feature}</li>
            ))}
          </ul>
        </div>
        <div className={"flex-grow-1 flex-shrink-1"} />
        <div className={"ph3 mb3"}>
          {props.onSelect && (!props.selectOutlined ?
              <PrimaryButton disabled={props.selectDisabled} className={"w-100"}
                             onClick={() => props.onSelect!(false)}>{props.selectText ?? "Select"}</PrimaryButton>
              : <OutlineButton disabled={props.selectDisabled} size={"medium"} className={"w-100"}
                               onClick={() => props.onSelect!(false)}>{props.selectText ?? "Select"}</OutlineButton>
          )}
        </div>
      </div>
    </div>
  );
};
