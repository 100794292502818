import React from "react";
import {
  useAccountPricingPlanQuery,
  usePricingPlansQuery,
  useSelectPricingPlanForAccountMutation
} from "../../../graphql/generated";
import FullPageLoader from "../../shared/loader";
import PricingPlanListing, {
  CustomPricingPlanBanner,
  CustomPricingPlanListing,
  TrialPricingPlanListing
} from "../pricing-plan-listing";
import classNames from "classnames";
import { css } from "emotion";
import Modal from "../../../components/atoms/modal";
import { useTheme } from "../../../theme";
import { useEnterpriseContextualState } from "../../../components/templates/enterprise";

export const NO_PLAN_ID = 'no-plan'

export interface PricingPlansListProps {
  isSelected?: { plan: string; trial?: boolean; custom?: boolean; } | null;
  onPlanSelect(id: string, trial: boolean): void;

  className?: string;
}

export const PricingPlansList: React.FC<PricingPlansListProps> = props => {
  const { data, loading } = usePricingPlansQuery();
  const selected = props.isSelected;

  if (!data || loading) return <FullPageLoader />;

  const plans = [...data.pricingPlans.results].sort((a, b) => (a.monthlyPrice ?? -1) - (b.monthlyPrice ?? -1));

  const onSelect = async (planId: string, trial: boolean) => {
    console.log(`Pricing Plan Selected: ${planId}, ${trial ? 'trial' : 'not trial'}`)

    props.onPlanSelect(planId, trial)
  }

  return (
    <div>
      <div className={"flex items-stretch pa3"}>
        <TrialPricingPlanListing isSelected={selected ? selected.plan === NO_PLAN_ID : undefined} onSelect={() => {
          onSelect(NO_PLAN_ID, false);
        }} className={"mh2"} />
        {plans.map(pricingPlan => (
          <PricingPlanListing key={pricingPlan.id} id={pricingPlan.id} isSelected={selected ? (selected.plan === pricingPlan.id ? { trial: Boolean(selected.trial) } : false) : undefined } onSelect={trial => {
            onSelect(pricingPlan.id, trial);
          }} className={"mh2"} />
        ))}
        <CustomPricingPlanListing isSelected={selected ? selected.custom : undefined} className={classNames("mh2", css({
          display: "none",
          [`@media (min-width: ${!selected ? 1480 : 1200}px)`]: {
            display: "block"
          }
        }))} />
      </div>
      <div className={classNames("pa3", css({
        display: "block",
        [`@media (min-width: ${!selected ? 1480 : 1200}px)`]: {
          display: "none"
        }
      }))}>
        <CustomPricingPlanBanner className={"mh2"} />
      </div>
      {/*
        <div className={"flex flex-column items-center"}>
          <div className={"bg-vocalid-tile pv4 ph5"}>
            <table className={classNames('mw-100 vocalid-secondary-text ', css({
              borderSpacing: 0,
              '&, & th, & td': {
                border: "1px solid transparent"
              }
            }))}>
              <thead>
                <tr className={'ttu vocalid-h3'}>
                  <td className={'v-mid ph2 pb3 pr3'}>Feature</td>
                  {plans.map(pricingPlan => (
                    <td className={'v-mid ph2 pb3 tc vocalid-primary-text'} key={pricingPlan.id}>{pricingPlan.name}</td>
                  ))}
                </tr>
              </thead>
              <tbody>
                { /* FIXME *!/
                {[...planListingSample.allFeatures, ...planListingSample.allFeatures].reduce<typeof planListingSample.allFeatures>((p, c) => {
                  if (!p.some(el => el.feature === c.feature)) p.push(c);
                  return p;
                }, []).map(feature => (
                  <tr key={feature.feature}>
                    <td className={'v-mid pv2 pl2 pr6'}>
                      <h4 className={"vocalid-h3 mt0 mb1"}>{feature.feature}</h4>
                      <div className={"vocalid-tertiary-text"}>{feature.description}</div>
                    </td>
                    {plans.map(pricingPlan => {
                      // FIXME
                      const planFeature = planListingSample.allFeatures.find(f => f.feature === feature.feature)

                      return (
                        <td key={pricingPlan.id} className={"v-mid pv2 ph4 tc vocalid-h3"}>{planFeature ?
                          planFeature.value === true ? <Icon name={EIconName.Checkmark} color={themeColors.primaryText} size={20} className={"ml1"} /> : planFeature.value === false ? <Icon name={EIconName.X} size={30} /> : planFeature.value
                          :"N/A"}</td>
                      );
                    })}
                  </tr>
                ))}
                <tr>
                  <td />
                  {plans.map(pricingPlan => (
                    <td key={pricingPlan.id} className={"ph2"}><PrimaryButton size={"small"} className={"w-100"} onClick={async () => {
                      console.log("CREATING PAYMENT SESSION")
                      const priceId = pricingPlan.paymentInfo?.prices?.[0]?.id!;

                      const result = await createPricingPlanPaymentSession({
                        variables: {
                          pricingPlan: pricingPlan.id,
                          price: priceId
                        }
                      });

                      const url = result.data?.createPricingPlanPaymentSession.url!
                      window.location.href = url;
                    }}>Select</PrimaryButton></td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        */}
    </div>
  );
};

interface PricingPlansListModalProps {
  isOpen: boolean;
  selected?: { plan: string; trial?: boolean; custom?: boolean; } | null;
  onPlanSelect?(planId: string, trial: boolean): void;
  onClose?(): void;
  className?: string;
}

export const PricingPlansListModal: React.FC<PricingPlansListModalProps> = props => {
  const { colors: themeColors } = useTheme()

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} containerClassName={css({
      backgroundColor: themeColors.page,
      borderRadius: 6,
      boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.5)'
    })}
     className={classNames(
       css({
         paddingTop: 35,
         paddingLeft: 55,
         paddingRight: 55,
         paddingBottom: 35,
         minHeight: 0,
         minWidth: 400
       }),
       props.className
     )}>
      <PricingPlansList isSelected={props.selected} onPlanSelect={(planId, trial) => {
        props.onPlanSelect?.(planId, trial);
      }} />
    </Modal>
  );
};
