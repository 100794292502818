import React from 'react';
import classNames from 'classnames';
import { css } from 'emotion';
import { useTheme } from '../../../theme';

export interface ISharedButtonProps {
  onClick?(): void;
  className?: string;
  style?: React.CSSProperties;
}

// button implementations

export interface IPrimaryButtonProps extends ISharedButtonProps {
  disabled?: boolean;
  size?: 'small' | 'medium';
}

export const PrimaryButton: React.FC<IPrimaryButtonProps> = props => {
  const { colors: themeColors } = useTheme();
  const bgColor = !props.disabled ? themeColors.primaryButton : themeColors.iconInactive;
  const textColor = !props.disabled ? themeColors.primaryButtonText : themeColors.secondaryText;

  const size = props.size ?? "medium";

  return (
    <button
      onClick={() => !props.disabled && props.onClick?.()}
      className={classNames(
        css({
          borderWidth: size !== 'small' ? 2 : 1,
          padding: size !== 'small' ? '10px 30px' : '8px 24px',
          fontSize: size !== 'small' ? 18 : 14,
          lineHeight: size !== 'small' ? '21px' : '16px',
          fontWeight: size !== 'small' ? 500 : 'normal',

          borderStyle: 'solid',
          borderRadius: 4,
          cursor: 'pointer',
          textTransform: 'uppercase',
          fontFamily: '"Roboto", sans-serif',

          borderColor: bgColor,
          backgroundColor: bgColor,
          color: textColor
        }),
        props.className
      )}
      style={props.style}
    >
      {props.children}
    </button>
  );
};

export interface IOutlineButtonProps extends ISharedButtonProps {
  size?: 'small' | 'medium';
  disabled?: boolean;
  dark?: boolean;
  color?: string;
}

export const OutlineButton: React.FC<IOutlineButtonProps> = props => {
  const { colors: themeColors } = useTheme();
  const color = props.color ?? (!props.dark ? themeColors.primaryText : themeColors.darkText);

  const size = props.size ?? "small";

  return (
    <button
      onClick={() => !props.disabled && props.onClick?.()}
      className={classNames(
        css({
          borderWidth: size !== 'small' ? 2 : 1,
          padding: size !== 'small' ? '10px 30px' : '8px 24px',
          fontSize: size !== 'small' ? 18 : 14,
          lineHeight: size !== 'small' ? '21px' : '16px',
          fontWeight: size !== 'small' ? 500 : 'normal',

          borderStyle: 'solid',
          borderRadius: 4,
          cursor: 'pointer',
          textTransform: 'uppercase',
          fontFamily: '"Roboto", sans-serif',

          borderColor: color,
          backgroundColor: 'transparent',
          color: color,
          opacity: props.disabled ? 0.5 : 1
        }),
        props.className
      )}
      style={props.style}
    >
      {props.children}
    </button>
  );
};

export interface ITextButtonProps extends ISharedButtonProps {
  dark?: boolean;
  disabled?: boolean;
  notUppercase?: boolean;
  noHorizontalPadding?: boolean;
}

export const TextButton: React.FC<ITextButtonProps> = props => {
  const { colors: themeColors } = useTheme();
  const color = !props.disabled ? (!props.dark ? themeColors.link : themeColors.darkText) : themeColors.iconInactive;

  return (
    <button
      onClick={() => !props.disabled && props.onClick?.()}
      className={classNames(
        css({
          borderWidth: 2,
          borderStyle: 'solid',
          padding: `5px ${props.noHorizontalPadding ? '0px' : '15px'}`,
          borderRadius: 4,
          cursor: !props.disabled ? 'pointer' : 'initial',
          textTransform: !props.notUppercase ? 'uppercase' : undefined,
          fontSize: 14,
          lineHeight: '16px',
          fontFamily: '"Roboto", sans-serif',
          fontWeight: 'normal',

          borderColor: 'transparent',
          backgroundColor: 'transparent',
          color: color
        }),
        props.className
      )}
      style={props.style}
    >
      {props.children}
    </button>
  );
};
