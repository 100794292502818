import React from 'react';
import classNames from 'classnames';
import Icon, { EIconName } from '../../atoms/icon';
import { css } from 'emotion';

export interface ISearchBarProps {
  searchQuery: string | null;
  onSearch(query: string | null): void;
  placeholder?: string;
  className?: string;
}

const SearchBar: React.FC<ISearchBarProps> = (props) => {
  return (
    <div className={classNames('relative db', props.className)}>
      <Icon
        name={EIconName.Search}
        color={'secondaryText'}
        size={20}
        className={classNames(
          css({
            top: 10,
            left: 10
          }),
          'absolute top-0 left-0'
        )}
      />
      <input
        placeholder={props.placeholder ?? 'Search'}
        value={props.searchQuery ?? ''}
        onChange={({ target: { value } }) => {
          const newQuery = value !== '' ? value : null;
          props.onSearch(newQuery);
        }}
        className={classNames(
          css({
            fontSize: 14,
            paddingLeft: 35,
            backgroundColor: '#376071',
            '&::placeholder': {
              color: 'inherit',
              opacity: 0.6
            }
          }),
          'db vocalid-secondary-text pv2 pr2 w-100 ba b--vocalid-secondary-text'
        )}
        style={{
          borderRadius: 4,
          minHeight: 40
        }}
      />
    </div>
  );
};

export default SearchBar;
