import { gql, useQuery } from "@apollo/client";
import { PaginationInput, TalentQuery, TalentQueryVariables } from "../generated";
import { useEnterpriseContextualState } from "../../components/templates/enterprise";

const query = gql`
query TalentQuery(
  $accountId: String!,
  $pagination: PaginationInput!
) {
  account(where: { id: $accountId }) {
    id
    talentEntitlements(input: { where: { hasAssignments: true }, pagination: $pagination }) {
      totalResults
      results {
        id
        ...EnterpriseTalentFragment
      }
    }
  }
}

fragment EnterpriseTalentFragment on Talent {
  id
  firstName
  lastName
  age
  gender
  ethnicIdentity
  pronouns
  memberOfUnion
  desiredProjectCategories
  hiredProjectCategories
  brandConflicts
  otherJobInfo
  limitations

  assignments {
    id
    created
    totalRecordingsCount(includeErrorLines: false)
    contentLines(includeErrorLines: false)
    voice {
      id
      title
    }
    isTalentRecordingRequestTarget
  }
}
`

export const useTalentQuery = (pagination: PaginationInput) => {
  const enterprise = useEnterpriseContextualState()

  return useQuery<TalentQuery, TalentQueryVariables>(
    query,
    { variables: { accountId: enterprise.state.selectedAccount!, pagination },
    skip: !enterprise.state.selectedAccount}
  )
}
