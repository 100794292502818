import React from "react";
import classNames from "classnames";

interface GlobalTemplateProps {
  className?: string;
  contentClassName?: string;
}

const GlobalTemplate: React.FC<GlobalTemplateProps> = props => {
  return (
    <div className={classNames("flex flex-column h-100 bg-vocalid-page vocalid-body", props.className)}>
      <div className={"relative z-999"}></div>
      <div className={classNames("flex-auto flex flex-column", props.contentClassName)}>
        {props.children}
      </div>
    </div>
  );
};

export default GlobalTemplate;
