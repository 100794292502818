import React, { EventHandler, MouseEvent } from 'react';
import {
  FaFilter,
  FaUndoAlt,
  FiChevronDown,
  FiChevronUp,
  IoMdArrowBack,
  IoMdArrowForward,
  IoMdClose,
  IoMdDownload,
  IoMdPause,
  IoMdPlay,
  IoMdSearch,
  IoMdThumbsDown,
  IoMdThumbsUp,
  IoMdArrowDropdown,
  MdDelete,
  MdHelp,
  IoIosSquare,
  BsChevronLeft,
  BsChevronRight,
  AiFillStar,
  MdModeComment, IoIosWarning, MdEdit, MdContentCopy, MdSort, MdPhoneInTalk, RiMovie2Fill, MdLiveTv, VscRadioTower, MdRecordVoiceOver, RiBook3Fill, MdGamepad, MdDirectionsRun, MdMonochromePhotos, RiGameFill, GoFileBinary
} from "react-icons/all";
import { IconType as ReactIconsType } from 'react-icons';
import { useTheme } from '../../../theme';
import classNames from 'classnames';
import { css } from 'emotion';
import { ReactComponent as TagsSVG } from './svg/tags.svg';
import { ReactComponent as PlaylistSVG } from './svg/playlist.svg';
import { ReactComponent as MixSVG } from './svg/mix.svg';
import { ReactComponent as DictionarySVG } from './svg/dictionary.svg';
import { ReactComponent as AddToPlaylistSVG } from './svg/add-to-playlist.svg';
import { ReactComponent as AddToMixSVG } from './svg/add-to-mix.svg';
import { ReactComponent as GrabDotsSVG } from './svg/grab-dots.svg';
import { ReactComponent as AutoplaySVG } from './svg/autoplay.svg';
import { ReactComponent as CascadeListSVG } from './svg/cascade-list.svg';
import { ReactComponent as EyeSVG } from './svg/eye.svg';
import { ReactComponent as AdjustmentsBadgeSVG } from './svg/adjustments-badge.svg';
import { ReactComponent as CheckmarkSVG } from './svg/checkmark.svg';
import { ReactComponent as SlidersSVG } from './svg/sliders.svg';
import { ReactComponent as MinusSVG } from './svg/minus.svg';
import { ReactComponent as PlusSVG } from './svg/plus.svg';
import { ReactComponent as CompactModeSolidSVG } from './svg/compact-mode-solid.svg';
import { ReactComponent as CompactModeOutlineSVG } from './svg/compact-mode-outline.svg';
import { ReactComponent as ExpandedModeSolidSVG } from './svg/expanded-mode-solid.svg';
import { ReactComponent as ExpandedModeOutlineSVG } from './svg/expanded-mode-outline.svg';
import { ReactComponent as MdReportSolidSVG } from './svg/md_report_solid.svg';
import { ReactComponent as MdReportOutlineSVG } from './svg/md_report_outline.svg';
import { ReactComponent as TempoSlowSVG } from './svg/tempo-slow.svg';
import { ReactComponent as TempoMidSVG } from './svg/tempo-mid.svg';
import { ReactComponent as TempoFastSVG } from './svg/tempo-fast.svg';

export enum EIconName {
  Approve,
  Reject,
  Download,
  Delete,
  Search,
  Tags,
  DropdownArrowDown,
  DropdownArrowUp,
  FilledArrowDown,
  ArrowLeft,
  ArrowRight,
  ThinChevronLeft,
  ThinChevronRight,
  X,
  Sliders,
  Play,
  Pause,
  Stop,
  Playlist,
  Mix,
  Dictionary,
  AddToPlaylist,
  AddToMix,
  GrabDots,
  Autoplay,
  Plus,
  Minus,
  CascadeList,
  Eye,
  Info,
  AdjustmentsBadge,
  Checkmark,
  CompactModeSolid,
  CompactModeOutline,
  ExpandedModeSolid,
  ExpandedModeOutline,
  Filter,
  Sort,
  Undo,
  Star,
  Comments,
  Warning,
  Edit,
  ReportInactive,
  ReportActive,
  Copy,
  TempoSlow,
  TempoMid,
  TempoFast,
  Phone,
  Movie,
  TV,
  Radio,
  Dubbing,
  Book,
  Animation,
  Promo,
  VideoGames,
  Games,
  Digital
}

export interface IIconProps {
  name: EIconName;
  color?: string;
  hoverColor?: string;
  activeColor?: string;

  active?: boolean;
  size?: number;

  children?: undefined;

  onClick?: EventHandler<MouseEvent<SVGElement>>;
  className?: string;
  style?: React.CSSProperties;
}

const Icon: React.FC<IIconProps> = (props) => {
  const { colors: themeColors } = useTheme();
  const iconColor = props.color
    ? (themeColors as any)[props.color] ?? props.color
    : themeColors.iconInactive;
  const activeColor = props.activeColor
    ? (themeColors as any)[props.activeColor] ?? props.activeColor
    : themeColors.iconActive;
  const hoverColor = props.hoverColor
    ? (themeColors as any)[props.hoverColor] ?? props.hoverColor
    : props.active || props.onClick
    ? activeColor
    : iconColor;

  let iconDef:
    | ['react-icons', ReactIconsType, boolean?]
    | ['svg', React.ComponentType<React.SVGProps<SVGSVGElement>>];

  switch (props.name) {
    case EIconName.Approve:
      iconDef = ['react-icons', IoMdThumbsUp];
      break;
    case EIconName.Reject:
      iconDef = ['react-icons', IoMdThumbsDown];
      break;
    case EIconName.Download:
      iconDef = ['react-icons', IoMdDownload];
      break;
    case EIconName.Delete:
      iconDef = ['react-icons', MdDelete];
      break;
    case EIconName.Search:
      iconDef = ['react-icons', IoMdSearch];
      break;
    case EIconName.Tags:
      iconDef = ['svg', TagsSVG];
      break;
    case EIconName.DropdownArrowDown:
      iconDef = ['react-icons', FiChevronDown, true];
      break;
    case EIconName.DropdownArrowUp:
      iconDef = ['react-icons', FiChevronUp, true];
      break;
    case EIconName.FilledArrowDown:
      iconDef = ['react-icons', IoMdArrowDropdown];
      break;
    case EIconName.ArrowLeft:
      iconDef = ['react-icons', IoMdArrowBack];
      break;
    case EIconName.ArrowRight:
      iconDef = ['react-icons', IoMdArrowForward];
      break;
    case EIconName.ThinChevronLeft:
      iconDef = ['react-icons', BsChevronLeft];
      break;
    case EIconName.ThinChevronRight:
      iconDef = ['react-icons', BsChevronRight];
      break;
    case EIconName.X:
      iconDef = ['react-icons', IoMdClose];
      break;
    case EIconName.Sliders:
      iconDef = ['svg', SlidersSVG];
      break;
    case EIconName.Play:
      iconDef = ['react-icons', IoMdPlay];
      break;
    case EIconName.Pause:
      iconDef = ['react-icons', IoMdPause];
      break;
    case EIconName.Stop:
      iconDef = ['react-icons', IoIosSquare];
      break;
    case EIconName.Playlist:
      iconDef = ['svg', PlaylistSVG];
      break;
    case EIconName.Mix:
      iconDef = ['svg', MixSVG];
      break;
    case EIconName.Dictionary:
      iconDef = ['svg', DictionarySVG];
      break;
    case EIconName.AddToPlaylist:
      iconDef = ['svg', AddToPlaylistSVG];
      break;
    case EIconName.AddToMix:
      iconDef = ['svg', AddToMixSVG];
      break;
    case EIconName.GrabDots:
      iconDef = ['svg', GrabDotsSVG];
      break;
    case EIconName.Autoplay:
      iconDef = ['svg', AutoplaySVG];
      break;
    case EIconName.Plus:
      iconDef = ['svg', PlusSVG];
      break;
    case EIconName.Minus:
      iconDef = ['svg', MinusSVG];
      break;
    case EIconName.CascadeList:
      iconDef = ['svg', CascadeListSVG];
      break;
    case EIconName.Eye:
      iconDef = ['svg', EyeSVG];
      break;
    case EIconName.Info:
      iconDef = ['react-icons', MdHelp];
      break;
    case EIconName.AdjustmentsBadge:
      iconDef = ['svg', AdjustmentsBadgeSVG];
      break;
    case EIconName.Checkmark:
      iconDef = ['svg', CheckmarkSVG];
      break;
    case EIconName.CompactModeSolid:
      iconDef = ['svg', CompactModeSolidSVG];
      break;
    case EIconName.CompactModeOutline:
      iconDef = ['svg', CompactModeOutlineSVG];
      break;
    case EIconName.ExpandedModeSolid:
      iconDef = ['svg', ExpandedModeSolidSVG];
      break;
    case EIconName.ExpandedModeOutline:
      iconDef = ['svg', ExpandedModeOutlineSVG];
      break;
    case EIconName.Filter:
      iconDef = ['react-icons', FaFilter];
      break;
    case EIconName.Sort:
      iconDef = ['react-icons', MdSort];
      break;
    case EIconName.Undo:
      iconDef = ['react-icons', FaUndoAlt];
      break;
    case EIconName.Star:
      iconDef = ['react-icons', AiFillStar];
      break;
    case EIconName.Comments:
      iconDef = ['react-icons', MdModeComment];
      break;
    case EIconName.Warning:
      iconDef = ['react-icons', IoIosWarning];
      break;
    case EIconName.Edit:
      iconDef = ['react-icons', MdEdit];
      break;
    case EIconName.ReportInactive:
      iconDef = ['svg', MdReportOutlineSVG];
      break;
    case EIconName.ReportActive:
      iconDef = ['svg', MdReportSolidSVG];
      break;
    case EIconName.Copy:
      iconDef = ['react-icons', MdContentCopy];
      break;
    case EIconName.TempoSlow:
      iconDef = ['svg', TempoSlowSVG];
      break;
    case EIconName.TempoMid:
      iconDef = ['svg', TempoMidSVG];
      break;
    case EIconName.TempoFast:
      iconDef = ['svg', TempoFastSVG];
      break;
    case EIconName.Phone:
      iconDef = ['react-icons', MdPhoneInTalk];
      break;
    case EIconName.Movie:
      iconDef = ['react-icons', RiMovie2Fill];
      break;
    case EIconName.TV:
      iconDef = ['react-icons', MdLiveTv];
      break;
    case EIconName.Radio:
      iconDef = ['react-icons', VscRadioTower];
      break;
    case EIconName.Dubbing:
      iconDef = ['react-icons', MdRecordVoiceOver];
      break;
    case EIconName.Book:
      iconDef = ['react-icons', RiBook3Fill];
      break;
    case EIconName.Animation:
      iconDef = ['react-icons', MdDirectionsRun];
      break;
    case EIconName.Promo:
      iconDef = ['react-icons', MdMonochromePhotos];
      break;
    case EIconName.VideoGames:
      iconDef = ['react-icons', MdGamepad];
      break;
    case EIconName.Games:
      iconDef = ['react-icons', RiGameFill];
      break;
    case EIconName.Digital:
      iconDef = ['react-icons', GoFileBinary];
      break;
  }

  const [iconType, IconComponent, noFill] = iconDef;

  const color = !props.active ? iconColor : activeColor;
  const size = props.size ?? 25;

  if (iconType === 'react-icons') {
    return (
      <IconComponent
        onClick={props.onClick}
        color={color}
        size={size}
        className={classNames(
          css({
            boxSizing: 'content-box'
          }),
          css({
            cursor: props.onClick && 'pointer',
            '&:hover': {
              color: hoverColor,
              fill: !noFill && hoverColor
            }
          }),
          props.className
        )}
        style={props.style}
      />
    );
  } else if (iconType === 'svg') {
    const computedSize = size || '1em';

    return (
      <IconComponent
        onClick={props.onClick}
        height={computedSize}
        width={computedSize}
        className={classNames(
          css({
            boxSizing: 'content-box'
          }),
          css({
            color
          }),
          css({
            cursor: props.onClick && 'pointer',
            '&:hover': {
              color: hoverColor
            }
          }),
          props.className
        )}
        style={props.style}
      />
    );
  } else {
    return null;
  }
};

export default Icon;
