import React from "react";
import { isEmptySlateValue, ISlateValue, slateValueAsText } from "./adjustments/internal-types";

export interface ITextEditorProps {
  value: ISlateValue;
}

const TextEditor: React.FC<ITextEditorProps> = props => {
  return (
    <div style={{ whiteSpace: "pre-line" }}>
      {
        !isEmptySlateValue(props.value) ?
        slateValueAsText(props.value) :
        <span className={"vocalid-tertiary-text"}>Type here to start making a clip</span>
      }
    </div>
  );
};

export default TextEditor
