import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import EnterpriseDashboardPageContainer from './containers/pages/enterprise-dashboard';
import TalentDashboardPageContainer from './containers/pages/talent-dashboard';
import EnterpriseProjectPageContainer from './containers/pages/enterprise-project';
import EnterpriseTemplateContainer from './containers/templates/enterprise';
import EnterpriseProjectsPageContainer from './containers/pages/enterprise-projects';
import TalentTemplateContainer from './containers/templates/talent';
import EnterpriseVocalsPageContainer from './containers/pages/enterprise-vocals';
import EnterpriseTalentPageContainer from './containers/pages/enterprise-talent';
import EnterpriseAuthTemplate from './components/templates/enterprise-auth';
import EnterpriseSignedOutPageContainer from './containers/pages/enterprise-signed-out';
import EnterpriseRecordingsReviewPageContainer from './containers/pages/enterprise-recordings-review';
import EnterpriseProfilePageContainer from './containers/pages/enterprise-profile';
import TalentProfilePageContainer from './containers/pages/talent-profile';
import { GraphQLProvider } from './graphql';
import TalentSignedOutPageContainer from "./containers/pages/talent-signed-out";
import TalentAuthTemplate from "./components/templates/talent-auth";
import LandingPage from "./components/pages/landing";
import { AuthCallback, AuthProvider } from "./auth";
import PublicPricingPlansPage from "./containers/pages/pricing-plans-page";
import MarketingTemplateContainer from "./containers/templates/marketing";
import CreateUserAccountPage from "./containers/pages/registration/user-account-creation";
import EnterpriseAccountPageContainer from "./containers/pages/enterprise-account";
import PricingPlanSelectCompletePage from "./containers/pages/enterprise-plan-select-complete";
import GlobalTemplate from './containers/templates/global';

const LoggedInProviders: React.FC = props => (
  <GraphQLProvider requireAuth>
    <QueryParamProvider ReactRouterRoute={Route}>
      {props.children}
    </QueryParamProvider>
  </GraphQLProvider>
)

const OptionalViewerProviders: React.FC = props => (
  <GraphQLProvider requireAuth={false}>
    <QueryParamProvider ReactRouterRoute={Route}>
      {props.children}
    </QueryParamProvider>
  </GraphQLProvider>
)

const Routes: React.FC = () => (
  <Router>
    <AuthProvider>
      <Switch>
        <Route exact path={'/'}>
          <GlobalTemplate>
            <OptionalViewerProviders>
              <MarketingTemplateContainer hideBorder>
                <LandingPage />
              </MarketingTemplateContainer>
            </OptionalViewerProviders>
          </GlobalTemplate>
        </Route>
        <Route exact path={'/cb'}>
          <AuthCallback />
        </Route>
        <Route exact path={'/talent/signed-out'}>
          <TalentAuthTemplate>
            <TalentSignedOutPageContainer />
          </TalentAuthTemplate>
        </Route>

        <Route path={'/talent*'}>
          <GlobalTemplate>
            <LoggedInProviders>
              <TalentTemplateContainer>
                <Switch>
                  <Route exact path={'/talent'} component={TalentDashboardPageContainer} />
                  <Route exact path={'/talent/profile'} component={TalentProfilePageContainer} />
                  <Redirect to={'/talent'} />
                </Switch>
              </TalentTemplateContainer>
            </LoggedInProviders>
          </GlobalTemplate>
        </Route>

        <Route exact path={'/enterprise/signed-out'}>
          <EnterpriseAuthTemplate>
            <EnterpriseSignedOutPageContainer />
          </EnterpriseAuthTemplate>
        </Route>

        <Route path={'/enterprise*'}>
          <GlobalTemplate>
            <LoggedInProviders>
              <EnterpriseTemplateContainer exemptedRoutesFromAllFeatureLock={[
                  "/enterprise/profile",
                  "/enterprise/account"
              ]}>
                <Switch>
                  <Route exact path={'/enterprise'} component={EnterpriseDashboardPageContainer} />
                  <Route
                    exact
                    path={'/enterprise/projects/:target?'}
                    component={EnterpriseProjectsPageContainer}
                  />
                  <Route
                    exact
                    path={'/enterprise/project/:id'}
                    component={EnterpriseProjectPageContainer}
                  />
                  <Route exact path={'/enterprise/talent'} component={EnterpriseTalentPageContainer} />
                  <Route
                    exact
                    path={'/enterprise/assignment/:id'}
                    component={EnterpriseRecordingsReviewPageContainer}
                  />
                  <Route exact path={'/enterprise/voices'} component={EnterpriseVocalsPageContainer} />
                  <Route
                    exact
                    path={'/enterprise/profile'}
                    component={EnterpriseProfilePageContainer}
                  />
                  <Route
                    exact
                    path={'/enterprise/account'}
                    component={EnterpriseAccountPageContainer}
                  />
                  <Route exact path={'/enterprise/plan-selected'}>
                    <PricingPlanSelectCompletePage />
                  </Route>
                  <Redirect to={'/enterprise'} />
                </Switch>
              </EnterpriseTemplateContainer>
            </LoggedInProviders>
          </GlobalTemplate>
        </Route>
        <Route path={'/pricing*'}>
          <OptionalViewerProviders>
            <MarketingTemplateContainer>
              <Switch>
                <Route exact path={'/pricing'}>
                  <PublicPricingPlansPage />
                </Route>
                <Redirect to={'/pricing'} />
              </Switch>
            </MarketingTemplateContainer>
          </OptionalViewerProviders>
        </Route>
        <Route exact path={'/create-account'}>
          <GlobalTemplate>
            <OptionalViewerProviders>
              <MarketingTemplateContainer>
                <CreateUserAccountPage />
              </MarketingTemplateContainer>
            </OptionalViewerProviders>
          </GlobalTemplate>
        </Route>
        <Redirect to='/' />
      </Switch>
    </AuthProvider>
  </Router>
);

const App: React.FC = () => {
  return <Routes />;
};

export default App;
