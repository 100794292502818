import React from "react";
import Tooltip from "../../atoms/tooltip";
import Tag from "../../atoms/tag";
import { colorForProjectType } from "../../../containers/organisms/voice-select";
import classNames from "classnames";

interface ProjectTypeTagProps {
  projectType: {
    id: string;
    title: string;
    description?: string;
  }
  className?: string;
}

const ProjectTypeTag: React.FC<ProjectTypeTagProps> = props => {
  return (
    <span className={classNames("dib", props.className)}><Tooltip disabled={props.projectType.description === ""} tooltipContent={props.projectType.description}><Tag color={colorForProjectType(props.projectType.title)} textColor={"black"}><span className={"dib pv1"}>{props.projectType.title}</span></Tag></Tooltip></span>
  );
};

export default ProjectTypeTag;
