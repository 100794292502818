import React from "react";
import Dialog from "../../../components/molecules/dialog";
import { PrimaryButton, TextButton } from "../../../components/atoms/button";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";
import { css } from "emotion";
import { useTheme } from "../../../theme";
import { uniqBy } from "lodash";
import {
  CreateRecordingSessionContentLineItemMutation,
  CreateRecordingSessionContentLineItemMutationDocument,
  CreateRecordingSessionContentLineItemMutationVariables,
  CreateRecordingSessionMutation,
  CreateRecordingSessionMutationDocument,
  CreateRecordingSessionMutationVariables,
  UpdateRecordingsSessionContentLineItemMutationDocument,
  UpdateRecordingsSessionContentLineItemMutation,
  UpdateRecordingsSessionContentLineItemMutationVariables
} from "../../../graphql/generated";
import { ApolloClient, useApolloClient } from "@apollo/client";
import AudioPlayer from "../../../components/molecules/audio-player";
import Checkbox from "../../../components/atoms/checkbox";
import Dropdown, { DropdownOption } from "../../../components/molecules/dropdown";
import PromisePool from "@supercharge/promise-pool";
import Icon, { EIconName } from "../../../components/atoms/icon";
import { Loader } from "../../shared/loader";

export interface IUploadTrainingDataDialogProps {
  isShown: boolean;
  assignmentId: string | null;

  onDismiss(): void;

  onUploaded?(): void;

  className?: string;
}

const UploadTrainingDataDialog: React.FC<IUploadTrainingDataDialogProps> = (props) => {
  const { colors: themeColors } = useTheme();

  const apolloClient = useApolloClient();

  const [uploading, setUploading] = React.useState(false);

  // const [acceptedFiles, setAcceptedFiles] = React.useState<File[]>([])
  const [mappings, setMappings] = React.useState<TranscriptFileMap[]>([]);

  const [selectedMappings, setSelectedMappings] = React.useState<string[]>([]);

  const [mappingsState, setMappingsState] = React.useState<{ [name: string]: MappingUploadState }>({});

  const getState = (mapping: TranscriptFileMap): MappingUploadState => {
    return mappingsState[mapping.audioFile.name] ?? MappingUploadState.NotStarted;
  };

  const clearState = () => {
    // setAcceptedFiles([]);
    setMappings([]);
    setSelectedMappings([]);
    setMappingsState({});
    setUploading(false);
  };

  const onDrop = React.useCallback(newAcceptedFiles => {
    // setAcceptedFiles(o => [...o, ...newAcceptedFiles])
    mappingsFromFiles(newAcceptedFiles).then(newMappings => {
      setMappings(o => uniqBy([...o, ...newMappings], e => e.audioFile.name));
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ accept: ["audio/wav", "text/plain"], multiple: true, onDrop });

  const selectedFilesTable = mappings.length !== 0 ? (
    <div>
      <div className={"flex justify-between mb4"}>
        <div>
          <Dropdown
            label={"Bulk Action..."}
            className={"mr3"}
            menuColor={"iconInactive"}
            color={"darkText"}
            menuClassName={css({ minWidth: 200 })}
            roundedCorners
            disabled={selectedMappings.length === 0}
          >
            <DropdownOption onSelect={() => {
              setMappings(o => o.filter(e => !selectedMappings.includes(e.audioFile.name)));
            }}>
              Remove from import
            </DropdownOption>
          </Dropdown>
        </div>
        <div>
          <span>{mappings.length} Recording{mappings.length !== 1 ? "s" : ""}</span>
        </div>
      </div>
      <table className={classNames("w-100 mb3", css({
        display: 'block',
        maxHeight: '300px',
        overflowY: 'auto',
        borderSpacing: 0,
        "& td": {
          verticalAlign: "top"
        }
      }))}>
        <thead>
        <tr className={"ttu vocalid-h3"}>
          <td className={"ph2 tc"}>
          </td>
          <td className={"pb3"} style={{ width: "50%" }}>Audio File</td>
          <td></td>
          <td className={"pb3"} style={{ width: "50%" }}>Transcript</td>
        </tr>
        </thead>
        <tbody>
        {mappings.map((mapping) => {
          const state = getState(mapping);

          return (
            <tr key={mapping.audioFile.name}>
              <td className={"pa2 tc"}>
                {state === MappingUploadState.NotStarted ?
                  <Checkbox
                    checked={selectedMappings.includes(mapping.audioFile.name)}
                    onChange={({ target }) => {
                      const checkbox = target as HTMLInputElement;
                      const checked = checkbox.checked;

                      setSelectedMappings(o => {
                        if (checked) {
                          return [...new Set([...o, mapping.audioFile.name])];
                        } else {
                          return o.filter(e => e !== mapping.audioFile.name);
                        }
                      });
                    }}
                  /> : state === MappingUploadState.CompletedUpdate ?
                    <Icon name={EIconName.Checkmark} /> : state === MappingUploadState.Errored ?
                      <Icon name={EIconName.X} /> :
                      <Loader />
                }
              </td>
              <td>
                {mapping.audioFile.name}
              </td>
              <td>
                <AudioPlayer src={mapping.audioFile} buttonOnly />
              </td>
              <td>
                {mapping.transcript ?? "<<TO BE AUTO-TRANSCRIBED>>"}
              </td>
            </tr>
          );
        })}
        </tbody>
      </table>
    </div>
  ) : undefined;

  return (
    <Dialog
      isShown={props.isShown}
      heading={<div className={"mr5"}>Upload Training Data</div>}
      className={props.className}
      maxHeight={650}
    >
      <div className={"mb3"}>
        {selectedFilesTable && <div>
          {selectedFilesTable}
        </div>}
        <div {...getRootProps({
          className: classNames("br2 ba pv2 ph3 pointer", css({
            borderColor: "grey",
            outline: "none"
          }))
        })}>
          <input {...getInputProps()} />
          <p>Drag & drop your audio/transcript files here, or click to select them</p>
        </div>
      </div>
      <div className={"flex justify-end"}>
        <TextButton
          dark
          onClick={() => {
            props.onDismiss();
            clearState();
          }}
          className={"mr3"}
        >
          CANCEL
        </TextButton>
        <PrimaryButton
          disabled={mappings.length === 0 || uploading}
          onClick={async () => {
            // props.onSelected(acceptedFiles)
            setUploading(true);
            setSelectedMappings([]);
            await uploadTrainingData(apolloClient, props.assignmentId!, mappings, {
              onCreatedItem: mapping => setMappingsState(o => ({
                ...o,
                [mapping.audioFile.name]: MappingUploadState.CreatedItem
              })),
              onStartedUpload: mapping => setMappingsState(o => ({
                ...o,
                [mapping.audioFile.name]: MappingUploadState.StartedUpload
              })),
              onCompletedUpload: mapping => setMappingsState(o => ({
                ...o,
                [mapping.audioFile.name]: MappingUploadState.CompletedUpload
              })),
              onCompletedUpdate: mapping => setMappingsState(o => ({
                ...o,
                [mapping.audioFile.name]: MappingUploadState.CompletedUpdate
              })),
              onError: mapping => setMappingsState(o => ({
                ...o,
                [mapping.audioFile.name]: MappingUploadState.Errored
              }))
            });
            clearState();
            props.onUploaded?.();
            props.onDismiss();
          }}
        >
          UPLOAD
        </PrimaryButton>
      </div>
    </Dialog>
  );
};

export default UploadTrainingDataDialog;


interface TranscriptFileMap {
  transcript?: string;
  audioFile: File;
}

enum MappingUploadState {
  NotStarted,
  CreatedItem,
  StartedUpload,
  CompletedUpload,
  CompletedUpdate,
  Errored
}

async function mappingsFromFiles(files: File[]): Promise<TranscriptFileMap[]> {
  const mappings: TranscriptFileMap[] = [];

  for (const file of files) {
    if (file.type === "audio/wav") {
      const mapping: TranscriptFileMap = { audioFile: file };

      // find matching transcript
      const transcriptFile = files.find(f => f.type === "text/plain" && f.name === file.name.replace(".wav", ".txt"));
      if (transcriptFile) {
        mapping.transcript = await transcriptFile.text();
      }

      mappings.push(mapping);
    }
  }

  return mappings;
}

type UploadEvents = {
  onCreatedItem?(mapping: TranscriptFileMap): void;
  onStartedUpload?(mapping: TranscriptFileMap): void;
  onCompletedUpload?(mapping: TranscriptFileMap): void;
  onCompletedUpdate?(mapping: TranscriptFileMap): void;
  onError?(mapping: TranscriptFileMap, err: string): void;
}

async function uploadTrainingData(apolloClient: ApolloClient<any>, assignment: string, mappings: TranscriptFileMap[], events?: UploadEvents) {
  const createSession = await apolloClient.mutate<CreateRecordingSessionMutation, CreateRecordingSessionMutationVariables>({
    mutation: CreateRecordingSessionMutationDocument,
    variables: {
      input: {
        assignment
      }
    }
  });

  if (createSession.errors) {
    throw Error("Create session error");
  }

  const { createRecordingSession: { id: sessionId } } = createSession.data!;

  await PromisePool
    .withConcurrency(5)
    .for(mappings)
    .process(async mapping => {
      events?.onCreatedItem?.(mapping);

      const createLineItem = await apolloClient.mutate<CreateRecordingSessionContentLineItemMutation, CreateRecordingSessionContentLineItemMutationVariables>({
        mutation: CreateRecordingSessionContentLineItemMutationDocument,
        variables: {
          sessionId: sessionId,
          recordedContentLineText: mapping.transcript
        }
      });

      if (createLineItem.errors) {
        events?.onError?.(mapping, `Create line item err: ${createLineItem.errors}`);
        throw Error("Create line item error");
      }

      const { createRecordingSessionContentLineItem: { id: recordingId, uploadUrl } } = createLineItem.data!;

      if (!uploadUrl) {
        events?.onError?.(mapping, `No upload url received`);
        throw Error("No upload url received");
      }

      // this.log(`Uploading to ${createRecordingLineRes.uploadUrl}`)

      events?.onStartedUpload?.(mapping);

      const res = await fetch(uploadUrl, {
        method: "put",
        body: mapping.audioFile
      });

      if (res.status !== 200) {
        events?.onError?.(mapping, `Upload failed: Code ${res.status}, ${JSON.stringify(res)}`);
        throw new Error(`Upload failed: Code ${res.status}, ${JSON.stringify(res)}`);
      }

      events?.onCompletedUpload?.(mapping);

      // this.log('Upload successful');
      const parsedUploadUrl = new URL(uploadUrl);
      const uploadedUrl = parsedUploadUrl.origin + parsedUploadUrl.pathname;

      // this.log(`Uploaded file hash: ${uploadedFileMd5Hash}`)

      const updateLineItem = await apolloClient.mutate<UpdateRecordingsSessionContentLineItemMutation, UpdateRecordingsSessionContentLineItemMutationVariables>({
        mutation: UpdateRecordingsSessionContentLineItemMutationDocument,
        variables: {
          input: {
            recordingId: recordingId,
            audioUrl: uploadedUrl
          }
        }
      });

      if (updateLineItem.errors) {
        events?.onError?.(mapping, `Update line item err: ${updateLineItem.errors}`);
        throw Error("Update line item error");
      }

      events?.onCompletedUpdate?.(mapping);
    });
}