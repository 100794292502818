import {
  ESlateElementType,
  ISlateNode,
  ISlateSelection,
  ISlateValue,
  ISlateWordNode,
  SlateAdjustment
} from "../../organisms/clip/editor/adjustments/internal-types";
import { IClipVoiceAdjustmentsData } from "../../organisms/project-sidebar/tabs/vocal-adjustments";
import { Range } from "slate";
import { AdjustmentValueMap, EAdjustment, EMagnitude } from "../../organisms/clip/editor/adjustments";
import { SidebarSelectionData } from "../../organisms/project-sidebar";
import { singleWordInSelection } from "../../organisms/clip/editor/adjustments/selection";

export function applySidebarAdjustmentsToEditorValue(editorValue: ISlateNode[], selection: NonNullable<ISlateSelection>, adjustments: IClipVoiceAdjustmentsData): ISlateNode[] {
  const [{ path: [_, startNodeIndex], offset: startOffset }, { path: [__, endNodeIndex], offset: endOffset }] = Range.edges(selection);

  const [___, pausableWordIndex] = singleWordInSelection(editorValue, selection) ?? [];

  return editorValue.map((node, nodeIndex) => {
    const inSelection = nodeIndex >= startNodeIndex && nodeIndex <= endNodeIndex;

    if (!inSelection) {
      return node;
    }

    const isPausableNode = pausableWordIndex !== undefined && nodeIndex === pausableWordIndex
    const pause = isPausableNode ? adjustments["pause"] : undefined

    const newAdjustments = Object.keys(adjustments).map<SlateAdjustment<EAdjustment> | null>(key => {
      const adjKey = key as keyof IClipVoiceAdjustmentsData;

      if (adjustments[adjKey] == null) {
        return null;
      }

      const adjustment = adjustments[adjKey]!

      switch (adjKey) {
        case "speed":
          return { type: EAdjustment.SpeedAdjustment, value: adjustment as EMagnitude };
        case "pitch":
          return { type: EAdjustment.PitchAdjustment, value: adjustment as EMagnitude };
        case "loudness":
          return { type: EAdjustment.LoudnessAdjustment, value: adjustment as EMagnitude };
        case "tone":
          return { type: EAdjustment.AutoToneEffect, value: adjustment as any };
        case "wordStress":
          return { type: EAdjustment.WordStress, value: adjustment as EMagnitude };
        case "pause":
          return null;
        case "pauseInMilliseconds":
          return null;
        case "alternatePronunciation":
          return null;
      }
    }).filter(adj => !!adj) as SlateAdjustment<EAdjustment>[]

    return ({
      ...node,
      ...(node.type === ESlateElementType.Word ? ({
        pause: adjustments["pause"] === null ? node.pause : pause === 'none' ? undefined : pause !== null ? pause : undefined,
        pauseInMilliseconds: adjustments["pauseInMilliseconds"] === null ? node.pauseInMilliseconds : adjustments["pauseInMilliseconds"] === 'none' ? undefined : adjustments["pauseInMilliseconds"],
        alternatePronunciation: adjustments["alternatePronunciation"] ?? undefined
      }) : {}),
      // union of old adjustments & new adjustments, with new adjustments taking priority on collision
      adjustments: newAdjustments.reduce((acc, curr) => {
        const existingIndex = acc.findIndex(adj => adj.type === curr.type)

        if (existingIndex > -1) {
          const newArr = [...acc];
          newArr[existingIndex] = curr;

          return newArr;
        } else {
          return [...acc, curr];
        }
      }, node.adjustments ?? []).filter(adj => adj.value !== "none" && adj.value !== EMagnitude.Med)
    });
  });
}

export function sidebarAdjustmentsFromEditorValueSelection(editorValue: ISlateNode[] | null, selection: ISlateSelection, defaultPronunciationMap: { [word: string]: string[] }): SidebarSelectionData | null {
  if (editorValue && selection) {
    const [{ path: [_, startNodeIndex] }, { path: [__, endNodeIndex] }] = Range.edges(selection);

    const selectedNodes = editorValue.slice(startNodeIndex, endNodeIndex + 1);
    if (selectedNodes.length === 0) {
      return null;
    }

    const commonAdjustments = selectedNodes.reduce<SlateAdjustment<EAdjustment>[]>((acc, curr) => [...acc, ...(curr.adjustments ?? []).filter(adj => acc.includes(adj))], selectedNodes[0].adjustments ?? []);

    const [singleSelectedWord] = singleWordInSelection(editorValue, selection) ?? [];

    const commonAdjValue = <T extends EAdjustment>(type: T) => {
      return commonAdjustments.find(a => a.type === type)?.value as AdjustmentValueMap[T] ?? null;
    };

    return {
      voiceAdjustments: {
        loudness: commonAdjValue(EAdjustment.LoudnessAdjustment),
        pitch: commonAdjValue(EAdjustment.PitchAdjustment),
        speed: commonAdjValue(EAdjustment.SpeedAdjustment),
        tone: commonAdjValue(EAdjustment.AutoToneEffect),
        wordStress: commonAdjValue(EAdjustment.WordStress),
        pause: (singleSelectedWord?.pauseInMilliseconds === undefined && singleSelectedWord?.pause) || null,
        pauseInMilliseconds: singleSelectedWord?.pauseInMilliseconds ?? null,
        alternatePronunciation: singleSelectedWord?.alternatePronunciation ?? null,
      },
      singleSelectedWord: singleSelectedWord ? {
        text: singleSelectedWord.text,
        defaultPhoneticPronunciation: defaultPronunciationMap[singleSelectedWord.text]
      } :  null
    };
  } else {
    return null
  }
}

