import React from 'react';
import RecordingsCompletedChart from '../../molecules/chart/recordings-completed-chart';
import RecordingsReviewedChart from '../../molecules/chart/recordings-reviewed-chart';
import InfoTooltip from '../../molecules/info-tooltip';
import Icon, { EIconName } from '../../atoms/icon';
import RecordingMetadata, { IRecordingMetadataProps } from '../../molecules/recording-metadata';
import {OutlineButton} from "../../atoms/button";
import {Link} from "react-router-dom";
import { css } from "emotion";
import classNames from "classnames";

export interface IRecordingInProgressInfoProps {
  data: {
    metadata: IRecordingMetadataProps['metadata'] | null;
  };
  totalRecordingsCount: number;
  recordedErrorCount: number;
  reviewedRecordingsCount: number;
  contentLines: number;
  acceptedCount: number;
  rejectedCount: number;
  hideMetadata?: boolean;
  hideVocalCreationStatus?: boolean;
  hideReviewRecordingButton?: boolean;
  className?: string;
  dueInDays: number | null;
}

const RecordingInProgressInfo: React.FC<IRecordingInProgressInfoProps> = (props) => {
  return (
    <div className={props.className}>
      <div className={'flex mt2'}>
        <div className={'w-25'}>
          {props.data.metadata && !props.hideMetadata && (
            <RecordingMetadata metadata={props.data.metadata} />
          )}
        </div>
        <div className={'w-25'}>
          <h3 className={classNames('mv0 vocalid-h3 vocalid-secondary-text ttu tc ph2', css({
            height: 25
          }))}>
            Training Recording Status
          </h3>
          <RecordingsCompletedChart
            completedCount={props.totalRecordingsCount}
            totalCount={props.contentLines}
            recordedErrorCount={props.recordedErrorCount}
            dueInNumDays={props.dueInDays ?? 0}
            className={'mw5 center'}
          />
        </div>
        <div className={'w-25'}>
          <h3 className={classNames('mv0 vocalid-h3 vocalid-secondary-text ttu tc ph2', css({
            height: 25
          }))}>
            Recording Review Status
          </h3>
          <RecordingsReviewedChart
            rejectedCount={props.rejectedCount}
            acceptedCount={props.acceptedCount}
            notReviewedCount={props.totalRecordingsCount - (props.rejectedCount + props.acceptedCount)}
            className={'mw5 center'}
          />
          {!props.hideReviewRecordingButton && (
            <div className={'flex justify-center'}>
              <Link to={'/enterprise/talent/:id:'}>
                <OutlineButton size={"small"}>
                  Review Recordings
                </OutlineButton>
              </Link>
            </div>
          )}
        </div>
        {!props.hideVocalCreationStatus && (
          <div className={'w-25'}>
            <h3 className={'mv0 vocalid-h3 vocalid-secondary-text ttu tc ph2'}>
              Vocal Creation Status
            </h3>
            <div className={'mt5 tc ph3'}>
              <div className={'vocalid-orange b ttu mb3 pt4'}>
                Pending completion of training recording
              </div>
              <InfoTooltip
                tooltipContent={
                  'Once the Talent finishes their recordings, those are used to train the AI that creates your custom vocal.'
                }
              >
                <span className={'vocalid-link'}>
                  <Icon name={EIconName.Info} color={'link'} className={'v-mid'} /> What does this
                  mean?
                </span>
              </InfoTooltip>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecordingInProgressInfo;
