import React from 'react';
import Dialog from '../../../../molecules/dialog';
import { OutlineButton, PrimaryButton } from "../../../../atoms/button";

export interface IConvertMixTypeDialogProps {
  isShown: boolean;
  onCancel(): void;
  onConvert(): void;
  className?: string;
}

const ConvertMixTypeDialog: React.FC<IConvertMixTypeDialogProps> = (props) => {
  return (
    <Dialog
      isShown={props.isShown}
      heading={'Are You Sure you want to change the Mix Type?'}
      className={props.className}
    >
      <div className={'vocalid-h3 mb4'}>
        This action is potentially destructive and cannot be undone.
      </div>
      <div className={'flex justify-end'}>
        <OutlineButton
          dark
          size={'medium'}
          onClick={() => props.onConvert()}
          className={'mr4'}
        >
          CONTINUE
        </OutlineButton>
        <PrimaryButton onClick={() => props.onCancel()}>
          CANCEL
        </PrimaryButton>
      </div>
    </Dialog>
  );
};

export default ConvertMixTypeDialog;
