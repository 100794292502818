import React from 'react';

export interface IRecordingMetadataProps {
  metadata: {
    vocal: string | null;
    talent: string | null;
    initiated: Date | null;
    due: Date | null;
  };

  className?: string;
}

const RecordingMetadata: React.FC<IRecordingMetadataProps> = (props) => {
  return (
    <div className={props.className}>
      {(props.metadata.vocal || props.metadata.talent) && (
        <h3 className={'mt0 mb2 vocalid-list-title vocalid-primary-text'}>
          {props.metadata.vocal ? (
            <>
              Vocal: <span className={'vocalid-icon-active'}>{props.metadata.vocal}</span>
            </>
          ) : props.metadata.talent ? (
            <>
              Talent: <span className={'vocalid-icon-active'}>{props.metadata.talent}</span>
            </>
          ) : null}
        </h3>
      )}
      <div className={'vocalid-list-title vocalid-icon-active'}>
        { props.metadata.initiated && (<div>Initiated: {props.metadata.initiated.toLocaleDateString()}</div>) }
        { props.metadata.due && (<div>Due: {props.metadata.due.toLocaleDateString()}</div>) }
      </div>
    </div>
  );
};

export default RecordingMetadata;
