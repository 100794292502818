import React from 'react';
import Tooltip from '../../atoms/tooltip';
import { css } from 'emotion';

export interface SsmlContentViewProps {
  ssml: string;
  className?: string;
}

const SsmlContentView: React.FC<SsmlContentViewProps> = props => {
  const renderedSsml = React.useMemo(() => {
    const ssmlDoc = parseSsmlBody(props.ssml);
    // noinspection CssInvalidHtmlTagReference
    return ssmlDoc.querySelector('parsererror') ? props.ssml : renderSSML(ssmlDoc);
  }, [props.ssml]);

  return (
    <span className={props.className}>
      {renderedSsml}
    </span>
  );
};

export default SsmlContentView;

// ssmlParser global (shared across all sentences)
declare global { interface Window { $ssmlParser: DOMParser; }}
window.$ssmlParser = new DOMParser();

export const parseSsmlBody = (ssmlBody: string): Document => {
  const ssml = `<ssml xmlns:vocalid="http://vocalid.ai/ssml-namespace">${ssmlBody}</ssml>`;
  return window.$ssmlParser.parseFromString(ssml, 'text/xml');
}

export const renderSSML = ssmlRendererBuilder((el, defaultRender) => {
  if (el.tagName === 'emphasis') {
    return (
      <Tooltip
        tooltipContent={'Read with Emphasis'}
        tooltipElStyle={{
          color: 'black'
        }}
        color={'#3CC4FA'}
      >
            <span
              className={css({
                fontWeight: 'bold'
              })}
            >
            {defaultRender(el)}
          </span>
      </Tooltip>
    );
  }
})

function ssmlRendererBuilder(
  renderEl: (el: Element, defaultRender: (node: Node) => React.ReactNode) => React.ReactNode
) {
  const renderNode = (node: Node): React.ReactNode => {
    const defaultRender = (n: Node) => {
      return Array.from(n.childNodes).map((childNode, i) => (
        <React.Fragment key={i}>{renderNode(childNode)}</React.Fragment>
      ));
    };

    if (node.nodeType === Node.ELEMENT_NODE) {
      const el = node as Element;
      const rendered = renderEl(el, defaultRender);

      if (rendered !== undefined) {
        return rendered;
      } else {
        return defaultRender(el);
      }
    } else if (node.nodeType === Node.TEXT_NODE) {
      return node.textContent;
    }

    return defaultRender(node);
  };

  return renderNode;
}
