import React from "react";
import { Link, matchPath, useLocation, useRouteMatch } from "react-router-dom";
import classNames from "classnames";
import { css } from "emotion";
import CompanySelect from "../../molecules/company-select";
import ProfileDropdown from "../../molecules/profile-dropdown";
import SignOutDialog from "../../organisms/sign-out-dialog";
import Avatar from "../../atoms/avatar";
import { useTheme } from "../../../theme";
import { FocusedAudioPlayerProvider } from "../../molecules/audio-player/context";
import Tile from "../../atoms/tile";
import { FeatureLock, StatelessFeatureLock, useLockedFeature } from "../../../utils/feature-locking";
import AgreementPdfBlock from "../../organisms/agreement-pdf-block";
import CopyrightText from "../../../copyright";
import AssertScreenSizeTemplate from "../assert-screen-size";
import SupportLink from "../../../support";
import CompanyAccountCreation from "../../../containers/pages/registration/company-account-creation";
import EmailVerification from "../../../containers/pages/registration/email-verification";

interface INavItemProps {
  to: string;
  alsoMatches?: Array<string>;
}

const NavItem: React.FC<INavItemProps> = (props) => {
  const match = useRouteMatch([props.to, ...(props.alsoMatches ?? [])]);

  return (
    <li>
      <Link
        to={props.to}
        className={classNames(
          "vocalid-nav db ph4 pv3 no-underline",
          match?.isExact ? "vocalid-icon-active" : "vocalid-secondary-text"
        )}
      >
        {props.children}
      </Link>
    </li>
  );
};

interface IAccountListingData {
  id: string;
  title: string;
  imageUrl: string | null;
}

export interface IEnterpriseTemplateProps {
  data: {
    selectedCompanyId: string | null;
    companies: Array<IAccountListingData>;
    user: {
      firstName: string;
      lastName: string;
      emailAddress: string;
      emailVerified: boolean;
    };
    pricingPlan: {
      inactive: boolean;
    } | null;
    hasAssociatedTalentAccounts: boolean;
    signedAgreementDate: Date | null;
  };
  exemptedFromAllFeatureLock?: boolean;

  onAgreeToTOSPdf(): void;

  onBeforeSignOut?(): void;

  onSignOutReturnTo: string;

  onSelectCompany(companyId: string): void;
  onAccountCreated(): void;

  className?: string;
}

const tosPdf = {
  file: `${window.location.origin}/Parrot_Terms of Service 9-2021.pdf`,
  date: new Date(Date.UTC(2021, 8 /* Sept */, 0, 0, 0))
};

const EnterpriseTemplate: React.FC<IEnterpriseTemplateProps> = (props) => {
  const [signingOut, setSigningOut] = React.useState(false);
  const { colors: themeColors } = useTheme();
  const allAppFeaturesLock = useLockedFeature("AllAppFeatures");
  const location = useLocation();

  const templateWrapperRef = React.useRef<HTMLDivElement | null>(null);

  const bypassPricingPlanAssertionAndTOS = matchPath(location.pathname, {
    path: "/enterprise/plan-selected",
    exact: true,
    strict: false
  }) != null;

  const showTOSAgreementPdf = (props.data.signedAgreementDate === null || props.data.signedAgreementDate < tosPdf.date) && props.data.pricingPlan != null;

  return (
    <>
      <SignOutDialog
        isShown={signingOut}
        onBeforeSignOut={props.onBeforeSignOut}
        onSignOutReturnTo={props.onSignOutReturnTo}
        onCancel={() => setSigningOut(false)}
      />
      {/* <NewFeatureAnnouncementModal isShown={true} onDismiss={() => {}} onSkip={() => {}} /> */}
      <FocusedAudioPlayerProvider>
        <div
          className={classNames(
            "flex flex-column h-100 bg-vocalid-page vocalid-body",
            props.className
          )}
        >
          <nav
            className={classNames(
              css({ borderWidth: 1 }),
              "bg-vocalid-header bb b--vocalid-header-border"
            )}
          >
            <div className={"center flex flex-shrink-0 items-center justify-between pv2 pl5 pr4"}>
              <div className={"flex items-center"}>
                <span
                  className={classNames(
                    css({
                      width: 40
                    }),
                    "dib mr4 flex justify-center flex-shrink-0"
                  )}
                >
                  <Link to={"/"}>
                    <img
                      src={"/logo-icon.png"}
                      alt={"VocaliD Icon"}
                      height={40}
                      width={"auto"}
                      className={"flex-shrink-0"}
                    />
                  </Link>
                </span>
                <Link to={"/"}>
                  <img
                    src={"/logo.png"}
                    alt={"VocaliD"}
                    height={17}
                    className={"ml5 pl2 flex-shrink-0"}
                  />
                </Link>
              </div>
              <div className={"pr3"}>
                <ProfileDropdown
                  profile={props.data.user}
                  profileUrl={"/enterprise/profile"}
                  onSignOut={() => setSigningOut(true)}
                >
                  <span className={"vocalid-secondary-text"}>
                    <span className={"div v-mid mr3"}>{props.data.user.firstName}</span>
                    <Avatar className={"v-mid"} bgColor={themeColors.red}>
                      {`${props.data.user.firstName.substring(
                        0,
                        1
                      )}${props.data.user.lastName.substring(0, 2)}`}
                    </Avatar>
                  </span>
                </ProfileDropdown>
              </div>
            </div>
          </nav>
          <AssertScreenSizeTemplate>
            <div className={"flex flex-grow-1 overflow-y-hidden"}>
              <div
                className={classNames(
                  "bg-vocalid-header flex-shrink-0 flex flex-column",
                  css({
                    width: 176
                  })
                )}
              >
                <div className={classNames("flex flex-column flex-grow-1", props.data.companies.length === 0 && css({
                  opacity: 0.5,
                  pointerEvents: "none"
                }))}>
                  <div className={classNames("mh2", css({
                    marginTop: 30, marginBottom: 30
                  }))}>
                    <CompanySelect
                      selectedCompanyId={props.data.selectedCompanyId}
                      companies={props.data.companies}
                      onSelect={props.onSelectCompany}
                      disabled={props.data.companies.length === 0}
                    />
                  </div>
                  <ul className={"list pa0 ma0 ttu tc"}>
                    <NavItem to={"/enterprise"}>Home</NavItem>
                    <StatelessFeatureLock lockedState={showTOSAgreementPdf}>
                      <FeatureLock feature={"AllAppFeatures"}>
                        <NavItem to={"/enterprise/projects"} alsoMatches={["/enterprise/project/:id"]}>
                          Projects
                        </NavItem>
                        {props.data.hasAssociatedTalentAccounts &&
                          <FeatureLock feature={"EnterpriseTalentPage"}>
                            <NavItem to={"/enterprise/talent"} alsoMatches={["/enterprise/talent/:id"]}>
                              Talent
                            </NavItem>
                          </FeatureLock>
                        }
                        <NavItem to={"/enterprise/voices"}>Voices</NavItem>
                      </FeatureLock>
                      <NavItem to={"/enterprise/account"}>Account</NavItem>
                    </StatelessFeatureLock>
                  </ul>
                  <div className={"flex-grow-1"} />
                  <footer className={"mh2 mt2 mb2"}>
                    <CopyrightText className={"tl vocalid-tertiary-text o-50"} />
                  </footer>
                </div>
              </div>
              <div ref={templateWrapperRef}
                   className={classNames("flex-grow-1 overflow-y-auto relative", css({ maxHeight: "100%" }))}>{
                !props.data.user.emailVerified ?
                  <EmailVerification />
                  :
                props.data.companies.length === 0 ?
                  <CompanyAccountCreation onAccountCreated={props.onAccountCreated} />
                  :
                  !bypassPricingPlanAssertionAndTOS ? (
                    allAppFeaturesLock && !props.exemptedFromAllFeatureLock ? (
                        props.data.pricingPlan?.inactive ?
                          <PricingPlanInactiveView {...props} />
                          :
                          <LockedAccountView {...props} />
                      )
                      :
                      showTOSAgreementPdf ?
                        <AgreementPdfBlock header={"Terms of Service"}
                                           agreeText={"I have read and understood the Terms of Service."}
                                           file={tosPdf.file} onAgree={props.onAgreeToTOSPdf} />
                        :
                        props.children
                  ) : props.children
              }</div>
            </div>
          </AssertScreenSizeTemplate>
        </div>
      </FocusedAudioPlayerProvider>
    </>
  );
};

export default EnterpriseTemplate;



interface PricingPlanInactiveViewProps extends IEnterpriseTemplateProps {}

const PricingPlanInactiveView: React.FC<PricingPlanInactiveViewProps> = props => {
  return (
    <div className={classNames("flex justify-center items-start vocalid-secondary-text", css({
      paddingTop: "15%"
    }))}>
      <Tile heading={<div className={"vocalid-h2 mr2"}>Account Pricing Plan is Inactive</div>}>
        <div className={"mb2"}>This is most likely due to an issue with billing.</div>
        <div>Please <SupportLink subject={"My PARROT STUDiO Pricing Plan is Inactive"}>contact
          VocaliD support</SupportLink> for assistance.
        </div>
      </Tile>
    </div>
  );
};

interface LockedAccountViewProps extends IEnterpriseTemplateProps {}

const LockedAccountView: React.FC<LockedAccountViewProps> = props => {
  return (
    <div className={classNames("flex justify-center items-start vocalid-secondary-text", css({
      paddingTop: "15%"
    }))}>
      <Tile heading={<div className={"vocalid-h2 mr2"}>Your account is Locked</div>}>
        Please <SupportLink subject={"My PARROT STUDiO Account is Locked"}>contact VocaliD
        support</SupportLink> for assistance.
      </Tile>
    </div>
  );
};


interface IEnterpriseState {
  state: {
    selectedAccount: string | null;
  };
}

const EnterpriseState = React.createContext<IEnterpriseState>({
  state: {
    selectedAccount: null
  }
});

export const EnterpriseStateProvider = EnterpriseState.Provider;

export function useEnterpriseContextualState() {
  return React.useContext(EnterpriseState);
}
