import React from "react";
import { useTalentQuery } from "../../graphql/queries/Talent";
import GenericErrorBoundary from "../../components/molecules/generic-error-boundary";
import FullPageLoader from "../shared/loader";
import Header from "../../components/atoms/header";
import { PrimaryButton } from "../../components/atoms/button";
import { css } from "emotion";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { EnterpriseTalentFragment } from "../../graphql/generated";
import ClipCounter from "../../components/atoms/clip-counter";
import Icon, { EIconName } from "../../components/atoms/icon";
import { labelForTalentGender, labelForTalentJobCategory } from "../shared/enums";
import PaginationControls from "../../components/atoms/pagination-controls";

export interface IEnterpriseTalentPageContainerProps {
  className?: string;
}

const EnterpriseTalentPageContainer: React.FC<IEnterpriseTalentPageContainerProps> = (props) => {
  const perPage = 10;
  const [page, setPage] = React.useState(0);

  const talentQuery = useTalentQuery({ page, perPage })

  if (!talentQuery.data) return <FullPageLoader />

  const talents = talentQuery.data.account.talentEntitlements

  return (
    <GenericErrorBoundary>
      <div className={props.className}>
        <Header
          rightArea={
            false ? <PrimaryButton onClick={() => {}}>Request New Talent</PrimaryButton> : <span />
          }
        >
          <h1 className={'db vocalid-h1 ma0'}>Talent</h1>
          <div className={css({ marginTop: 14 })}>
            <span>{talents.totalResults} Talent{talents.totalResults !== 1 && "s"}</span>
          </div>
        </Header>
        <div className={'mh4 mb4 mt3'}>
          <table className={'w-100'} style={{ borderSpacing: 0 }}>
            <tbody>
            {talents.results.map((talent) => (
              <React.Fragment key={talent.id}>
                <TalentMini talent={talent} />
                <tr className={'h1'}></tr>
              </React.Fragment>
            ))}
            </tbody>
          </table>
        </div>
        <div className={"flex justify-center"}>
          <PaginationControls numPages={Math.ceil(talents.totalResults / perPage)} page={page + 1} onChangePage={newPage => setPage(newPage - 1)} />
        </div>
      </div>
    </GenericErrorBoundary>
  );
};

export default EnterpriseTalentPageContainer;

export interface ITalentMiniProps {
  talent: EnterpriseTalentFragment;
}

const TalentMini: React.FC<ITalentMiniProps> = ({ talent }) => {
  const history = useHistory();
  const [assignmentsOpen, setAssignmentsOpen] = React.useState(false);
  // const hasExtraDetails = ((talent.recommendedUseCases && talent.recommendedUseCases !== "") || (talent.limitations && talent.limitations !== ""))

  return (
    <>
      <tr className={"bg-vocalid-tile"}>
        <td
          className={classNames(
            "pl3 pv3 v-top w-50",
            css({
              borderTopLeftRadius: 6,
              borderBottomLeftRadius: !assignmentsOpen ? 6 : 0
            })
          )}
        >
          <h2 className={"no-underline white vocalid-tile-title mr2 mt2 mb4 db"}>
            {talent.firstName} {talent.lastName}
          </h2>
          <div className={"vocalid-secondary-text mb3"}>
            {[talent.age, (talent.ethnicIdentity ? talent.ethnicIdentity + " " : "") + (talent.gender ? labelForTalentGender(talent.gender) : ""), talent.pronouns].filter(e => !!e).join(", ")}
          </div>
        </td>
        <td
          className={classNames(
            "ph2 pv3 v-top",
            css({
              borderTopRightRadius: 6,
              borderBottomRightRadius: !assignmentsOpen ? 6 : 0
            })
          )}
        >
          <table className={"tl w-100"} style={{ borderSpacing: 0 }}>
            <thead className={"vocalid-tertiary-text ttu"}>
            <tr>
              <th className={"w-40 ph2 tc"}>VoiceDubb Assignments</th>
              <th className={"w-30 pr3"}>Desired Project Categories</th>
              <th className={"w-30 pr3"}>Limitations</th>
              {/*<th className={'w-30'}>Created</th>*/}
            </tr>
            </thead>
            <tbody
              className={classNames(
                "vocalid-icon-active",
                css({
                  "td, td *": {
                    verticalAlign: "top"
                  }
                })
              )}
            >
            <tr>
              <td className={"w-40 pv2 tl"}>
              <span className={"w-100 flex justify-center"}>
                <span className={"flex items-center pointer"} onClick={() => setAssignmentsOpen(o => !o)}>
                  <ClipCounter count={talent.assignments.length} size={"large"} />
                  <Icon name={!assignmentsOpen ? EIconName.DropdownArrowDown : EIconName.DropdownArrowUp}
                        className={"ml2"} />
                </span>
              </span>
              </td>
              <td className={"w-30 f5 b tl pr3 lh-title"}>{talent.desiredProjectCategories?.map(c => labelForTalentJobCategory(c)).join(", ") ?? "None"}</td>
              <td className={"w-30 f5 b tl pr3 lh-title"}>{talent.brandConflicts || talent.limitations ? [talent.brandConflicts && `Brand Conflicts: ${talent.brandConflicts.join(", ")}`, talent.limitations].filter(e => !!e).join(". ") : "None"}</td>
              {/*<td className={'w-30 b tl'}>{props.vocal.createdAt.toLocaleDateString()}</td>*/}
            </tr>
            </tbody>
          </table>
        </td>
      </tr>
      {assignmentsOpen && <tr className={"bg-vocalid-tile"}>
        <td colSpan={2} className={
          css({
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6
          })}>
          <div className={"ph6"}>
            <table className="b--none w-100">
              {talent.assignments.map((assignment) => (
                <tr style={{ border: 0 }} className="b--none">
                  <td style={{ border: 0 }} className={classNames("b--none", css({ minWidth: 400 }))}>
                    <span className="white ma0 pa0" style={{ fontSize: 18 }}>
                      {assignment.voice ? `Vocal: ${assignment.voice.title}` : 'Unnamed Assignment'}{assignment.isTalentRecordingRequestTarget && ` (Pickup)`}
                    </span>
                  </td>
                  <td style={{ border: 0 }} className={"b--none tr pr3"}>
                    <span className="vocalid-secondary-text ma0 pa0" style={{ fontSize: 18 }}>
                    {new Date(assignment.created).toLocaleDateString()}
                    </span>
                  </td>
                  <td style={{ border: 0 }} className={"b--none tr pr3"}>
                    <span className="vocalid-secondary-text ma0 pa0" style={{ fontSize: 18 }}>
                    {assignment.totalRecordingsCount}/{assignment.contentLines} <span
                      className={"vocalid-tertiary-text"}>Completed</span>
                    </span>
                  </td>
                  <td style={{ border: 0 }} className="b--none pb3">
                    <PrimaryButton
                      size={"small"}
                      onClick={() => history.push(`/enterprise/assignment/${assignment.id}`)}
                    >
                      Review Recordings
                    </PrimaryButton>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </td>
      </tr>}
    </>
  );
};
