import { Gender, TalentJobCategory } from "../../graphql/generated";

export function labelForTalentJobCategory(category: TalentJobCategory): string {
  switch (category) {
    case TalentJobCategory.Animation_Dubbing:
      return "Animation/Dubbing";
    case TalentJobCategory.Audiobooks:
      return "Audiobooks";
    case TalentJobCategory.Cinema_Events:
      return "Cinema/Events";
    case TalentJobCategory.Digital_Broadcast:
      return "Digital Broadcast";
    case TalentJobCategory.ELearning:
      return "E-Learning";
    case TalentJobCategory.IVR_Message_on_Hold:
      return "IVR/Message-on-Hold";
    case TalentJobCategory.Non_Broadcast:
      return "Non-Broadcast";
    case TalentJobCategory.Promo_Imaging:
      return "Promo & Imaging";
    case TalentJobCategory.Radio_Broadcast:
      return "Radio Broadcast";
    case TalentJobCategory.TV_Broadcast:
      return "TV Broadcast";
    case TalentJobCategory.VideoGames_Toys_Games:
      return "Video Games/Toys&Games";
  }

  return (category as TalentJobCategory).toString()
}

export function labelForTalentGender(gender: Gender): string {
  switch (gender) {
    case Gender.Male:
      return "Male";
    case Gender.Female:
      return "Female";
    case Gender.Other:
      return "Other";
    default:
      return (gender as Gender).toString()
  }
}
