import React from "react";
import { VictoryLabel, VictoryPie } from "victory";
import { useTheme } from "../../../theme";
import Icon, { EIconName } from "../../atoms/icon";
import Tooltip from "../../atoms/tooltip";
import { css } from "emotion";

export interface IRecordingsCompletedChartProps {
  completedCount: number;
  totalCount: number;
  recordedErrorCount: number;
  dueInNumDays: number;
  className?: string;
  style?: React.CSSProperties;
}

const RecordingsCompletedChart: React.FC<IRecordingsCompletedChartProps> = (props) => {
  const theme = useTheme();

  const completeRatioText = `${props.completedCount}/${props.totalCount}`

  return (
    <div className={props.className} style={props.style}>
      <Tooltip
        disabled={props.recordedErrorCount === 0}
        placement={"bottom"}
        tooltipElStyle={{
          position: 'relative',
          top: -30
        }}
        tooltipContent={
          <div>
            There {props.recordedErrorCount !== 1 ? 'are' : 'is' } {props.recordedErrorCount} recorded {props.recordedErrorCount !== 1 ? 'lines' : 'line'} marked as containing errors. These lines will not be included in training unless the text is updated or the recording is approved.
          </div>
        }
        containerStyle={{
          display: 'block'
        }}
      >
        <div className={'relative'}>
          <svg viewBox={`0 0 400 400`}>
            {/*<g transform="translate(55, 72), scale(1.1)">
              <path
                d="M251.506224,152.877288 L11.5062237,152.877288 C24.4443175,177.119173 67.269098,225.604443 135.06434,225.604443 C202.858982,225.604443 240.940517,177.119173 251.506224,152.877288 Z"
                fill={
                  props.dueInNumDays <= 3
                    ? theme.colors.red
                    : props.dueInNumDays <= 9
                    ? theme.colors.orange
                    : theme.colors.popover
                }
              />
            </g>*/}
            <g transform="translate(55, 72), scale(1.1)">
              <path
                d="M251.506224,152.877288 L11.5062237,152.877288 C24.4443175,177.119173 67.269098,225.604443 135.06434,225.604443 C202.858982,225.604443 240.940517,177.119173 251.506224,152.877288 Z"
                fill={theme.colors.popover}
              />
            </g>
            <VictoryPie
              standalone={false}
              width={400}
              height={400}
              data={[
                { x: 'Fallback', y: 100, fill: theme.colors.iconInactive }
              ]}
              innerRadius={120}
              labels={[]}
              style={{
                data: {
                  fill: ({ datum }) => datum.fill,
                  opacity: ({ datum }) => datum.opacity
                }
              }}
            />
            <VictoryPie
              standalone={false}
              width={400}
              height={400}
              data={[
                { x: 'Complete', y: props.completedCount, fill: theme.colors.blue },
                {
                  x: 'Incomplete',
                  y: props.totalCount - props.completedCount,
                  fill: theme.colors.darkBlue
                }
              ]}
              innerRadius={120}
              labels={[]}
              style={{
                data: {
                  fill: ({ datum }) => datum.fill,
                  opacity: ({ datum }) => datum.opacity
                }
              }}
            />
            <VictoryLabel
              textAnchor="middle"
              verticalAnchor="end"
              x={200}
              y={210}
              dy={5}
              style={{
                fontSize: 70,
                fill: theme.colors.primaryText,
                fontFamily: theme.typography.body.fontFamily
              }}
              text={`${props.totalCount === 0 ? 0 : Math.min(100, Math.ceil((props.completedCount / props.totalCount) * 100))}%`}
            />
            {/*<VictoryLabel
              textAnchor="middle"
              verticalAnchor="start"
              x={200}
              y={230}
              dy={20}
              style={{
                fontSize: 20,
                textTransform: 'uppercase',
                fill: theme.colors.page,
                fontFamily: theme.typography.body.fontFamily
              }}
              text="Due in"
            />
            <VictoryLabel
              textAnchor="middle"
              verticalAnchor="start"
              x={200}
              y={230}
              dy={45}
              style={{ fontSize: 20, textTransform: 'uppercase', fill: theme.colors.page }}
              text={`${props.dueInNumDays} days`}
            />*/}
            <VictoryLabel
              textAnchor="middle"
              verticalAnchor="start"
              x={200}
              y={230}
              dy={20}
              style={{
                fontSize: 20,
                textTransform: 'uppercase',
                fill: theme.colors.darkText,
                fontFamily: theme.typography.body.fontFamily
              }}
              text={completeRatioText}
            />
            { props.recordedErrorCount !== 0 && <g transform={`translate(${212 + ((completeRatioText.length - 1) / 2)*10}, 247)`}>
              <Icon name={EIconName.Warning} color={theme.colors.orange} />
            </g> }
            <VictoryLabel
              textAnchor="middle"
              verticalAnchor="start"
              x={200}
              y={230}
              dy={45}
              style={{
                fontSize: 20,
                textTransform: 'uppercase',
                fill: theme.colors.darkText,
                fontFamily: theme.typography.body.fontFamily
              }}
              text="Completed"
            />
          </svg>
        </div>
      </Tooltip>
    </div>
  );
};

export default RecordingsCompletedChart;
