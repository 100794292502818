import React from 'react';
import FullPageLoader, { Loader } from "../shared/loader";
import GenericErrorBoundary from "../../components/molecules/generic-error-boundary";
import { useEnterpriseContextualState } from "../../components/templates/enterprise";
import { getCurrentMonthPeriod, useAnalytics } from "./enterprise-dashboard";
import { useEnterpriseAccountQuery, useSelectPricingPlanForAccountMutation } from "../../graphql/generated";
import { NO_PLAN_ID, PricingPlansListModal } from "../organisms/pricing-plan-selection/pricing-plans-list";
import { Link } from "react-router-dom";
import Tile from "../../components/atoms/tile";
import classNames from "classnames";
import { css } from "emotion";
import PricingPlanListing, {
  CustomPricingPlanListing, TrialDetail,
  TrialPricingPlanListing
} from "../organisms/pricing-plan-listing";
import { OutlineButton, TextButton } from "../../components/atoms/button";
import SupportLink from "../../support";
import queryString from "querystring";
import RendersTable from "../organisms/renders-table";
import Modal from "../../components/atoms/modal";
import { BooleanParam, useQueryParams } from "use-query-params";

export const EnterpriseAccountPageQueryParams = {
  changing_plan: BooleanParam
} as const;

export interface IEnterpriseAccountPageContainerProps {}

const EnterpriseAccountPageContainer: React.FC<IEnterpriseAccountPageContainerProps> = (props) => {
  const [{
    changing_plan: passedChangingPlan
  }] = useQueryParams(EnterpriseAccountPageQueryParams);
  console.log(passedChangingPlan)

  const { state: { selectedAccount } } = useEnterpriseContextualState()
  const { data, loading, refetch } = useEnterpriseAccountQuery({ variables: { account: selectedAccount! }, skip: !selectedAccount })

  const [selectPricingPlanForAccount] = useSelectPricingPlanForAccountMutation();
  const [changingPlan, setChangingPlan] = React.useState(passedChangingPlan ?? false);
  const [redirectingToPayment, setRedirectingToPayment] = React.useState(false);

  const period = React.useMemo(() => {
    if (data?.account.pricingPlanSubscription) {
      return {
        startDate: new Date(data.account.pricingPlanSubscription.currentPeriodStart),
        endDate: new Date(data.account.pricingPlanSubscription.currentPeriodEnd)
      }
    } else {
      return getCurrentMonthPeriod()
    }
  }, [data?.account.pricingPlanSubscription])

  const analytics = useAnalytics(period)

  if (!data || loading) return <FullPageLoader />
  if (!selectedAccount) return <GenericErrorBoundary hasError={true} />

  return (
    <GenericErrorBoundary>
        {/*<CreateApiTokenDialog isShown={creatingApiToken} onCancel={() => setCreatingApiToken(false)} onCreated={() => {
        setCreatingApiToken(false);
        props.onCreatedApiToken();
      }} />*/}
        <PricingPlansListModal isOpen={changingPlan} selected={data.account.pricingPlanSubscription ? { plan: data.account.pricingPlanSubscription.pricingPlan.id, trial: data.account.pricingPlanSubscription.isTrial, custom: data.account.pricingPlanSubscription.pricingPlan.isCustom } : { plan: NO_PLAN_ID }} onClose={() => setChangingPlan(false)} onPlanSelect={async (planId, trial) => {
          console.log("Applying to account...")
          const selectResult = await selectPricingPlanForAccount({ variables: {
              account: data.account.id,
              pricingPlan: planId,
              trial: trial,
              cancelUrl: window.location.href,
              successUrl: window.location.origin + "/plan-selected",
            } });

          const paymentUrl = selectResult.data?.selectPricingPlanForAccount.url;
          if (selectResult.errors) return;

          if (paymentUrl) {
            setRedirectingToPayment(true);
            setTimeout(() => {
              window.location.href = paymentUrl;
            }, 1000)
          } else {
            refetch({ account: selectedAccount! })
          }
          setChangingPlan(false);
        }} />
        <Modal isOpen={redirectingToPayment} containerClassName={css({
          minHeight: 200
        })}>
          <div className={"vocalid-white flex flex-column items-center justify-center"}>
            <h3 className={"vocalid-h3"}>Redirecting to Payment...</h3>
            <div className={"flex justify-center items-center w6 h6"}>
              <Loader />
            </div>
          </div>
        </Modal>
        <div className={classNames(redirectingToPayment && css({
          pointerEvents: 'none'
        }))}>
          <div className={'mh4 white'}>
            <div className={'ml4 mt4 mb4 pt2 pb2'}>
              <h1 className={'dib v-mid vocalid-h2'}>
              <span className={'f2'}>
                {data.account.name}
              </span>
              </h1>
              <div className={"vocalid-tertiary-text"}>Looking for API Access? <Link to={"/enterprise/profile"} className={"no-underline vocalid-link"}>Go to your profile</Link></div>
            </div>
            <div className={"flex mb4"}>
              <Tile heading={<div className={'vocalid-h2'}>Plan</div>} className={'w-100 mr4'}>
                <div className={classNames('pv3', css({ maxHeight: 400 }))}>
                  <div className={"flex"}>
                    <div className={"flex flex-column items-center"}>
                      <div className={"dib ba bw2 br3 b--vocalid-blue shadow-4"}>
                        {
                          !data.account.pricingPlanSubscription ?
                            <TrialPricingPlanListing
                              key={"trial"}
                              hideFeatures
                            />
                            :
                            !data.account.pricingPlanSubscription.pricingPlan.isCustom ?
                              <PricingPlanListing
                                id={data.account.pricingPlanSubscription.pricingPlan.id}
                                show={data.account.pricingPlanSubscription.isTrial ? 'trial' : 'plan'}
                                hideFeatures
                              />
                              :
                              <CustomPricingPlanListing
                                key={"custom"}
                                hideFeatures
                                hideSelect
                              />
                        }
                      </div>
                      { !data.account.pricingPlanSubscription && <OutlineButton className={"mt3"} onClick={() => setChangingPlan(true)}>Upgrade Plan</OutlineButton> }
                    </div>
                    <div className={"ml4 flex flex-column"}>
                      <div className={'vocalid-h3 ttu mb3'}>Usage</div>
                      {data.account.pricingPlanSubscription && <div className={"mb2"}>{new Date(data.account.pricingPlanSubscription.currentPeriodStart).toLocaleDateString()} - {new Date(data.account.pricingPlanSubscription.currentPeriodEnd).toLocaleDateString()}</div> }
                      <div className={"mb3"}>
                        Selected Plan:
                        <span className={"vocalid-h3 ttu ml3"}>
                        {!data.account.pricingPlanSubscription ? TrialDetail.name : <span>{data.account.pricingPlanSubscription.pricingPlan.name} {data.account.pricingPlanSubscription.isTrial && <span className={"vocalid-orange ml2"}>Trial</span>} {!data.account.pricingPlanSubscription.isActive && <span className={"b vocalid-red ml2"}>INACTIVE</span>}</span> }
                      </span>
                      </div>
                      <div>
                        <table className={"vocalid-list-title bn mb4"}>
                          <tbody>
                          <tr>
                            <td className={"vocalid-secondary-text pr4"}>Renders</td>
                            <td>{analytics.totalRenders}</td>
                            <td>/ {data.account.allRoleRestrictions.synthesisMaxRequests ?? "Unlimited"}</td>
                          </tr>
                          <tr>
                            <td className={"vocalid-secondary-text pr4"}>Projects</td>
                            <td>{data.account.projectsCount}</td>
                            <td>/ {data.account.allRoleRestrictions.maxProjects ?? "Unlimited"}</td>
                          </tr>
                          </tbody>
                        </table>
                        <div>
                          <div className={"mb2"}>Want to add users to your account?</div>
                          <div>{data.account.pricingPlanSubscription?.pricingPlan?.isCustom ? <>Please <SupportLink subject={"I Want to Add Users to my PARROT STUDiO Account"}>contact VocaliD support</SupportLink> so that we may assist you.</> : <a href={`mailto:sales@vocalid.ai?${queryString.stringify({
                            subject: "Custom Parrot Studio Plan Request",
                            body: ""
                          })}`}><TextButton noHorizontalPadding>Upgrade to a Custom Plan</TextButton></a>}</div>
                        </div>
                      </div>
                      <div className={"flex-grow-1"}/>
                    </div>
                  </div>
                </div>
              </Tile>
              {/*<Tile heading={<div className={"flex justify-between items-center"}><div className={'vocalid-h2'}>API Access</div><PrimaryButton disabled={!props.data.account.apiAccess} size={"small"} onClick={() => setCreatingApiToken(true)}>Create Token</PrimaryButton></div>} className={"w-40"}>
              <div className={classNames("flex flex-column", css({ maxHeight: 400 }))}>
                {props.data.account.apiAccess ? <>
                  <p className={"vocalid-body mb4"}>
                    <a href={`${API_BASE_URL}/docs`} target="_blank" rel="noopener noreferrer" className={"vocalid-link no-underline"}>View API Documentation</a>
                  </p>
                  <div className={"flex-grow-1 flex-shrink-1 overflow-y-auto pr3"}>
                    <table className={'w-100'} style={{ borderSpacing: 0 }}>
                    <thead>
                    <tr className={'ttu vocalid-h3 vocalid-secondary-text'}>
                      <td className={'v-mid pb3'}>Name</td>
                      <td className={'v-mid pb3'}>Created</td>
                    </tr>
                    </thead>
                    <tbody>
                    {[...props.data.account.apiTokens].sort((a, b) => (b.createdAt as any) - (a.createdAt as any)).map((apiToken) => (
                      <tr key={apiToken.id} className={classNames('vocalid-secondary-text br0', props.className)}>
                        <td className={'pv2 pr2'}>
                          {apiToken.name}
                        </td>
                        <td className={'pa2'}>
                          <div>{apiToken.createdAt.toLocaleDateString()}</div>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                  </div>
                </> : (
                    <div>
                      <div className={"mb2"}>Want to get access to the Parrot API?</div>
                      <div>{props.data.account.pricingPlanSubscription?.isCustom ? <>Please <SupportLink subject={"I Want Access to the PARROT STUDiO API"}>contact VocaliD support</SupportLink> so that we may assist you.</> : <Link to={"/pricing"}><TextButton noHorizontalPadding>Upgrade to a Custom Plan</TextButton></Link>}</div>
                    </div>
                )}
              </div>
            </Tile>
            */}
            </div>
            <Tile heading={<div className={'vocalid-h2'}>Renders</div>} className={"mb4"}>
              <RendersTable className={"mb3"} />
            </Tile>
            <div className={'pv2 mb5 vocalid-tertiary-text tc'}>
              Want to cancel your account? Please <SupportLink subject={"I'd Like to Cancel PARROT STUDiO"}>contact VocaliD support</SupportLink> so we can process your cancellation request.
            </div>
          </div>
        </div>
    </GenericErrorBoundary>
  );
};

export default EnterpriseAccountPageContainer;
