import { gql, useQuery } from "@apollo/client";
import { AnalyticsContextQuery, AnalyticsContextQueryVariables } from "../generated";
import { useEnterpriseContextualState } from "../../components/templates/enterprise";

const query = gql`
query AnalyticsContextQuery(
  $account: String!
) {
  account(where: { id: $account }) {
    id
    pricingPlanSubscription {
      currentPeriodStart
      currentPeriodEnd
    }
  }
}
`

export const useAnalyticsContextQuery = () => {
  const enterprise = useEnterpriseContextualState()
  return useQuery<AnalyticsContextQuery, AnalyticsContextQueryVariables>(
    query,
    {
      variables: {
        account: enterprise.state.selectedAccount!
      },
      skip: !enterprise.state.selectedAccount
    }
  )
}
