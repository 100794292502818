import React from 'react';
import classNames from 'classnames';
import { css } from 'emotion';
import { useTheme } from '../../../theme';
import Icon, { EIconName } from '../../atoms/icon';
import Tooltip from '../../atoms/tooltip';

type AccountId = string
type IAccountData = {
  id: string;
  title: string;
  imageUrl: string | null;
}

export interface ICompanySelectProps {
  selectedCompanyId: AccountId | null;
  companies: Array<IAccountData>;
  onSelect(companyId: AccountId): void;
  disabled?: boolean;
  className?: string;
}

export const PLACEHOLDER_IMAGE = "https://via.placeholder.com/300"

const CompanySelect: React.FC<ICompanySelectProps> = (props) => {
  const [isShown, setIsShown] = React.useState(false);
  const { colors: themeColors } = useTheme();

  const selectedCompany = props.selectedCompanyId ? props.companies.find((c) => c.id === props.selectedCompanyId) : null;

  return (
    <Tooltip
      visible={isShown}
      onClickOutside={() => setIsShown(false)}
      interactive
      tooltipContent={
        <>
          {[...props.companies].sort((a, b) => (a.title as any) > (b.title as any) ? 1 : -1)
            .map((company, i, arr) => (
            <div
              key={company.id}
              className={classNames(
                'pv3 ph2 pointer b--vocalid-slider-groove tl',
                i !== arr.length - 1 && 'bb'
              )}
              onClick={() => {
                props.onSelect(company.id);
                setIsShown(false);
              }}
            >
              <span>
                {/*<img
                  src={company.imageUrl ?? PLACEHOLDER_IMAGE}
                  alt={""}
                  width={30}
                  height={30}
                  className={'dib br-100 v-mid mr2'}
                />*/}
                <span className={classNames('ml2 dib v-mid', css({
                  width: 150,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }))}>{company.title}</span>
              </span>
              {props.selectedCompanyId === company.id && (
                <span className={"dib v-mid"}>
                  <Icon
                    name={EIconName.Checkmark}
                    color={'primaryButton'}
                    size={15}
                    className={'ml3 mr2 v-mid'}
                  />
                </span>
              )}
            </div>
          ))}
        </>
      }
      color={themeColors.page}
      tooltipElStyle={{
        color: themeColors.secondaryText,
        width: 250,
        borderRadius: 6,
        boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.2)',
        padding: 0
      }}
      containerClassName={classNames("w-100", props.className)}
    >
      <span
        className={classNames(
          css({ borderRadius: 30, userSelect: 'none', height: 40 }),
          'inline-flex items-center pv1 pl1 pr1 pointer w-100', // was pr3
          !props.disabled ? "vocalid-secondary-text ba b--vocalid-secondary-text" : "bg-vocalid-tile vocalid-tile"
        )}
        onClick={() => setIsShown((o) => !o)}
      >
        {/*<img
          src={selectedCompany?.imageUrl ?? PLACEHOLDER_IMAGE}
          width={30}
          height={30}
          className={classNames('br-100 v-mid', props.disabled && "o-0")}
        />*/}
        <span className={classNames('dib v-mid ml2 mr2 tc flex-grow-1', css({
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }), props.disabled && "o-0")}>{selectedCompany?.title ?? "Select Account"}</span>
      </span>
    </Tooltip>
  );
};

export default CompanySelect;
