import React from "react";
import { css } from "emotion";
import classNames from "classnames";
import { useTheme } from "../../../theme";
import Icon, { EIconName } from "../icon";
import { CircularProgress, ClickAwayListener } from "@material-ui/core";

interface RevertableHiddenInputProps {
  initialValue: string;
  onChange(newValue: string): void;
  onBlur?(): void;
  noSaveOnEnter?: boolean;
  disabled?: boolean;
  className?: string;
}

export const RevertableHiddenInput: React.FC<RevertableHiddenInputProps> = props => {
  const { colors: themeColors } = useTheme()

  const inputRef = React.useRef<HTMLInputElement>(null);

  const [internalValue, setInternalValue] = React.useState(props.initialValue);
  React.useEffect(() => {
    setInternalValue(props.initialValue);
  }, [props.initialValue]);

  const [focused, setFocused] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);

  const revert = () => {
    setInternalValue(props.initialValue);
    setFocused(false);
  }

  const save = async () => {
    setFocused(false);

    setUpdating(true);
    await props.onChange(internalValue);
    setUpdating(false);
  }

  return (
    <span className={props.className}>
      <ClickAwayListener
        // acts as onBlur()
        onClickAway={() => {
          revert();
          props.onBlur?.();
        }}
      >
        <ControlledHiddenInput
          ref={inputRef}
          value={internalValue}
          focused={focused}
          disabled={props.disabled}
          onChange={setInternalValue}
          onFocus={() => setFocused(true)}
          onEnter={!props.noSaveOnEnter ? (() => {
            save()
            inputRef.current?.blur();
          }) : undefined}
          controls={(
            <span className={classNames("flex items-center", css({
              opacity: (focused || updating) ? 1 : 0,
              pointerEvents: focused ? 'initial' : 'none'
            }))}>
              {
                !updating ?
                  <>
                    <Icon name={EIconName.X} size={20} activeColor={themeColors.red} onClick={() => {
                      revert()
                      props.onBlur?.();
                    }} />
                    <Icon name={EIconName.Checkmark} size={14} color={themeColors.white} activeColor={themeColors.green} className={"ml2"} onClick={async () => {
                      props.onBlur?.();
                      save();
                    }} />
                  </> :
                  <span className={"vocalid-white"}>
                    <CircularProgress size={20} color={"inherit"} />
                  </span>
              }
            </span>
          )}
        />
      </ClickAwayListener>
    </span>
  );
};

interface ControlledHiddenInputProps {
  value: string;
  onChange(newValue: string): void;
  focused: boolean;
  onFocus(): void;
  onBlur?(): void;
  onEnter?(): void;
  disabled?: boolean;
  controls?: React.ReactNode;
  className?: string;
}

const ControlledHiddenInput = React.forwardRef<HTMLInputElement, ControlledHiddenInputProps>((props, ref) => {
  const { colors: themeColors } = useTheme()

  const focusedCss = React.useMemo(() => ({
    outline: '1px solid',
    paddingLeft: 4,
    paddingRight: 4,
    outlineColor: themeColors.white,
  }), []);

  return (
    <span className={classNames("flex items-center", props.className)}>
      <input ref={ref} disabled={props.disabled} value={props.value} onChange={({ target: { value: newValue } }) => props.onChange(newValue)} onKeyDown={e => {
        if (e.key === 'Enter') {
          if (props.onEnter) {
            props.onEnter();
            e.preventDefault()
            e.stopPropagation()
          }
        }
      }} onFocus={props.onFocus} onBlur={props.onBlur} className={classNames(css({
        //"bn bg-transparent outline-0 color-inherit",
        border: 'none',
        borderRadius: 4,
        paddingTop: 4,
        paddingBottom: 4,
        background: 'transparent',
        outline: 0,
        color: 'inherit',
        width: '100%',
        '&:hover': !props.focused && !props.disabled ? {
          outline: '1px solid',
          paddingLeft: 4,
          paddingRight: 4,
          outlineColor: themeColors.iconInactive,
        } : {},
        ...(props.focused && focusedCss)
      }))} />
      <span className={"ml2"}>{props.controls}</span>
    </span>
  );
});
