import React from "react";
import Dialog from "../../../components/molecules/dialog";
import Input from "../../../components/atoms/input";
import { PrimaryButton, TextButton } from "../../../components/atoms/button";
import { CircularProgress } from "@material-ui/core";
import FullPageLoader from "../../shared/loader";
import VoiceSelect from "../voice-select";
import { useEnterpriseContextualState } from "../../../components/templates/enterprise";
import { CreateProjectQuery, useCreateProjectMutation, useCreateProjectQuery } from "../../../graphql/generated";
import Carousel from "../../../components/molecules/carousel";
import { css } from "emotion";
import classNames from "classnames";
import Icon, { EIconName } from "../../../components/atoms/icon";
import { useTheme } from "../../../theme";
import Select from "../../../components/molecules/select";
import { iconForProjectType } from "../../shared/icons";
import { sortBy } from "lodash";

export interface ICreateProjectDialogProps {
  isShown: boolean;
  onCancel(): void;
  onCreated(projectId: string, voiceId: string): void;
  className?: string;
}

const CreateProjectDialog: React.FC<ICreateProjectDialogProps> = (props) => {
  const appState = useEnterpriseContextualState();
  const createProjectQuery = useCreateProjectQuery({ variables: { account: appState.state.selectedAccount! }, skip: !appState.state.selectedAccount });

  const [createProjectMutation] = useCreateProjectMutation()

  const [projectName, setProjectName] = React.useState("")
  const [projectVoice, setProjectVoice] = React.useState<string | null>(null);
  const [projectType, setProjectType] = React.useState("");

  React.useEffect(() => {
    if (createProjectQuery.data) {
      if (createProjectQuery.data.projectTypes[0]) {
        setProjectType(createProjectQuery.data.projectTypes[0].id)
      }
    }
  }, [createProjectQuery.data]);

  React.useEffect(() => {
    setProjectName("");
    setProjectVoice(null);
    setProjectType("");
  }, [props.isShown])

  const [creating, setCreating] = React.useState(false)

  const onCreate = async () => {
    if (!appState.state.selectedAccount) throw Error("unexpected - account not selected before attempting to create project")

    setCreating(true);
    const createdProject = await createProjectMutation({
      variables: {
        input: {
          title: projectName,
          defaultVoice: projectVoice!,
          account: appState.state.selectedAccount,
          type: projectType!
        }
      }
    })

    if (!createdProject.data?.createProject.project) throw Error("unexpected - could not create project")

    await props.onCreated(createdProject.data?.createProject.project.id, projectVoice!)
    setCreating(false);
  }

  const wouldExceedMaxProjects = createProjectQuery.data ?
    createProjectQuery.data.account.allRoleRestrictions.maxProjects != null &&
    createProjectQuery.data.account.projectsCount + 1 > createProjectQuery.data.account.allRoleRestrictions.maxProjects : false;

  return (
    <Dialog
      isShown={props.isShown}
      heading={<div className={'mr5'}>Create New Project</div>}
      maxHeight={650}
      className={props.className}
    >
      {(!createProjectQuery.data || createProjectQuery.loading) ? <FullPageLoader /> :
        <>
          {!wouldExceedMaxProjects ?
            <>
              <div className={'vocalid-h3 mb2'}>Name</div>
              <Input value={projectName} onChange={(value) => setProjectName(value)} white className={'mb4'} />
              <div className={"flex justify-between"}>
                <div className={classNames("flex w-40 pt2 pb3 ph3 b--vocalid-tile bt bl br", css({
                  borderRadius: 5,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0
                }))}>
                  <Select
                    label={<span className={"vocalid-dark-text"}>Project Type</span>}
                    color={'darkText'}
                    value={projectType ?? ''}
                    onChange={setProjectType}
                    className={'dib v-top flex-grow-1'}
                  >
                    {sortBy(createProjectQuery.data.projectTypes, p => p.created).map(projectType =>
                      ({ value: projectType.id, label: projectType.title }))}
                  </Select>
                </div>
                <div className={classNames("flex justify-end flex-grow-1 pt2 pl5 b--vocalid-tile bb", css({
                  borderRadius: 5,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0
                }))}>
                  <VoiceSelect label={<span className={"vocalid-dark-text"}>Default VoiceDubb</span>} selectedVocalId={projectVoice} selectedProjectTypeId={projectType} onSelect={setProjectVoice} color={'darkText'} className={"mb4"} />
                </div>
              </div>
              <div className={classNames("b--vocalid-tile bb bl br pv3", css({
                borderRadius: 5,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0
              }))}>
                <div className={css({ maxWidth: 500 })}>
                  <ProjectTypeSelect projectTypes={createProjectQuery.data.projectTypes} selected={projectType} onSelect={setProjectType} />
                </div>
              </div>
            </> :
            <div>
              Creating an additional project would exceed your max project limit of {createProjectQuery.data.account.allRoleRestrictions.maxProjects}.
            </div>
          }
          <div className={'flex justify-end mt4'}>
            <TextButton
              dark
              onClick={() => {
                props.onCancel();
              }}
              className={'mr3'}
            >
              CANCEL
            </TextButton>
            <PrimaryButton
              disabled={projectVoice === "" || projectVoice === null || /^\s*$/.test(projectName) || wouldExceedMaxProjects}
              onClick={() => onCreate()}
            >
              {creating ? <CircularProgress /> : <span>CREATE</span>}
            </PrimaryButton>
          </div>
        </>
      }
    </Dialog>
  );
};

export default CreateProjectDialog;

interface ProjectTypeSelectProps {
  projectTypes: CreateProjectQuery["projectTypes"];
  selected: string;
  onSelect(type: string): void;
  className?: string;
}

const ProjectTypeSelect: React.FC<ProjectTypeSelectProps> = props => {
  const { colors: themeColors } = useTheme()

  const collection = props.projectTypes;

  const [currentCarouselIndex, setCurrentCarouselIndex] = React.useState<number>(0)
  React.useEffect(() => {
    const selectedIndex = collection.findIndex(projectType => projectType.id === props.selected)

    if (selectedIndex !== -1) {
      setCurrentCarouselIndex(selectedIndex)
    }
  }, [props.selected])

  return (
    <Carousel
      selectedItem={currentCarouselIndex}
      onChange={newIndex => {
        setCurrentCarouselIndex(newIndex);
        props.onSelect(collection[newIndex]!.id);
      }}
      hideIndicator
      // arrowClassName={css({
      //   top: '-75px !important'
      // })}
      itemClassName={css({
        padding: '0 30px 0 30px !important',
        height: '100%'
      })}
    >
      {sortBy(collection, p => p.created).map((projectType, i) => {
        return (
          <div key={projectType.id} className={classNames("flex h-100 pv2 items-stretch", css({
            width: 450
          }))}>
            <div
              className={"flex-shrink-0 flex flex-column items-center vocalid-dark-text tc ph3"}>
              <Icon name={iconForProjectType(projectType.title)} color={themeColors.darkText} size={36} className={classNames("flex-shrink-0", css({
                marginTop: 10
              }))} />
            </div>
            <div className={"ml3 pl2 pr4 tl flex flex-column"}>
              <h3 className={"vocalid-h3 mt0 mb2"}>{projectType.title}</h3>
              <div>{projectType.description}</div>
            </div>
          </div>
        );
      })}
    </Carousel>
  );
};
