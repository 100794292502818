import React from 'react';
import TalentTemplate from '../../components/templates/talent';
import { useAppContextQuery } from '../../graphql/generated';
import FullPageLoader from '../shared/loader';
import GenericErrorBoundary from "../../components/molecules/generic-error-boundary";
import classNames from "classnames";
import { css } from "emotion";
import Tile from "../../components/atoms/tile";

export interface ITalentTemplateContainerProps { }

const TalentTemplateContainer: React.FC<ITalentTemplateContainerProps> = (props) => {
  const { data: appContextData, loading: appContextDataLoading } = useAppContextQuery()

  // const { perform: acceptTalentConsent } = useAcceptTalentConsentMutation()

  if (appContextDataLoading || !appContextData?.viewer) return <FullPageLoader />

  return (
    <TalentTemplate
      data={{
        user: {
          firstName: appContextData.viewer.user.firstName,
          lastName: appContextData.viewer.user.lastName,
          emailAddress: appContextData.viewer.user.emailAddress,
        },
        // signedConsentDate: appContextData.viewer.user.talent?.talent?.talentConsentAccepted ? new Date(appContextData.viewer.user.talent?.talent?.talentConsentAccepted) : null
      }}
      /*onAgreeToTalentConsentPdf={async () => {
        await acceptTalentConsent({
          variables: {
            talent: appContextData.viewer!.user.talent!.talent.id
          }
        });
      }}*/
      onSignOutReturnTo={`${window.location.origin}/talent/signed-out`}
    >
      <GenericErrorBoundary>
        {
          appContextData.viewer.user.talent ? props.children :
          <div className={classNames("flex justify-center items-start vocalid-secondary-text", css({
          paddingTop: '15%'
        }))}>
            <Tile heading={<div className={'vocalid-h2 mr2'}>No Talent Account Found for User</div>}>Contact your account owner to gain access.</Tile>
          </div>
        }
      </GenericErrorBoundary>
    </TalentTemplate>
  );
};

export default TalentTemplateContainer;
