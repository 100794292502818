import React, { FormEvent } from "react";
import classNames from "classnames";
import { css } from "emotion";
import { useTheme } from "../../../theme";
import ReactInputMask, { Props as ReactInputMaskProps } from "react-input-mask";
import Tooltip from "../tooltip";
import Icon, { EIconName } from "../icon";
import Checkbox from "../checkbox";

export interface InputValidationResultError {
  error: string;
}

export interface InputValidationResultWarning {
  warning: string;
}

export type InputValidationResult =
  | InputValidationResultError
  | InputValidationResultWarning
  | true;

export type InputMask = ReactInputMaskProps["mask"];

export interface FormInputBaseProps {
  value: string;
  onChange?(newValue: string): void;
  onBlur?(): void;
  onFocus?(): void;
  onKeyDown?(key: string): void;
  onKeyPress?(key: string): void;
  validationResult?: InputValidationResult;
}

export interface IInputProps extends FormInputBaseProps {
  type?: string;

  placeholder?: string;
  white?: boolean;
  label?: React.ReactNode;
  validation?: (value: string | null) => InputValidationResult;
  size?: 'small' | 'medium';

  mask?: InputMask;
  maskChar?: string;
  disabled?: boolean;

  className?: string;
}

const Input = React.forwardRef<HTMLInputElement, IInputProps>(
  (props, ref) => {
    const { colors: themeColors } = useTheme();
    const [validationResult, setValidationResult] = React.useState<InputValidationResult>(
      props.validationResult ?? (props.validation ? props.validation(props.value) : true)
    );

    const [isFocused, setIsFocused] = React.useState(false);

    React.useEffect(() => {
      if (props.validationResult !== undefined) {
        setValidationResult(props.validationResult);
      }
    }, [props.validationResult]);

    React.useEffect(() => {
      if (props.validation) {
        setValidationResult(props.validation(props.value));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value, props.validation]);

    const validationColor =  validationResult !== true ?
      (validationResult.hasOwnProperty('error') ? themeColors.red : themeColors.orange) : undefined

    const inputProps: Partial<React.HTMLProps<HTMLInputElement>> = {
      placeholder: props.placeholder,
      type: props.type,
      value: props.value ?? '',
      readOnly: !props.onChange,
      onChange: (e: FormEvent<HTMLInputElement>) =>
        props.onChange?.((e.target as HTMLInputElement).value),
      onBlur: () => {
        setIsFocused(false);
        props.onBlur?.();
      },
      onFocus: () => {
        setIsFocused(true);
        props.onFocus?.();
      },
      onKeyDown: e => props.onKeyDown?.(e.key),
      onKeyPress: e => props.onKeyPress?.(e.key),
      className: classNames(
        css({
          minHeight: props.size === 'small' ? 25 : 40,
          backgroundColor: !props.white ? '#376071' : themeColors.primaryText,
          borderColor: !props.white ? validationColor ?? themeColors.secondaryText : 'transparent',
          '&:focus': {
            outlineColor: validationColor
          },
          color: !props.white ? themeColors.secondaryText : themeColors.darkText,
          fontSize: 14,
          '&::placeholder': {
            color: 'inherit',
            opacity: 0.6
          },
          opacity: !props.disabled ? 1 : 0.3
        }),
        'db pv2 ph2 w-100 ba'
      ),
      style: {
        borderRadius: 4
      }
    };

    const validationTooltipContent = (
      <div>
        {validationResult.hasOwnProperty('error') ? (
          <span>
            {(validationResult as InputValidationResultError).error}
          </span>
        ) : (
          <span>
            {(validationResult as InputValidationResultWarning).warning}
          </span>
        )}
      </div>
    )

    const labelArea = (
      <div className={"flex justify-between"}>
        {props.label ? (
          <label
            className={classNames(
              'dib vocalid-h3 vocalid-secondary-text mb1',
              css({ margin: '0px 2px 5px 2px' })
            )}
          >
            {props.label}
          </label>
        ) : <span />}
        <Tooltip
          disabled={isFocused}
          tooltipContent={validationTooltipContent}
          portalEl={document.getElementById("tooltip-container") ?? undefined}
        >
          {validationResult !== true ?
            validationResult.hasOwnProperty('error') ? <Icon name={EIconName.X} color={themeColors.red} size={16} />
              : <Icon name={EIconName.Warning} color={themeColors.orange} size={16} />
            : null}
        </Tooltip>
      </div>
    )

    const validationErrorArea = (
      <Tooltip
        visible={isFocused && validationResult !== true}
        placement={'bottom-start'}
        tooltipContent={validationTooltipContent}
        portalEl={document.getElementById("tooltip-container") ?? undefined}
        tooltipElStyle={{
          position: 'relative',
          top: -12
        }}
      >
        <div />
      </Tooltip>
    )

    return (
      <div className={classNames('relative db', props.className)}>
        {labelArea}
        {!props.mask ? (
          <input ref={ref} {...inputProps} />
        ) : (
          <ReactInputMask
            inputRef={ref}
            mask={props.mask}
            maskChar={props.maskChar}
            {...inputProps as any}
          />
        )}
        {validationErrorArea}
      </div>
    );
  }
);

export default Input;
