import React from "react";
import { AdMixContents, IAdMixSoundTemplate, PlaylistItemData } from "../index";
import { isEqual } from "lodash";
import { DragDropContext, Draggable, DraggableLocation, Droppable, DropResult } from "react-beautiful-dnd";
import { PlaylistPauseItem, PlaylistTakeItem } from "../playlist";
import { css } from "emotion";
import { useTheme } from "../../../../../../theme";
import classNames from "classnames";
import Icon, { EIconName } from "../../../../../atoms/icon";
import { colorForSoundTemplateTag, SoundTemplateButton, SoundTemplateInfoTooltip } from "./template";

export interface AdContentSidebarProps {
  contents: AdMixContents;
  soundTemplates: Array<IAdMixSoundTemplate> | undefined;
  onChange(contents: AdMixContents): void;
  onOpenCoolBar(): void;
  className?: string;
}

const AdContentSidebar: React.FC<AdContentSidebarProps> = props => {
  const { colors: themeColors } = useTheme();

  const [sections, _setSections] = React.useState<AdMixContents["sections"]>(props.contents.sections);
  React.useEffect(() => {
    if (!isEqual(props.contents.sections, sections)) {
      _setSections(props.contents.sections);
    }
  }, [props.contents.sections])

  const setSections = (newSections: AdMixContents["sections"]) => {
    _setSections(newSections);
    props.onChange({
      ...props.contents,
      sections: newSections
    });
  }

  const setSectionItems = (sectionKey: string, newItems: Array<PlaylistItemData>) => {
    const newSections = sections.map(s => s.key === sectionKey ? {
      ...s,
      items: newItems
    } : s)

    setSections(newSections)
  }

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const source: DraggableLocation = result.source;
    const destination: DraggableLocation = result.destination;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const sourceSectionIndex = sections.findIndex(s => s.key === source.droppableId);
    const destinationSectionIndex = sections.findIndex(s => s.key === destination.droppableId);

    let newSections = [...sections];
    const [sourceItem] = newSections[sourceSectionIndex].items.splice(source.index, 1);
    newSections[destinationSectionIndex].items.splice(destination.index, 0, sourceItem);

    setSections(newSections);
  };

  const allSectionsAreEmpty = sections.reduce((acc, section) => acc && section.items.length === 0, true)

  const selectedSoundTemplate = props.soundTemplates && props.soundTemplates.find(t => t.id === props.contents.soundTemplateId)

  return (
    <div>
      <div className={"relative mb4"}>
        {allSectionsAreEmpty && <div className={classNames(css({
          position: 'absolute',
          top: 38,
          left: 20,
          right: 20,
          bottom: 38,
          backgroundColor: themeColors.tile,
          borderRadius: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }), "vocalid-h3 tc ph4")}>
          <div className={"mb4"}>Your Mix is currently empty.</div>
          <div className={"mb3"}>
            <div>Add Takes to your Mix by pressing</div>
            <div className={"flex justify-center items-center vocalid-icon-active mv3"}><Icon name={EIconName.AddToMix} color={"inherit"} className={"mr2"}/>Add to Mix</div>
            <div>in the Take list of a Clip.</div>
          </div>
        </div>}
        <div>
          <DragDropContext onDragEnd={onDragEnd}>
            {sections.map(section => {
              return (
                <div key={section.key} className={classNames("vocalid-body", css({
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: themeColors.ad,
                  borderRadius: 5,
                  marginBottom: 15,
                  marginLeft: -10,
                  marginRight: -10,
                  paddingBottom: 4
                }))}>
                  <div className={classNames("ttu b vocalid-ad", css({
                    paddingLeft: 7,
                    paddingTop: 5,
                    marginBottom: 5,
                    fontSize: 10,
                    userSelect: 'none'
                  }))}>{section.key}</div>
                  <div className={css({
                    marginLeft: 10,
                    marginRight: 10
                  })}>
                    <Droppable droppableId={section.key}>
                      {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className={css({
                          minHeight: 75
                        })}>
                          {section.items.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                              {(provided, snapshot) => (
                                <div ref={provided.innerRef} {...provided.draggableProps}>
                                  <div className={"pv2"}>
                                    {item.type === "take" ? (
                                      <PlaylistTakeItem
                                        take={item}
                                        grabberProps={provided.dragHandleProps}
                                        onRemove={() => {
                                          setSectionItems(section.key, section.items.filter(it => it.id !== item.id));
                                        }} />
                                    ) : (
                                      <PlaylistPauseItem
                                        durationMs={item.duration}
                                        grabberProps={provided.dragHandleProps}
                                        onChangeDuration={newPauseMs => {
                                          setSectionItems(section.key, section.items.map(i => i.id === item.id ? {
                                            ...i,
                                            duration: newPauseMs
                                          } : i));
                                        }}
                                        onRemove={() => {
                                          setSectionItems(section.key, section.items.filter(it => it.id !== item.id));
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          { provided.placeholder }
                        </div>
                      )}
                    </Droppable>
                  </div>
                </div>
              );
            })}
          </DragDropContext>
        </div>
      </div>
      <div>
        { selectedSoundTemplate && <div className={"flex flex-wrap justify-center items-center mb2"}>
          <span className={"mr3"}>Selected Template: </span>
          <span>
            <SoundTemplateButton template={selectedSoundTemplate} onClick={props.onOpenCoolBar} /> <SoundTemplateInfoTooltip className={"ml2 v-mid"} />
          </span>
        </div> }
      </div>
    </div>
  );
};

export default AdContentSidebar;
