import React from 'react';
import classNames from 'classnames';
import { css } from 'emotion';
import { PrimaryButton } from "../../atoms/button";
import Tile from "../../atoms/tile";
import { useSessionState } from "../../../utils/hooks/session-state";
import useMedia from 'react-use/lib/useMedia'

export interface IAssertScreenSizeTemplateProps {

}

const AssertScreenSizeTemplate: React.FC<IAssertScreenSizeTemplateProps> = (props) => {
  const [bypassed, setBypassed] = useSessionState("bypassed-screen-size-warning", false);

  const isSmall = useMedia('(max-width: 950px)');

  if (isSmall && !bypassed) {
    return (
      <div className={classNames("flex justify-center items-start vocalid-secondary-text", css({
        paddingTop: '15%'
      }))}>
        <Tile heading={<div className={'vocalid-h2 mr2'}>Window too small</div>}>
          <div className={"mb2"}>Parrot Studio does not currently support browser windows smaller than 950px.</div>
          <div>UI elements may behave in unexpected ways.</div>
          <div className={"mt4 tc flex justify-center"}>
            <PrimaryButton onClick={() => setBypassed(true)}>Proceed</PrimaryButton>
          </div>
        </Tile>
      </div>
    )
  } else {
    return <>{props.children}</>;
  }

  /*return (
    <div className={"flex flex-column flex-grow-1"}>
      {!bypassed && <div className={css({
        display: 'none',
        '@media (max-width: 950px)': {
          display: 'block'
        }
      })}>
        <div className={classNames("flex justify-center items-start vocalid-secondary-text", css({
          paddingTop: '15%'
        }))}>
          <Tile heading={<div className={'vocalid-h2 mr2'}>Window too small</div>}>
            <div className={"mb2"}>Parrot Studio does not currently support browser windows smaller than 950px.</div>
            <div>UI elements may behave in unexpected ways.</div>
            <div className={"mt4 tc flex justify-center"}>
              <PrimaryButton onClick={() => setBypassed(true)}>Proceed</PrimaryButton>
            </div>
          </Tile>
        </div>
      </div>
      }
      <div className={classNames("flex-grow-1", !bypassed && css({
        display: 'block',
        '@media (max-width: 950px)': {
          display: 'none'
        }
      }))}>
        {props.children}
      </div>
    </div>
  );*/
};

export default AssertScreenSizeTemplate;
