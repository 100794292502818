import { gql, useQuery } from "@apollo/client";
import { PaginationInput, VoicesQuery, VoicesQueryVariables } from "../generated";
import { useEnterpriseContextualState } from "../../components/templates/enterprise";

const query = gql`
query VoicesQuery(
  $accountId: String!,
  $pagination: PaginationInput!
) {
  account(where: { id: $accountId }) {
    id
    allRoleRestrictions {
      synthesisApiAccess
    }
    allVoiceEntitlements(input: { pagination: $pagination }) {
      totalResults
      results {
        ...EnterpriseVoiceFragment
      }
    }
  }
}

fragment EnterpriseVoicesTalentFragment on Talent {
  id
  firstName
  lastName
}

fragment EnterpriseVoiceFragment on Voice {
  id
  created
  title
  countOfClips(account: $accountId)
  countOfTakes(account: $accountId)
  
  age
  gender
  ethnicIdentity
  desiredProjectCategories
  brandConflicts
  limitations

  projectTypeWhitelist {
    id
    title
  }
  
  talent {
    id
    ...EnterpriseVoicesTalentFragment
  }
}
`

export const useVoicesQuery = (pagination: PaginationInput) => {
  const enterprise = useEnterpriseContextualState()

  return useQuery<VoicesQuery, VoicesQueryVariables>(
    query,
    { variables: { accountId: enterprise.state.selectedAccount || "", pagination },
    skip: !enterprise.state.selectedAccount}
  )
}
