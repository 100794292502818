import { FormInputBaseProps, IInputProps } from "../atoms/input";
import { FormikConfig, FormikProps, FormikValues } from 'formik/dist/types';
import { useFormik } from 'formik';
import { IMultiSelectProps, ISelectProps } from "../molecules/select";
import { ICheckboxProps } from "../atoms/checkbox";

export type ControlledFormInputProps = Pick<
  FormInputBaseProps,
  'value' | 'onChange' | 'onBlur' | 'validationResult'
>;

export type ControlledFormSelectProps<V extends ISelectProps['value']> = Pick<
  ISelectProps,
  'onChange' | 'onBlur' | 'validationResult'
> & {
  value: V;
};

export type ControlledFormMultiSelectProps<V extends IMultiSelectProps['value']> = Pick<
  IMultiSelectProps,
  'onChange' | 'onBlur' | 'validationResult'
  > & {
  value: V;
};

export type ControlledFormCheckboxProps = Pick<
  ICheckboxProps,
  'checked' | 'onChange' | 'onBlur'
  >;

export interface UseFormConfig<Values extends FormikValues> extends FormikProps<Values> {
  inputProps(key: keyof Values, defaultValue?: FormInputBaseProps['value']): ControlledFormInputProps;
  selectProps<V extends ISelectProps['value']>(key: keyof Values, defaultValue?: V): ControlledFormSelectProps<V>;
  multiSelectProps<V extends IMultiSelectProps['value']>(key: keyof Values, defaultValue?: V): ControlledFormMultiSelectProps<V>;
  checkboxProps(key: keyof Values): ControlledFormCheckboxProps;
}

export function useForm<Values extends FormikValues = FormikValues>(
  config: FormikConfig<Values>
): UseFormConfig<Values> {
  const formik = useFormik<Values>(config);

  return {
    ...formik,
    inputProps(key: keyof Values, defaultValue?: FormInputBaseProps['value']): ControlledFormInputProps {
      // noinspection DuplicatedCode
      return {
        value: formik.values[key] ?? defaultValue ?? "",
        onChange(newValue: string) {
          formik.setFieldTouched(key as string, true);
          return formik.handleChange(key)(newValue);
        },
        onBlur() {
          formik.setFieldTouched(key as string, true);
        },
        validationResult:
          formik.errors[key] !== undefined && formik.touched[key]
            ? { error: formik.errors[key] as string }
            : true
      };
    },
    selectProps<V extends ISelectProps['value']>(key: keyof Values, defaultValue?: V): ControlledFormSelectProps<V> {
      // noinspection DuplicatedCode
      return {
        value: (formik.values[key] ?? defaultValue ?? "") as V,
        onChange(newValue: string) {
          console.log(`Select value changed "${key}": ${JSON.stringify(newValue)}`)
          formik.setFieldTouched(key as string, true);
          formik.setValues({ ...formik.values, [key]: newValue }, true);
        },
        onBlur() {
          formik.setFieldTouched(key as string, true);
        },
        validationResult:
          formik.errors[key] !== undefined && formik.touched[key]
            ? { error: formik.errors[key] as string }
            : true
      };
    },
    multiSelectProps<V extends IMultiSelectProps['value']>(key: keyof Values, defaultValue?: V): ControlledFormMultiSelectProps<V> {
      // noinspection DuplicatedCode
      return {
        value: (formik.values[key] ?? defaultValue ?? []) as V,
        onChange(newValue: string[]) {
          formik.setFieldTouched(key as string, true);
          formik.setValues({ ...formik.values, [key]: newValue }, true);
        },
        onBlur() {
          formik.setFieldTouched(key as string, true);
        },
        validationResult:
          formik.errors[key] !== undefined && formik.touched[key]
            ? { error: formik.errors[key] as string }
            : true
      };
    },
    checkboxProps(key: keyof Values): ControlledFormCheckboxProps {
      // noinspection DuplicatedCode
      return {
        checked: formik.values[key] ?? false,
        onChange(e) {
          formik.setFieldTouched(key as string, true);
          return formik.handleChange(key)(e);
        },
        onBlur() {
          formik.setFieldTouched(key as string, true);
        }
      };
    },
  };
}
