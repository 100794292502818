import * as React from 'react';
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink, split } from "@apollo/client";
import FullPageLoader from "../containers/shared/loader";
import { useAuth } from "../auth";
import fragmentMatcher from './generated_fragments';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from "@apollo/client/utilities";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
export const API_DOCS_URL = process.env.REACT_APP_API_BASE_URL + "/docs"
export const API_WS_BASE_URL = process.env.REACT_APP_WS_BASE_URL

const cache = new InMemoryCache({
  possibleTypes: fragmentMatcher.possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        voice: {
          merge: false
        }
      },
    },
    Account: {
      fields: {
        allRoleRestrictions: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
      },
    },
    Mix: {
      fields: {
        content: {
          merge: false
        }
      }
    },
    RoleFeatureFlag: {
      keyFields: ["key"]
    }
  },
})


export function buildClient(accessToken: string | null) {
  const httpLink = new HttpLink({
    uri: `${API_BASE_URL}/graphql`,
    headers: {
      ...(accessToken ? {
        "X-Vocalid-Parrot-Access-Token": accessToken
      } : {})
    }
  });

  // const wsLink = new WebSocketLink({
  //   uri: `${API_WS_BASE_URL}/subscriptions`,
  //   options: {
  //     reconnect: true,
  //     connectionParams: {
  //       ...(accessToken ? {
  //         Authorization: accessToken
  //       } : {})
  //     }
  //   }
  // });

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
//   const splitLink = split(
//     ({ query }) => {
//       const definition = getMainDefinition(query);
//       return (
//         definition.kind === 'OperationDefinition' &&
//         definition.operation === 'subscription'
//       );
//     },
//     wsLink,
//     httpLink,
//   );

  return new ApolloClient({
    link: httpLink,
    cache
  });
}

export const GraphQLProvider: React.FC<{
  requireAuth: boolean;
}> = (props) => {
  const [initialized, setInitialized] = React.useState(false);
  const { accessToken, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth();

  React.useEffect(
    () => {
      if (!isLoading) {
        (async () => {
          // initialize (get access token silently if authenticated or (if required) login)
          if (!isAuthenticated && props.requireAuth) {
            await loginWithRedirect(window.location.pathname + window.location.search);
          } else if (isAuthenticated) {
            await getAccessTokenSilently();
          }

          setInitialized(true);
        })()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]
  )

  if (!initialized || isLoading || (accessToken === null && props.requireAuth)) return <FullPageLoader />

  const client = buildClient(accessToken)

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
