import React from 'react';
import AudioPlayer from '../../../../../molecules/audio-player';
import Icon, { EIconName } from '../../../../../atoms/icon';
import classNames from 'classnames';
import { css } from 'emotion';
import { useTheme } from '../../../../../../theme';
import {CircularProgress} from "@material-ui/core";
import { FeatureLock } from "../../../../../../utils/feature-locking";
import { useMixTakeRenderedListener } from '../../../../../../graphql/subscriptions/MixTakeRendered';
import { MixTakeData } from "../index";
import Tooltip from "../../../../../atoms/tooltip";

export interface IPlaylistTakeProps {
  take: MixTakeData;
  primary?: boolean;
  hovering?: boolean;
  className?: string;
  preload?: boolean;
  playerButtonOnly?: boolean;

  onRendered?(): void;
  onClick?(): void;
  onDelete(): void;
}

const PlaylistTake: React.FC<IPlaylistTakeProps> = ({ className, ...props }) => {
  const { url, error } = useMixTakeRenderedListener(props.take.id, props.take.renderedUrl != null || props.take.error != null)

  const effectiveUrl = props.take.renderedUrl ?? url
  const effectiveError = props.take.error ?? error

  const { colors: themeColors } = useTheme();
  const [hovering, setHovering] = React.useState(false)

  const primaryCss = props.primary && css({
    background: themeColors.tileAlt,
    zIndex: 1,
    outlineColor: themeColors.iconActive,
    outlineWidth: 2,
    outlineStyle: 'solid'
  })

  const hoveringCss = hovering && css({
    background: themeColors.tileAlt,
    zIndex: 1
  })

  const sectionCss = css({
    paddingTop: 12,
    paddingBottom: 6,
    paddingLeft: '.5rem',
    paddingRight: '.5rem'
  })

  const rendering = !effectiveUrl && !error;

  return (
    <div className={classNames('ph2 flex items-center vocalid-tertiary-text pointer', css({ background: themeColors.tile, borderRadius: 4 }), primaryCss, hoveringCss, className)} onClick={() => props.onClick && props.onClick()} onMouseOut={() => setHovering(false)} onMouseOver={() => setHovering(true)}>
      <div className={classNames(sectionCss, "flex-shrink-0")}>
        <strong className={'db ml2 mb1 mr3 vocalid-list-title'}>Render {props.take.number}</strong>
        <span className={classNames('db ml2 mb2 mr3', css({
          fontWeight: 300,
          fontSize: '12px'
        }))}>{props.take.createdAt.toLocaleString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "2-digit",
          hour: "2-digit",
          hour12: true,
          minute: "2-digit"
        })}</span>
      </div>
      <div className={classNames(sectionCss, "flex-grow-1 overflow-x-hidden")} onClick={e => {
        e.stopPropagation()
      }}>
        <div className={css({
          paddingTop: 8,
          paddingBottom: 5
        })}>
          { effectiveError ? <Tooltip tooltipContent={
            <div>Error: {effectiveError}</div>
          }><Icon name={EIconName.Warning} className={'ph2'} /></Tooltip> : null }
          { (rendering && !effectiveError) ? <CircularProgress color={"inherit"}/> : null }
          {(!rendering && effectiveUrl) ? <AudioPlayer buttonOnly={props.playerButtonOnly} src={effectiveUrl} preload={props.preload} className={'w-100'} /> : null }
        </div>
      </div>
      <div className={classNames("flex-shrink-0 flex items-center ml4", css({ marginTop: 8 }))}>
        { (!rendering) ? <_TakeActions { ...props } effectiveUrl={effectiveUrl} rendering={rendering} /> : null }
      </div>
    </div>
  );
};

const _TakeActions: React.FC<IPlaylistTakeProps & {
  effectiveUrl: string | null;
  rendering: boolean;
  className?: string;
}> = (props) => {
  const { colors: themeColors } = useTheme()

  const className = classNames(
    props.rendering ?
      classNames('ph2 pv3 tr mr2 o-40', css({ pointerEvents: 'none' })) :
      'tr mr2',
    props.className)

  return (
    <div className={className}>
      { props.effectiveUrl && <FeatureLock feature={'TakeDownload'}>
        <Icon className={'ph2'} name={EIconName.Download} onClick={() => { if (props.effectiveUrl) window.location.href = props.effectiveUrl } }/>
      </FeatureLock> }
      <Icon className={'ph2'} name={EIconName.Delete} onClick={() => props.onDelete()} />
    </div>
  )
}

export default PlaylistTake;
