import React from "react";
import classNames from "classnames";
import * as queryString from "querystring";
import { css } from "emotion";
import { useTheme } from "./theme";

export interface SupportLinkProps {
  subject?: string;
  color?: string;
  className?: string
}

const SupportLink: React.FC<SupportLinkProps> = props => {
  const { colors: themeColors } = useTheme()

  return <a href={`mailto:parrot@vocalid.ai?${queryString.stringify({
    subject: props.subject ?? "PARROT STUDiO Help",
    body: ""
  })}`} className={classNames("no-underline", css({
    color: props.color ?? themeColors.link
  }), props.className)}>{props.children}</a>;
};

export default SupportLink;
