import { EAdjustment, EMagnitude } from "../../../components/organisms/clip/editor/adjustments";

export interface IWordNode {
  type: 'word';
  value: string;
  alternatePronunciation?: {
    phonetic: string[];
  } | {
    text: string;
  };
  pause: 'long' | 'short' | undefined;
  pauseInMilliseconds: number | undefined;
}

export type IAlternatePronunciation = {
  phonetic: string[];
} | {
  text: string;
}

export interface ISpaceNode {
  type: 'space';
}

export interface INewlineNode {
  type: 'newline';
}

export type IEditorNode = IWordNode | ISpaceNode | INewlineNode;

export interface IEditorAdjustmentRange {
  offset: number;
  length: number;
  type: EAdjustment;
  magnitude?: EMagnitude;
  tone: 'disappointed' | 'terse' | 'excited' | 'none' | undefined;
}

export interface IEditorValue {
  nodes: Array<IEditorNode>;
  adjustmentRanges: Array<IEditorAdjustmentRange>;
}

export const EMPTY_EDITOR_VALUE: IEditorValue = ({
  nodes: [],
  adjustmentRanges: []
})
