import React from 'react';
import SignedOutPage from '../../components/pages/signed-out';
import { useHistory } from 'react-router-dom';
import GenericErrorBoundary from "../../components/molecules/generic-error-boundary";

export interface ITalentSignedOutPageContainerProps {
  className?: string;
}

const TalentSignedOutPageContainer: React.FC<ITalentSignedOutPageContainerProps> = (
  props
) => {
  const history = useHistory();
  return (
    <GenericErrorBoundary>
      <SignedOutPage
        onSignBackIn={() => {
          history.push('/talent');
        }}
        className={props.className}
      />
    </GenericErrorBoundary>
  );
};

export default TalentSignedOutPageContainer;
