import React from 'react';
import Icon, { EIconName } from '../icon';
import classNames from 'classnames';
import { css } from 'emotion';

export interface INumberStepperProps {
  value: number;
  step?: number;
  fixed?: number;
  suffix?: React.ReactNode;
  inputEditable?: boolean;
  onChange(newValue: number): void;
  className?: string;
}

const NumberStepper: React.FC<INumberStepperProps> = (props) => {
  const coerceValue = (value: number): string => props.fixed === undefined ? value.toString() : value.toFixed(props.fixed)

  const [state, setState] = React.useState<string>(coerceValue(props.value));
  React.useEffect(() => {
    setState(coerceValue(props.value))
  }, [props.value])
  const step = props.step ?? 1;

  return (
    <div className={classNames('dib', props.className)}>
      <div
        className={classNames(
          'flex items-stretch ba black b--vocalid-tertiary-text',
          css({ borderRadius: 20 })
        )}
      >
        <button
          type="button"
          className={'pointer vocalid-tertiary-text bg-transparent bn flex-shrink-0 flex-grow-0'}
          onClick={() => props.onChange(Number(props.value - step))}
        >
          <Icon name={EIconName.Minus} size={12} className={'v-mid'} />
        </button>
        <div className={'flex-shrink-1 flex-grow-1 relative bg-vocalid-tertiary-text'}>
          { props.suffix && <span className={classNames("absolute db top-0 bottom-0 right-0 tr black pr2", css({
             paddingTop: 1,
             paddingBottom: 1
           }))}>
            {props.suffix}
          </span>}
          <input
            type="number"
            readOnly={!props.inputEditable}
            value={state}
            className={classNames(
              'w-100 h-100 bn bg-transparent tc',
              css({
                /* Chrome, Safari, Edge, Opera */
                '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                  WebkitAppearance: 'none',
                  margin: 0
                },
                /* Firefox */
                '&[type=number]': {
                  MozAppearance: 'textfield'
                }
              })
            )}
            onChange={({ target: { value: newValue } }) => {
              if (props.inputEditable) {
                setState(newValue)
                if (isNumeric(newValue)) {
                  props.onChange(Number(newValue));
                }
              }
            }}
            onBlur={() => {
              if (!isNumeric(state)) {
                setState(coerceValue(props.value))
              }
            }}
          />
        </div>
        <button
          type="button"
          className={'pointer vocalid-tertiary-text bg-transparent bn flex-shrink-0 flex-grow-0'}
          onClick={() => props.onChange(Number(props.value + step))}
        >
          <Icon name={EIconName.Plus} size={12} className={classNames('v-mid', css({
            position: 'relative',
            left: -1
          }))} />
        </button>
      </div>
    </div>
  );
};

export default NumberStepper;

// https://stackoverflow.com/a/175787
function isNumeric(str: string): boolean {
  return !isNaN(str as any) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}
