import React from 'react';
import classNames from 'classnames';
import { css } from 'emotion';

export interface ISeparatorProps {
  className?: string;
  style?: React.CSSProperties;
}

const Separator: React.FC<ISeparatorProps> = (props) => {
  return (
    <div
      className={classNames(
        css({
          height: 1,
          backgroundColor: '#13181A'
        }),
        props.className
      )}
      style={props.style}
    />
  );
};

export default Separator;
