import * as React from "react";
import ReconnectingWebSocket from "reconnecting-websocket";

/*const MIX_TAKE_RENDERED_SUBSCRIPTION = gql`
  subscription MixTakeRenderedSubscription($id: String!) {
    mixTakeRendered(id: $id) {
      ... on MixTakeRenderingSucceeded {
        url
      }
      ... on MixTakeRenderingFailed {
        error
      }
    }
  }
`


export const useMixTakeRenderedListener = (takeId: string, skip: boolean = false) => {
  const { data } = useSubscription<MixTakeRenderedSubscription, MixTakeRenderedSubscriptionVariables>(MIX_TAKE_RENDERED_SUBSCRIPTION, {
    variables: {
      id: takeId
    },
    skip: skip
  })

  return {
    url: data?.mixTakeRendered.__typename === "MixTakeRenderingSucceeded" ? data.mixTakeRendered.url : null,
    error: data?.mixTakeRendered.__typename === "MixTakeRenderingFailed" ? data.mixTakeRendered.error : null
  }
}*/

export const useMixTakeRenderedListener = (mixTakeId: string, skip: boolean = false) => {
  const rwsRef = React.useRef<ReconnectingWebSocket>()
  const [url, setUrl] = React.useState<string | null>(null)
  const [error, setError] = React.useState<string | null>(null)

  React.useEffect(() => {
    const rws = new ReconnectingWebSocket(`${process.env.REACT_APP_WS_BASE_URL}/render/mix/${mixTakeId}`);
    rwsRef.current = rws;

    rws.addEventListener('message', (message) => {
      const json = JSON.parse(message.data)

      if (json.url) {
        setUrl(json.url)
        setError(null)
      }
      if (json.error) {
        setError(json.error)
        setUrl(null)
      }
    })
  }, []);

  return {
    url,
    error
  }
}
