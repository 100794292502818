import React from "react";
import Carousel from "../../../../../molecules/carousel";
import { css, keyframes } from "emotion";
import classNames from "classnames";
import { useTheme } from "../../../../../../theme";
import TagSelect from "../../../../tag-select";
import { AdjustmentSlider } from "../../vocal-adjustments";
import { capitalize } from "lodash";
import feathers from "./feathers.png";
import AudioPlayer from "../../../../../molecules/audio-player";
import { OutlineButton } from "../../../../../atoms/button";
import { IAdMixSoundTemplate } from "../index";
import InfoTooltip from "../../../../../molecules/info-tooltip";
import Icon, { EIconName } from "../../../../../atoms/icon";
import { AdMixSoundTemplateTempo } from "../../../../../../graphql/generated";

export interface AdSoundTemplateSelectorProps {
  soundTemplates: Array<IAdMixSoundTemplate> | undefined;
  selectedTemplateId: string | null;
  renderCarousel: boolean;
  showFilters: boolean;
  onSelect(soundTemplateId: string): void;
}

const AdSoundTemplateSelector: React.FC<AdSoundTemplateSelectorProps> = props => {
  const { colors: themeColors } = useTheme()
  const tags = (props.soundTemplates ?? []).reduce((acc, curr) => Array.from(new Set([...acc, ...(curr.tags ?? [])])), [] as string[]);

  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  const [tempo, setTempo] = React.useState<AdMixSoundTemplateTempo | null>(null);

  const hasFilters = selectedTags.length !== 0 || tempo !== null;

  const reorderedSoundTemplates = props.soundTemplates ? [...props.soundTemplates].sort((a, b) => {
    return ((b.tags ?? []).filter(tag => selectedTags.includes(tag)).length + (b.tempo && tempo && b.tempo === tempo ? 1 : 0)) -
      ((a.tags ?? []).filter(tag => selectedTags.includes(tag)).length +  + (a.tempo && tempo && a.tempo === tempo ? 1 : 0))
  }) : undefined;
  const selectedIndex = reorderedSoundTemplates?.findIndex(t => t.id === props.selectedTemplateId)

  const [currentCarouselIndex, setCurrentCarouselIndex] = React.useState<number>(selectedIndex ?? 0)
  React.useEffect(() => {
    setCurrentCarouselIndex(selectedIndex ?? 0)
  }, [selectedIndex])

  return (
    <div className={classNames("flex flex-column items-center", css({
      width: 360
    }))}>
      <div className={"flex items-center mb3"}>
        <div className={css({
          width: 360 - 40,
          minHeight: 300
        })}>
          {(props.renderCarousel && reorderedSoundTemplates) ?
            <Carousel
              key={`${selectedTags.join(" ")}_${tempo}`}
              onChange={setCurrentCarouselIndex}
              selectedItem={!hasFilters ? selectedIndex : undefined}
              hideIndicator
              arrowClassName={css({
                top: '-75px !important'
              })}
              itemClassName={css({
                padding: '0 50px 0 50px !important'
              })}
            >
              {reorderedSoundTemplates.map((template, i) =>
                <SoundTemplate
                  key={template.id}
                  selected={props.selectedTemplateId === template.id}
                  animate={i >= currentCarouselIndex - 1 && i <= currentCarouselIndex + 1}
                  onSelect={() => props.onSelect(template.id)}
                  template={template}
                />
              )}
            </Carousel> : (
              <div className={"flex justify-center"}>
                <div className={css({
                  width: 220,
                  height: 220,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '0 50px 40px 50px' // same as carousel item padding
                })}>
                  <div className={css({
                    width: 143,
                    height: 143,
                    backgroundColor: themeColors.tileAlt
                  })} />
                </div>
              </div>
            )
          }
        </div>
      </div>
      <div className={"w-100 ph4"}>
        <div className={classNames("w-100", css({
          maxHeight: props.showFilters ? 400 : 0,
          overflowY: 'hidden',
          transition: 'max-height 800ms, opacity 400ms',
          opacity: props.showFilters ? 1 : 0
        }))}>
          <div className={classNames("ph3 pv3", css({
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: themeColors.tertiaryText,
            borderRadius: 5
          }))}>
            <TagSelect
              value={selectedTags}
              renderLabel={capitalize}
              menuPortalTarget={document.getElementById("select-container")!}
              placeholder={"Search Tags..."}
              onChange={setSelectedTags}
              tags={tags}
              className={classNames("mb4", css({
                display: 'block',
                width: '100%'
              }))}
            />
            <AdjustmentSlider name={"Tempo"} value={tempo ? tempoToValue(tempo) : 2} inactive={tempo === null} onMakeInactive={() => setTempo(null)} min={1} max={3} step={1} onChange={value => setTempo(valueToTempo(value))}  />
          </div>
        </div>
      </div>
    </div>
  );
};

function tempoToValue(tempo: AdMixSoundTemplateTempo): number {
  switch (tempo) {
    case AdMixSoundTemplateTempo.Down:
      return 1;
    case AdMixSoundTemplateTempo.Mid:
      return 2;
    case AdMixSoundTemplateTempo.Up:
      return 3;
  }
}

function valueToTempo(value: number): AdMixSoundTemplateTempo {
  switch (value) {
    case 1:
      return AdMixSoundTemplateTempo.Down;
    case 2:
      return AdMixSoundTemplateTempo.Mid;
    case 3:
      return AdMixSoundTemplateTempo.Up;
    default:
      throw Error(`Value not serializable to tempo: ${value}`)
  }
}

export default AdSoundTemplateSelector;


export interface SoundTemplateProps {
  template: IAdMixSoundTemplate;
  selected?: boolean;
  animate?: boolean;
  onSelect?(): void;
  className?: string;
}

const SoundTemplate: React.FC<SoundTemplateProps> = props => {
  const { colors: themeColors } = useTheme();
  const relevantTags = props.template.tags?.slice(0, 3) ?? []
  const tagColors = [relevantTags[0], relevantTags[1], relevantTags[2]].map(colorForSoundTemplateTag)

  return (
    <div>
      <div className={classNames(props.onSelect && "pointer")} onClick={() => props.onSelect?.()}>
        <GradientIcon id={`sound_template_${props.template.id}`} colors={tagColors}>
          { props.template.tempo && <g transform={"translate(186 184)"}>
            <Icon name={props.template.tempo === AdMixSoundTemplateTempo.Down ?
              EIconName.TempoSlow :
              props.template.tempo === AdMixSoundTemplateTempo.Mid ?
                EIconName.TempoMid :
                EIconName.TempoFast
            } color={"#1C2E36"} size={32} />
          </g> }
        </GradientIcon>
      </div>
      <div className={css({
        marginTop: -18
      })}>
        <div className={"tc ttc mb1 flex justify-center items-center"}>
          <span className={classNames(props.selected && "vocalid-icon-active b")}>{props.template.name}</span>
          { props.template.sampleUrl && <span className={classNames("pl1", css({
            width: 0,
            overflowX: 'visible'
          }))}>
            <AudioPlayer src={props.template.sampleUrl} buttonOnly buttonColor={props.selected ? themeColors.iconActive : undefined} className={css({
              transform: 'scale(0.8)',
              marginTop: 2
            })} />
          </span> }
        </div>
        <div className={"vocalid-tertiary-text mb3"}>{relevantTags.map(capitalize).join(", ")}</div>
        {props.selected ? <div className={"vocalid-icon-active ttu pv1"}>Selected</div> : <OutlineButton color={themeColors.iconActive} onClick={props.onSelect}>Select</OutlineButton>}
      </div>
    </div>
  );
};

interface GradientIconProps {
  id: string;
  selected?: boolean;
  colors: string[];
  animate?: boolean;
  className?: string;
}

const GradientIcon: React.FC<GradientIconProps> = props => {
  const { colors: themeColors } = useTheme();

  return (
    <svg width="220" height="220" viewBox="0 0 276 276" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1352_18313)">
        <rect x="48" y="46" width="180" height="180" fill={themeColors.header}/>
        { props.selected && <rect x="42" y="40" width="192" height="192" stroke={themeColors.iconActive} strokeWidth="16"/> }
      </g>
      <rect x="44" y="42" width="188" height="188" stroke="#1C2E36" strokeWidth="8"/>
      <mask id="mask0_1352_18313" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="48" y="46" width="180" height="180">
        <rect x="48" y="46" width="180" height="180" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask0_1352_18313)">
        <g>
          <rect x="48" y="46" width="180" height="180" fill="white"/>
          <rect x="48" y="46" width="180" height="180" fill={`url(#paint0_radial_1360_18328_${props.id})`}/>
          <rect x="48" y="46" width="180" height="180" fill={`url(#paint1_radial_1360_18328_${props.id})`}/>
          <rect x="48" y="46" width="180" height="180" fill={`url(#paint2_radial_1360_18328_${props.id})`}/>
        </g>
        <g style={{ mixBlendMode: "multiply" }}>
          <image href={feathers} x="48" y="46" height="180" width="180"/>
        </g>
      </g>
      {props.children}
      <defs>
        <filter id="filter0_d_1352_18313" x="0" y="0" width="276" height="276" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feMorphology radius="16" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_1352_18313"/>
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="10"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1352_18313"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1352_18313" result="shape"/>
        </filter>
        <radialGradient id={`paint0_radial_1360_18328_${props.id}`} cx="50%" cy="100%" r={"75%"} gradientUnits="userSpaceOnUse">
          <stop stopColor={props.colors[1]}>
            { props.animate && <animate attributeName="stop-color" values={[props.colors[1], props.colors[2], props.colors[0], props.colors[1]].join("; ")} dur="10s" repeatCount="indefinite" /> }
          </stop>
          <stop offset="1" stopColor={props.colors[1]} stopOpacity="0">
            { props.animate && <animate attributeName="stop-color" values={[props.colors[1], props.colors[2], props.colors[0], props.colors[1]].join("; ")} dur="10s" repeatCount="indefinite" /> }
          </stop>
        </radialGradient>
        <radialGradient id={`paint1_radial_1360_18328_${props.id}`} cx="100%" cy="0%" r="90%" gradientUnits="userSpaceOnUse">
          <stop stopColor={props.colors[2]}>
            { props.animate && <animate attributeName="stop-color" values={[props.colors[2], props.colors[0], props.colors[1], props.colors[2]].join("; ")} dur="10s" repeatCount="indefinite" /> }
          </stop>
          <stop offset="1" stopColor={props.colors[2]} stopOpacity="0">
            { props.animate && <animate attributeName="stop-color" values={[props.colors[2], props.colors[0], props.colors[1], props.colors[2]].join("; ")} dur="10s" repeatCount="indefinite" /> }
          </stop>
        </radialGradient>
        <radialGradient id={`paint2_radial_1360_18328_${props.id}`} cx="0%" cy="0%" r="90%" gradientUnits="userSpaceOnUse">
          <stop stopColor={props.colors[0]}>
            { props.animate && <animate attributeName="stop-color" values={[props.colors[0], props.colors[1], props.colors[2], props.colors[0]].join("; ")} dur="10s" repeatCount="indefinite" /> }
          </stop>
          <stop offset="1" stopColor={props.colors[0]} stopOpacity="0">
            { props.animate && <animate attributeName="stop-color" values={[props.colors[0], props.colors[1], props.colors[2], props.colors[0]].join("; ")} dur="10s" repeatCount="indefinite" /> }
          </stop>
        </radialGradient>
      </defs>
    </svg>
  );
};

export interface SoundTemplateButtonProps {
  template: IAdMixSoundTemplate;
  onClick?(): void;
}

export const SoundTemplateButton: React.FC<SoundTemplateButtonProps> = props => {
  const { colors: themeColors } = useTheme();

  const template = props.template;

  const gradientFlow = keyframes`
    0%{background-position:0% 0%}
    100%{background-position:100% 0%}
  `
  //    50%{background-position:100% 0%}

  return (
    <button
      onClick={props.onClick}
      className={css({
        position: 'relative',
        borderWidth: 0,
        padding: "8px 24px",
        fontSize: 14,
        lineHeight: "16px",

        borderStyle: "solid",
        borderRadius: 4,
        cursor: props.onClick ? "pointer" : "unset",
        textTransform: "capitalize",
        fontFamily: "\"Roboto\", sans-serif",
        fontWeight: 'bold',

        // borderColor: bgColor,
        background: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), linear-gradient(90deg, ${colorForSoundTemplateTag(template.tags?.[0])} 0%, ${colorForSoundTemplateTag(template.tags?.[1])} 20%, ${colorForSoundTemplateTag(template.tags?.[2])} 40%, ${colorForSoundTemplateTag(template.tags?.[0])} 60%, ${colorForSoundTemplateTag(template.tags?.[1])} 80%, ${colorForSoundTemplateTag(template.tags?.[2])} 100%)`,
        backgroundSize: '240% 100%',
        animation: `${gradientFlow} 20s linear infinite`,
        color: themeColors.header,
      })}
    >
      {template.name}
    </button>
  );
}

export const SoundTemplateInfoTooltip: React.FC<{ className?: string; }> = ({ className }) => (
  <InfoTooltip
    tooltipContent={
      <>Sound Templates are comprised of sound segments and sound effects that are applied to your entire Mix.<br/><br/>They are mixed and mastered automatically along with your speech track to create a full produced ad in seconds.</>
    }
    className={className}
  />
)

export function colorForSoundTemplateTag(tag: string | undefined): string {
  switch (tag) {
    case 'intense':
      return '#FF3A3A';
    case 'minimal':
      return '#FFFFFF';
    case 'happy':
      return '#FFD600';
    case 'energetic':
      return '#FFC225';
    case 'uplifting':
      return '#FFEE52';
    case 'workout':
      return '#F25700';
    case 'active':
      return '#FF7628';
    case 'playful':
      return '#FFF59D';
    case 'positive':
      return '#BEFE6D';
    case 'travel':
      return '#17A400';
    case 'work':
      return '#0094FF';
    case 'focus':
      return '#00C2FF';
    case 'reflective':
      return '#06F0FF';
    case 'meditation':
      return '#00FFC2';
    case 'relaxed':
      return '#006DEC';
    case 'informative':
      return '#0B14FF';
    case 'mysterious':
      return '#8F00FF';
    case 'nostalgic':
      return '#8F7CFF';
    case 'acoustic':
      return '#DDB073';
    case 'melodic':
      return '#F14BFF';
    case 'ambience':
      return '#FFE9C8';
    default:
      return "#FFFFFF";
  }
}
