import { gql, useApolloClient } from "@apollo/client";
import { DefaultPronunciationQuery, DefaultPronunciationQueryVariables } from "../generated";

const query = gql`
query DefaultPronunciationQuery(
  $voiceId: String!,
  $text: String!
) {
  voice(where:{id:$voiceId}){
    pronunciation(text: $text)
  }
}
`

export const useDefaultPronunciationQuery = () => {
  const apolloClient = useApolloClient();

  return (voiceId: string, text: string) => apolloClient.query<DefaultPronunciationQuery, DefaultPronunciationQueryVariables>({ query, variables: { voiceId, text }})
}
