import React from "react";
import classNames from "classnames";
import Select from "../../molecules/select";
import { css } from "emotion";
import Header from "../../atoms/header";
import { PrimaryButton, OutlineButton } from "../../atoms/button";
import ProjectSidebar, { IProjectSidebarRef, SidebarSelectionData } from "../../organisms/project-sidebar";
import { Clip, ClipState, IClipData } from "../../organisms/clip";
import { IProjectSubstitutionDictionaryData } from "../../organisms/project-sidebar/tabs/dictionary";
import {
  IMixSidebarActions,
  IProjectMixData,
  PlaylistMixContents,
  PlaylistItemData, MixType
} from "../../organisms/project-sidebar/tabs/mix";
import Axios from "axios";
import { API_BASE_URL } from "../../../graphql";
import { IClipVoiceAdjustmentsData } from "../../organisms/project-sidebar/tabs/vocal-adjustments";
import {
  ISlateAlternatePronunciation,
  ISlateNode,
  ISlateSelection,
  ISlateValue
} from "../../organisms/clip/editor/adjustments/internal-types";
import UploadClipSourcesDialog from "../../organisms/upload-clip-sources-dialog";
import { applySidebarAdjustmentsToEditorValue, sidebarAdjustmentsFromEditorValueSelection } from "./sidebar_adjustments";

import {
  deserializeInternalValue,
  serializeExternalValue
} from "../../../containers/pages/enterprise-project/editor-value-serialization";
import { EMPTY_EDITOR_VALUE, IEditorValue } from "../../../containers/pages/enterprise-project/editor-types";
import { useDebounceCallback } from "@react-hook/debounce";
import { selectCurrentEditor, selectCurrentEditorValue, setCurrentEditorValue } from "./editor-slice";
import { useAppDispatch, useAppSelector } from "../../../store";
import Tile from "../../atoms/tile";
import { RevertableHiddenInput } from "../../atoms/input/hidden";
import ProjectTypeTag from "../../molecules/project-type-tag";

export interface IEnterpriseProjectPageProps {
  data: {
    id: string;
    title: string;
    type: {
      id: string;
      title: string;
      description: string;
    };
    defaultVocal: {
      id: string;
      title: string;
      talent: boolean;
    };
    clips: Array<IClipData>;
    currentRenderCount: number;
    maxRenders: number | null;
    maxCharactersPerRequest: number | null;
    substitutionDictionary: IProjectSubstitutionDictionaryData;
    mixes: IProjectMixData[];
    maxPlaylists: number | null;
  };
  className?: string;

  onUpdateProjectTitle(newTitle: string): void;

  // clip operations
  onCreateClip(): void;
  onCreateClips(clips: Array<{ title: string; text: string; }>): Promise<void>;
  onUpdateClipTitle(clipId: string, newTitle: string): void;
  onDeleteClip(clipId: string): void;
  onCreateTake(clipId: string, vocalId: string, content: IEditorValue, requestTalentRecording: boolean): void;
  onDeleteTake(takeId: string): void;
  onApproveTake(takeId: string): void;
  onRejectTake(takeId: string): void;
  onTakeRendered(takeId: string): void;

  // mix operations
  mixActions: IMixSidebarActions & {
    onAddTakeToMix(mixId: string, takeId: string): Promise<void>;
  };

  // substitution dictionary operations
  onAddSubstitutionDictionaryMember(oldPronunciation: string, newPronunciation: ISlateAlternatePronunciation): Promise<void>;
  onSetSubstitutionDictionaryMemberAsGlobal(id: string, global: boolean): Promise<void>;
  onRemoveSubstitutionDictionaryMember(id: string): Promise<void>;
  onRequestDefaultPronunciation(voiceId: string, text: string): Promise<string[] | null>;

  SearchBarComponent: React.ComponentType;
}

export const contentWrapperId = 'enterprise-project-page-content-wrapper'

const EnterpriseProjectPage: React.FC<IEnterpriseProjectPageProps> = (props) => {
  const [editingClipId, setEditingClipId] = React.useState<string | null>(null);
  const sidebarRef = React.useRef<IProjectSidebarRef>(null)

  const selectedVocalIdRef = React.useRef<string>(props.data.defaultVocal.id);
  const [defaultPronunciationMap, setDefaultPronunciationMap] = React.useState<{ [word: string]: string[] }>({});
  React.useEffect(() => {
    setDefaultPronunciationMap({});
  }, [editingClipId]);
  const [selectedMixId, setSelectedMixId] = React.useState<string | null>(props.data.mixes[props.data.mixes.length - 1]?.id ?? null);
  React.useEffect(() => {
    setSelectedMixId(props.data.mixes[props.data.mixes.length - 1]?.id ?? null)
  }, [props.data.mixes.length])

  const numberOfClipsToPreload = 3

  const selectedMix = props.data.mixes.find(mix => selectedMixId === mix.id)

  return (
    <div className={classNames('h-100', props.className)}>
      <div className={'flex items-start h-100'}>
        <div className={"relative flex-grow-1 h-100 overflow-x-hidden"}>
          <div id={contentWrapperId} className={classNames("absolute top-0 left-0 right-0 bottom-0 z-1", css({ pointerEvents: 'none' }))} />
          <div className={'relative h-100 overflow-y-auto z-0'}>
            <PageHeader {...props}/>
            <div className={'mh4 mb4 mt3'}>
              <PageControls {...props} />
              <div className={'pb4'}>
                <div>
                  { props.data.clips.length !== 0
                      ? props.data.clips.map((clipData, index) => {
                        const isEditing = clipData.id === editingClipId;

                        return (
                          <PageClip
                            key={clipData.id}
                            pageProps={props}
                            selectedMix={selectedMix ? {
                              id: selectedMix.id,
                              type: selectedMix.contents.type
                            } : null}

                            clipData={clipData}
                            isEditing={isEditing}
                            onEditing={() => setEditingClipId(clipData.id)}
                            onDone={() => setEditingClipId(null)}
                            preloadTakes={index < numberOfClipsToPreload}
                            onVocalSelected={newVocalId => {
                              selectedVocalIdRef.current = newVocalId
                            }}
                            onAddTakeToMix={(mixId, takeId) => {
                              sidebarRef.current?.setActiveTab("mix")

                              return props.mixActions.onAddTakeToMix(mixId, takeId);
                            }}
                          />
                        );
                      })
                      : <div className={classNames("flex justify-center items-start vocalid-secondary-text", css({
                      paddingTop: '15%'
                    }))}>
                      <Tile heading={<div className={'vocalid-h2 mh3'}>No Clips In Project</div>}>
                        <div className={"flex justify-center"}>
                          <PrimaryButton size={"small"} onClick={() => props.onCreateClip()}>New Clip</PrimaryButton>
                        </div>
                      </Tile>
                    </div> }
                </div>
              </div>
            </div>
          </div>
        </div>
        <PageSidebar
          ref={sidebarRef}
          pageProps={props}
          selectedPlaylistId={selectedMixId}
          onSelectedMix={setSelectedMixId}
          defaultPronunciationMap={defaultPronunciationMap}
          onRequestDefaultPronunciation={async word => {
            const pronunciation = await props.onRequestDefaultPronunciation(selectedVocalIdRef.current, word);
            if (pronunciation) {
              setDefaultPronunciationMap(o => ({
                ...o,
                [word]: pronunciation
              }))
            }
          }}
        />
      </div>
    </div>
  );
};

export default EnterpriseProjectPage;


interface PageClipProps {
  clipData: IClipData;
  isEditing: boolean;

  onEditing(): void;
  onDone(): void;
  onVocalSelected(newVocalId: string): void;
  onAddTakeToMix(mixId: string, takeId: string): void;

  selectedMix: {
    id: string;
    type: MixType;
  } | null;
  pageProps: IEnterpriseProjectPageProps;
  preloadTakes?: boolean;
}

const PageClip: React.FC<PageClipProps> = (props) => {
  const clipEditorValue = useAppSelector(state => props.isEditing ? state.currentEditor.value : null);
  const dispatch = useAppDispatch();

  const managedSSML = useSSML(props.clipData.projectId);
  const [selectedTakeId, setSelectedTakeId] = React.useState<string | null>(null);

  const contentForTake = (takeId: string | null) => (takeId !== null ? props.clipData.takes.find(take => take.id === takeId)?.content : null) ?? props.clipData.takes[props.clipData.takes.length - 1]?.content ?? EMPTY_EDITOR_VALUE;

  const selectedTakeContent = contentForTake(selectedTakeId);
  const resolvedContent = React.useMemo(() => clipEditorValue ?? serializeExternalValue(selectedTakeContent), [clipEditorValue, selectedTakeContent]);

  return (
    <Clip
      key={props.clipData.id}
      clip={props.clipData}

      setTitle={newTitle => props.pageProps.onUpdateClipTitle(props.clipData.id, newTitle)}
      onDelete={() => props.pageProps.onDeleteClip(props.clipData.id)}

      currentRenderCount={props.pageProps.data.currentRenderCount}
      maxRenders={props.pageProps.data.maxRenders}
      maxCharactersPerRequest={props.pageProps.data.maxCharactersPerRequest}

      alternatePronunciations={props.pageProps.data.substitutionDictionary.dictionaryMembers.reduce((acc, m) => ({
        ...acc,
        [m.oldPronunciation]: m.newPronunciation
      }), {} as { [text: string]: ISlateAlternatePronunciation })}

      state={props.isEditing ? ClipState.Editing : ClipState.Inactive}
      onEditing={() => {
        const newEditorValue = serializeExternalValue(selectedTakeContent);
        dispatch(setCurrentEditorValue({ value: newEditorValue }));
        props.onEditing();
      }}
      onDone={() => {
        props.onDone();
        dispatch(setCurrentEditorValue({ value: null, selection: null }));
      }}

      content={resolvedContent}
      onContentChange={newContent => {
        dispatch(setCurrentEditorValue({ value: newContent }))
      }}
      onSelectionChange={newSelection => {
        dispatch(setCurrentEditorValue({ selection: newSelection }))
      }}

      ssml={managedSSML.ssml}
      onRequestSSML={() => {
        const deserialized = deserializeInternalValue(resolvedContent);
        console.log("Deserialized: ");
        console.log(deserialized);
        return managedSSML.getSsml(deserialized);
      }}

      preloadTakes={props.preloadTakes}
      mixType={props.selectedMix?.type}

      selectedTakeId={selectedTakeId}
      onSelectedTake={takeId => {
        setSelectedTakeId(takeId);
        if (props.isEditing) {
          const newEditorValue = serializeExternalValue(contentForTake(takeId));
          dispatch(setCurrentEditorValue({ value: newEditorValue }))
        }
      }}

      defaultVocal={props.pageProps.data.defaultVocal}
      onVocalSelected={props.onVocalSelected}

      onGenerate={async (selectedVocalId) => {
        console.log("Deserializing from:");
        console.log(resolvedContent);
        const deserializedValue = deserializeInternalValue(resolvedContent);
        console.log("to:");
        console.log(deserializedValue);
        props.pageProps.onCreateTake(props.clipData.id, selectedVocalId, deserializedValue, false);
      }}
      onRequestTalentRecording={async (selectedVocalId) => {
        console.log("Deserializing from:");
        console.log(resolvedContent);
        const deserializedValue = deserializeInternalValue(resolvedContent);
        console.log("to:");
        console.log(deserializedValue);
        props.pageProps.onCreateTake(props.clipData.id, selectedVocalId, deserializedValue, true);
      }}
      onDeleteTake={props.pageProps.onDeleteTake}
      onApproveTake={props.pageProps.onApproveTake}
      onRejectTake={props.pageProps.onRejectTake}
      onAddTakeToMix={takeId => props.selectedMix && props.onAddTakeToMix(props.selectedMix.id, takeId)}
      onTakeRendered={props.pageProps.onTakeRendered}

      className={"mb4"}
    />
  )
};

const useSSML = (projectId: string) => {
  const [ssmlLoading, setSsmlLoading] = React.useState(false)
  const [ssml, setSsml] = React.useState("")

  const getSsml = async (content: IEditorValue) => {
    setSsmlLoading(true)
    const _ssml = await Axios.post(`${API_BASE_URL}/ssml/${projectId}`, JSON.stringify(content))
    const ssml: string = _ssml.data;
    setSsml(ssml)
    setSsmlLoading(false)
    return ssml;
  }

  return {
    ssmlLoading,
    ssml,
    getSsml
  }
}

const PageSidebar = React.forwardRef<IProjectSidebarRef, {
  pageProps: IEnterpriseProjectPageProps;
  selectedPlaylistId: string | null;
  onSelectedMix(playlistId: string | null): void;
  defaultPronunciationMap: { [word: string]: string[] };
  onRequestDefaultPronunciation(text: string): void;
}>((props, ref) => {
  const { value: currentEditorValue, selection } = useAppSelector(selectCurrentEditor)

  const isEditing = Boolean(currentEditorValue);
  const selectionAdjustments = sidebarAdjustmentsFromEditorValueSelection(currentEditorValue, selection, props.defaultPronunciationMap);
  const debouncedGetDefaultPron = useDebounceCallback(props.onRequestDefaultPronunciation, 750)

  const dispatch = useAppDispatch()

  return (
    <ProjectSidebar
      ref={ref}
      data={{
        editingClip: isEditing ? {
          selection: selectionAdjustments
        } : null,
        substitutionDictionary: props.pageProps.data.substitutionDictionary,
        mixes: props.pageProps.data.mixes,
        maxPlaylists: props.pageProps.data.maxPlaylists,
        selectedMixId: props.selectedPlaylistId
      }}
      onVoiceAdjustmentsChanged={newAdjustments => {
        if (currentEditorValue && selection && selectionAdjustments) {
          const resolvedAdjustments: IClipVoiceAdjustmentsData = {
            ...selectionAdjustments.voiceAdjustments,
            ...newAdjustments
          };

          const newEditorValue = applySidebarAdjustmentsToEditorValue(currentEditorValue, selection, resolvedAdjustments)
          dispatch(setCurrentEditorValue({ value: newEditorValue }))
        }
      }}

      mixActions={props.pageProps.mixActions}
      onSelectedMix={props.onSelectedMix}

      onAddSubstitutionDictionaryMember={props.pageProps.onAddSubstitutionDictionaryMember}
      onSetSubstitutionDictionaryMemberAsGlobal={props.pageProps.onSetSubstitutionDictionaryMemberAsGlobal}
      onRemoveSubstitutionDictionaryMember={props.pageProps.onRemoveSubstitutionDictionaryMember}
      onRequestDefaultPronunciation={debouncedGetDefaultPron}

      className={"flex-shrink-0 w-30 h-100"}
    />
  );
});


const PageControls: React.FC<IEnterpriseProjectPageProps> = props => {
  const [filterControlsShown, setFilterControlsShown] = React.useState(false);

  return (
    <>
      <div className={'mt2 mb3 flex justify-between items-center'}>
        <div className={'flex items-center'}>
          {/* <ShowFiltersToggleButton
            active={filterControlsShown}
            onClick={() => setFilterControlsShown((o) => !o)}
            className={'mr3'}
          />
          <props.SearchBarComponent/> */}
        </div>
        <div>

        </div>
      </div>
      {filterControlsShown && (
        <PageFilters {...props}/>
      )}
    </>
  );
};

const PageFilters: React.FC<IEnterpriseProjectPageProps> = props => (
  <div className={'mb3'}>
    <Select value="" placeholder={'Sort by Date'} onChange={() => {}} className={'mr3'}>
      {[{ value: 'opt1', label: 'An Option' }]}
    </Select>
    <Select value="" placeholder={'Tags'} onChange={() => {}} className={'mr3'}>
      {[{ value: 'opt1', label: 'An Option' }]}
    </Select>
  </div>
);

const PageHeader: React.FC<IEnterpriseProjectPageProps> = props => {
  const [importingClipSources, setImportingClipSources] = React.useState(false);

  return (
    <>
      <UploadClipSourcesDialog isShown={importingClipSources} onDismiss={() => {
        setImportingClipSources(false);
      }} onCreate={props.onCreateClips} />
      <Header
        rightArea={
          <>
            <OutlineButton size={"medium"} onClick={() => {
              setImportingClipSources(true);
            }} className={"mr2"}>
              Upload
            </OutlineButton>
            <PrimaryButton onClick={props.onCreateClip}>
              New Clip
            </PrimaryButton>
          </>
        }
      >
        <h1 className={"db vocalid-h1 ma0 pr5"}><RevertableHiddenInput initialValue={props.data.title} onChange={async newTitle => await props.onUpdateProjectTitle(newTitle)} className={"w-100"} /></h1>
        <div className={css({ marginTop: 14 })}>
          <span className={"mr3"}><ProjectTypeTag projectType={props.data.type} /></span>
          <span className={"mr3"}>{props.data.clips.length} Clips</span>
          <span>Default VoiceDubb: {props.data.defaultVocal.title ?? "Not Found"}</span>
        </div>
      </Header>
    </>
  );
};
