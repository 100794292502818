import { gql, useQuery } from "@apollo/client";
import { VOICE_FRAGMENT } from "../fragments";
import { PaginationInput, ProjectsQuery, ProjectsQueryVariables } from "../generated";
import { useEnterpriseContextualState } from "../../components/templates/enterprise";

const query = gql`
query ProjectsQuery(
  $account: String!,
  $pagination: PaginationInput!
) {
  account(where: { id: $account }) {
    id
    projectsCount
    allRoleRestrictions {
      maxProjects
    }
    
    projects(input: { pagination: $pagination }) {
      totalResults
      results {
        id
        title
        created
        updated
        countOfClips
        defaultVoice {
          ...VoiceFragment
        }
      }
    }
  }
}

${VOICE_FRAGMENT}
`

export const useProjectsQuery = (pagination: PaginationInput) => {
  const enterprise = useEnterpriseContextualState()
  return useQuery<ProjectsQuery, ProjectsQueryVariables>(
    query,
    {
      variables: { account: enterprise.state.selectedAccount!, pagination },
      skip: !enterprise.state.selectedAccount
    }
  )
}
