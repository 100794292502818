import { AdjustmentValueMap, EAdjustment } from "./index";
import { Editor as SlateEditor } from "slate";
import _ from "lodash";

export type SlateAdjustment<T extends EAdjustment> = {
  type: T;
  value: AdjustmentValueMap[T]
}

type TextNode = {
  text: string;
};

export type DecoratedNode<T> = T & {}

export enum ESlateElementType {
  Word = "Word",
  Space = "Space",
  Newline = "Newline"
}

export type ISlateWordNode = DecoratedNode<TextNode> & {
  type: ESlateElementType.Word;
  adjustments?: SlateAdjustment<EAdjustment>[];
  pause?: 'long' | 'short';
  pauseInMilliseconds?: number;
  alternatePronunciation?: ISlateAlternatePronunciation;
}

export type ISlateAlternatePronunciation = ISlateAlternatePronunciationPhonetic | ISlateAlternatePronunciationText;
export type ISlateAlternatePronunciationPhonetic = { phonetic: string[]; };
export function isPhoneticAlternatePronunciation(alternatePronunciation: ISlateAlternatePronunciation): alternatePronunciation is ISlateAlternatePronunciationPhonetic {
  return (alternatePronunciation as ISlateAlternatePronunciationPhonetic).phonetic !== undefined
}

export type ISlateAlternatePronunciationText = { text: string; };
export function isTextAlternatePronunciation(alternatePronunciation: ISlateAlternatePronunciation): alternatePronunciation is ISlateAlternatePronunciationText {
  return (alternatePronunciation as ISlateAlternatePronunciationText).text !== undefined
}

export function isEmptyAlternatePronunciation(alternatePronunciation: ISlateAlternatePronunciation): boolean {
  return isPhoneticAlternatePronunciation(alternatePronunciation) ? alternatePronunciation.phonetic.length === 0 : _.trim(alternatePronunciation.text) === ""
}

export type ISlateSpaceNode = DecoratedNode<TextNode> & {
  type: ESlateElementType.Space;
  text: " ";
  adjustments?: SlateAdjustment<EAdjustment>[];
}

export type ISlateNewlineNode = DecoratedNode<TextNode> & {
  type: ESlateElementType.Newline;
  text: "\n";
  adjustments?: SlateAdjustment<EAdjustment>[];
}

export type ISlateNode = ISlateWordNode | ISlateSpaceNode | ISlateNewlineNode;
export type ISlateValue = ISlateNode[];
export type ISlateSelection = SlateEditor["selection"];

export function isEmptySlateValue(value: ISlateValue): boolean {
  return value.length === 0 || value.every(el => el.type === ESlateElementType.Space || (el.type === ESlateElementType.Word && el.text.replace(/\s/g, '').length === 0))
}

export function slateValueAsText(value: ISlateValue): string {
  return value.reduce((acc, curr) => curr.type === ESlateElementType.Word ? acc + curr.text : curr.type === ESlateElementType.Space ? acc + " " : curr.type === ESlateElementType.Newline ? acc + "\n" : acc, "")
}

export function slateValueWords(value: ISlateValue): string[] {
  return value.reduce((acc, curr) => curr.type === ESlateElementType.Word ? [...acc, curr.text] : acc, [] as string[])
}
