import React from "react";
import Icon, { EIconName } from "../../../../atoms/icon";
import classNames from "classnames";
import { css } from "emotion";
import { useTheme } from "../../../../../theme";
import InfoTooltip from "../../../../molecules/info-tooltip";
import Select, { ISelectOption } from "../../../../molecules/select";
import DeleteMixDialog from "../../../delete-playlist-dialog";
import { FeatureLock, StatelessFeatureLock, useLockedFeature } from "../../../../../utils/feature-locking";
import Separator from "../../../../atoms/separator";
import { GenerateButtonBase } from "../../../clip";
import { PlaylistItemList } from "./playlist";
import MixTake from "./playlist/take";
import { isEqual } from "lodash";
import { TextButton } from "../../../../atoms/button";
import AdContentSidebar from "./ad";
import ReactDOM from "react-dom";
import { contentWrapperId } from "../../../../pages/enterprise-project";
import NumberStepper from "../../../../atoms/number-stepper";
import AdSoundTemplateSelector, { SoundTemplateInfoTooltip } from "./ad/template";
import ConvertMixTypeDialog from "./convert-dialog";
import Tooltip from "../../../../atoms/tooltip";
import { AdMixSoundTemplateTempo, useAdMixSoundTemplatesQuery } from "../../../../../graphql/generated";
import { useSessionState } from "../../../../../utils/hooks/session-state";

export interface IProjectMixData<Contents = MixContents> {
  id: string;
  contents: Contents;
  takes: Array<MixTakeData>;
}

export interface IMixSidebarActions {
  onAddMix(): Promise<void>;
  onDeleteMix(mixId: string): Promise<void>;
  onDeleteMixTake(mixId: string, takeId: string): Promise<void>;
  onUpdateMix(mixId: string, contents: MixContents): Promise<void>;
  onAddPause(mixId: string): Promise<void>;
  onChangeMixFormat(mixId: string, type: MixType): Promise<void>;
  onGenerate(mixId: string): Promise<void>;
}

export interface MixTakeData<Contents = MixContents> {
  id: string;
  number: number;
  createdAt: Date;
  contents: Contents;
  renderedUrl: string | null;
  error: string | null;
}

export type MixContents = PlaylistMixContents | AdMixContents;
export type MixType = MixContents['type'];

export interface PlaylistMixContents {
  type: 'Playlist';
  items: Array<PlaylistItemData>;
}

export interface AdMixContents {
  type: 'Ad';
  sections: Array<{
    key: string;
    items: Array<PlaylistItemData>;
  }>
  soundTemplateId: string | null;
  maxDurationSeconds: number | null;
}

export type PlaylistItemData = IPlaylistRenderedTakeItemData | IPlaylistPauseItemData;

export interface IPlaylistRenderedTakeItemData {
  type: 'take';
  id: string;
  takeId: string;
  num: number;
  clipName: string;
  audioUrl: string;
  voiceName: string;
  talentRecording: boolean;
}

export interface IPlaylistPauseItemData {
  type: 'pause';
  id: string;
  duration: number;
}

export interface IAdMixSoundTemplate {
  id: string;
  name: string;
  description: string | null;
  sampleUrl: string | null;
  tags: Array<string> | null;
  genre: string | null;
  tempo: AdMixSoundTemplateTempo | null;
}

export interface MixSidebarMenuProps {
  mixes: IProjectMixData[];
  maxMixes: number | null;
  selectedMixId: string | null;
  onSelectMixId(mixId: string | null): void;
  actions: IMixSidebarActions;
  className?: string;
}

export const MixSidebarMenu: React.FC<MixSidebarMenuProps> = props => {
  const { colors: themeColors } = useTheme();
  const selectedMix = props.mixes.find(p => p.id === props.selectedMixId);
  const [isDeletingMix, setIsDeletingMix] = React.useState(false);

  const mixTypePillCss = (color: string) => css({
    display: 'inline-block',
    padding: '3px 8px',
    borderRadius: 2,
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    backgroundColor: color,
    color: 'black',
    minWidth: 80,
    textAlign: 'center'
  })

  return (
    <div className={"flex flex-column flex-grow-1"}>
      <DeleteMixDialog isShown={isDeletingMix} onCancel={() => setIsDeletingMix(false)} onDelete={() => {
        props.actions.onDeleteMix(props.selectedMixId!);
        setIsDeletingMix(false);
      }} />
      <div className={"flex justify-between items-center mb3"}>
        <Select className={"flex-grow-1"} isSearchable={false} styleOverrides={{
          singleValue: {
            flexGrow: 1,
            width: '100%',
            '& .mix-type-label': {
              display: 'none'
            }
          }
        } as any} value={props.selectedMixId ?? "null"} menuColor={themeColors.tileAlt} onChange={playlistId => props.onSelectMixId(playlistId === "null" ? null : playlistId as string)}>{[
          ...(!props.selectedMixId ? [{
            value: 'null',
            label: 'No Mix selected'
          }] : []),
          ...props.mixes.map((mix, i) => ({
            value: mix.id,
            label: <span className={"w-100 flex justify-between items-center"}>
              <span>Mix {i + 1}</span>
              <span className={"mix-type-label ml3"}>{mix.contents.type === "Playlist" ? <span className={mixTypePillCss(themeColors.playlist)}>Playlist</span> : mix.contents.type === "Ad" ? <span className={mixTypePillCss(themeColors.ad)}>Ad</span> : ""}</span>
            </span>
          }))
        ]}</Select>
        <StatelessFeatureLock lockedState={props.maxMixes !== null && props.mixes.length >= props.maxMixes ? {
          lockedReason: `Creating an additional Mix would exceed your max Mix limit of ${props.maxMixes}`
        } : false}>
          <Icon name={EIconName.Plus} size={16} onClick={props.actions.onAddMix} className={'ml3'} />
        </StatelessFeatureLock>
        <StatelessFeatureLock lockedState={!props.selectedMixId || props.mixes.length <= 1 ? {
          lockedReason: `Removing the only Mix in a project is not permitted`
        } : false}>
          <Icon name={EIconName.Delete} size={24} onClick={() => setIsDeletingMix(true)} className={'ml3'} />
        </StatelessFeatureLock>
      </div>
      <div className={"flex flex-column flex-grow-1"}>
        {
          selectedMix ? (
            <SingleMixSidebarMenu mix={selectedMix} actions={props.actions} />
          ) : <div />
        }
      </div>
    </div>
  );
};

export default MixSidebarMenu;


export interface ISingleMixSidebarMenuProps {
  mix: IProjectMixData;
  actions: IMixSidebarActions;
  className?: string;
}

const SingleMixSidebarMenu: React.FC<ISingleMixSidebarMenuProps> = (props) => {
  const { colors: themeColors } = useTheme()

  const adModeFeatureLock = useLockedFeature("ProjectMixAdMode")

  const mixContentsFromProps = props.mix.contents;
  const [_contents, _setContents] = React.useState<MixContents>(mixContentsFromProps)
  const [previewingTakeId, setPreviewingTakeId] = React.useState<string | null>(null)

  React.useEffect(() => {
    if (!isEqual(mixContentsFromProps, _contents)) {
      _setContents(mixContentsFromProps)
      setPreviewingTakeId(null);
    }
  }, [mixContentsFromProps]);

  const [convertingToMixType, setConvertingToMixType] = React.useState<MixType | null>(null);

  const previewingTake = (previewingTakeId && props.mix.takes.find(t => t.id === previewingTakeId)) ?? null
  const contents = previewingTake ? previewingTake.contents : _contents;

  const setContents = (newContents: MixContents) => {
    _setContents(newContents);
    setPreviewingTakeId(null);
    props.actions.onUpdateMix(props.mix.id, newContents)
  }

  const [generating, setGenerating] = React.useState(false)

  const generate = async () => {
    setGenerating(true)
    await props.actions.onGenerate(props.mix.id);
    setGenerating(false)
  }

  const [coolBarOpen, setCoolBarOpen] = React.useState(false);
  const [mixesOpened, setMixesOpened] = useSessionState<string[]>("mixes-opened", [])

  React.useLayoutEffect(() => {
    switch (contents.type) {
      case "Playlist":
        setCoolBarOpen(false);
        break;
      case "Ad":
        if (!mixesOpened.includes(props.mix.id)) {
          setCoolBarOpen(true)
        }
        break;
    }

    if (!mixesOpened.includes(props.mix.id)) {
      setMixesOpened(o => [...o, props.mix.id])
    }
  }, [contents.type, props.mix.id])

  let mixUI: {
    actions?: React.ReactNode;
    contents?: React.ReactNode;
    generateDisabled?: boolean;
    coolBar?: Pick<CoolBarOverlayProps, "color" | "title" | "tips" | "disabled"> & { contents: React.ReactNode; };
  };

  const soundTemplates = useAdMixSoundTemplatesQuery().data?.adMixSoundTemplates.results;
  const [showSoundTemplateFilters, setShowSoundTemplateFilters] = React.useState(false);

  switch (contents.type) {
    case "Playlist":
      mixUI = {
        actions: <>
          <div className={'flex justify-start flex-wrap'}>
            <MixAction className={"mb2 mr3"} icon={EIconName.AddToPlaylist} iconSize={24} onClick={() => props.actions.onAddPause(props.mix.id)}>Pause</MixAction>
            <MixAction className={"mb2 mr3"} icon={EIconName.CascadeList} onClick={() => {
              setContents({
                ...contents,
                items: []
              })
            }}>Clear List</MixAction>
          </div>
          <div>
            <InfoTooltip
              tooltipContent={
                <>
                  <strong>TIPS:</strong><br/>
                  Use Add To- Mix button on clips at left<br/><br/>

                  Use grab handles to drag clips to rearrange Mix order<br/><br/>

                  Add pauses where needed, adjust duration in seconds.<br/><br/>

                  Play individual clips, or Generate a render of the entire Mix.<br/><br/>

                  Export single clips or entire sequence, with pauses.
                </>
              }
              className={'ml2 v-mid'}
            />
          </div>
        </>,
        contents: <>
          <PlaylistItemList
            contents={contents}
            onChange={setContents}
          />
        </>,
        generateDisabled: contents.items.length === 0
      }
      break;
    case "Ad":
      mixUI = {
        actions: <>
          <div className={'flex justify-start flex-wrap'}>
            <MixAction className={"mb2 mr3"} icon={EIconName.CascadeList} onClick={() => {
              setContents({
                ...contents,
                sections: contents.sections.map(section => ({
                  ...section,
                  items: []
                }))
              })
            }}>Clear List</MixAction>
          </div>
        </>,
        contents: <>
          <FeatureLock feature={"ProjectMixAdMode"} noHidden className={"db w-100"}><AdContentSidebar contents={contents} soundTemplates={soundTemplates} onOpenCoolBar={() => setCoolBarOpen(true)} onChange={setContents} /></FeatureLock>
        </>,
        generateDisabled: Boolean(adModeFeatureLock) || contents.sections.reduce((acc, curr) => acc + curr.items.length, 0) === 0,
        coolBar: {
          color: themeColors.ad,
          title: 'Ad Options',
          disabled: Boolean(adModeFeatureLock),
          tips: <>
            TIPS:<br/>
            Select a Sound Template using the querying sliders and selections on the right.<br/>
            <br/>
            Sound Templates apply background music and audio mastering to produce a professional ad.
          </>,
          contents: (
            <div className={"vocalid-secondary-text flex flex-column items-center pt4"}>
              <div className={"mb4"}>
                <div className={"ph4 flex justify-between"}>
                  <div className={"vocalid-h3 tc"}>Sound Template <SoundTemplateInfoTooltip className={"ml2 v-mid"} /></div>
                  <Tooltip tooltipContent={"Sort Templates"}><Icon name={EIconName.Sort} active={showSoundTemplateFilters} onClick={() => setShowSoundTemplateFilters(o => !o)} /></Tooltip>
                </div>
                <AdSoundTemplateSelector
                  key={props.mix.id}
                  showFilters={showSoundTemplateFilters}
                  renderCarousel={coolBarOpen}
                  soundTemplates={soundTemplates}
                  selectedTemplateId={contents.soundTemplateId}
                  onSelect={newSoundTemplateId => setContents({
                    ...contents,
                    soundTemplateId: newSoundTemplateId
                  })}
                />
              </div>
              <div className={"mb5 flex items-center ph4 w-100"}>
                <span className={"mr4 vocalid-h3"}>Max Duration </span>
                <span className={css({
                  width: 120
                })}>
                  <NumberStepper
                    value={contents.maxDurationSeconds ?? 45}
                    inputEditable
                    onChange={(newValue) => {
                      setContents({
                        ...contents,
                        maxDurationSeconds: newValue
                      });
                    }}
                    step={1}
                    fixed={0}
                    suffix={'s'}
                    className={css({
                      opacity: !contents.maxDurationSeconds ? 0.5 : 1,
                      '&:hover, &:focus': {
                        opacity: 1,
                        color: 'inherit !important'
                      }
                    })}
                  />
                </span>
                {contents.maxDurationSeconds !== null && <Icon name={EIconName.X} size={20} onClick={() => {
                  setContents({
                    ...contents,
                    maxDurationSeconds: null
                  })
                }} className={"mh2"} /> }
              </div>
            </div>
          )
        }
      }
      break;
  }

  const formatOptions: Array<ISelectOption<MixContents["type"]>> = [
    {
      value: 'Playlist',
      label: <span className={"vocalid-playlist b ttu"}>Playlist</span>
    },
    ...(adModeFeatureLock !== "hidden" ? [{
      value: 'Ad',
      label: <span className={"vocalid-ad b ttu"}>Ad</span>,
      disabled: Boolean(adModeFeatureLock)
    } as const] : [])
  ]

  return (
    <>
      <ConvertMixTypeDialog isShown={Boolean(convertingToMixType)} onCancel={() => setConvertingToMixType(null)} onConvert={() => {
        props.actions.onChangeMixFormat(props.mix.id, convertingToMixType!);
        setConvertingToMixType(null);
      }} />
      {
        ReactDOM.createPortal(
          <><CoolBarOverlay hideArrow={mixUI.coolBar === undefined} open={coolBarOpen} disabled={mixUI.coolBar?.disabled} onToggleOpen={setCoolBarOpen} color={mixUI.coolBar?.color ?? themeColors.primaryText} title={mixUI.coolBar?.title ?? ""} tips={mixUI.coolBar?.tips}>
            {mixUI.coolBar?.contents}
          </CoolBarOverlay></>,
          document.getElementById(contentWrapperId)!
        )
      }
      <div className={classNames('flex flex-column flex-grow-1 flex-shrink-1', props.className)}>
        <div className={"flex-shrink-0"}>
          { adModeFeatureLock !== "hidden" && <div className={"mb3 flex items-center"}>
            <div className={"mr3"}>Format: </div>
            <Select value={props.mix.contents.type} isSearchable={false} color={props.mix.contents.type === "Playlist" ? themeColors.playlist : props.mix.contents.type === "Ad" ? themeColors.ad : undefined} onChange={(newType: MixContents["type"]) => contents.type !== newType && setConvertingToMixType(newType)} menuColor={themeColors.tileAlt}>{formatOptions}</Select>
          </div> }
          <div className={'flex justify-between items-start mb4'}>
            {mixUI.actions}
          </div>
        </div>
        {previewingTake && (
          <div className={"mb3 pv2 ph3 bg-vocalid-tile-alt vocalid-primary-text"}>
            <label className={"db tc vocalid-body"}>Previewing Render {previewingTake.number}'s contents. <TextButton onClick={() => setContents(previewingTake.contents)}>Apply?</TextButton></label>
          </div>
        )}
        <div className={'flex-grow-1'}>
          {mixUI.contents}
        </div>
        <Separator className={"mv4"} />
        <div className={classNames("pb4 flex-shrink-0", css({
          minHeight: 255
        }))}>
          <div className={'flex justify-end items-center flex-wrap mb3'}>
            <span className={"vocalid-tertiary-text mr4"}>{ props.mix.takes.length } Mix Render{props.mix.takes.length !== 1 ? "s" : "" }</span>
            <GenerateButtonBase mini disabled={mixUI.generateDisabled || previewingTake !== null} onGenerate={() => generate()} isGenerating={generating} />
          </div>
          <div className={'flex-grow-1'}>
            {props.mix.takes.map(take => {
              const isSelected = previewingTakeId === take.id

              return (
                <MixTake
                  key={take.id}
                  playerButtonOnly
                  take={take}
                  onClick={() => !isSelected ? setPreviewingTakeId(take.id) : setPreviewingTakeId(null)}
                  primary={isSelected}
                  onDelete={() => props.actions.onDeleteMixTake(props.mix.id, take.id)}
                  className={"mb3"}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export interface IMixActionProps {
  icon: EIconName;
  iconSize?: number;
  active?: boolean;
  className?: string;
  onClick(): void;
}

const MixAction: React.FC<IMixActionProps> = (props) => {
  const { colors: themeColors } = useTheme();

  return (
    <div
      className={classNames(
        'flex items-center pointer',
        css({
          color: !props.active ? themeColors.tertiaryText : themeColors.iconActive,
          '&:hover': {
            color: themeColors.iconActive
          }
        }),
        props.className
      )}
      onClick={props.onClick}
    >
      <Icon name={props.icon} size={props.iconSize} color={!props.active ? "inherit" : themeColors.iconActive} hoverColor={themeColors.iconActive} className={'mr2'} />
      <span>{props.children}</span>
    </div>
  );
};

export interface CoolBarOverlayProps {
  color: string;
  title: string;
  open: boolean;
  disabled?: boolean;
  hideArrow?: boolean;
  tips?: React.ReactNode;
  onToggleOpen(open: boolean): void;
}

const CoolBarOverlay: React.FC<CoolBarOverlayProps> = props => {
  const { colors: themeColors } = useTheme();

  const open = props.open && !props.disabled

  return (
    <div className={classNames(css({
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'flex-end',
      pointerEvents: 'none',
      overflow: 'hidden',
      transition: 'background 400ms'
    }), open && css({
      background: 'rgba(0, 0, 0, 0.75)',
      // backdropFilter: 'blur(5px)',
      pointerEvents: 'initial',
      borderRight: `1px solid ${props.color}`
    }))} onClick={() => {
      props.onToggleOpen(false);
    }}>
      {props.tips && <div className={classNames("vocalid-h3 white mv6 mr4 tr mw5", css({
        opacity: open ? 1 : 0,
        transform: `translate3d(${open ? '0%' : '50%'}, 0px, 0px)`,
        transition: 'transform 400ms, opacity 400ms'
      }))}>
        {props.tips}
      </div>}
      <div className={classNames("vocalid-body flex flex-column", css({
        backgroundColor: themeColors.header,
        borderRadius: 7,
        border: `1px solid ${props.color}`,
        minWidth: 364,
        marginTop: 64,
        marginBottom: 64,
        marginRight: 20,
        transform: `translate3d(${open ? '0%' : 'calc(100% + 40px)'}, 0px, 0px)`,
        transition: 'transform 400ms'
      }))} onClick={e => {
        e.stopPropagation()
      }}>
        <div className={"flex justify-end ph3 mt2 flex-shrink-0"}>
          <label className={css({
            color: props.color,
            textTransform: 'uppercase'
          })}>{props.title}</label>
        </div>
        <div className={"flex-grow-1 overflow-y-auto"}>
          {props.children}
        </div>
      </div>
      { !props.hideArrow && !props.disabled && <div className={css({
        position: 'absolute',
        right: 0,
        top: 'calc(50% - 45px)',
        pointerEvents: 'initial',
        cursor: 'pointer'
      })} onClick={e => {
        e.stopPropagation()
        props.onToggleOpen(!props.open)
      }}>
        <svg width="39.6" height="83.7" viewBox="0 0 44 93" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.14753 17.2369L34.0584 4.41057L44 0V13.8821V79.4098V93L34.2035 88.9436L5.29263 76.9724C2.08896 75.6458 0 72.5199 0 69.0524V67.4386V26.7084V25.1519C0 21.7279 2.01768 18.6254 5.14753 17.2369Z" fill={themeColors.ad}/>
        </svg>
        <div className={css({
          position: 'absolute',
          top: '31px',
          left: '8px'
        })}>
          <Icon name={EIconName.ThinChevronLeft} color={"black"} size={25} className={css({
            transform: `rotate(${!open ? '0deg' : '180deg'})`,
            transition: 'transform 400ms'
          })} />
        </div>
      </div> }
    </div>
  );
};

