import React from 'react';
import MarketingTemplate from '../../components/templates/marketing';
import { useAppContextQuery } from '../../graphql/generated';
import FullPageLoader from "../shared/loader";
import GenericErrorBoundary from "../../components/molecules/generic-error-boundary";
import { useAuth } from "../../auth";

export interface IMarketingTemplateContainerProps {
  hideBorder?: boolean;
}

const MarketingTemplateContainer: React.FC<IMarketingTemplateContainerProps> = (props) => {
  const { isAuthenticated } = useAuth()
  const { data: appContextData, loading: appContextDataLoading } = useAppContextQuery()

  if (appContextDataLoading || !appContextData) return <FullPageLoader />

  return (
      <MarketingTemplate
        data={{
          user: appContextData.viewer ?{
            firstName: appContextData.viewer.user.firstName,
            lastName: appContextData.viewer.user.lastName,
            emailAddress: appContextData.viewer.user.emailAddress,
          } : null
        }}
        hideBorder={props.hideBorder}
      >
        <GenericErrorBoundary>
          {props.children}
        </GenericErrorBoundary>
    </MarketingTemplate >
  );
};

export default MarketingTemplateContainer;
