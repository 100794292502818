import React from "react";
import Dialog from "../../../components/molecules/dialog";
import Input from "../../../components/atoms/input";
import { OutlineButton, PrimaryButton, TextButton } from "../../../components/atoms/button";
import { CircularProgress } from "@material-ui/core";
import Icon, { EIconName } from "../../../components/atoms/icon";
import { useTheme } from "../../../theme";
import { css } from "emotion";
import classNames from "classnames";
import Tooltip from "../../../components/atoms/tooltip";
import SupportLink from "../../../support";
import { copyTextToClipboard } from "../../../utils/clipboard";
import { useCreateApiTokenQuery, useCreateApiTokenMutation } from "../../../graphql/generated";

export interface ICreateApiTokenDialogProps {
  isShown: boolean;
  onCancel(): void;
  onCreated?(tokenId: string): void;
  className?: string;
}

const CreateApiTokenDialog: React.FC<ICreateApiTokenDialogProps> = (props) => {
  const { colors: themeColors } = useTheme();
  const createApiTokenQuery = useCreateApiTokenQuery()
  const [createApiTokenMutation] = useCreateApiTokenMutation()

  const [tokenName, setTokenName] = React.useState("")
  const [creating, setCreating] = React.useState(false)

  const [createdToken, setCreatedToken] = React.useState<{ id: string; token: string; } | null>(null);
  const [copiedToClipboard, setCopiedToClipboard] = React.useState(false);

  const [accountIds, setAccountIds] = React.useState<string[]>([]);
  const toggleAccountId = (accountId: string) => {
    setAccountIds(o => {
      if (accountIds.includes(accountId)) {
        const arr = [...o]
        arr.splice(o.indexOf(accountId), 1);
        return arr;
      } else {
        return [...o, accountId]
      }
    });
  }

  const onCreate = async () => {
    setCreating(true);
    const result = await createApiTokenMutation({
      variables: {
        name: tokenName,
        accounts: accountIds
      }
    })

    if (!result.data?.createApiToken) throw Error("unexpected - could not create token")
    setCreatedToken({
      id: result.data.createApiToken.tokenModel.id,
      token: result.data.createApiToken.token
    });

    setAccountIds([]);
    setCreating(false);
  }

  return (
    <Dialog
      isShown={props.isShown}
      heading={<div className={'mr5'}>Create New API Token</div>}
      className={classNames(css({
        maxHeight: 700
      }), props.className)}
    >
      {!createdToken ? <>
        <div className={'vocalid-h3 mb2'}>Token Name</div>
        <Input value={tokenName} onChange={(value) => setTokenName(value)} white className={'mb4'} />
        <div>
          <label
            className={classNames(
              'db vocalid-h3 vocalid-dark-text mb1',
              css({ margin: '0px 2px 5px 2px' })
            )}
          >
            Accounts
          </label>
          <div className={"mb2"}>
            <Tooltip color={themeColors.secondaryText} tooltipContent={"Limiting API access to select accounts reduces the risk of malicious parties obtaining a master key to all of your Parrot data."}><span className={"vocalid-dark-blue vocalid-body"}>Why do I need to limit access to accounts?</span></Tooltip>
          </div>
          <div className={css({
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            maxHeight: 185
          })}>
            {[...(createApiTokenQuery.data?.viewer?.user.accounts.map(a => a.account) ?? [])
              ].sort((a, b) => a.allRoleRestrictions.synthesisApiAccess ? -1 : (a.name as any) > (b.name as any) ? 1 : -1)
              .map(account => {
                const isEnabled = account.allRoleRestrictions.synthesisApiAccess;
                const isSelected = accountIds.includes(account.id);

                return (
                  <button key={account.id} className={classNames(
                    "tc flex justify-between items-center db bg-transparent ba pa3 mh4 mv1",
                    css({ borderRadius: 100 }),
                    isEnabled ? ["pointer", isSelected ? "bg-vocalid-icon-inactive b--vocalid-icon-inactive white" : "b--vocalid-icon-inactive"] : "vocalid-icon-inactive b--vocalid-icon-inactive not-allowed"
                  )} onClick={() => account.allRoleRestrictions.synthesisApiAccess && toggleAccountId(account.id)}>
                    <Icon name={EIconName.Checkmark} size={14} className={"o-0 ml2"} />
                    <Tooltip interactive color={themeColors.secondaryText} disabled={isEnabled} tooltipContent={<>This account does not have API Access enabled. Please <SupportLink color={themeColors.blue} subject={"I Want Access to the PARROT STUDiO API"}>contact VocaliD support</SupportLink> so that we may assist you.</>}>
                      <span className={"lh-copy mh3"}>{account.name}</span>
                    </Tooltip>
                    <Icon name={EIconName.Checkmark} size={14} color={themeColors.white} className={classNames(isSelected ? "o-100" : "o-0", "mr2")} />
                  </button>
                );
              })}
          </div>
        </div>
        <div className={'flex justify-end mt4'}>
          <TextButton
            dark
            onClick={() => {
              props.onCancel();
              setTokenName("");
              setCreatedToken(null);
              setCopiedToClipboard(false);
              setAccountIds([]);
            }}
            className={'mr3'}
          >
            CANCEL
          </TextButton>
          <PrimaryButton
            disabled={/^\s*$/.test(tokenName) || accountIds.length === 0}
            onClick={() => onCreate()}
          >
            {creating ? <CircularProgress /> : <span>CREATE</span>}
          </PrimaryButton>
        </div>
      </> : <>
        <div className={"mb4"}>
          <div className={"mb2"}>Make sure to copy your personal access token now.</div>
          <div className={"mb2"}>You will not have the ability to view it once this dialog is closed.</div>
        </div>
        <div className={"flex mb4"}>
          <Input value={createdToken.token} white className={'flex-grow-1 mr2'} />
          <Icon name={EIconName.Copy} active={copiedToClipboard} color={themeColors.iconInactive} hoverColor={themeColors.iconActive} activeColor={themeColors.primaryButton} onClick={() => {
            copyTextToClipboard(createdToken.token);
            setCopiedToClipboard(true);
          }} className={"flex-shrink-0"} style={{ marginTop: 7 }} />
        </div>
        <div className={'flex justify-end mt4'}>
          <OutlineButton
            dark
            size={"medium"}
            onClick={() => {
              props.onCreated?.(createdToken!.id);
              setTokenName("");
              setCreatedToken(null);
              setCopiedToClipboard(false);
            }}
          >
            CLOSE
          </OutlineButton>
        </div>
      </>
    }
    </Dialog>
  );
};

export default CreateApiTokenDialog;
