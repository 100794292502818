import React from 'react';

export function usePreviousRef<T>(value: T): T {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = React.useRef(value);

  // Store current value in ref
  React.useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function usePreviousState<T>(value: T): T {
  const previousRef = usePreviousRef(value);
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const [state, setState] = React.useState(value);

  // Store current value in ref
  React.useEffect(() => {
    setState(previousRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return state;
}
