import React from "react";
import { css } from "emotion";
import classNames from "classnames";
import talentImage from "./talent.jpg";
import enterpriseImage from "./enterprise.jpg";
import { useAppContextQuery } from '../../../graphql/generated';
import FullPageLoader from "../../../containers/shared/loader";
import { Redirect, Link } from "react-router-dom";
import { PrimaryButton } from "../../atoms/button";

export interface LandingPageProps {
  className?: string;
}

const LandingPage: React.FC<LandingPageProps> = props => {
  const { data: appContextData, loading: appContextDataLoading } = useAppContextQuery()

  if (appContextDataLoading || !appContextData) return <FullPageLoader />

  if (appContextData.viewer != null && appContextData.viewer.user.talent == null) return <Redirect to={"/enterprise"} />

  return (
    <div className={classNames("h-100 bg-vocalid-header", props.className)}>
      <div className={"fixed top-0 left-0 right-0 bottom-0 flex z-0"}>
        <BiggerLink image={enterpriseImage} title={"Enterprise"} url={"/enterprise"} />
        <BiggerLink image={talentImage} title={"Talent"} url={"/talent"} />
      </div>
      <div className={"select-box flex flex-column items-center"}>
        <div className={"relative flex flex-column items-center z-1 mt7 bg-vocalid-page pt4 ph4 pb3"}>
          <img
            src={'/logo.png'}
            alt={'VocaliD'}
            height={26}
            className={'flex-shrink-0 mb1'}
          />
          <h2 className={"vocalid-h2 vocalid-primary-text pt1 mb0"} style={{
            fontSize: 18
          }}>Please select your experience</h2>
          {appContextData.viewer == null &&
            <>
              <div className={"mt1 mb2 vocalid-h2 vocalid-secondary-text ttu"} style={{
                fontSize: 14
              }}>or</div>
              <Link to={"/pricing"} className={"mb1"}><PrimaryButton>Create an Account</PrimaryButton></Link>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default LandingPage;


export interface BiggerLinkProps {
  image: string;
  url: string;
  title: string;
  className?: string;
}

const BiggerLink: React.FC<BiggerLinkProps> = props => {
  return (
    <a href={props.url} className={classNames("relative db no-underline vocalid-primary-text flex-grow-1 overflow-x-hidden overflow-y-hidden", props.className)}>
      <div className={css({
        position: 'relative',
        transform: 'scale(1.0)',
        transition: 'transform 0.25s',
        width: '100%',
        height: '100%',
        '&:hover': {
          transform: 'scale(1.05)',
          '.link-select-image': {
            opacity: 1,
            filter: 'none'
          }
        }
      })}>
        <div className={classNames("link-select-image", css({
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundImage: `url(${props.image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: 0.2,
          filter: 'grayscale(100%)'
        }))}/>
      </div>
      <div style={{
        position: 'absolute',
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 1
      }}>
        <h1 className={"vocalid-h2 ml4 mb4"} style={{ fontSize: 54 }}>{props.title}</h1>
      </div>
    </a>
  );
}
