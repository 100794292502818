import React from 'react';
import PhoneticInput from '../phonetic-input';
import {
  isEmptyAlternatePronunciation,
  ISlateAlternatePronunciation, ISlateAlternatePronunciationPhonetic, ISlateAlternatePronunciationText,
  isPhoneticAlternatePronunciation, isTextAlternatePronunciation
} from "../clip/editor/adjustments/internal-types";
import Input from '../../atoms/input';
import Checkbox from '../../atoms/checkbox';
import classNames from 'classnames';
import { css } from 'emotion';
import _ from "lodash";

export interface AlternatePronunciationInputProps {
  value: ISlateAlternatePronunciation | null;
  defaultPhonetics?: ISlateAlternatePronunciationPhonetic;
  defaultText?: ISlateAlternatePronunciationText;
  onChange?(newValue: ISlateAlternatePronunciation | null): void;
  requestDefaultPhonetics?(mode: "phonetic" | "text"): void;
  disabled?: boolean;

  className?: string;
}

const AlternatePronunciationInput: React.FC<AlternatePronunciationInputProps> = (props) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const emptyPhoneticValue = { phonetic: [] }
  const fallbackPhoneticValue = props.defaultPhonetics ?? emptyPhoneticValue
  const emptyTextValue = { text: "" }
  const fallbackTextValue = props.defaultText ?? emptyTextValue

  const isEmptyValue = (val: ISlateAlternatePronunciation) => _.isEqual(val, emptyPhoneticValue) || _.isEqual(val, emptyTextValue) || isEmptyAlternatePronunciation(val)
  const isFallbackValue = (val: ISlateAlternatePronunciation) => _.isEqual(val, fallbackPhoneticValue) || _.isEqual(val, fallbackTextValue) || isEmptyValue(val)

  const defaultPhoneticValue = props.value && isPhoneticAlternatePronunciation(props.value) ? props.value : fallbackPhoneticValue
  const defaultTextValue = props.value && isTextAlternatePronunciation(props.value) ? props.value : fallbackTextValue

  const [phoneticState, setPhoneticState] = React.useState<ISlateAlternatePronunciationPhonetic>(defaultPhoneticValue);
  const [textState, setTextState] = React.useState<ISlateAlternatePronunciationText>(defaultTextValue);

  React.useEffect(() => {
    setPhoneticState(defaultPhoneticValue);
  }, [props.value, props.defaultPhonetics]);

  React.useEffect(() => {
    setTextState(defaultTextValue);
  }, [props.value, props.defaultText]);

  const [mode, setMode] = React.useState<"phonetic" | "text">("text");
  React.useEffect(() => {
    props.requestDefaultPhonetics?.(mode);
  }, [props.value, props.requestDefaultPhonetics]);

  const onChange = (newValue: ISlateAlternatePronunciation) => {
    if (isEmptyValue(newValue)) {
      // do nothing
    } else if (!isFallbackValue(newValue)) {
      props.onChange?.(newValue);
    } else {
      props.onChange?.(null);
    }
  }

  return (
    <span className={classNames('flex', props.className)}>
      <span>
        {mode === 'phonetic' ? (
          <PhoneticInput
            ref={inputRef}
            value={phoneticState.phonetic}
            placeholder={"New pronunciation".split("")}
            disabled={props.disabled}
            onChange={(newValue) => {
              const phonetic = { phonetic: newValue };
              setPhoneticState(phonetic);
              onChange(phonetic);
            }}
          />
        ) : (
          <Input
            ref={inputRef}
            value={textState.text}
            placeholder={"New pronunciation"}
            disabled={props.disabled}
            onChange={(newValue) => {
              const text = { text: newValue };
              setTextState(text);
              onChange(text);
            }}
          />
        )}
      </span>
      <span
        className={classNames(
          'ml2 flex flex-column items-center',
          css({
            position: 'relative',
            top: -2
          })
        )}
      >
        <span className={'db tc mb1'} title={'Phonetic Mode'}>
          Phon.
        </span>
        <Checkbox
          checked={mode === 'phonetic'}
          onChange={() => {
            if (mode === 'phonetic') {
              if (!isFallbackValue(textState)) {
                props.onChange?.(textState);
              } else {
                props.onChange?.(null);
              }

              setMode('text');
              props.requestDefaultPhonetics?.('text');
            } else {
              if (!isFallbackValue(phoneticState)) {
                props.onChange?.(phoneticState);
              } else {
                props.onChange?.(null);
              }

              setMode('phonetic');
              props.requestDefaultPhonetics?.('phonetic');
            }

            setTimeout(() => {
              inputRef.current?.focus();
            }, 100);
          }}
          className={'db'}
        />
      </span>
    </span>
  );
};

export default AlternatePronunciationInput;
