import React from "react";
import classNames from "classnames";
import { css } from "emotion";
import { createEditor } from "slate";
import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import { RenderLeafProps } from "slate-react/dist/components/editable";
import {
  ESlateElementType,
  ISlateAlternatePronunciation,
  ISlateNode,
  ISlateSelection,
  ISlateValue
} from "./internal-types";
import { withNormalizationConstraints } from "./normalization";
import { useDecoration } from "./decoration";
import renderLeaf from "./leaf";

export enum EAdjustment {
  SpeedAdjustment = 'SPEED_ADJUSTMENT',
  PitchAdjustment = 'PITCH_ADJUSTMENT',
  WordStress = 'WORD_STRESS',
  LoudnessAdjustment = 'LOUDNESS_ADJUSTMENT',
  AutoToneEffect = 'AUTO_TONE_EFFECT'
}

export enum EMagnitude {
  Low = "Low",
  MedLow = "MedLow",
  Med = "Med",
  MedHigh = "MedHigh",
  High = "High"
}

export type AdjustmentValueMap = {
  [EAdjustment.SpeedAdjustment]: EMagnitude;
  [EAdjustment.PitchAdjustment]: EMagnitude;
  [EAdjustment.WordStress]: EMagnitude;
  [EAdjustment.LoudnessAdjustment]: EMagnitude;
  [EAdjustment.AutoToneEffect]: 'disappointed' | 'terse' | 'excited' | 'none';
}

export type IEditorSelection = {
  start: {
    point: number;
    offset: number;
  };
  end: {
    point: number;
    offset: number;
  };
} | null;

export interface IEditorRef {
  focus(): void;
  blur(): void;
}

export interface IEditorProps {
  value: ISlateValue;
  readOnly?: boolean;
  alternatePronunciations: { [text: string]: ISlateAlternatePronunciation };

  onChange(value: ISlateValue): void;
  onSelectionChange?(selection: ISlateSelection): void;

  className?: string;
}

const Editor = React.forwardRef<IEditorRef, IEditorProps>((props, ref) => {
  const editor = React.useMemo(() => {
    const editor = createEditor();
    withNormalizationConstraints(editor)

    return withReact(editor);
  }, [])

  const onBlur = () => props.onSelectionChange?.(null);

  // allow components keeping track of this editor via a ref to control focus
  React.useImperativeHandle(ref, () => ({
    focus: () => {
      ReactEditor.focus(editor);
    },
    blur: () => {
      ReactEditor.blur(editor);
      onBlur();
    }
  }));

  const decorate = useDecoration(editor, props.alternatePronunciations)

  return (
    <Slate editor={editor} value={[{
      type: 'paragraph',
      children: props.value
    }]}
     onChange={newValue => {
      props.onChange(newValue[0].children as ISlateNode[]);
    }}>
      <Editable readOnly={props.readOnly} decorate={decorate} renderLeaf={renderLeaf(props.value, editor.selection)} onSelect={() => {
        // console.log("Selection changed");
        props.onSelectionChange?.(editor.selection)
      }} />
    </Slate>
  );
});

export default Editor
