import React from "react";
import classNames from "classnames";
import { BooleanParam, StringParam, useQueryParams } from "use-query-params";
import { Redirect, useHistory } from "react-router-dom";
import { css } from "emotion";
import Tile from "../../components/atoms/tile";
import FullPageLoader, { Loader } from "../shared/loader";
import { useEnterpriseContextualState } from "../../components/templates/enterprise";
import Icon, { EIconName } from "../../components/atoms/icon";
import { useTheme } from "../../theme";
import SupportLink from "../../support";
import { useAccountPricingPlanQuery } from "../../graphql/generated";

export const PricingPlanSelectCompletePageQueryParams = {
  plan: StringParam,
  trial: BooleanParam,
  checkout_session: StringParam
} as const

export interface PricingPlanSelectCompletePageProps {
  className?: string;
}

const PricingPlanSelectCompletePage: React.FC<PricingPlanSelectCompletePageProps> = props => {
  const { colors: themeColors } = useTheme();
  const [{
    plan: passedPricingPlanId,
    trial: passedTrial,
    checkout_session
  }] = useQueryParams(PricingPlanSelectCompletePageQueryParams);

  const history = useHistory();

  const trial = passedTrial ?? false;

  const { state } = useEnterpriseContextualState()
  const { data: subscriptionData, refetch } = useAccountPricingPlanQuery({ variables: { account: state.selectedAccount! }, skip: !state.selectedAccount })

  const accountPlanMatchesParams = subscriptionData != null && subscriptionData.account != null && subscriptionData.account.pricingPlanSubscription != null &&
    subscriptionData.account.pricingPlanSubscription.pricingPlan.id === passedPricingPlanId && subscriptionData.account.pricingPlanSubscription.isTrial === trial;

  const { retriesExceeded } = useRetryQueryOnCondition(async () => void await refetch?.({ account: state.selectedAccount! }), !accountPlanMatchesParams, !subscriptionData?.account)
  const loading: boolean = !accountPlanMatchesParams && !retriesExceeded;

  React.useEffect(() => {
    if (accountPlanMatchesParams) {
      setTimeout(() => {
        history.push("/enterprise")
      }, 2000)
    }
  }, [accountPlanMatchesParams])

  if (!subscriptionData?.account) return <FullPageLoader />

  if (!passedPricingPlanId) return <Redirect to={"/pricing"} />

  return (
    <div className={classNames('relative h-100', props.className)}>
      <div className={classNames("flex justify-center items-start vocalid-secondary-text", css({
        paddingTop: '15%'
      }))}>
        <Tile>
          <div className={css({
            minWidth: 300
          })}>
            {
              accountPlanMatchesParams ? (
                <div className={"flex flex-column items-center justify-center pt3"}>
                  <Icon name={EIconName.Checkmark} size={64} color={themeColors.green} className={"mb2"} />
                  <h3 className={"vocalid-h3 vocalid-secondary-text tc mb2"}>Plan Selection Complete!</h3>
                  <div className={"vocalid-h3 vocalid-tertiary-text tc"}>Redirecting...</div>
                </div>
              ) : (
                loading ? (
                  <div className={"flex flex-column items-center justify-center pt3"}>
                    <Loader />
                    <h3 className={"vocalid-h3 vocalid-secondary-text tc"}>Completing plan selection...</h3>
                  </div>
                ) : (
                  <div className={"flex flex-column items-center justify-center pt3"}>
                    <Icon name={EIconName.Warning} size={64} className={"mb2"} />
                    <h3 className={"vocalid-h3 vocalid-secondary-text tc"}>
                      <div className={"mb2"}>An error has occurred.</div>
                      <div className={"mb2"}>Please <SupportLink subject={"Error Selecting PARROT STUDiO Pricing Plan"}>contact VocaliD support</SupportLink> for assistance.</div>
                      { checkout_session && <div className={"mt4 tc vocalid-body vocalid-tertiary-text"}><div className={"ttu mb2"}>Checkout Session Id:</div><div>{checkout_session}</div></div>}
                    </h3>
                  </div>
                )
              )
            }
          </div>
        </Tile>
      </div>
    </div>
  )
}

export default PricingPlanSelectCompletePage;

export const useRetryQueryOnCondition = (refetch: () => Promise<void>, shouldRetry: boolean, skip: boolean = false, maxRetries = 3, retryDelay = 3000) => {
  const [retries, setRetries] = React.useState(0);
  const delayRef = React.useRef<NodeJS.Timeout | null>(null)

  const retry = async (retryCount: number) => {
    // console.log(`Should retry: ${shouldRetry}, retry count: ${retryCount}`)
    if (shouldRetry && retryCount < maxRetries && !skip) {
      setRetries(retryCount + 1);
      // console.log("Retrying");
      refetch()
        .finally(() => {
          delayRef.current = setTimeout(() => {
            retry(retryCount + 1);
          }, retryDelay)
        });
    } else {
      if (delayRef.current) {
        clearTimeout(delayRef.current)
        delayRef.current = null;
      }
    }
  };

  React.useEffect(() => {
    if (shouldRetry && !skip) {
      retry(retries);
    }

    return () => {
      if (delayRef.current) {
        clearTimeout(delayRef.current);
        delayRef.current = null;
      }
    }
  }, [shouldRetry, skip]);

  return {
    retries,
    retriesExceeded: retries >= maxRetries
  }
}
