import React from 'react';
import Modal from '../../atoms/modal';
import classNames from 'classnames';
import { css } from 'emotion';
import { useTheme } from '../../../theme';

type DialogChildrenFn = (renderPropsInput: { close(): void }) => React.ReactNode;

export interface IDialogProps {
  isShown: boolean;
  heading?: React.ReactNode;
  children: React.ReactNode | DialogChildrenFn;

  maxHeight?: number;
  onClose?(): void;
  className?: string;
  style?: React.CSSProperties;
}

const Dialog: React.FC<IDialogProps> = (props) => {
  const { colors: themeColors } = useTheme();

  return (
    <Modal
      isOpen={props.isShown}
      onClose={props.onClose}
      style={props.style}
      containerClassName={css({
        backgroundColor: themeColors.popover,
        borderRadius: 6,
        boxShadow: '2px 2px 7px rgba(0, 0, 0, 0.5)'
      })}
      className={classNames(
        css({
          paddingTop: 35,
          paddingLeft: 55,
          paddingRight: 55,
          minHeight: 0,
          minWidth: 400,
          maxHeight: props.maxHeight ?? 500
        }),
        props.className
      )}
    >
      <h2 className={classNames('mt0 vocalid-h1', css({ marginBottom: 25 }))}>
        {props.heading ?? 'Dialog'}
      </h2>
      <div className={css({ paddingBottom: 35 })}>
        {typeof props.children === 'function'
          ? (props.children as DialogChildrenFn)({
              close: () => {
                props.onClose?.();
              }
            })
          : props.children}
      </div>
    </Modal>
  );
};

export default Dialog;
