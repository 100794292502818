import React from 'react';
import classNames from 'classnames';
import { css } from 'emotion';
import { useTheme } from '../../../theme';
import Icon, { EIconName } from '../icon';

export interface ICheckboxProps {
  checked: boolean;
  label?: React.ReactNode;
  indeterminate?: boolean;
  backgroundColor?: string;
  onChange?: React.HTMLProps<HTMLInputElement>["onChange"];
  onBlur?(): void;
  className?: string;
}

const Checkbox: React.FC<ICheckboxProps> = ({ indeterminate, className, ...props }) => {
  const { colors: themeColors } = useTheme();

  const backgroundColor = props.backgroundColor
    ? (themeColors as any)[props.backgroundColor] ?? props.backgroundColor
    : themeColors.secondaryText;

  const inputRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = indeterminate ?? false;
    }
  }, [indeterminate])

  const checkbox = (cn?: string) => (
    <label
      className={classNames(
        css({
          display: 'inline-block',
          position: 'relative',
          cursor: 'pointer',
          userSelect: 'none',
          height: 20,
          width: 20
        }),
        cn
      )}
    >
      <input
        type={'checkbox'}
        ref={inputRef}
        className={css({
          position: 'absolute',
          opacity: 0,
          cursor: 'pointer',
          height: 0,
          width: 0,
          [`&:checked ~ .checkbox-icon`]: {
            display: 'flex'
          },
          [`&:indeterminate ~ span::before`]: {
            display: 'block'
          }
        })}
        checked={props.checked}
        onChange={props.onChange}
      />
      <span
        className={css({
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundColor,
          borderRadius: 2,
          zIndex: 0,
          '&::before': {
            display: 'none',
            content: "''",
            position: 'absolute',
            left: 3,
            top: 9,
            right: 3,
            bottom: 9,
            backgroundColor: themeColors.tile
          }
        })}
      />
      <div
        className={
          'dn checkbox-icon absolute top-0 left-0 right-0 bottom-0 justify-center items-center z-1'
        }
      >
        <Icon name={EIconName.Checkmark} color={'tile'} size={12} />
      </div>
    </label>
  );

  if (props.label) {
    return (
      <span className={classNames("inline-flex", props.onChange && "pointer", className)} onClick={e => {
        inputRef.current?.click();
        e.stopPropagation();
        e.preventDefault();
      }} /*onClick={() => props.onChange?.(!props.checked)}*/>
        {checkbox()}
        <span className={"pl2 vocalid-h3 vocalid-secondary-text"}>{props.label}</span>
      </span>
    );
  }

  return checkbox(className)
};

export default Checkbox;
