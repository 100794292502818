import React, {ErrorInfo} from "react";

interface IEditorErrorBoundaryState {
  hasError: boolean
}

export default class EditorErrorBoundary extends React.Component<{}, IEditorErrorBoundaryState> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {}

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div className={"vocalid-secondary-text tc pt1 pb3"}>An error has occurred within the Editor. Please refresh the page.</div>;
    }

    return this.props.children;
  }
}
