import React from 'react';
import { VictoryPie, VictoryLabel } from 'victory';
import { useTheme } from '../../../theme';

export interface IRecordingsReviewedChartProps {
  acceptedCount: number;
  rejectedCount: number;
  notReviewedCount: number;
  className?: string;
  style?: React.CSSProperties;
}

const RecordingsReviewedChart: React.FC<IRecordingsReviewedChartProps> = (props) => {
  const theme = useTheme();

  const reviewed = props.acceptedCount + props.rejectedCount
  const total = props.acceptedCount + props.rejectedCount + props.notReviewedCount

  return (
    <div className={props.className} style={props.style}>
      <div className={'relative'}>
        <svg viewBox={`0 0 400 400`}>
          <g transform="translate(55, 72), scale(1.1)">
            <path
              d="M251.506224,152.877288 L11.5062237,152.877288 C24.4443175,177.119173 67.269098,225.604443 135.06434,225.604443 C202.858982,225.604443 240.940517,177.119173 251.506224,152.877288 Z"
              fill={theme.colors.popover}
            />
          </g>
          <VictoryPie
            standalone={false}
            width={400}
            height={400}
            data={[
              { x: 'Fallback', y: 100, fill: theme.colors.iconInactive }
            ]}
            innerRadius={120}
            labels={[]}
            style={{
              data: {
                fill: ({ datum }) => datum.fill,
                opacity: ({ datum }) => datum.opacity
              }
            }}
          />
          <VictoryPie
            standalone={false}
            width={400}
            height={400}
            data={[
              { x: 'Liked', y: props.acceptedCount, fill: theme.colors.primaryButton },
              { x: 'Disliked', y: props.rejectedCount, fill: theme.colors.red },
              { x: 'Not Reviewed', y: props.notReviewedCount, fill: theme.colors.tertiaryText }
            ]}
            innerRadius={120}
            labels={[]}
            style={{
              data: {
                fill: ({ datum }) => datum.fill,
                opacity: ({ datum }) => datum.opacity
              }
            }}
          />
          <VictoryLabel
            textAnchor="middle"
            verticalAnchor="end"
            x={200}
            y={210}
            dy={5}
            style={{
              fontSize: 70,
              fill: theme.colors.primaryText,
              fontFamily: theme.typography.body.fontFamily
            }}
            text={(total) === 0 ? "0%" : `${(
              (reviewed / total) *
              100
            ).toFixed(0)}%`}
          />
          <VictoryLabel
            textAnchor="middle"
            verticalAnchor="start"
            x={200}
            y={230}
            dy={20}
            style={{
              fontSize: 20,
              textTransform: 'uppercase',
              fill: theme.colors.darkText,
              fontFamily: theme.typography.body.fontFamily
            }}
            text={`${reviewed}/${total}`}
          />
          <VictoryLabel
            textAnchor="middle"
            verticalAnchor="start"
            x={200}
            y={230}
            dy={45}
            style={{
              fontSize: 20,
              textTransform: 'uppercase',
              fill: theme.colors.darkText,
              fontFamily: theme.typography.body.fontFamily
            }}
            text="Reviewed"
          />
        </svg>
      </div>
    </div>
  );
};

export default RecordingsReviewedChart;
