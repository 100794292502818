import { ClipFragment, useCreateClipMutation, useCreateTakeMutation, useDeleteClipMutation, useDeleteTakeMutation, useUpdateClipMutation, useUpdateTakeMutation } from "../../../graphql/generated";
import React from "react";
import { IEditorValue } from "./editor-types";
import { ITake } from "../../../components/organisms/clip";
import { ETakeFlag, graphQLClipToLocalClipData } from "../../shared/gql-clip";
import { serializeTextToEditorValue } from "./editor-value-serialization";
import { useDefaultPronunciationQuery } from "../../../graphql/queries/DefaultPronunciationQuery";

export function useManagedClips(projectId: string, clipsData: ClipFragment[], projectQueryRefetch: () => Promise<any>) {
  const [createClipMutation] = useCreateClipMutation();
  const createClip = async () => {
    await createClipMutation({
      variables: {
        project: projectId,
        title: null
      }
    });
    await projectQueryRefetch();
  };

  const [updateClipMutation] = useUpdateClipMutation();
  const updateClipTitle = async (clipId: string, title: string) => {
    await updateClipMutation({
      variables: {
        clip: clipId,
        title
      },
      // optimisticResponse: {
      //   updateClip: {
      //     __typename: "UpdateClipSuccessResponse",
      //     updated: true,
      //     clip: {
      //       __typename: "Clip",
      //       id: clipId,
      //       title
      //     }
      //   }
      // }
    })
  }

  const [deletedClipIds, setDeletedClipIds] = React.useState<string[]>([]);
  const [deleteClipMutation] = useDeleteClipMutation()
  const deleteClip = async (clipId: string) => {
    await deleteClipMutation({
      variables: {
        clipId
      }
    })
    setDeletedClipIds(oldIds => [...oldIds, clipId])
    // dispatch(createSetClipToDeleteAction({ clipId }));
  };

  const [generatingTake, setGeneratingTake] = React.useState(false)

  const [createTakeMutation] = useCreateTakeMutation()
  const generateTake = async (clipId: string, content: IEditorValue, voiceId: string, requestRecordingByTalent: boolean = false) => {
    // if (!project) return
    // if (!project.defaultVoice) return

    setGeneratingTake(true)
    await createTakeMutation({
      variables: {
        input: {
          clip: clipId,
          voice: voiceId,
          serializedTranscript: JSON.stringify(content),
          requestRecordingByTalent
        }
      }
    })

    setGeneratingTake(false)
  }

  const [updateTakeMutation] = useUpdateTakeMutation();
  const updateTakeStatus = async (id: string, state: ITake["status"]) => {
    await updateTakeMutation({ variables: {
        id,
        flag: state === 'approved' ? ETakeFlag.ThumbsUp : ETakeFlag.ThumbsDown
      } })
  }

  const [deletedTakes, setDeletedTakes] = React.useState([] as string[])
  const [deleteTakeMutation] = useDeleteTakeMutation()
  const deleteTake = async (id: string) => {
    setDeletedTakes([...deletedTakes, id])
    await deleteTakeMutation({
      variables: {
        takeId: id
      }
    })
  }

  const clips = clipsData.filter(clip => !deletedClipIds.includes(clip.id)).map(clip => {
    const convertedClip = graphQLClipToLocalClipData(clip);

    return {
      ...convertedClip,
      takes: convertedClip.takes.filter(take => !deletedTakes.includes(take.id)),
      projectId
    }
  })

  // React.useEffect(() => {
  //   console.log("CONVERTED CLIPS");
  //   console.log(JSON.stringify(clips, null, 2))
  // }, [clips]);

  const createClips = async (clips: Array<{ title: string; text: string; }>, voiceId: string) => {
    const reversedClips = clips.reduceRight((previous, current) => {
      previous.push(current);
      return previous;
    }, [] as typeof clips);

    await Promise.all(reversedClips.map(async (clip, index) => {
      // wait, so they arrive in order
      await new Promise(resolve => setTimeout(resolve, index * 300));

      const createClipResult = await createClipMutation({
        variables: {
          project: projectId,
          title: clip.title
        }
      });

      const editorValue: IEditorValue = serializeTextToEditorValue(clip.text);

      await createTakeMutation({
        variables: {
          input: {
            clip: createClipResult.data!.createClip.clip.id,
            voice: voiceId,
            serializedTranscript: JSON.stringify(editorValue)
          }
        }
      });
    }));

    await projectQueryRefetch();
  }

  const defaultPronunciationQuery = useDefaultPronunciationQuery();
  const getDefaultPronunciation = async (voiceId: string, text: string) => {
    const defaultPronunciationData = (await defaultPronunciationQuery(voiceId, text)).data?.voice?.pronunciation ?? null;
    const defaultPronunciation = [...defaultPronunciationData];

    if (defaultPronunciation && defaultPronunciation.length > 0) {
      // remove trailing periods, if any
      if (defaultPronunciation[defaultPronunciation.length - 1] === '.') {
        defaultPronunciation.pop();
      }
    }

    return defaultPronunciation
  }

  return {
    clips,
    createClip,
    createClips,
    updateClipTitle,
    deleteClip,
    generateTake,
    updateTakeStatus,
    deleteTake,
    getDefaultPronunciation
  }
}
