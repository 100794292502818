import React from 'react';
import Select from '../../molecules/select';
import RecordingsReviewTable, {
  IRecordingsReviewTableProps
} from '../../organisms/recordings-review-table';
import Header from '../../atoms/header';
import PaginationControls from '../../atoms/pagination-controls';
import Dropdown, { DropdownOption, DropdownSeparator } from '../../molecules/dropdown';
import Checkbox from '../../atoms/checkbox';
import Icon, { EIconName } from '../../atoms/icon';
import { css } from 'emotion';
import RecordingInProgressInfo from '../../organisms/recording-in-progress-info';
import { CircularProgress } from '@material-ui/core';
import RecordingsReviewTableContainer from "../../../containers/organisms/recordings-review-table";

export interface IEnterpriseRecordingsReviewPageProps {
  data: {
    assignment: {
      id: string;
      due: Date | null;
      started: Date | null;
      totalRecordingsCount: number;
      reviewedRecordingsCount: number;
      contentLines: number;
      acceptedCount: number;
      rejectedCount: number;
      recordedErrorCount: number;
    }
    vocal: {
      name: string;
    };
    talent: {
      id: string;
      name: string;
    }
    completedRecordingsCount: number;
  };
  className?: string;
}

const EnterpriseRecordingsReviewPage: React.FC<IEnterpriseRecordingsReviewPageProps> = (props) => {
  const dueIn = props.data.assignment.due ? Math.max(0, Math.round((props.data.assignment.due.getTime() - (new Date().getTime() * 1000))/(24*60*60*1000))) : null

  return (
    <div className={props.className}>
      <Header>
        <h1 className={'db mt2 mb3 vocalid-h1 ma0'}>
          Training Recording for vocal:
          <br />
          {props.data.vocal.name}
        </h1>
        <div>
          <RecordingInProgressInfo
            {...props.data.assignment}
            data={{
              metadata: {
                talent: props.data.talent.name,
                vocal: null,
                initiated: props.data.assignment.started,
                due: props.data.assignment.due
              }
            }}
            hideVocalCreationStatus
            hideReviewRecordingButton
            dueInDays={dueIn}
          />
        </div>
      </Header>
      <RecordingsReviewTableContainer assignmentId={props.data.assignment.id} commentWritingAllowed />
    </div>
  );
};

export default EnterpriseRecordingsReviewPage;
