import React from 'react';
import EnterpriseProfilePage from '../../components/pages/enterprise-profile';
import FullPageLoader from "../shared/loader";
import GenericErrorBoundary from "../../components/molecules/generic-error-boundary";
import { Auth0Config } from "../../auth";
import { useEnterpriseProfileQuery, useDeleteApiTokenMutation } from "../../graphql/generated";

export interface IEnterpriseProfilePageContainerProps {}

const EnterpriseProfilePageContainer: React.FC<IEnterpriseProfilePageContainerProps> = (props) => {
  const { data, loading, refetch } = useEnterpriseProfileQuery()

  const [deleteApiTokenMutation] = useDeleteApiTokenMutation()

  if (!data || loading) return <FullPageLoader />
  if (!data.viewer?.user) return null // add error?

  return (
    <GenericErrorBoundary>
      <EnterpriseProfilePage
        data={{
          user: {
            firstName: data.viewer.user.firstName,
            lastName: data.viewer.user.lastName,
            email: data.viewer.user.emailAddress
          },
          apiTokens: data.viewer.user.apiTokens.map(token => ({
            id: token.id,
            name: token.name,
            createdAt: new Date(token.created),
            limitedToAccounts: token.limitedToAccounts
          }))
        }}
        // onSave={(newUserData) => {}}
        // onDelete={() => {}}
        onResetPassword={async () => {
          await fetch('https://vocalid.auth0.com/dbconnections/change_password', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              client_id: Auth0Config.clientId,
              email: data!.viewer!.user.emailAddress,
              connection: 'Username-Password-Authentication'
            })
          });
        }}
        onCreatedApiToken={() => {
          refetch();
        }}
        onDeleteApiToken={async (tokenId) => {
          await deleteApiTokenMutation({
            variables: {
              token: tokenId
            }
          });

          refetch();
        }}
      />
    </GenericErrorBoundary>
  );
};

export default EnterpriseProfilePageContainer;
