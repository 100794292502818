import { IEditorAdjustmentRange, IEditorNode, IEditorValue, INewlineNode, ISpaceNode, IWordNode } from "./editor-types";
import {
  ESlateElementType, ISlateNewlineNode,
  ISlateNode,
  ISlateSpaceNode, ISlateWordNode,
  SlateAdjustment
} from "../../../components/organisms/clip/editor/adjustments/internal-types";
import { AdjustmentValueMap, EAdjustment, EMagnitude } from "../../../components/organisms/clip/editor/adjustments";

export const serializeExternalValue = (value: IEditorValue): ISlateNode[] => {
  const nodes = value.nodes.reduce<ISlateNode[]>((acc, node, i) => {
    const adjustments = value.adjustmentRanges.reduce<Array<SlateAdjustment<EAdjustment>>>((adjAcc, range) => {
      if (i >= range.offset && i < (range.offset + range.length)) {
        const newAdj = {
          type: range.type,
          value: range.tone ?? range.magnitude!
        }

        return [...adjAcc, newAdj]
      }

      return adjAcc
    }, [])

    switch (node.type) {
      case "word":
        const wordNode: ISlateWordNode = {
          type: ESlateElementType.Word,
          text: node.value,
          adjustments,
          pause: node.pause,
          pauseInMilliseconds: node.pauseInMilliseconds,
          alternatePronunciation: node.alternatePronunciation
        }

        return [
          ...acc,
          wordNode
        ]
      case "space":
        const spaceNode: ISlateSpaceNode = {
          type: ESlateElementType.Space,
          text: " ",
          adjustments
        }

        return [
          ...acc,
          spaceNode
        ]
      case "newline":
        const newlineNode: ISlateNewlineNode = {
          type: ESlateElementType.Newline,
          text: "\n",
          adjustments
        }

        return [
          ...acc,
          newlineNode
        ]
    }
  }, []);

  if (nodes.length !== 0) {
    return nodes;
  } else {
    return [{ type: ESlateElementType.Word, text: "" }]
  }
}

type AdjustmentAccumulator = {
  [key in EAdjustment]: [number, AdjustmentValueMap[key]] | null;
}

export const deserializeInternalValue = (slateNodes: ISlateNode[]): IEditorValue => {
  let lastAdjustmentIndexes: AdjustmentAccumulator = {
    [EAdjustment.SpeedAdjustment]: null,
    [EAdjustment.PitchAdjustment]: null,
    [EAdjustment.WordStress]: null,
    [EAdjustment.LoudnessAdjustment]: null,
    [EAdjustment.AutoToneEffect]: null
  };

  const adjustmentRanges: Array<IEditorAdjustmentRange> = [];

  const nodes = slateNodes.reduce<IEditorNode[]>((acc, node, i, arr) => {
    const slateNode = node as ISlateNode;

    // deserialize node
    const editorNode: IEditorNode | null = slateNode.type === ESlateElementType.Word ? {
      type: 'word',
      value: slateNode.text,
      pause: slateNode.pauseInMilliseconds === undefined ? slateNode.pause : undefined,
      pauseInMilliseconds: slateNode.pauseInMilliseconds,
      alternatePronunciation: slateNode.alternatePronunciation
    } as IWordNode : slateNode.type === ESlateElementType.Space ? {
      type: 'space'
    } as ISpaceNode : slateNode.type === ESlateElementType.Newline ? {
      type: 'newline'
    } : null;

    if (editorNode === null) {
      throw new Error(`slate node type ${slateNode.type} not supported for deserialization`);
    }

    // close/open new adjustment range
    for (const adjIndexKey in lastAdjustmentIndexes) {
      const adjustment = adjIndexKey as EAdjustment;
      let lastAdjustmentIndex = lastAdjustmentIndexes[adjustment];

      const foundAdjustment = slateNode.adjustments?.find(adj => adj.type === adjustment);

      if (lastAdjustmentIndex === null && foundAdjustment) {
        // console.log(`Adjustment range opened (${i})`);
        // range opened
        lastAdjustmentIndexes[adjustment] = [i, foundAdjustment.value] as any; // this type cast is weird
      }

      lastAdjustmentIndex = lastAdjustmentIndexes[adjustment];

      if (lastAdjustmentIndex !== null && (i === arr.length - 1 || !foundAdjustment)) {
        adjustmentRanges.push({
          offset: lastAdjustmentIndex[0],
          length: ((i === arr.length - 1 && foundAdjustment) ? i + 1 : i) - lastAdjustmentIndex[0],
          type: adjustment,
          magnitude: adjustment !== EAdjustment.AutoToneEffect ? lastAdjustmentIndex[1] as EMagnitude : undefined,
          tone: adjustment === EAdjustment.AutoToneEffect ? lastAdjustmentIndex[1] as AdjustmentValueMap[EAdjustment.AutoToneEffect] : undefined
        });

        lastAdjustmentIndexes[adjustment] = null;
      }
    }

    return [...acc, editorNode]
  }, [])

  return {
    nodes,
    adjustmentRanges
  }
}

export const serializeTextToEditorValue = (text: string): IEditorValue => {
  return {
    nodes: text.split(" ").flatMap((w, i, arr) => {
      const newArr: IEditorNode[] = []

      if (w !== "") {
        const splitNewlines = w.split("\n")

        if (splitNewlines.length !== 1) {
          newArr.push(...splitNewlines.flatMap((sw, sI, sArr) => {
            const wordNode: IWordNode = {
              type: "word",
              value: sw,
              pause: undefined,
              pauseInMilliseconds: undefined
            };

            const newlineNode: INewlineNode = {
              type: "newline"
            }

            return sw !== "" ? [wordNode, ...(sI !== sArr.length - 1 ? [newlineNode] : [])] : []
          }));
        } else {
          newArr.push({ type: "word", value: w, pause: undefined, pauseInMilliseconds: undefined });
        }

        if (i !== arr.length - 1) {
          newArr.push({ type: "space" });
        }
      }

      return newArr
    }),
    adjustmentRanges: []
  };
}
