import {Editor as SlateEditor, Element, Node, Text, Transforms} from "slate";
import {ESlateElementType, ISlateNewlineNode, ISlateNode, ISlateSpaceNode, ISlateWordNode} from "./internal-types";

// when another paragraph is added (newline), normalization is referencing the first paragraph

const logNormalizations = false;

const withWordNodesSplitBySpaces = (editor: SlateEditor): SlateEditor => {
  const { normalizeNode } = editor;

  editor.normalizeNode = entry => {
    const [node, path] = entry;

    if (Element.isElement(node) && node.type === 'paragraph') {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (Text.isText(child) && (child as Text).text !== " ") {
          const textChild = child as Text;

          // break word into pieces
          const match = / +/.exec(textChild.text.toLowerCase());

          if (match) {
            logNormalizations && console.log("Breaking text spaces", textChild.text);
            // console.log(match[0], match.index)

            Transforms.setNodes(editor, {
              type: ESlateElementType.Word
            } as Partial<ISlateWordNode>, {
              at: childPath
            });

            Transforms.delete(editor, {
              at: {
                anchor: {
                  path: childPath, offset: match.index
                },
                focus: {
                  path: childPath, offset: match.index + match[0].length
                }
              }
            });

            Transforms.insertNodes(editor, {
              type: ESlateElementType.Space,
              text: " ",
              adjustments: textChild.adjustments
            } as ISlateSpaceNode, {
              at: { path: childPath, offset: match.index }
            });

            // this depends on there being a root node containing all child nodes
            Transforms.select(editor, { path: [childPath[0], childPath[1] + 1], offset: 1 });

            return;
          }
        }
      }
    }

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry)
  }

  return editor
}

const withNoSpacesWithContent = (editor: SlateEditor): SlateEditor => {
  const { normalizeNode } = editor;

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (Element.isElement(node) && node.type === 'paragraph') {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (Text.isText(child)) {
          const slateChild = child as Text;

          // any node without the content of " " should not be a space
          if (slateChild.text !== " " && slateChild.type !== ESlateElementType.Word && !(slateChild.type === ESlateElementType.Newline && slateChild.text === "\n")) {
            logNormalizations && console.log("Converting non-empty node to word", slateChild)
            Transforms.setNodes(editor, {
              type: ESlateElementType.Word
            } as Partial<ISlateWordNode>, {
              at: childPath
            });

            return;
          }

          if (slateChild.text === " " && slateChild.type !== ESlateElementType.Space) {
            logNormalizations && console.log("Converting empty node to space", slateChild);
            Transforms.setNodes(editor, {
              type: ESlateElementType.Space
            } as Partial<ISlateSpaceNode>, {
              at: childPath
            });

            return;
          }
        }
      }
    }

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry)
  }

  return editor
}

// const noEmptyParagraphNodes = (editor: SlateEditor): SlateEditor => {
//   const { normalizeNode } = editor;
//
//   editor.normalizeNode = entry => {
//     const [node, path] = entry
//
//     if (Element.isElement(node) && node.type === 'paragraph') {
//       if (node.children.length === 0) {
//         console.log("Adding word node to empty paragraph", path)
//
//         Transforms.insertNodes(editor, {
//           type: ESlateElementType.Word,
//           text: ""
//         } as ISlateWordNode, {
//           at: [...path, 0]
//         });
//
//         // Transforms.insertText(editor, "")
//         return;
//       }
//     }
//
//     // Fall back to the original `normalizeNode` to enforce other constraints.
//     normalizeNode(entry);
//   }
//
//   return editor
// }

const withOnlyOneRootParagraphNode = (editor: SlateEditor): SlateEditor => {
  const { normalizeNode } = editor;

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (Element.isElement(node) && node.type === 'paragraph') {
      const [pIndex, ...restOfPath] = path;

      if (pIndex !== 0) {
        logNormalizations && console.log("Converting paragraph to newline", path)

        const paragraphNodes = node.children;

        Transforms.delete(editor, {
          at: path
        });

        const newlineIndex = (editor.children[0].children as Node[]).length;

        Transforms.insertNodes(editor, {
          type: ESlateElementType.Newline,
          text: "\n"
        } as ISlateNewlineNode, {
          at: [0, ...restOfPath, newlineIndex]
        });

        Transforms.insertNodes(editor, paragraphNodes, {
          at: [0, ...restOfPath, (editor.children[0].children as Node[]).length]
        });

        // jump selection to where newline was added
        // this depends on there being a root node containing all child nodes
        Transforms.select(editor, { path: [0, ...restOfPath, newlineIndex + 1], offset: 0 });

        return;
      }
    }

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry);
  }

  return editor;
}

const withWordNodesSplitByNewlines = (editor: SlateEditor): SlateEditor => {
  const { normalizeNode } = editor;

  editor.normalizeNode = entry => {
    const [node, path] = entry;

    if (Element.isElement(node) && node.type === 'paragraph') {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (Text.isText(child) && (child.type !== ESlateElementType.Newline || (child as Text).text !== "\n")) {
          const textChild = child as Text;

          // break word into pieces
          const match = /\n+/.exec(textChild.text.toLowerCase());

          if (match) {
            logNormalizations && console.log("Breaking newlines", textChild.text);
            // console.log(match[0], match.index)

            Transforms.setNodes(editor, {
              type: ESlateElementType.Word
            } as Partial<ISlateWordNode>, {
              at: childPath
            });

            Transforms.delete(editor, {
              at: {
                anchor: {
                  path: childPath, offset: match.index
                },
                focus: {
                  path: childPath, offset: match.index + match[0].length
                }
              }
            });

            Transforms.insertNodes(editor, {
              type: ESlateElementType.Newline,
              text: "\n",
              adjustments: textChild.adjustments
            } as ISlateNewlineNode, {
              at: { path: childPath, offset: match.index }
            });

            return;
          }
        }
      }
    }

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry)
  }

  return editor
}

const withNoNodesWithEmptyContent = (editor: SlateEditor): SlateEditor => {
  const { normalizeNode } = editor;

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (Element.isElement(node) && node.type === 'paragraph') {
      const children = Array.from(Node.children(editor, path));

      let lastChild: ISlateNode | null = null

      for (const [child, childPath] of children) {
        if (Text.isText(child)) {
          const slateChild = child as ISlateWordNode | ISlateSpaceNode | ISlateNewlineNode;

          const isOnlyTextElementInParagraph = (childPath[1] === 0 && children.length === 1)

          const isWordRightAfterNewline = lastChild?.type === ESlateElementType.Newline

          // && !isOnlyTextElementInParagraph to prevent only text node from being deleted
          if (slateChild.text === "" && !isOnlyTextElementInParagraph && !isWordRightAfterNewline) {
            // nodes with empty content "" should be removed
            logNormalizations && console.log("Removing word/space node with empty content", slateChild, childPath, children.length)
            Transforms.removeNodes(editor, {
              at: childPath
            })

            return
          }

          lastChild = slateChild
        }
      }
    }

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry)
  }

  return editor
}

const withNoAdjacentWords = (editor: SlateEditor): SlateEditor => {
  const { normalizeNode } = editor;

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (Element.isElement(node) && node.type === 'paragraph') {
      let lastChild: ISlateNode | null = null

      for (const [child, childPath] of Node.children(editor, path)) {
        const slateChild = child as ISlateNode;

        // if both this child and last child are words, merge them
        if (slateChild.type === ESlateElementType.Word && !slateChild.text.includes(" ") && lastChild && lastChild.type === ESlateElementType.Word && !lastChild.text.includes(" ")) {
          logNormalizations && console.log("Merging adjacent words", lastChild, slateChild)

          Transforms.mergeNodes(editor, {
            at: childPath
          })

          return;
        }

        lastChild = slateChild
      }
    }

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry)
  }

  return editor
}

export const withNormalizationConstraints = (editor: SlateEditor): SlateEditor => {
  // noEmptyParagraphNodes(editor)
  withOnlyOneRootParagraphNode(editor)
  withWordNodesSplitByNewlines(editor)
  withWordNodesSplitBySpaces(editor)
  withNoSpacesWithContent(editor)
  withNoNodesWithEmptyContent(editor)
  withNoAdjacentWords(editor)

  // const { normalizeNode } = editor;
  // editor.normalizeNode = entry =>  {
  //   console.log("Normalizing")
  //   console.log("Value")
  //   console.log(editor.children)
  //   normalizeNode(entry)
  // }

  return editor;
}
