import React from 'react';
import classNames from 'classnames';
import { css } from 'emotion';

export interface ITalentAuthTemplateProps {
  className?: string;
}

const TalentAuthTemplate: React.FC<ITalentAuthTemplateProps> = (props) => {
  return (
    <>
      <div
        className={classNames(
          'flex flex-column h-100 bg-vocalid-page vocalid-body',
          props.className
        )}
      >
        <nav
          className={classNames(
            css({ borderWidth: 1 }),
            'bg-vocalid-header bb b--vocalid-header-border'
          )}
        >
          <div className={'center flex flex-shrink-0 items-center justify-between pv2 pl5 pr4'}>
            <img
              src={'/icon-192x192.png'}
              alt={'VocaliD Icon'}
              width={40}
              height={40}
              className={'mr4'}
            />
            <img src={'/logo.png'} alt={'VocaliD'} height={17} />
            <span style={{ width: 40 }} className={'ml4'} />
          </div>
        </nav>
        <div className={'flex-grow-1 overflow-y-auto relative'}>{props.children}</div>
      </div>
    </>
  );
};

export default TalentAuthTemplate;
