import React from 'react';
import SignedOutPage from '../../components/pages/signed-out';
import { useHistory } from 'react-router-dom';
import GenericErrorBoundary from "../../components/molecules/generic-error-boundary";

export interface IEnterpriseSignedOutPageContainerProps {
  className?: string;
}

const EnterpriseSignedOutPageContainer: React.FC<IEnterpriseSignedOutPageContainerProps> = (
  props
) => {
  const history = useHistory();
  return (
    <GenericErrorBoundary>
      <SignedOutPage
        onSignBackIn={() => {
          history.push('/enterprise');
        }}
        className={props.className}
      />
    </GenericErrorBoundary>
  );
};

export default EnterpriseSignedOutPageContainer;
