import React from "react";
import classNames from "classnames";
import FullPageLoader from "../../shared/loader";
import { StringParam, useQueryParams, BooleanParam, encodeQueryParams } from "use-query-params";
import { useAuth } from "../../../auth";
import { useCreateUserMutation } from "../../../graphql/generated";
import Tile from "../../../components/atoms/tile";
import { PrimaryButton } from "../../../components/atoms/button";
import { Redirect } from "react-router-dom";
import { useForm } from "../../../components/shared/form";
import Input from "../../../components/atoms/input";
import { css } from "emotion";
import Icon, { EIconName } from "../../../components/atoms/icon";
import { useTheme } from "../../../theme";
import * as queryString from "querystring";
import { CircularProgress } from "@material-ui/core";
import LogRocket from "logrocket";
import { PricingPlansPageQueryParams } from "../pricing-plans-page";
import {
  usePricingPlanQuery
} from "../../../graphql/generated";
import enterpriseImage from "../../../components/pages/landing/enterprise.jpg";
import { Link } from "react-router-dom";
import { NO_PLAN_ID } from "../../organisms/pricing-plan-selection/pricing-plans-list";
import RegistrationTemplate from "./template";
import { CompanyAccountCreationPageQueryParams } from "./company-account-creation";

export const CreateUserAccountPageQueryParams = {
  plan: StringParam,
  trial: BooleanParam,
  origin: StringParam,
  mailing_list_addr: StringParam
} as const;

export interface CreateUserAccountPageProps {
  className?: string;
}

const CreateUserAccountPage: React.FC<CreateUserAccountPageProps> = props => {
  const [{
    plan: pricingPlanId,
    trial: passedTrial,
    origin,
    mailing_list_addr: mailingListAddr
  }] = useQueryParams(CreateUserAccountPageQueryParams);

  const { colors: themeColors } = useTheme();
  const { isAuthenticated } = useAuth();

  const trial = passedTrial ?? false;

  const { data, loading, error } = usePricingPlanQuery({ variables: { id: pricingPlanId! }, skip: !pricingPlanId });

  React.useEffect(() => {
    if (pricingPlanId) {
      LogRocket.track("PricingPlanSelectionFlowStarted", {
        plan: pricingPlanId,
        trial: passedTrial ?? false,
        ...(origin && {
          origin
        }),
        ...(mailingListAddr && {
          mailingListAddr: mailingListAddr
        })
      });
    }
  }, []);

  if (!pricingPlanId) return <Redirect
    to={"/pricing" + "?" + queryString.stringify(encodeQueryParams(PricingPlansPageQueryParams, {
      origin: origin,
      mailing_list_addr: mailingListAddr
    }) as any)} />;

  if (loading) return <FullPageLoader />;

  if (pricingPlanId !== NO_PLAN_ID && (!data || (trial && data.pricingPlan.trial == null) || !data.pricingPlan)) return <Redirect
    to={"/pricing" + "?" + queryString.stringify(encodeQueryParams(PricingPlansPageQueryParams, {
      origin: origin,
      mailing_list_addr: mailingListAddr
    }) as any)} />;

  return (

    <RegistrationTemplate heading={<>Create<br />Your Account</>} subtitle={<>Step 2 of 3</>}
                          className={props.className}>
      <Tile contentClassName={"flex-grow-1 vocalid-secondary-text flex mv2"}>
        {/*<div className={"flex-l flex-column dn mr4"}>
                <div className={"ba bw2 br3 b--vocalid-blue shadow-4 flex-grow-1"}>
                  <div className={"relative overflow-y-hidden"} style={{ minHeight: 500, width: PricingPlanListingWidth }}>
                    <div className={css({
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background: "linear-gradient(0deg, rgba(43,77,90,1) 0%, rgba(43,77,90,0) 20%)",
                      pointerEvents: 'none',
                      zIndex: 1
                    })} />
                    <div className={"absolute top-0 left-0"}>
                      <SmartPricingPlanListing id={props.pricingPlanId} show={props.trial ? 'trial' : 'plan'} />
                    </div>
                  </div>
                </div>
                <label className={"vocalid-body vocalid-icon-active mt2 tc ttu"}>Selected Plan</label>
              </div>*/}
        <div className={"flex flex-column"}>
          {isAuthenticated ?
            <Redirect
              to={"/enterprise"}
            /> :
            <UnauthenticatedView
              pricingPlanId={pricingPlanId}
              trial={trial}
              mailingListAddr={mailingListAddr ?? undefined}
            />
          }
        </div>
      </Tile>
    </RegistrationTemplate>
  );
};

export default CreateUserAccountPage;


interface UnauthenticatedViewProps {
  pricingPlanId: string;
  trial: boolean;
  mailingListAddr: string | undefined;
}

type FormAccountState = {
  state: "form";
  creating: boolean;
  error: boolean;
}

type CreatedUserState = {
  state: "created",
  userId: string;
  userEmail: string;
}

const UnauthenticatedView: React.FC<UnauthenticatedViewProps> = props => {
  const { colors: themeColors } = useTheme();
  const { loginWithRedirect } = useAuth();
  const [creatingState, setCreatingState] = React.useState<FormAccountState | CreatedUserState>({
    state: "form",
    creating: false,
    error: false
  });

  React.useEffect(() => {
    if (creatingState.state === "created") {
      setTimeout(() => {
        loginWithRedirect("/enterprise" + "?" + queryString.stringify(encodeQueryParams(CompanyAccountCreationPageQueryParams, {
          plan: props.pricingPlanId,
          trial: props.trial
        }) as any), {
          login_hint: creatingState.userEmail
        });
      }, 1000);
    }
  }, [creatingState]);

  const [createUser] = useCreateUserMutation();

  const form = useCreateUserForm(async values => {
    LogRocket.track("PricingPlanSignup", {
      plan: props.pricingPlanId,
      trial: props.trial,
      email: form.values.email
    });

    setCreatingState(o => ({
      ...o as FormAccountState,
      creating: true
    }));

    const res = await createUser({
      variables: {
        input: {
          mailingListEmailAddress: props.mailingListAddr ?? null,
          emailAddress: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          password: values.password
        }
      }
    });

    if (!res.data || res.errors) {
      setCreatingState(o => ({
        ...o as FormAccountState,
        error: true
      }));
      return;
    }

    setCreatingState({
      state: "created",
      userId: res.data.createUser.user.id,
      userEmail: values.email
    });

    /*LogRocket.track('PricingPlanSignedUp', {
      plan: props.pricingPlanId,
      trial: props.trial,
      email: form.values.email,
      userId: res.data.createAccount.user.user.id,
      account: res.data.createAccount.account.id
    });*/
  });

  return (
    creatingState.state === "form" ? (
      <div>
        <div className={"pv1 ph1"} /*className={"mb3 ba br3 b--vocalid-tertiary-text pa3"}*/>
          <Input
            label={"Email*"}
            placeholder={"johnny@email.com"}
            {...form.inputProps("email")}
            className={"mb3"}
          />
          <div className={"flex"}>
            <Input
              label={"First Name*"}
              placeholder={"Johnny"}
              {...form.inputProps("firstName")}
              className={"flex-auto mb3 dib mr3 v-top"}
            />
            <Input
              label={"Last Name*"}
              placeholder={"Appleseed"}
              {...form.inputProps("lastName")}
              className={"flex-auto mb3 dib v-top"}
            />
          </div>
          <div className={"flex"}>
            <Input
              type={"password"}
              label={"Password*"}
              {...form.inputProps("password")}
              className={"flex-auto mb3 dib mr3 v-top"}
            />
            <Input
              type={"password"}
              label={"Confirm Password*"}
              {...form.inputProps("confirmPassword")}
              className={"flex-aut omb3 dib v-top"}
            />
          </div>
          <PrimaryButton
            disabled={!form.isValid || !form.dirty}
            onClick={() => form.isValid && form.dirty && form.handleSubmit()}
            className={"w-100 mt3"}
          >
            {!creatingState.creating ? "Sign Up" : <CircularProgress color={"inherit"} />}
          </PrimaryButton>
        </div>
        {/*<div className={"mv3 tc ttu"}>Or</div>
        <PrimaryButton onClick={() => {
          LogRocket.track('PricingPlanLogin', {
            plan: props.pricingPlanId,
            trial: props.trial
          });

          loginWithRedirect(window.location.pathname + window.location.search);
        }} className={"w-100"}>Log In</PrimaryButton>*/}
      </div>
    ) : creatingState.state === "created" ? (
      <div className={"mv5 tc"}>
        <Icon name={EIconName.Checkmark} size={48} color={themeColors.iconActive} />
        <h3 className={"vocalid-h3"}>Created Account, redirecting...</h3>
      </div>
    ) : null
  );
};

type CreateUserFormValues = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
};

function useCreateUserForm(onSubmit: (values: CreateUserFormValues) => void) {
  return useForm<CreateUserFormValues>({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: ""
    },
    onSubmit,
    validate(values) {
      const errors: { [P in keyof typeof values]?: string } = {};

      if (/^\s*$/.test(values.email)) {
        errors.email = "Email is Required";
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = "Invalid email address";
      }

      if (/^\s*$/.test(values.firstName)) {
        errors.firstName = "First Name is Required";
      }

      if (/^\s*$/.test(values.lastName)) {
        errors.lastName = "Last Name is Required";
      }

      if (/^\s*$/.test(values.password)) {
        errors.password = "Password is Required";
      }

      if (!isPasswordStrong(values.password)) {
        errors.password = `Password must be at least 8 characters including at least 3 of the following 4 types of characters:
        a lower-case letter,
        an upper-case letter,
        a number,
        a special character (such as !@#$%^&*)`;
      }

      if (values.confirmPassword !== values.password) {
        errors.confirmPassword = "Entered passwords must match";
      }

      return errors;
    }
  });
}

function isPasswordStrong(password: string): boolean {
  const groupCounts = password.split("").reduce((acc, char) => {
    // uppercase
    if (/[a-z]/.test(char)) {
      return {
        ...acc,
        lowerCase: acc.lowerCase + 1
      };
    }

    // uppercase
    if (/[A-Z]/.test(char)) {
      return {
        ...acc,
        upperCase: acc.upperCase + 1
      };
    }

    // number
    if (!isNaN(char as any)) {
      return {
        ...acc,
        numbers: acc.numbers + 1
      };
    }

    return {
      ...acc,
      specialChars: acc.specialChars + 1
    };
  }, { lowerCase: 0, upperCase: 0, numbers: 0, specialChars: 0 });

  const totalGroups = (groupCounts.upperCase > 0 ? 1 : 0) + (groupCounts.numbers > 0 ? 1 : 0) + (groupCounts.specialChars > 0 ? 1 : 0) + (groupCounts.lowerCase > 0 ? 1 : 0);

  return password.length >= 8 && totalGroups >= 3;
}
