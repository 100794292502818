import React from 'react';
import { IMultiSelectProps, MultiSelect } from "../../molecules/select";
import { useTheme } from '../../../theme';

export interface ITagSelectProps
  extends Pick<IMultiSelectProps, 'validationResult' | 'placeholder' | 'label' | 'styleOverrides'> {
  value: string[];
  tags: string[];
  renderLabel?(tag: string): React.ReactNode;
  menuPortalTarget?: HTMLElement | null;
  menuPosition?: IMultiSelectProps["menuPosition"];
  onChange?(value: string[]): void;
  onCreateNewTag?(newTag: string): void;
  className?: string;
}

const TagSelect: React.FC<ITagSelectProps> = ({ value, tags, onChange, ...props }) => {
  const { colors: themeColors } = useTheme();

  return (
    <MultiSelect
      value={value}
      creatable={Boolean(props.onCreateNewTag)}
      menuPortalTarget={props.menuPortalTarget}
      styleOverrides={{
        multiValue: {
          backgroundColor: themeColors.secondaryText,
          color: themeColors.tile,
          borderRadius: 12,
          paddingLeft: 5,
          paddingRight: 5
        }
      }}
      multiValueRemoveIconColor={themeColors.tile}
      onChange={(newValue) => {
        if (Array.isArray(newValue)) {
          onChange?.(newValue);
        } else {
        }
      }}
      onCreateValue={props.onCreateNewTag}
      {...props}
    >
      {tags.map((tag) => ({
        value: tag,
        label: props.renderLabel ? props.renderLabel(tag) : tag
      }))}
    </MultiSelect>
  );
};

export default TagSelect;
