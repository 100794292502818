import React from "react";
import classNames from "classnames";
import RecordingInProgressInfo from "../../organisms/recording-in-progress-info";
import Tile from "../../atoms/tile";
import { OutlineButton, PrimaryButton } from "../../atoms/button";
import { css } from "emotion";
import RecordingsReviewTableContainer from "../../../containers/organisms/recordings-review-table";
import FullPageLoader from "../../../containers/shared/loader";
import SsmlContentView from "../../organisms/recordings-review-table/ssml";
import Icon, { EIconName } from "../../atoms/icon";
import { groupBy } from "lodash";
import { usePickupAssignmentQuery } from "../../../graphql/generated";

type Assignment = {
  id: string;
  created: Date;
  due: Date | null;
  started: Date | null;
  totalRecordingsCount: number;
  reviewedRecordingsCount: number;
  contentLines: number;
  acceptedCount: number;
  rejectedCount: number;
  recordedErrorCount: number;
  isTalentRecordingRequestTarget: boolean;
  voice: {
    id: string;
    name: string;
  } | null;
}

export interface ITalentDashboardPageProps {
  talentId: string;
  assignments: Assignment[]
  className?: string;
}

const NoVocalId = "no-vocal"

const TalentDashboardPage: React.FC<ITalentDashboardPageProps> = (props) => {
  const assignmentsByVocal = React.useMemo(() => {
    const grouped = groupBy(props.assignments, a => a.voice?.id ?? NoVocalId);

    return Object.keys(grouped).map(voiceId => ({
      voice: voiceId,
      assignments: [...grouped[voiceId]].sort((a, b) => b.created.getTime() - a.created.getTime()) // desc
    })).sort((a, b) => b.assignments[0]!.created.getTime() - a.assignments[0]!.created.getTime()) // desc
  }, [props.assignments])

  return (
    <div className={classNames('relative', props.className)}>
      <div className={'pa4'}>
        <div>
          <div className={'vocalid-tile-title white mt0 mb2'}>Vocals</div>
          <div className={"mt4"}>
            { assignmentsByVocal.length !== 0 ? assignmentsByVocal.map(vocalGroup => {
              if (vocalGroup.voice === NoVocalId) {
                return <AssignmentsGroup key={vocalGroup.voice} voice={null} assignments={vocalGroup.assignments} />
              }

              return (
                <AssignmentsGroup
                  key={vocalGroup.voice}
                  voice={vocalGroup.assignments[0].voice!}
                  assignments={vocalGroup.assignments}
                />
              );
            }) : <div className={classNames("flex justify-center items-start vocalid-secondary-text", css({
              paddingTop: '15%'
            }))}>
              <Tile heading={<div className={'vocalid-h2 mr2'}>No Assignments Available to User</div>}>Contact your account owner to gain access.</Tile>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TalentDashboardPage;

export interface IAssignmentsGroupProps {
  voice: {
    id: string;
    name: string;
  } | null;
  assignments: Array<Assignment>;
  className?: string;
}

const AssignmentsGroup: React.FC<IAssignmentsGroupProps> = (props) => {
  return (
    <Tile className={'mb4'}>
      <div className={'flex justify-between items-start mb3'}>
        <div>
          <h1 className={'db mt2 mb0 vocalid-tile-title white ma0'}>{ props.voice ? `Vocal: ${props.voice.name}` : 'No Vocal' }</h1>
        </div>
      </div>
      <div>
        {props.assignments.map(assignment => (
          <AssignmentMini key={assignment.id} assignment={assignment} />
        ))}
      </div>
    </Tile>
  );
};

export interface AssignmentMiniProps {
  assignment: Assignment;
  className?: string;
}

const AssignmentMini: React.FC<AssignmentMiniProps> = props => {
  const dueIn = props.assignment.due && Math.max(0, Math.round((props.assignment.due.getTime() - (new Date().getTime() * 1000))/(24*60*60*1000)))
  const [reviewingRecordings, setReviewingRecordings] = React.useState(false);

  return (
    <div className={'mb4'}>
      <div className={'mb4'}>
        <div className={'flex justify-between items-start mb3'}>
          <div>
            <h1 className={'db mt2 mb0 vocalid-h2 white ma0'}>{props.assignment.isTalentRecordingRequestTarget ? "Pick up" : props.assignment.voice ? "Main" : "Unnamed Assignment"}</h1>
            <div className={'vocalid-h2 vocalid-orange mv0'} style={{ fontSize: 16 }}>
              { props.assignment!.started ? "IN PROGRESS" : "NOT YET STARTED" }
            </div>
          </div>
          <div className={'mt2'}>
            <a href={`${process.env.REACT_APP_RECORD_URL}/?assignment=${props.assignment.id}`} target={"_blank"} rel="noopener noreferrer">
              <PrimaryButton>
                {props.assignment.started ? "Continue Recording" : "Start Recording"}
              </PrimaryButton>
            </a>
          </div>
        </div>
      </div>
      {/* <UsageChart data={props.data.usage} valueLabel={'Recordings'} /> */}
      <RecordingInProgressInfo
        {...props.assignment}
        data={{
          metadata: {
            due: props.assignment.due,
            initiated: props.assignment.started,
            vocal: null,
            talent: null
          }
        }}
        hideVocalCreationStatus
        hideReviewRecordingButton
        dueInDays={dueIn}
      />
      { props.assignment.started &&
      <div>
        <div className={"flex justify-center mt4"}>
          <OutlineButton onClick={async () => {
            setReviewingRecordings(!reviewingRecordings);
          }}>
            {!reviewingRecordings ? "Review" : "Hide" } recordings
          </OutlineButton>
        </div>
        {reviewingRecordings && <RecordingsReviewTableContainer assignmentId={props.assignment.id} commentWritingAllowed={false} />}
      </div>
      }
    </div>
  );
};

interface PickupAssignmentTileContainerProps {
  id: string;
}

const PickupAssignmentTileContainer: React.FC<PickupAssignmentTileContainerProps> = props => {
  const { data } = usePickupAssignmentQuery({ variables: { assignmentId: props.id } });

  const assignment = data?.assignment;

  if (!assignment) return <FullPageLoader />;

  return (
    <Tile className={'mb4'}>
      <div className={'mb4'}>
        <div className={'flex justify-between items-start mb3'}>
          <div className={'mt2'}>
            <a href={`${process.env.REACT_APP_RECORD_URL}/?assignment=${assignment.id}`} target={"_blank"} rel="noopener noreferrer">
              <PrimaryButton>
                Record
              </PrimaryButton>
            </a>
          </div>
        </div>
      </div>
      <div className={"vocalid-body vocalid-secondary-text"}>
        <table>
          {assignment.content.flatMap(c => c.lines).map(line => (
            <tr key={line.id}>
              <td className={"pr2"}>{new Date(line.created).toLocaleDateString()}</td>
              <td className={"pr2"}>{line.recording?.downloadUrl ? <Icon name={EIconName.Checkmark} /> : ""}</td>
              <td className={"vocalid-list-title"}><SsmlContentView ssml={line.text} className={css({
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              })}/></td>
            </tr>
          ))}
        </table>
      </div>
      {/*<RecordingInProgressInfo
        {...assignment}
        data={{
          metadata: {
            due: assignment.due,
            initiated: assignment.started,
            vocal: null,
            talent: null
          }
        }}
        hideVocalCreationStatus
        hideReviewRecordingButton
        dueInDays={null}
      />*/}
    </Tile>
  )
}
