import React from 'react';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { css } from 'emotion';
import ProfileDropdown from '../../molecules/profile-dropdown';
import SignOutDialog from '../../organisms/sign-out-dialog';
import Avatar from '../../atoms/avatar';
import { useTheme } from '../../../theme';
import { FocusedAudioPlayerProvider } from '../../molecules/audio-player/context';
import AgreementPdfBlock from "../../organisms/agreement-pdf-block";
import CopyrightText from "../../../copyright";
import AssertScreenSizeTemplate from "../assert-screen-size";

interface INavItemProps {
  to: string;
  alsoMatches?: Array<string>;
}

const NavItem: React.FC<INavItemProps> = (props) => {
  const match = useRouteMatch([props.to, ...(props.alsoMatches ?? [])]);

  return (
    <li>
      <Link
        to={props.to}
        className={classNames(
          'vocalid-nav db ph4 pv3 no-underline',
          match?.isExact ? 'vocalid-icon-active' : 'vocalid-secondary-text'
        )}
      >
        {props.children}
      </Link>
    </li>
  );
};

export interface ITalentTemplateProps {
  data: {
    user: {
      firstName: string;
      lastName: string;
      emailAddress: string;
    }
    // signedConsentDate: Date | null;
  };

  // onAgreeToTalentConsentPdf(): void;
  onSignOutReturnTo: string;
  className?: string;
}

const talentConsentPdf = {
  file: `${window.location.origin}/sample.pdf`,
  date: new Date(Date.UTC(2021, 9 /* Oct */, 4, 0, 0))
}

const TalentTemplate: React.FC<ITalentTemplateProps> = (props) => {
  const [signingOut, setSigningOut] = React.useState(false);
  const { colors: themeColors } = useTheme();

  const showTalentConsentAgreementPdf = false // (props.data.signedConsentDate === null || props.data.signedConsentDate < talentConsentPdf.date);

  return (
    <>
      <SignOutDialog
        isShown={signingOut}
        onSignOutReturnTo={props.onSignOutReturnTo}
        onCancel={() => setSigningOut(false)}
      />
      <FocusedAudioPlayerProvider>
        <div
          className={classNames(
            'flex flex-column h-100 bg-vocalid-page vocalid-body',
            props.className
          )}
        >
          <nav
            className={classNames(
              css({ borderWidth: 1 }),
              'bg-vocalid-header bb b--vocalid-header-border'
            )}
          >
            <div className={'center flex flex-shrink-0 items-center justify-between pv2 pl5 pr4'}>
              <div className={'flex items-center'}>
                <span
                  className={classNames(
                    css({
                      width: 40
                    }),
                    'dib mr4 flex justify-center flex-shrink-0'
                  )}
                >
                  <Link to={'/'}>
                    <img
                      src={'/logo-icon.png'}
                      alt={'VocaliD Icon'}
                      height={40}
                      width={'auto'}
                      className={'flex-shrink-0'}
                    />
                  </Link>
                </span>
                <Link to={'/'}>
                  <img
                    src={'/logo.png'}
                    alt={'VocaliD'}
                    height={17}
                    className={'ml5 pl2 flex-shrink-0'}
                  />
                </Link>
              </div>
              <div className={'pr3'}>
                <ProfileDropdown
                  profile={props.data.user}
                  profileUrl={'/talent/profile'}
                  onSignOut={() => setSigningOut(true)}
                >
                  <span className={'vocalid-secondary-text'}>
                    <span className={'div v-mid mr3'}>{props.data.user.firstName}</span>
                    <Avatar className={'v-mid'} bgColor={themeColors.red}>
                      {`${props.data.user.firstName.substring(
                        0,
                        1
                      )}${props.data.user.lastName.substring(0, 2)}`}
                    </Avatar>
                  </span>
                </ProfileDropdown>
              </div>
            </div>
          </nav>
          <AssertScreenSizeTemplate>
            <div className={'flex flex-grow-1 overflow-y-hidden'}>
              <div
                className={classNames(
                  'bg-vocalid-header flex-shrink-0 flex flex-column',
                  css({
                    width: 176
                  })
                )}
              >
                <ul className={'list ph0 pb0 pt3 ma0 ttu tc'}>
                  <NavItem to={'/talent'}>Recording</NavItem>
                  <NavItem to={'/talent/profile'}>Profile</NavItem>
                </ul>
                <div className={"flex-grow-1"} />
                <footer className={"mh2 mt2 mb2"}>
                  <CopyrightText className={"tl vocalid-tertiary-text o-50"} />
                </footer>
              </div>
              <div className={'flex-grow-1 overflow-y-auto relative'}>
                {
                  showTalentConsentAgreementPdf ? <AgreementPdfBlock header={"Talent Consent Form"} file={talentConsentPdf.file} onAgree={() => {} /* props.onAgreeToTalentConsentPdf */} /> :
                    props.children
                }
              </div>
            </div>
          </AssertScreenSizeTemplate>
        </div>
      </FocusedAudioPlayerProvider>
    </>
  );
};

export default TalentTemplate;
