import React, { useState } from "react";
import Avatar from "../../atoms/avatar";
import Header from "../../atoms/header";
import Tile from "../../atoms/tile";
import Input from "../../atoms/input";
import { useTheme } from "../../../theme";
import { PrimaryButton, TextButton } from "../../atoms/button";
import ResetPasswordDialog from "../../organisms/change-password-dialog";
import classNames from "classnames";
import { API_DOCS_URL } from "../../../graphql";
import { css } from "emotion";
import CreateApiTokenDialog from "../../../containers/organisms/create-api-token-dialog";
import Tooltip from "../../atoms/tooltip";
import Icon, { EIconName } from "../../atoms/icon";
import DeleteApiTokenDialog from "../../organisms/delete-api-token-dialog";

export interface IEnterpriseProfilePageProps {
  data: {
    user: {
      firstName: string;
      lastName: string;
      email: string;
    };
    apiTokens: Array<{
      id: string;
      name: string;
      createdAt: Date;
      limitedToAccounts: Array<{
        id: string;
        name: string;
      }> | null;
    }>;
  };
  onCreatedApiToken(): void;
  onDeleteApiToken(tokenId: string): void;
  // onSave(userData: {
  //   firstName: string;
  //   lastName: string;
  // }): void;
  // onDelete(): void;
  onResetPassword(): void;

  className?: string;
}

const EnterpriseProfilePage: React.FC<IEnterpriseProfilePageProps> = (props) => {
  const { colors: themeColors } = useTheme();
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [creatingApiToken, setCreatingApiToken] = useState(false);
  const [deletingApiToken, setDeletingApiToken] = useState<string | null>(null);
  // const [isDeletingProfile, setDeletingProfile] = useState(false);
  //
  // const form = useForm({
  //   initialValues: {
  //     firstName: props.data.user.firstName,
  //     lastName: props.data.user.lastName
  //   },
  //   onSubmit(values) {
  //     props.onSave(values);
  //   }
  // });
  //
  const firstName = props.data.user.firstName // form.values.firstName;
  const lastName = props.data.user.lastName // form.values.lastName;

  return (
    <>
{/*      <DeleteProfileDialog
        isShown={isDeletingProfile}
        onCancel={() => setDeletingProfile(false)}
        onDelete={() => {
          props.onDelete();
          setDeletingProfile(false);
        }}
      />*/}
      <CreateApiTokenDialog isShown={creatingApiToken} onCancel={() => setCreatingApiToken(false)} onCreated={() => {
        setCreatingApiToken(false);
        props.onCreatedApiToken();
      }} />
      <DeleteApiTokenDialog isShown={Boolean(deletingApiToken)} onCancel={() => setDeletingApiToken(null)} onDelete={async () => {
        await props.onDeleteApiToken(deletingApiToken!);
        setDeletingApiToken(null)
      }} />
      <ResetPasswordDialog
        isShown={isResettingPassword}
        email={props.data.user.email}
        onCancel={() => setIsResettingPassword(false)}
        onReset={() => {
          props.onResetPassword();
          setIsResettingPassword(false);
        }}
      />
      <div className={props.className}>
        <Header>
          <h1 className={'db vocalid-h1 mh0 mv2'}>PROFILE</h1>
        </Header>
        <div className={'mh4 white'}>
          <div className={'mt4 mb4 pt2 pb2'}>
            <Avatar size={120} className={'v-mid'} bgColor={themeColors.red}>
              {`${firstName.substring(0, 1)}${lastName.substring(0, 1)}`}
            </Avatar>
            <h1 className={'ml4 dib v-mid vocalid-h2'}>
              <span className={'f2'}>
                {firstName} {lastName}
              </span>
            </h1>
          </div>
          <div className={"flex justify-center"}>
            <div className={"w-60 pr4"}>
              <Tile heading={<div className={'vocalid-h2'}>About You</div>} className={'mb4'}>
                <div className={'ph4 pv3'}>
                  <div className={'mb4'}>
                    <Input
                      label={'First Name'}
                      placeholder={'Johnny'}
                      value={firstName}
                      disabled
                      /*{...form.inputProps('firstName')}*/
                      className={'w-40 dib mr3 v-top'}
                    />
                    <Input
                      label={'Last Name'}
                      placeholder={'Appleseed'}
                      value={lastName}
                      disabled
                      /*{...form.inputProps('lastName')}*/
                      className={'w-40 dib v-top'}
                    />
                  </div>
                </div>
              </Tile>
              <Tile heading={<div className={'vocalid-h2'}>Security</div>}>
                <div className={'ph4 pv3 inline-flex'}>
                  <Input
                    type={'password'}
                    label={'Password'}
                    value={'**************'}
                    className={'w-40 dib mr1'}
                    disabled={true}
                  />
                  <TextButton onClick={() => {}}>
                    <span className={'ttn'} onClick={() => setIsResettingPassword(true)}>Change Password</span>
                  </TextButton>
                </div>
              </Tile>
            </div>
            <div className={"w-40"}>
              <Tile className={"h-100"} heading={<div className={"flex justify-between items-center"}><div className={'vocalid-h2'}>API Access</div><PrimaryButton size={"small"} onClick={() => setCreatingApiToken(true)}>Create Token</PrimaryButton></div>}>
                <div className={classNames("flex flex-column", css({ maxHeight: 400 }))}>
                  <p className={"vocalid-body mb4"}>
                    <a href={API_DOCS_URL} target="_blank" rel="noopener noreferrer" className={"vocalid-link no-underline"}>View API Documentation</a>
                  </p>
                  <div className={"flex-grow-1 flex-shrink-1 overflow-y-auto pr3"}>
                    {props.data.apiTokens.length !== 0 ?
                      <table className={'w-100'} style={{ borderSpacing: 0 }}>
                        <thead>
                        <tr className={'ttu vocalid-h3 vocalid-secondary-text'}>
                          <td className={'v-mid pb3'}>Name</td>
                          <td className={'v-mid pb3'}>Created</td>
                        </tr>
                        </thead>
                        <tbody>
                        {[...props.data.apiTokens].sort((a, b) => (b.createdAt as any) - (a.createdAt as any)).map((apiToken) => (
                          <tr key={apiToken.id} className={classNames('vocalid-secondary-text br0', props.className)}>
                            <td className={'pv2 pr2'}>
                              {apiToken.name}
                            </td>
                            <td className={'pa2'}>
                              <div>{apiToken.createdAt.toLocaleDateString()}</div>
                            </td>
                            <td className={'pa2 tr'}>
                              { apiToken.limitedToAccounts !== null && <Tooltip tooltipContent={<ul className={"tl list ph3"}>{[...apiToken.limitedToAccounts].sort((a, b) => (a.name as any) > (b.name as any) ? 1 : -1).map(account => (
                                <li key={account.id} className={"mb2"}>{account.name}</li>
                              ))}</ul>}>{apiToken.limitedToAccounts.length} Account{apiToken.limitedToAccounts.length > 1 ? 's' : ''}</Tooltip> }
                            </td>
                            <td className={'pv2 pl3'}>
                              <Icon name={EIconName.Delete} onClick={() => setDeletingApiToken(apiToken.id)} />
                            </td>
                          </tr>
                        ))}
                        </tbody>
                      </table> : (
                        <div className={"tc mv3 vocalid-secondary-text ttu"}>
                          <span>No API Tokens Created</span>
                        </div>
                      )
                    }
                  </div>
                </div>
              </Tile>
            </div>
          </div>
          {/*<div className={'flex justify-between mb4'}>
            <OutlineButton size={'medium'} onClick={() => setDeletingProfile(true)}>
              Delete
            </OutlineButton>
            <PrimaryButton
              disabled={!form.isValid || !form.dirty}
              onClick={() => form.isValid && form.dirty && form.handleSubmit()}
            >
              Save
            </PrimaryButton>
          </div>*/}
        </div>
      </div>
    </>
  );
};

export default EnterpriseProfilePage;
