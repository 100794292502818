import React from 'react';
import classNames from 'classnames';
import { Link, useHistory } from "react-router-dom";
import AudioPlayer from '../../molecules/audio-player';
import Icon, { EIconName } from '../../atoms/icon';
import UsageChart, { IUsageChartProps } from '../../molecules/chart/usage-chart';
import Banner from '../../molecules/banner';
import Tile from '../../atoms/tile';
import AllocationChart from './allocation-chart';
import SavingsChart from './savings-chart';
import ProductivityChart from './productivity-chart';
import InfoTooltip from '../../molecules/info-tooltip';
import { css } from 'emotion';
import ClipCounter from "../../atoms/clip-counter";
import { OutlineButton, TextButton } from "../../atoms/button";
import { FeatureLock } from "../../../utils/feature-locking";
import { TrialDetail } from "../../../containers/organisms/pricing-plan-listing";
import { encodeQueryParams } from "use-query-params";
import queryString from "querystring";
import { EnterpriseAccountPageQueryParams } from "../../../containers/pages/enterprise-account";

export interface IProjectMiniProps {
  project: {
    id: string;
    updatedAt: Date;
    title: string;
    voice: string;
    countOfClips: number;
  };
  className?: string;
}

const ProjectMini: React.FC<IProjectMiniProps> = (props) => {
  const modified = props.project.updatedAt.toLocaleDateString()

  return (
    <tr className={classNames('vocalid-secondary-text br0', props.className)}>
      <td className={'pv2 pr2'}>
        <Link
          to={`/enterprise/project/${props.project.id}`}
          className={'no-underline vocalid-list-title vocalid-secondary-text mr2'}
        >
          {props.project.title}
        </Link>
      </td>
      <td className={'pa2'}>
        <div>Voice: {props.project.voice}</div>
        <div>Modified: {modified}</div>
      </td>
      <td className={'pl2 pv2 flex justify-end mr2'}>
        <ClipCounter count={props.project.countOfClips} size={'small'} />
      </td>
    </tr>
  );
};

export interface IClipMiniData {
  id: string;
  project: {
    id: string;
    title: string;
  };
  title: string;
  audioUrl: string;
}

export interface IClipMiniProps {
  clip: {
    id: string;
    title: string | null;
    audioUrl: string;
  };
  className?: string;
}

const ClipMini: React.FC<IClipMiniProps> = (props) => {
  return (
    <tr className={classNames('vocalid-secondary-text v-top', props.className)}>
      <td className={classNames('pv2 pr2', css({
        minWidth: 200
      }))}>
        <Link
          to={`/enterprise/projects`}
          className={'db no-underline vocalid-list-title vocalid-secondary-text mr2'}
        >
          {props.clip.title ?? "Unnamed Clip"}
        </Link>
      </td>
      <td>
        <AudioPlayer src={props.clip.audioUrl} preload className={'w-100 mt1 mb2'} />
      </td>
      {props.clip.audioUrl && (
      <td className={'pv2 pl3 tr'}>
        <FeatureLock feature={"TakeDownload"}>
          <a
            href={props.clip.audioUrl}
            rel="noopener noreferrer"
            className={'mr2 vocalid-secondary-text'}
          >
            <Icon name={EIconName.Download} size={32} />
          </a>
        </FeatureLock>
      </td>
      )}
    </tr>
  );
};

export interface IEnterpriseDashboardPageProps {
  data: {
    account: {
      name: string;
    };
    projects: Array<IProjectMiniProps["project"]>;
    clips: Array<IClipMiniProps["clip"]>;
    pricingPlanSubscription: {
      name: string;
      isTrial: boolean;
      isActive: boolean;
    } | null;
    usage: {
      rangeOptions: Array<string>;
      selectedRange: {
        key: string;
        from: Date;
        to: Date;
      };
      chart: {
        points: Array<{ date: Date; renders: number }>;
        totalRenders: number;
        // totalSpend: number;
        pointInterval: string;
      };
      // hoursSaved: number;
      // dollarsSaved: number;
      // audioHoursProduced: number;
      // hoursSpent: number;
      // remainingAllocation: number;
    };
  };

  setSelectedUsageRange(newRange: string): void;
  onCreateProject(): void;
  onCreateClip(): void;
  className?: string;
}

const EnterpriseDashboardPage: React.FC<IEnterpriseDashboardPageProps> = (props) => {
  const history = useHistory();

  return (
    <div className={classNames('relative', props.className)}>
      <div className={'pa4'}>
        <div className={'mb4 flex'}>
          <h1 className={"vocalid-tile-title white mt0 mb2 mr5"} style={{
            fontSize: 64
          }}>{props.data.account.name}</h1>
          <div
            className={'vocalid-secondary-text pt2'}
          >
            <div className={"ttu"}>{props.data.pricingPlanSubscription ? <span>{props.data.pricingPlanSubscription.name} Plan {props.data.pricingPlanSubscription.isTrial && <span className={"vocalid-orange ml2"}>Trial</span>}</span> : <span>{TrialDetail.name} <TextButton onClick={() => history.push("/enterprise/account" + "?" + queryString.stringify(encodeQueryParams(EnterpriseAccountPageQueryParams, {
              changing_plan: true
            }) as any))}>Change Plan</TextButton></span>}</div>
            <div><TextButton noHorizontalPadding notUppercase onClick={() => history.push("/enterprise/account")}>Settings</TextButton></div>
          </div>
        </div>

        <div className={'flex mb4'}>
          <Tile
            heading={
              <div className={'flex justify-between'}>
                <div className={'vocalid-h2'}>Projects</div>
                <OutlineButton
                  onClick={props.onCreateProject}
                >
                  New Project
                </OutlineButton>
              </div>
            }
            className={'w-50 mr4'}
          >
            <table className={'w-100'} style={{ borderSpacing: 0 }}>
              <thead>
                <tr className={'ttu vocalid-h3 vocalid-secondary-text'}>
                  <td className={'v-mid pb3'}>Name</td>
                  <td className={'v-mid pb3'}>Date</td>
                  <td className={'v-mid pb3 tr'}>Clips</td>
                </tr>
              </thead>
              <tbody>
                {props.data.projects.map((projectData) => (
                  <ProjectMini key={projectData.id} project={projectData} className={'mv2'} />
                ))}
              </tbody>
            </table>
            <Link to={'/enterprise/projects'} className={'dib mt3 vocalid-link b no-underline'}>
              See All
            </Link>
          </Tile>
          <Tile
            heading={
              <div className={'flex justify-between'}>
                <div className={'vocalid-h2'}>Latest Clips</div>
              </div>
            }
            className={'w-50'}
          >
            <table className={'w-100'} style={{ borderSpacing: 0 }}>
              <tbody>
                {props.data.clips.map((clipData) => (
                  <ClipMini key={clipData.id} clip={clipData} className={'mv2'} />
                ))}
              </tbody>
            </table>
          </Tile>
        </div>
        <Tile className={"mb4"}>
          <UsageChart
            data={{
              points: props.data.usage.chart.points.map(p => ({ date: p.date, value: p.renders })),
              keyValues: [
                {
                  key: 'renders',
                  label: 'Renders',
                  value: props.data.usage.chart.totalRenders
                }
              ]
            }}
            ranges={props.data.usage.rangeOptions}
            selectedRange={props.data.usage.selectedRange}
            setSelectedRange={props.setSelectedUsageRange}
            hideDay={props.data.usage.chart.pointInterval === "Month"}
            hideYear={props.data.usage.chart.pointInterval === "Day"}
            heading={<h2 className={"mt0 mb3 vocalid-h2"}>Activity</h2>}
          />
          {/*<div className={"flex justify-between items-start mt5 mb3"}>
            <div className={"w-50 ph3"}>
              <div className={"tc"}>
                <h3 className={"mt0 mb3 vocalid-h3 vocalid-secondary-text ttu ph2"}>
                  Savings{" "}
                  <InfoTooltip
                    tooltipContent={
                      "Estimated amount of time and money saved by using Parrot Studio."
                    }
                    className={"ml2 v-mid"}
                  />
                </h3>
                <SavingsChart hoursSaved={props.data.usage.hoursSaved} dollarsSaved={props.data.usage.dollarsSaved} className={"w-50"} />
              </div>
            </div>
            <div className={"w-50 ph3"}>
              <div className={"tc"}>
                <h3 className={"mt0 mb3 vocalid-h3 vocalid-secondary-text ttu ph2"}>
                  Productivity{" "}
                  <InfoTooltip
                    tooltipContent={
                      "The number of hours of content created compared to the number of hours spent using Parrot Studio."
                    }
                    className={"ml2 v-mid"}
                  />
                </h3>
                <ProductivityChart audioHoursProduced={props.data.usage.audioHoursProduced} hoursSpent={props.data.usage.hoursSpent} className={"w-50"} />
              </div>
            </div>
            <div className={"w-33 ph3"}>
              <div className={"tc"}>
                <h3 className={"mt0 mb1 vocalid-h3 vocalid-secondary-text ttu ph2"}>
                  Remaining Allocation
                </h3>
                <div className={"flex justify-center"}>
                  <AllocationChart completed={props.data.usage.chart.totalClips} remaining={props.data.usage.remainingAllocation} className={css({ width: 240 })} />
                </div>
              </div>
            </div>
          </div>*/}
        </Tile>
        {/*<Tile heading={<span className={"vocalid-h2"}>New Vocal Progress</span>} className={"mb4"}>
          <RecordingInProgressInfo
            data={{
              metadata: {
                vocal: "Nikki Soothing",
                talent: null,
                initiated: new Date(),
                due: new Date()
              }
            }}
          />
        </Tile>*/}
      </div>
    </div>
  );
};

export default EnterpriseDashboardPage;
