import { IClipData, ITake } from "../../components/organisms/clip";
import { ClipFragment, TakeFragment } from "../../graphql/generated";

// FIXME: replace with real flag type
export enum ETakeFlag {
  ThumbsDown = "ThumbsDown",
  ThumbsUp = "ThumbsUp",
}

export function graphQLClipToLocalClipData(graphqlClip: ClipFragment): IClipData {
  return {
    ...graphqlClip,
    createdAt: new Date(graphqlClip.created),
    takes: graphqlClip.takes.map((take, ii) => graphQLTakeToLocalTake(take, ii)),
    projectId: graphqlClip.project?.id ?? "",
    projectTypeId: graphqlClip.project?.type?.id ?? ""
  }
}

export function graphQLTakeToLocalTake(graphqlTake: TakeFragment, number: number): ITake {
  const flag: ETakeFlag | null = null // ETakeFlag.ThumbsUp
  const status = flag === ETakeFlag.ThumbsUp ? 'approved' : flag === ETakeFlag.ThumbsDown ? 'rejected' : 'unreviewed'

  const common = {
    id: graphqlTake.id,
    createdAt: new Date(graphqlTake.created as number),
    number,
    status,
    content: JSON.parse(graphqlTake.content), // TODO: add validation
    generatedSSML: graphqlTake.generatedSSML,
    vocal: {
      id: graphqlTake.voice.id,
      title: graphqlTake.voice.title,
      talent: graphqlTake.voice.talent !== null
    }
  } as const;

  if (graphqlTake.__typename === "GeneratedTake") {
    return {
      type: 'GeneratedTake',
      ...common,
      audioUrl: graphqlTake.rendered?.url ?? null,
      rendering: !graphqlTake.rendered,
      error: graphqlTake.error,
      renderedTakeId: graphqlTake.rendered?.id ?? null
    }
  } else {
    return {
      type: 'TalentRecordedTake',
      ...common,
      audioUrl: graphqlTake.recorded?.downloadUrl ?? null,
      talent: {
        name: graphqlTake.voice.talent!.firstName + " " + graphqlTake.voice.talent!.lastName
      }
    }
  }
}
