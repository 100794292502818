import React from "react";
import { RenderLeafProps } from "slate-react/dist/components/editable";
import {
  ESlateElementType, isEmptyAlternatePronunciation,
  ISlateNode,
  ISlateSelection,
  ISlateValue,
  ISlateWordNode,
  isPhoneticAlternatePronunciation
} from "./internal-types";
import { css } from "emotion";
import classNames from "classnames";
import { EAdjustment } from "./index";
import Tooltip from "../../../../atoms/tooltip";
import { singleWordInSelection } from "./selection";

const renderLeaf = (editorValue: ISlateValue, selection: ISlateSelection) => (props: RenderLeafProps) => {
  const node = props.leaf as ISlateNode
  const pause = node.type === ESlateElementType.Word && (node.pause || node.pauseInMilliseconds) || null
  const pauseText = (pause === 'long' || Number.isFinite(pause)) ? "()" : pause === 'short' ? ")(" : "";

  const alternatePronunciationClassName = (node as ISlateWordNode).alternatePronunciation ? css({
    '&:before': {
      content: '"/"',
      color: '#3CC4FA'
    },
    '&:after': {
      content: `"/"`,
      color: '#3CC4FA'
    }
  }) : null;

  const adjClassNames = getAdjustmentsClassNames(node.adjustments?.map(a => a.type) ?? [])

  const pauseClassName = pause && css({
    '&:after': {
      content: `"${pauseText}"`,
      color: '#3cc4fa'
    }
  })

  const [singleSelectedWord] = (selection && singleWordInSelection(editorValue, selection)) ?? [];
  const selectionIsNode = singleSelectedWord && singleSelectedWord.text === node.text && (singleSelectedWord.alternatePronunciation ? singleSelectedWord.alternatePronunciation === (node as ISlateWordNode).alternatePronunciation : true);

  const base = (
    <span
      {...props.attributes}
      className={classNames(adjClassNames, /*selectionIsNode && css({
        backgroundColor: 'rgba(60, 196, 250, 0.15)'
      })*/)}
    >
        <span className={classNames(alternatePronunciationClassName)}><span
          className={classNames(pauseClassName)}>{props.children}</span></span>
    </span>
  )

  if (node.type === ESlateElementType.Word && node.alternatePronunciation && selectionIsNode) {
    return (
      <Tooltip
        visible
        portalEl={document.getElementById("tooltip-container") ?? undefined}
        tooltipContent={
          <span>{isPhoneticAlternatePronunciation(node.alternatePronunciation) ? node.alternatePronunciation.phonetic.reduce((acc, curr) => acc + curr, "") : node.alternatePronunciation.text}</span>}
        tooltipElStyle={{
          color: 'black'
        }}
        color={'#3CC4FA'}
      >
        {base}
      </Tooltip>
    )
  }

  return base
}

export default renderLeaf;

function getAdjustmentsClassNames(adjustments: EAdjustment[]) {
  const adjustmentStyleMap: { [key in EAdjustment]: string } = {
    [EAdjustment.SpeedAdjustment]: css({
      color: '#13C08E !important'
    }),
    [EAdjustment.PitchAdjustment]: css({
      fontStyle: 'italic !important'
    }),
    [EAdjustment.WordStress]: css({
      '&:before': {
        content: '"*"',
        color: '#3CC4FA'
      },
      '&:after': {
        content: '"*"',
        color: '#3CC4FA'
      }
    }),
    [EAdjustment.LoudnessAdjustment]: css({
      fontWeight: 'bold !important' as any
    }),
    [EAdjustment.AutoToneEffect]: css({
      backgroundColor: '#203942 !important'
    })
  };

  return adjustments.map(adj => adjustmentStyleMap[adj]);
}
