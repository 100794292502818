import React from "react";
import EnterpriseProjectPage, { IEnterpriseProjectPageProps } from "../../../components/pages/enterprise-project";
import SearchBar from "../../../components/molecules/search-bar";
import { useRouteMatch } from "react-router-dom";
import { IEditorSelection } from "../../../components/organisms/clip/editor/adjustments";
import { IClipVoiceAdjustmentsData } from "../../../components/organisms/project-sidebar/tabs/vocal-adjustments";
import FullPageLoader from "../../shared/loader";
import GenericErrorBoundary from "../../../components/molecules/generic-error-boundary";
import { useEnterpriseContextualState } from "../../../components/templates/enterprise";
import { useManagedClips } from "./clip";
import { useManagedSubstitutionDictionary } from "./substitution-dictionary";
import { useManagedMixes } from "./mix";
import { useProjectQuery, useUpdateProjectMutation } from "../../../graphql/generated";

export interface IEnterpriseProjectPageContainerProps {}

export interface IProjectPageSelection {
  clip: string;
  range: IEditorSelection
}

export interface IProjectPageAdjustment {
  clip: string;
  range: IEditorSelection;
  adjustment: IClipVoiceAdjustmentsData
}

const EnterpriseProjectPageContainer: React.FC<IEnterpriseProjectPageContainerProps> = (props) => {
  const { state: { selectedAccount } }  = useEnterpriseContextualState()
  const { params: { id } } = useRouteMatch<{ id: string }>();
  const projectQuery = useProjectQuery({ variables: { projectId: id, accountId: selectedAccount! }, skip: !selectedAccount })

  const project = projectQuery.data?.project ?? null

  const [updateProject] = useUpdateProjectMutation();

  const managedClips = useManagedClips(project?.id ?? "", project?.clips ?? [], projectQuery.refetch);
  const managedSubstitutionDictionary = useManagedSubstitutionDictionary(project?.account?.substitutionDictionary?.id ?? '', project?.substitutionDictionary?.id ?? '', project?.account?.substitutionDictionary?.members ?? [], project?.substitutionDictionary?.members ?? [], projectQuery.refetch);
  const managedMixes = useManagedMixes(project?.id ?? "", project?.mixes ?? [], projectQuery.refetch);

  const SearchBarComponent = React.useMemo<IEnterpriseProjectPageProps['SearchBarComponent']>(
    () => (searchProps) => <SearchBar searchQuery={''} onSearch={() => {}} />,
    []
  );

  if (!project) return <FullPageLoader />

  return (
    <GenericErrorBoundary>
      <EnterpriseProjectPage
        data={{
          id: project.id,
          title: project.title,
          type: project.type,
          defaultVocal: {
            id: project.defaultVoice.id,
            title: project.defaultVoice.title,
            talent: project.defaultVoice.talent !== null
          },
          clips: managedClips.clips,
          substitutionDictionary: {
            dictionaryMembers: managedSubstitutionDictionary.members
          },
          currentRenderCount: projectQuery.data!.account.pricingPlanSubscription?.billingPeriodUsage.renders ?? 0,
          maxCharactersPerRequest: projectQuery.data!.account.allRoleRestrictions.synthesisMaxCharactersPerRequest,
          maxRenders: projectQuery.data!.account.allRoleRestrictions.synthesisMaxRequests,
          mixes: managedMixes.mixes,
          maxPlaylists: projectQuery.data!.account.allRoleRestrictions.maxPlaylists
        }}
        onUpdateProjectTitle={async newTitle => await updateProject({ variables: { input: { id: project.id, title: newTitle } } })}
        onCreateClip={() => managedClips.createClip()}
        onCreateClips={clips => managedClips.createClips(clips, project.defaultVoice.id)}
        onUpdateClipTitle={(clipId, newTitle) => managedClips.updateClipTitle(clipId, newTitle)}
        onDeleteClip={clipId => managedClips.deleteClip(clipId)}
        onCreateTake={async (clipId, vocalId, content, requestTalentRecording) => {
          await managedClips.generateTake(clipId, content, vocalId, requestTalentRecording);
          await projectQuery.refetch()
        }}
        onDeleteTake={takeId => managedClips.deleteTake(takeId)}
        onApproveTake={takeId => managedClips.updateTakeStatus(takeId, "approved")}
        onRejectTake={takeId => managedClips.updateTakeStatus(takeId, "rejected")}
        onTakeRendered={takeId => projectQuery?.refetch?.()}

        mixActions={{
          onAddMix: managedMixes.addMix,
          onDeleteMix: managedMixes.deleteMix,
          onDeleteMixTake: managedMixes.deleteMixTake,
          onUpdateMix: managedMixes.update,
          onAddTakeToMix: managedMixes.addTakeItem,
          onAddPause: managedMixes.addPauseItem,
          onGenerate: managedMixes.generateTake,
          onChangeMixFormat: managedMixes.changeFormat
        }}

        onAddSubstitutionDictionaryMember={async (oldPronunciation, newPronunciation) => {
          await managedSubstitutionDictionary.addMember(oldPronunciation, newPronunciation);
        }}
        onSetSubstitutionDictionaryMemberAsGlobal={async (id, global) => {
          await managedSubstitutionDictionary.setMemberGlobal(id, global);
        }}
        onRemoveSubstitutionDictionaryMember={async id => {
          await managedSubstitutionDictionary.removeMember(id);
        }}
        onRequestDefaultPronunciation={managedClips.getDefaultPronunciation}

        SearchBarComponent={SearchBarComponent}
      />
    </GenericErrorBoundary>
  );
};

export default EnterpriseProjectPageContainer;
