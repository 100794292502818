import React from 'react';
import classNames from 'classnames';
import { css } from 'emotion';

export interface ITileProps {
  heading?: React.ReactNode;
  className?: string;
  contentClassName?: string;
}

const Tile: React.FC<ITileProps> = (props) => {
  return (
    <div
      className={classNames(
        'bg-vocalid-tile flex flex-column',
        css({
          borderRadius: 6
        }),
        props.className
      )}
    >
      {props.heading && (
        <div className={'white ttu b'} style={{ padding: '30px 30px 15px 30px' }}>
          {props.heading}
        </div>
      )}
      <div
        className={classNames(
          css({ padding: `${props.heading ? 0 : 30}px 30px 30px 30px` }),
          props.contentClassName
        )}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Tile;
