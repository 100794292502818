import React from 'react';
import signedOutBGJpg from './signed-out-bg.jpg';
import { css } from 'emotion';
import classNames from 'classnames';
import {PrimaryButton} from "../../atoms/button";

export interface ISignedOutPageProps {
  onSignBackIn(): void;
  className?: string;
}

const SignedOutPage: React.FC<ISignedOutPageProps> = (props) => {
  return (
    <div className={props.className}>
      <div
        className={css({
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${signedOutBGJpg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: 0
        })}
      />
      <div
        className={classNames(
          'relative mt7 tc',
          css({
            zIndex: 1
          })
        )}
      >
        <h1 className={'vocalid-h1 white mt0'}>
          Thanks for using Parrot Studio!
          <br />
          You’ve now been signed out.
        </h1>
        <PrimaryButton onClick={() => props.onSignBackIn()}>
          SIGN BACK IN
        </PrimaryButton>
      </div>
    </div>
  );
};

export default SignedOutPage;
