import React, {ErrorInfo} from "react";
import Tile from "../../atoms/tile";
import { css } from "emotion";
import classNames from "classnames";

interface IGenericErrorBoundaryProps {
  hasError?: boolean;
}

interface IGenericErrorBoundaryState {
  hasError: boolean
}

export default class GenericErrorBoundary extends React.Component<IGenericErrorBoundaryProps, IGenericErrorBoundaryState> {
  constructor(props: IGenericErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {}

  render() {
    if (this.state.hasError || this.props.hasError) {
      // You can render any custom fallback UI
      return <div className={classNames("flex justify-center items-start vocalid-secondary-text", css({
        paddingTop: '15%'
      }))}><Tile heading={<div className={'vocalid-h2 mr2'}>An error has occurred</div>}>Please refresh the page.</Tile></div>;
    }

    return this.props.children;
  }
}
