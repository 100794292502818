import React from 'react';
import classNames from 'classnames';
import { css } from 'emotion';
import { useTheme } from '../../../theme';

export interface IAvatarProps {
  initialsColor?: string;
  bgColor?: string;
  size?: number;
  children: string;
  className?: string;
}

const Avatar: React.FC<IAvatarProps> = (props) => {
  const { colors: themeColors } = useTheme();
  const size = props.size ?? 35;

  return (
    <div className={classNames('dib', props.className)}>
      <div
        className={classNames(
          'vocalid-h3 normal flex justify-center ttu items-center dib br-100 pa1 tc',
          css({
            width: size,
            height: size,
            backgroundColor: props.bgColor ?? themeColors.tile,
            color: props.initialsColor ?? 'white'
          })
        )}
      >
        <strong
          className={css({
            fontSize: size / 2
          })}
        >
          {props.children.substring(0, 2)}
        </strong>
      </div>
    </div>
  );
};

export default Avatar;
