import React, { Dispatch, SetStateAction } from 'react';

const sessionStorage = window.sessionStorage;

export function useSessionState<S>(key: string, initialState: S): [S, Dispatch<SetStateAction<S>>] {
  const [state, setState] = React.useState<S>(() => {
    const savedState = sessionStorage.getItem(key);

    return savedState ? JSON.parse(savedState) : initialState;
  });
  React.useEffect(() => {
    const savedState = sessionStorage.getItem(key);
    if (savedState) {
      setState(JSON.parse(savedState));
    }
  }, []);

  return [
    state,
    newState => {
      setState(newState);

      const resolvedState = typeof newState === 'function' ? (newState as any)(state) : newState;
      sessionStorage.setItem(key, JSON.stringify(resolvedState));
    }
  ];
}
