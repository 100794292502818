import React from "react";
import RegistrationTemplate from "./template";
import { PrimaryButton } from "../../../components/atoms/button";
import { CircularProgress } from "@material-ui/core";
import Tile from "../../../components/atoms/tile";
import classNames from "classnames";
import { css } from "emotion";
import { useAppContextQuery, useRequestEmailVerificationMutation } from "../../../graphql/generated";
import FullPageLoader from "../../shared/loader";
import { NetworkStatus } from "@apollo/client";

interface EmailVerificationProps {
  className?: string;
}

const EmailVerification: React.FC<EmailVerificationProps> = props => {
  const { data, loading } = useAppContextQuery();

  const [requestEmailVerification] = useRequestEmailVerificationMutation();
  const [requestingEmailVerification, setRequestingEmailVerification] = React.useState(false);
  const [requestedEmailVerification, setRequestedEmailVerification] = React.useState(false);

  if (!data?.viewer || loading) return <FullPageLoader />

  const { user } = data.viewer;

  return (
    <RegistrationTemplate heading={<>Verify<br/>Your Email</>} className={props.className}>
      <Tile heading={<div className={"vocalid-h2 mr2"}>Verify Your Email to Continue</div>} className={"mw6"} contentClassName={classNames("flex-grow-1 vocalid-secondary-text flex flex-column mv2", css({
        minHeight: 175
      }))}>
        <div className={"mb4"}>You should have received an email at the address you provided during signup.</div>
        <div className={"flex items-center"}>
          <div className={"mr3"}>Didn't receive an email?</div>
          <PrimaryButton size={'small'} onClick={async () => {
            setRequestedEmailVerification(false);
            setRequestingEmailVerification(true);
            await requestEmailVerification({ variables: { user: user.id } });
            setRequestingEmailVerification(false);
            setRequestedEmailVerification(true);
          }}>{ requestingEmailVerification ? <CircularProgress size={16} /> : requestedEmailVerification ? "Sent" : "Send Again"}</PrimaryButton>
        </div>
        <div className={"vocalid-tertiary-text mt3"}>Please refresh this page once you have verified your email address.</div>
      </Tile>
    </RegistrationTemplate>
  );
};

export default EmailVerification;
