import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel as BaseCarousel } from 'react-responsive-carousel';
import { css } from 'emotion';
import { Props as BaseCarouselProps } from 'react-responsive-carousel/lib/ts/components/Carousel';
import classNames from 'classnames';
import Icon, { EIconName } from '../../atoms/icon';
import './style-overrides.css';

export interface ICarouselProps {
  children: BaseCarouselProps['children'];
  hideIndicator?: boolean;
  selectedItem?: number;
  onChange?(index: number, item: React.ReactNode): void;
  arrowClassName?: string;
  itemClassName?: string;
  className?: string;
}

const Carousel: React.FC<ICarouselProps> = (props) => {
  const Indicator: BaseCarouselProps['renderIndicator'] = React.useCallback(
    (clickHandler, isSelected, index, label) => (
      <li
        onClick={clickHandler}
        className={classNames(
          css({
            background: '#fff',
            borderRadius: '50%',
            width: 8,
            height: 8,
            cursor: 'pointer',
            display: 'inline-block',
            margin: '0 8px',
            opacity: 0.3
          }),
          isSelected &&
            css({
              opacity: 1
            })
        )}
      />
    ),
    []
  );

  const arrowContainerCss = css({
    position: 'absolute',
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    zIndex: 2
  });

  return (
    <BaseCarousel
      showThumbs={false}
      showStatus={false}
      selectedItem={props.selectedItem}
      onChange={(index, item) => props.onChange?.(index, item)}
      renderArrowPrev={(clickHandler, hasPrev) =>
        hasPrev && (
          <div
            className={classNames(
              arrowContainerCss,
              css({
                left: 0
              }),
              props.arrowClassName
            )}
          >
            <Icon name={EIconName.ThinChevronLeft} onClick={clickHandler} />
          </div>
        )
      }
      renderArrowNext={(clickHandler, hasNext) =>
        hasNext && (
          <div
            className={classNames(
              arrowContainerCss,
              css({
                right: 0
              }),
              props.arrowClassName
            )}
          >
            <Icon name={EIconName.ThinChevronRight} onClick={clickHandler} />
          </div>
        )
      }
      renderIndicator={!props.hideIndicator ? Indicator : () => null}
      renderItem={(item) => <div className={classNames(css({ padding: '0 50px 40px 50px' }), props.itemClassName)}>{item}</div>}
    >
      {props.children}
    </BaseCarousel>
  );
};

export default Carousel;
