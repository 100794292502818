import React from 'react';
import classNames from 'classnames';
import { css } from 'emotion';
import { usePagination } from '@material-ui/lab/Pagination';
import { useTheme } from '../../../theme';
import Icon, { EIconName } from '../icon';

export interface IPaginationControlsProps {
  numPages: number;
  page: number;
  onChangePage(page: number): void;
  className?: string;
}

const PaginationControls: React.FC<IPaginationControlsProps> = (props) => {
  const { colors: themeColors } = useTheme();
  const { items } = usePagination({
    count: props.numPages,
    page: props.page,
    onChange(e, pageNum) {
      props.onChangePage(pageNum);
    },
    showFirstButton: false,
    showLastButton: false
  });

  const itemCss = css({
    height: '100%',
    padding: '8px 10px',
    verticalAlign: 'center'
  });

  const btnCss = css({
    border: 'none',
    backgroundColor: 'transparent',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
      backgroundColor: themeColors.tileStroke
    }
  });

  return (
    <div className={classNames('dib', props.className)}>
      <div
        className={classNames(
          'dib bg-vocalid-tile vocalid-secondary-text vocalid-list-title',
          css({
            borderRadius: 4
          })
        )}
      >
        <ul
          className={css({
            listStyle: 'none',
            padding: 0,
            margin: 0,
            display: 'flex',
            alignItems: 'stretch'
          })}
        >
          {items.map(({ page, type, selected, ...item }, index) => {
            let children = null;

            if (type === 'start-ellipsis' || type === 'end-ellipsis') {
              children = <span className={classNames('dib', itemCss)}>…</span>;
            } else if (type === 'page') {
              children = (
                <button
                  type="button"
                  className={classNames(itemCss, btnCss)}
                  style={{ color: selected ? themeColors.orange : undefined }}
                  {...item}
                >
                  {page}
                </button>
              );
            } else {
              const disabled =
                (props.page === 1 && type === 'previous') ||
                (props.page === props.numPages && type === 'next');

              children = ['previous', 'next'].includes(type) ? (
                <button
                  type="button"
                  className={classNames(
                    itemCss,
                    btnCss,
                    'flex items-center',
                    type,
                    disabled &&
                      css({
                        cursor: 'initial !important',
                        '&:hover': {
                          backgroundColor: 'initial'
                        }
                      })
                  )}
                  {...item}
                >
                  <Icon
                    name={type === 'previous' ? EIconName.ArrowLeft : EIconName.ArrowRight}
                    color={!disabled ? themeColors.secondaryText : themeColors.tertiaryText}
                    size={18}
                  />
                </button>
              ) : null;
            }

            return <li key={index}>{children}</li>;
          })}
        </ul>
      </div>
    </div>
  );
};

export default PaginationControls;
