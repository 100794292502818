import React from 'react';
import classNames from "classnames";
import {css} from "emotion";

export interface IClipCounterProps {
  count: number;
  size: 'small' | 'large';
  className?: string;
}

const ClipCounter: React.FC<IClipCounterProps> = (props) => {
  const counterCss = props.size === 'small' ? css({
    width: 36,
    height: 36
  }) : css({
    width: 72,
    height: 72,
    '& > span': {
      fontSize: 28,
      lineHeight: 33
    }
  });

  return (
    <div className={props.className}>
      <div
        className={
          classNames('vocalid-list-title flex justify-center items-center bg-vocalid-orange dib br-100 tc',
          counterCss, css({
              color: '#F2F2F2',
              // boxShadow: '0px 0px 7px rgba(236, 149, 32, 0.5)'
            }))
        }
      >
        <span>{props.count}</span>
      </div>
    </div>
  );
};

export default ClipCounter;
