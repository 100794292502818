import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISlateSelection, ISlateValue } from "../../organisms/clip/editor/adjustments/internal-types";
import { RootState } from "../../../store";

export interface IEditorSliceState {
  value: ISlateValue | null;
  selection: ISlateSelection;
}

interface ISetCurrentEditorValueActionPayload {
  value?: ISlateValue | null;
  selection?: ISlateSelection;
}

const currentEditorSlice = createSlice({
  name: 'currentEditor',
  initialState: {
    value: null,
    selection: null
  } as IEditorSliceState,
  reducers: {
    setCurrentEditorValue(state: IEditorSliceState, action: PayloadAction<ISetCurrentEditorValueActionPayload>) {
      if (action.payload.value !== undefined) {
        state.value = action.payload.value;
      }
      if (action.payload.selection !== undefined) {
        state.selection = action.payload.selection;
      }
    }
  }
})

// Extract the action creators object and the reducer
const { actions, reducer } = currentEditorSlice
// Extract and export each action creator by name
export const { setCurrentEditorValue } = actions
// Export the reducer, either as a default or named export
export default reducer

export const selectCurrentEditor = (state: RootState) => state.currentEditor
export const selectCurrentEditorValue = (state: RootState) => state.currentEditor.value
export const selectCurrentEditorSelection = (state: RootState) => state.currentEditor.selection
