import React from 'react';
import TalentDashboardPage from '../../components/pages/talent-dashboard';
import GenericErrorBoundary from "../../components/molecules/generic-error-boundary";
import FullPageLoader from "../shared/loader";
import { useTalentDashboardQuery } from "../../graphql/generated";

export interface ITalentDashboardPageContainerProps {}

const TalentDashboardPageContainer: React.FC<ITalentDashboardPageContainerProps> = (props) => {
  const { data, loading } = useTalentDashboardQuery()
  if (!data || loading || !data.viewer!.user.talent) return <FullPageLoader />

  const assignments = data.viewer!.user.talent.talent.assignments.map(assignment => ({
    id: assignment?.id,
    created: new Date(assignment.created),
    started: assignment.started ? new Date(assignment.started) : null,
    due: assignment.due ? new Date(assignment.due) : null,
    totalRecordingsCount: assignment.totalRecordingsCount,
    reviewedRecordingsCount: assignment.reviewedRecordingsCount,
    contentLines: assignment.contentLines,
    acceptedCount: assignment.reviewedAcceptedRecordingsCount,
    rejectedCount: assignment.reviewedRejectedRecordingsCount,
    recordedErrorCount: assignment.totalRecordingsWithoutErrors - assignment.totalRecordingsCount,
    isTalentRecordingRequestTarget: assignment.isTalentRecordingRequestTarget,
    voice: assignment.voice ? {
      id: assignment.voice.id,
      name: assignment.voice.title
    } : null
  }))

  return (
    <GenericErrorBoundary>
      <TalentDashboardPage
          talentId={data.viewer!.user.talent!.talent.id}
          assignments={assignments}
      />
    </GenericErrorBoundary>
  );
};

export default TalentDashboardPageContainer;
