import React from 'react';
import classNames from 'classnames';
import { css } from 'emotion';
import { PrimaryButton } from "../../atoms/button";
import ProfileDropdown from "../../molecules/profile-dropdown";
import Avatar from "../../atoms/avatar";
import { useTheme } from "../../../theme";
import { Link, useHistory } from "react-router-dom";
import SignOutDialog from "../../organisms/sign-out-dialog";
import AssertScreenSizeTemplate from "../assert-screen-size";

export interface IMarketingTemplateProps {
  data: {
    user: {
      firstName: string;
      lastName: string;
      emailAddress: string;
    } | null;
  }
  hideBorder?: boolean;
  className?: string;
}

const MarketingTemplate: React.FC<IMarketingTemplateProps> = (props) => {
  const { colors: themeColors } = useTheme();
  const [signingOut, setSigningOut] = React.useState(false);

  return (
    <>
      <SignOutDialog
        isShown={signingOut}
        onSignOutReturnTo={window.location.origin}
        onCancel={() => setSigningOut(false)}
      />
      <div
        className={classNames(
          'flex flex-column h-100 bg-vocalid-page vocalid-body',
          props.className
        )}
      >
        <nav
          className={classNames(
            !props.hideBorder && [css({ borderWidth: 1 }), "bb b--vocalid-header-border"],
            'bg-vocalid-header z-2',
          )}
        >
          <div className={'center flex flex-shrink-0 items-center justify-between pv2 pl5 pr4'}>
            <div className={'flex items-center'}>
              <Link to={'/'}>
                <img
                  src={'/icon-192x192.png'}
                  alt={'VocaliD Icon'}
                  width={40}
                  height={40}
                  className={'mr4'}
                />
              </Link>
              <Link to={'/'}>
                <img src={'/logo.png'} alt={'VocaliD'} height={17} />
              </Link>
              <span style={{ width: 40 }} className={'ml4'} />
            </div>
            <div className={'pr3'}>
              {props.data.user && <ProfileDropdown
                profile={props.data.user}
                onSignOut={() => setSigningOut(true)}
              >
                  <span className={'vocalid-secondary-text mr3'}>
                    <span className={'div v-mid mr3'}>{props.data.user.firstName}</span>
                    <Avatar className={'v-mid'} bgColor={themeColors.red}>
                      {`${props.data.user.firstName.substring(
                        0,
                        1
                      )}${props.data.user.lastName.substring(0, 2)}`}
                    </Avatar>
                  </span>
              </ProfileDropdown>}
              {/*<PrimaryButton size={"small"} onClick={() => {
                history.push("/");
              }}>Enter</PrimaryButton>*/}
            </div>
          </div>
        </nav>
        <AssertScreenSizeTemplate>
          <div className={'flex-grow-1 overflow-y-auto relative'}>{props.children}</div>
        </AssertScreenSizeTemplate>
      </div>
    </>
  );
};

export default MarketingTemplate;
