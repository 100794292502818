import { gql } from "@apollo/client";

export const VOICE_FRAGMENT  = gql`
fragment VoiceFragment on Voice {
  id
  title
  talent {
    id
    firstName
    lastName
  }
}
`

export const TAKE_FRAGMENT = gql`
fragment TakeFragment on Take {
  id
  content
  generatedSSML
  created
  voice {
    id
    title
    talent {
      id
      firstName
      lastName
    }
  }
  ... on GeneratedTake {
    rendered {
      url
      id
    }
    error
  }
  ... on TalentRecordedTake {
    recorded {
      downloadUrl
    }
  }
}
`

export const CLIP_FRAGMENT = gql`
fragment ClipFragment on Clip {
  id
  created
  title
  project {
    id
    type {
      id
    }
  }
  countOfTakes
  takes {
    ...TakeFragment
  }
}

${TAKE_FRAGMENT}
`

export const SUBSTITUTION_DICTIONARY_MEMBER_FRAGMENT = gql`
fragment SubstitutionDictionaryMemberFragment on SubstitutionDictionaryMember {
  id
  created
  original
  replacement
  pronunciation
}
`

export const SUBSTITUTION_DICTIONARY_FRAGMENT = gql`
fragment SubstitutionDictionaryFragment on SubstitutionDictionary {
  id
  project {
    id
  }
  members {
    ...SubstitutionDictionaryMemberFragment
  }
}
${SUBSTITUTION_DICTIONARY_MEMBER_FRAGMENT}
`

export const PLAYLIST_ITEM_FRAGMENT = gql`
fragment PlaylistItemFragment on PlaylistItem {
  __typename
  ...on PlaylistPauseItem {
    id
    duration
  }
  ...on PlaylistTakeItem {
    id
    take {
      id
      voice {
        title
        talent {
          firstName
          lastName
        }
      }
      ... on GeneratedTake {
        voice {
          title
        }
        rendered {
          url
        }
      }
      ... on TalentRecordedTake {
        recorded {
          downloadUrl
        }
      }
      clip {
        id
        title
        takes {
          id
        }
      }
    }
  }
}
`

export const PLAYLIST_MIX_CONTENT_FRAGMENT = gql`
fragment PlaylistMixContentFragment on PlaylistMixContent {
  items {
    ...PlaylistItemFragment
  }
}
${PLAYLIST_ITEM_FRAGMENT}
`

export const AD_MIX_CONTENT_FRAGMENT = gql`
fragment AdMixContentFragment on AdMixContent {
  sections {
    key
    items {
      ...PlaylistItemFragment
    }
  }
  soundTemplate {
    id
    name
  }
  maxDurationSeconds
}
${PLAYLIST_ITEM_FRAGMENT}
`

export const MIX_CONTENT_FRAGMENT = gql`
fragment MixContentFragment on MixContent {
  ...PlaylistMixContentFragment
  ...AdMixContentFragment
}
${PLAYLIST_MIX_CONTENT_FRAGMENT}
${AD_MIX_CONTENT_FRAGMENT}
`

export const MIX_FRAGMENT = gql`
fragment MixFragment on Mix {
  id
  created
  content {
    ...MixContentFragment
  }
  takes {
    id
    created
    content {
      ...MixContentFragment
    }
    rendered {
      id
      url
    }
  }
}
${MIX_CONTENT_FRAGMENT}
`

export const PROJECT_FRAGMENT = gql`
fragment ProjectFragment on Project {
  id
  type {
    id
    title
    description
  }
  title
  created
  updated
  countOfClips
  account {
    id
    substitutionDictionary {
      ...SubstitutionDictionaryFragment
    }
  }
  substitutionDictionary {
    ...SubstitutionDictionaryFragment
  }
  mixes {
    ...MixFragment
  }
  defaultVoice {
    ...VoiceFragment
  }
  clips {
    ...ClipFragment
  }
}

${CLIP_FRAGMENT}
${VOICE_FRAGMENT}
${SUBSTITUTION_DICTIONARY_FRAGMENT}
${MIX_FRAGMENT}
${VOICE_FRAGMENT}
`

export const PRICING_PLAN_FRAGMENT = gql`
fragment PricingPlanFragment on PricingPlan {
  id
  name
  description
  featuresCopy
  monthlyPrice
  trial {
    periodDays
    description
    featuresCopy
  }
}
`

export const AD_MIX_SOUND_TEMPLATE_FRAGMENT = gql`
fragment AdMixSoundTemplateFragment on AdMixSoundTemplate {
  id
  name
  description
  sampleUrl

  tags
  genre
  tempo
}
`

export const TALENT_DETAIL_FRAGMENT = gql`
fragment TalentDetailFragment on Talent {
  firstName
  lastName
  age
  gender
  ethnicIdentity
  pronouns
  memberOfUnion
  desiredProjectCategories
  hiredProjectCategories
  brandConflicts
  limitations
  otherJobInfo
  interestedInCharacterVoiceRecording
  showcaseVoicesOnVoice123
  interestedInRegionalAccentRecording
  interestedInNonenglishLanguageRecording
}
`
