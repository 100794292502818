import React from "react";
import classNames from "classnames";

export interface CopyrightTextProps {
  className?: string
}

const CopyrightText: React.FC<CopyrightTextProps> = props =>
  <span className={classNames("vocalid-body", props.className)}>© 2022 VocaliD, Inc.</span>;

export default CopyrightText;
