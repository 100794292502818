import React from 'react';
import classNames from 'classnames';
import Icon, { EIconName } from '../../atoms/icon';
import { css } from 'emotion';
import VocalAdjustmentsSidebarMenu, { IClipVoiceAdjustmentsData } from './tabs/vocal-adjustments';
import MixSidebarMenu, { IMixSidebarActions, IProjectMixData } from "./tabs/mix";
import SubstitutionDictionarySidebarMenu, { IProjectSubstitutionDictionaryData } from "./tabs/dictionary";
import { useTheme } from "../../../theme";
import { EMagnitude } from '../clip/editor/adjustments';
import { ISlateAlternatePronunciation } from "../clip/editor/adjustments/internal-types";
import { FeatureFlag, FeatureLock, useLockedFeatures } from "../../../utils/feature-locking";

type SidebarTabKey = 'vocal-adjustments' | 'mix' | 'dictionary'

interface ISidebarTab {
  id: SidebarTabKey;
  name: string;
  icon: EIconName;
  iconSizeOverride?: number;
  iconStyle?: React.CSSProperties;
  render: JSX.Element;
  featureLock?: FeatureFlag;
}

type ISidebarTabs = Array<ISidebarTab>;

export interface SidebarSelectionData {
  voiceAdjustments: IClipVoiceAdjustmentsData;
  singleSelectedWord: {
    text: string;
    defaultPhoneticPronunciation?: string[];
  } | null;
}

interface ProjectSidebarClipData {
  selection: SidebarSelectionData | null;
}

export interface IProjectSidebarProps {
  data: {
    editingClip: ProjectSidebarClipData | null;
    substitutionDictionary: IProjectSubstitutionDictionaryData;
    selectedMixId: string | null;
    mixes: IProjectMixData[];
    maxPlaylists: number | null;
  };

  mixActions: IMixSidebarActions;

  onVoiceAdjustmentsChanged(data: Partial<IClipVoiceAdjustmentsData>): void;

  onSelectedMix(mixId: string): void;
  onAddSubstitutionDictionaryMember(oldPronunciation: string, newPronunciation: ISlateAlternatePronunciation): Promise<void>;
  onSetSubstitutionDictionaryMemberAsGlobal(id: string, global: boolean): Promise<void>;
  onRemoveSubstitutionDictionaryMember(id: string): Promise<void>;
  onRequestDefaultPronunciation(text: string): void;
  // ProjectTagsSearchBarComponent: ITagsSidebarMenuProps['ProjectTagsSearchBarComponent'];
  className?: string;
}

export interface IProjectSidebarRef {
  setActiveTab(tab: SidebarTabKey): void;
}

const ProjectSidebar = React.forwardRef<IProjectSidebarRef, IProjectSidebarProps>((props, ref) => {
  const { colors: themeColors } = useTheme();
  const [activeTabId, setActiveTabId] = React.useState<SidebarTabKey>('vocal-adjustments');

  React.useImperativeHandle(ref, () => ({
    setActiveTab(tab) {
      setActiveTabId(tab)
    }
  }));

  const selectedClipsConditionalRender = (render: (clip: ProjectSidebarClipData) => JSX.Element) => {
    if (props.data.editingClip != null) {
      return render(props.data.editingClip);
    } else {
      return noClipJsx;
    }
  }

  const tabs: ISidebarTabs = [
    {
      id: 'vocal-adjustments',
      name: 'Vocal Adjustments',
      icon: EIconName.Sliders,
      render: selectedClipsConditionalRender(clip => {
        const appliedAdjustments = clip.selection != null ? clip.selection.voiceAdjustments : {
          loudness: EMagnitude.Med,
          pitch: EMagnitude.Med,
          speed: EMagnitude.Med,
          tone: 'none',
          wordStress: null,
          pause: 'none',
          pauseInMilliseconds: null,
          alternatePronunciation: null
        } as const;

        return (
          <VocalAdjustmentsSidebarMenu
            clipData={appliedAdjustments}
            onClipDataChange={props.onVoiceAdjustmentsChanged}
            active={clip.selection !== null}
            singleSelectedWord={clip.selection?.singleSelectedWord ?? null}
            onRequestDefaultPronunciation={props.onRequestDefaultPronunciation}
            className={"mt2"}
          />
          )
      })
    },
    {
      id: 'mix',
      name: 'Mix',
      icon: EIconName.Mix,
      featureLock: 'ProjectMix',
      iconStyle: { marginTop: -6 },
      render: <MixSidebarMenu
        mixes={props.data.mixes}
        maxMixes={props.data.maxPlaylists}
        selectedMixId={props.data.selectedMixId}
        onSelectMixId={props.onSelectedMix}
        actions={props.mixActions}
      />
    },
    {
      id: 'dictionary',
      name: 'Substitution Dictionary',
      icon: EIconName.Dictionary,
      featureLock: 'ProjectDictionary',
      iconSizeOverride: 35,
      iconStyle: { marginTop: -7.5 },
      render: (
        <SubstitutionDictionarySidebarMenu
          data={props.data.substitutionDictionary}
          addSubstitutionDictionaryMember={props.onAddSubstitutionDictionaryMember}
          setSubstitutionDictionaryMemberAsGlobal={props.onSetSubstitutionDictionaryMemberAsGlobal}
          removeSubstitutionDictionaryMember={props.onRemoveSubstitutionDictionaryMember}
          className={"mt2"} />
      )
    }
  ];

  const activeSection = tabs.find((s) => s.id === activeTabId)!!;

  const lockedFeatures = useLockedFeatures();

  return (
    <div
      className={classNames(
        'bg-vocalid-header flex flex-column items-stretch',
        css({ width: 420 }),
        props.className
      )}
    >
      <div className={"flex flex-shrink-0 justify-center"}>
        <nav className={classNames(css({ width: 338 }), 'flex justify-between')}>
          {tabs.filter(section => !section.featureLock || lockedFeatures[section.featureLock] !== 'hidden').map((section, i) => {
            const first = i === 0;
            const last = i === tabs.length - 1;
            const isActive = section.id === activeTabId;

            return (
              <FeatureLock key={section.id} feature={section.featureLock}>
                <div className={classNames('flex flex-column items-center', !first && 'pl3', !last && 'pr3')}>
                  <div className={css({
                    height: 20
                  })}>
                    <Icon
                      name={EIconName.FilledArrowDown}
                      active={isActive}
                      size={30}
                      className={classNames(css({ marginTop: -10, visibility: isActive ? 'visible' : 'hidden', filter: isActive ? `drop-shadow(0px 0.5px 3px ${themeColors.iconActive})` : 'none' }), 'db')}
                    />
                  </div>
                  <Icon
                    key={section.id}
                    name={section.icon}
                    active={isActive}
                    size={section.iconSizeOverride ?? 30}
                    className={classNames(css({ marginTop: -5, filter: isActive ? `drop-shadow(0px 0.5px 2px ${themeColors.iconActive})` : undefined }), 'db')}
                    style={section.iconStyle}
                    onClick={() => {
                      setActiveTabId(section.id);
                    }}
                  />
                </div>
              </FeatureLock>
            );
          })}
        </nav>
      </div>
      <div className={"mt4 flex-grow-1 flex flex-column items-center overflow-y-auto"}>
        <div className={classNames(css({ width: 338 }), 'mt1 vocalid-secondary-text flex-grow-1 flex flex-column')}>
          <h2 className={'vocalid-h2 vocalid-icon-active tc mt2 mb3'}>{activeSection.name}</h2>
          <div className={'flex flex-column flex-grow-1'}>{activeSection.render}</div>
        </div>
      </div>
    </div>
  );
});

export default ProjectSidebar;

const iconExplanationsJsx: JSX.Element = (
  <div>
    <div className={'mb3'}>
      <Icon name={EIconName.AddToMix} className={'v-mid mr3'} />
      <span>Add to/Remove from Mix</span>
    </div>
    <div className={'mb3'}>
      <Icon name={EIconName.Download} className={'v-mid mr3'} />
      <span>Download</span>
    </div>
    <div className={'mb3'}>
      <Icon name={EIconName.Delete} className={'v-mid mr3'} />
      <span>Delete</span>
    </div>
    {/*<div className={'mb3'}>
      <Icon name={EIconName.Reject} className={'v-mid mr3'} />
      <span>Reject</span>
    </div>
    <div className={'mb3'}>
      <Icon name={EIconName.Approve} className={'v-mid mr3'} />
      <span>Approve</span>
    </div>*/}
    {/*<div className={'mb3'}>
      <Icon name={EIconName.AdjustmentsBadge} color={'blue'} className={'v-mid mr3'} />
      <span>Adjustments are applied</span>
    </div>*/}
  </div>
)

const noClipJsx: JSX.Element = (
  <div className={'vocalid-icon-active vocalid-h3'}>
    <div className={'pt4 mb5'}>
      <strong>TIPS:</strong>
      <br />
      Select a clip to see adjustments applied to it. {/*Click again to deselect.*/}
      <br />
      <br />
      Double-click clip to start editing text or vocal adjustments.
      {/*<br />
      <br />
      Shift-click to select more than one clip.*/}
    </div>
    {iconExplanationsJsx}
  </div>
);

const multipleClipsJsx: JSX.Element = (
  <div className={'vocalid-icon-active vocalid-h3'}>
    <div className={'pt4 mb5'}>
      <strong>TIPS:</strong>
      <br />
      Select a clip to see adjustments applied to it. Click again to deselect.
      <br />
      <br />
      Double-click clip to start editing text or vocal adjustments.
      <br />
      <br />
      Shift-click to select more than one clip.
    </div>
    {iconExplanationsJsx}
  </div>
);
