import React from "react";
import {EditableProps} from "slate-react/dist/components/editable";
import {Editor as SlateEditor, Range, Text} from "slate";
import { DecoratedNode, ISlateAlternatePronunciation, ISlateWordNode } from "./internal-types";

export const useDecoration = (editor: SlateEditor, alternatePronunciations: { [text: string]: ISlateAlternatePronunciation }) => {
  return React.useCallback<NonNullable<EditableProps["decorate"]>>(
    ([node, path]) => {
      const ranges: Array<Range> = [];

      // alternate pronunciations
      for (const replacedText of Object.keys(alternatePronunciations)) {
        if (Text.isText(node)) {
          const { text } = node;
          const parts = text.toLowerCase().split(replacedText.toLowerCase());
          let offset = 0;

          parts.forEach((part, i) => {
            if (i !== 0) {
              ranges.push({
                anchor: { path, offset: offset - replacedText.length },
                focus: { path, offset },
                alternatePronunciation: alternatePronunciations[replacedText],
              });
            }

            offset = offset + part.length + replacedText.length;
          })
        }
      }

      // space adjustments
      /*if (Text.isText(node) && (node as ISlateNode).type === ESlateElementType.Space) {
        // this depends on there being a root node containing all child nodes
        const priorNode = path[1] !== 0 ? Node.get(editor, [path[0], path[1] - 1]) as ISlateNode : null;
        const priorAdjustments = priorNode && priorNode.type !== ESlateElementType.Space ? priorNode.adjustments : []

        const nextNode = path[1] !== (editor.children[0] as Element).children.length - 1 ? Node.get(editor, [path[0], path[1] + 1]) as ISlateNode : null;
        const nextAdjustments = nextNode && nextNode.type !== ESlateElementType.Space ? nextNode.adjustments : []

        ranges.push({
          anchor: { path, offset: 0 },
          focus: { path, offset: node.text.length },
          adjustments: intersection(priorAdjustments, nextAdjustments)
        })
      }*/

      return ranges
    },
    [editor, alternatePronunciations]
  )
}
