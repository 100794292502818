import React from "react";
import classNames from "classnames";
import { css } from "emotion";
import Icon, { EIconName } from "../../../components/atoms/icon";
import AudioPlayer from "../../../components/molecules/audio-player";
import { useTheme } from "../../../theme";
import SsmlContentView from "../../../components/organisms/recordings-review-table/ssml";
import FullPageLoader from "../../shared/loader";
import PaginationControls from "../../../components/atoms/pagination-controls";
import { FeatureLock } from "../../../utils/feature-locking";
import { useEnterpriseContextualState } from "../../../components/templates/enterprise";
import { useRendersTableQuery } from "../../../graphql/generated";

export interface IRendersTableProps {
  accountId?: string | null;

  className?: string;
}

const numberOfRendersToPreload = 10;

type SharedRenderProps = {
  id: string;
  timestamp: Date;
  content: string;
  audioUrl: string | null;
  error: string | null;
}

interface TakeRender extends SharedRenderProps {
  type: 'take';
  number: number;
  clipTitle: string | null;
  projectTitle: string;
}

interface ApiRender extends SharedRenderProps {
  type: 'api';
}

type Render = TakeRender | ApiRender;

const RendersTable: React.FC<IRendersTableProps> = (props) => {
  const { state: { selectedAccount } } = useEnterpriseContextualState()

  const account = props.accountId ?? selectedAccount;

  const perPage = 50;
  const [page, setPage] = React.useState(1);

  const { data, loading } = useRendersTableQuery({ variables: {
    account: account!,
    pagination: {
      page: page - 1,
      perPage: perPage
    }
  }, skip: !account })

  if (loading || !data) return <FullPageLoader />

  if (data.account.renderingAttempts.totalResults === 0) return <div className={"mv3 tc ttu vocalid-h3 vocalid-secondary-text"}>No Renders</div>

  const numPages = Math.ceil(data.account.renderingAttempts.totalResults / perPage);

  const renders: Array<Render> = data.account.renderingAttempts.results.map(attempt => {
    if (attempt.__typename === "TakeRenderingAttempt") {
      return {
        id: attempt.id,
        type: 'take',
        timestamp: new Date(attempt.created),
        content: attempt.take.generatedSSML ?? "",
        audioUrl: attempt.renderedTake?.url ?? null,
        error: attempt.error,
        number: [...attempt.take.clip?.takes ?? []].sort((a, b) => a.created - b.created).findIndex(t => t.id === attempt.take.id) + 1,
        clipTitle: attempt.take.clip ? attempt.take.clip.title ?? "Unnamed Clip" : "Deleted Clip",
        projectTitle: attempt.take.clip ? attempt.take.clip.project?.title ?? "Deleted Project" : "Unknown Project"
      };
    } else {
      return {
        id: attempt.id,
        type: 'api',
        timestamp: new Date(attempt.created),
        content: attempt.text,
        audioUrl: attempt.url,
        error: attempt.error
      };
    }
  });

  return (
    <>
    {/*<div className={'mh4 mb4 mt3'}>
      <div className={'mt2 mb4 flex justify-between'}>
        <div>
          <Dropdown
            label={'Bulk Action...'}
            className={'mr3'}
            menuColor={'iconInactive'}
            menuClassName={css({ minWidth: 200 })}
            roundedCorners
          >
             <DropdownOption onSelect={() => {}}>
              <Checkbox backgroundColor={'primaryText'} checked={true} className={'mr2 v-mid'} /> Invert
              Selection
            </DropdownOption>
             <DropdownSeparator />
             <DropdownOption onSelect={() => {}}>
              <Icon name={EIconName.Download} color={'primaryText'} className={'mr2 v-mid'} /> Download
            </DropdownOption>
          </Dropdown>
        </div>
      </div>
    </div>*/}
    <div className={'ph4'}>
      <div className={props.className}>
        <table className={'w-100'} style={{ borderSpacing: 0 }}>
          <thead>
            <tr className={'ttu vocalid-h3 vocalid-secondary-text'}>
              {/*<td className={'v-top ph2 tc'}>
                <Checkbox
                  checked={allSelected}
                  indeterminate={someSelected && !allSelected}
                  onChange={() =>
                    allSelected
                      ? setSelectedRecordingIds([])
                      : setSelectedRecordingIds(shownRecordings.map((r) => r.id))
                  }
                />
              </td>*/}
              {/*<td className={'v-mid pb3'}>No.</td>*/}
              <td className={'v-mid pb3'} style={{ width: '12%' }}></td>
              <td className={'v-mid pb3'} style={{ width: '17%' }}>Date</td>
              <td className={'v-mid pb3'} style={{ width: '40%' }}>Sentence</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {renders.map((render, index) => {
              return (
                <RendersTableItem
                  key={render.id}
                  render={render}
                  preloadAudio={index < numberOfRendersToPreload}
                />
              );
            })}
          </tbody>
        </table>
      </div>
      <div className={"flex justify-center"}>
        <PaginationControls numPages={numPages} page={page} onChangePage={setPage} />
      </div>
    </div>
    </>
  );
};

export default RendersTable;


export interface RendersTableItemProps {
  render: Render;
  preloadAudio: boolean;
}

export const RendersTableItem: React.FC<RendersTableItemProps> = props => {
  const { colors: themeColors } = useTheme();

  const leftBorderCss = css({
    borderLeft: '1.5px solid #3C6575'
  });

  return (
    <tr
      key={props.render.id}
      className={classNames(
        "vocalid-secondary-text",
        "bg-vocalid-tile",
        css({
          "&:nth-child(odd)": {
            backgroundColor: "#2A404A"
          }
        })
      )}
    >
      {/*<td className={"pa2 tc"}>
        <Checkbox
          checked={props.selected}
          onChange={({ target }) => {
            const checkbox = target as HTMLInputElement;
            const checked = checkbox.checked;

            props.onSelected(checked);
          }}
        />
      </td>*/}
      <td className={classNames("pa2 tl lh-copy")}>
        {props.render.type === "take" ?
          <div>{props.render.projectTitle}.{props.render.clipTitle ?? "Unnamed Clip"}[{props.render.number}]</div>
        : "API"}
      </td>
      <td className={classNames(leftBorderCss, "pa2")}>
        {props.render.timestamp.toLocaleString()}
      </td>
      <td className={classNames(leftBorderCss, "pa2")}>
        <SsmlContentView ssml={props.render.content} />
      </td>
      <td className={classNames(leftBorderCss, "pa2 tl")}>
        {props.render.audioUrl && (
          <div className={"flex justify-between"}>
            <AudioPlayer
              src={props.render.audioUrl}
              preload={props.preloadAudio}
              className={"flex-grow-1 flex-shrink-1 mt2 ml1 mr3"}
            />
            <FeatureLock feature={"TakeDownload"}>
              <Icon
                name={EIconName.Download}
                onClick={() => {
                  if (props.render.audioUrl) window.location.href = props.render.audioUrl;
                }}
                className={"mt2 mr2"}
              />
            </FeatureLock>
         </div>)}
        {props.render.error && (
          <div className={"pv2"}>
            Error: {props.render.error}
          </div>)}
      </td>
    </tr>
  );
};
