import React from "react";
import { useTheme } from "../../../theme";
import classNames from "classnames";
import { css } from "emotion";
import Tile from "../../../components/atoms/tile";
import { CircularProgress } from "@material-ui/core";
import Input from "../../../components/atoms/input";
import { PrimaryButton, TextButton } from "../../../components/atoms/button";
import {
  PricingPlanListingWidth,
  SmartPricingPlanListing
} from "../../organisms/pricing-plan-listing";
import { NO_PLAN_ID, PricingPlansListModal } from "../../organisms/pricing-plan-selection/pricing-plans-list";
import RegistrationTemplate from "./template";
import { BooleanParam, StringParam, useQueryParams } from "use-query-params";
import FullPageLoader, { Loader } from "../../shared/loader";
import Modal from "../../../components/atoms/modal";
import { useAppContextQuery, useCreateAccountMutation } from "../../../graphql/generated";

export const CompanyAccountCreationPageQueryParams = {
  plan: StringParam,
  trial: BooleanParam
} as const;

interface CompanyAccountCreationProps {
  onAccountCreated?(): void;
  className?: string;
}

const CompanyAccountCreation: React.FC<CompanyAccountCreationProps> = props => {
  const [{
    plan: pricingPlanId,
    trial: passedTrial,
  }] = useQueryParams(CompanyAccountCreationPageQueryParams);

  const { colors: themeColors } = useTheme();
  const [createAccount] = useCreateAccountMutation()
  const appContextReq = useAppContextQuery()

  const [accountName, setAccountName] = React.useState("");
  const [creatingAccount, setCreatingAccount] = React.useState(false);
  const [createdAccount, setCreatedAccount] = React.useState(false);
  const [redirectingToPayment, setRedirectingToPayment] = React.useState(false);

  const [pricingPlanInfo, setPricingPlanInfo] = React.useState<{ plan: string; trial: boolean; }>({ plan: pricingPlanId ?? NO_PLAN_ID, trial: passedTrial ?? false })
  const [changingPlan, setChangingPlan] = React.useState(false);

  // <PaymentRedirectView accountId={account} pricingPlanId={pricingPlanId} trial={trial ?? false} />

  if (!appContextReq.data || appContextReq.loading) return <FullPageLoader />

  return (
    <>
      <PricingPlansListModal selected={pricingPlanInfo} isOpen={changingPlan} onClose={() => setChangingPlan(false)} onPlanSelect={(planId, trial) => {
        setPricingPlanInfo({ plan: planId, trial });
        setChangingPlan(false);
      }} />
      <Modal isOpen={redirectingToPayment} containerClassName={css({
        minHeight: 200
      })}>
        <div className={"vocalid-white flex flex-column items-center justify-center"}>
          <h3 className={"vocalid-h3"}>Redirecting to Payment...</h3>
          <div className={"flex justify-center items-center w6 h6"}>
            <Loader />
          </div>
        </div>
      </Modal>
      <RegistrationTemplate heading={<>You're<br />Almost There!</>} subtitle={<>Step 3 of 3</>}
                            className={props.className}>
        <Tile heading={<div className={"vocalid-h2 mr2"}>Finish Setting Up Your Account</div>}
              contentClassName={"flex-grow-1 vocalid-secondary-text flex mv2"}>
          <div className={"flex-l flex-column dn mr4"}>
            <div className={"ba bw2 br3 b--vocalid-blue shadow-4 flex-grow-1"}>
              <div className={"relative overflow-y-hidden"} style={{ minHeight: 500, width: PricingPlanListingWidth }}>
                <div className={css({
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: "linear-gradient(0deg, rgba(43,77,90,1) 0%, rgba(43,77,90,0) 20%)",
                  pointerEvents: "none",
                  zIndex: 1
                })} />
                <div className={"absolute top-0 left-0"}>
                  <SmartPricingPlanListing id={pricingPlanInfo.plan} show={pricingPlanInfo.trial ? "trial" : "plan"} />
                </div>
              </div>
            </div>
            <label className={"vocalid-body vocalid-icon-active mt2 tc ttu flex items-center"}><span
              className={"w-30"} /><span
              className={"w-40"}>Selected Plan</span><TextButton className={"w-30"} onClick={() => setChangingPlan(true)}>Change</TextButton></label>
          </div>
          <div className={"flex-grow-1 flex flex-column pt4"}>
            {createdAccount ?
              <div className={classNames("flex-grow-1 flex flex-column vocalid-white items-center")}>
                <h3>Created Account.</h3>
              </div> : creatingAccount ?
                <div className={classNames("flex-grow-1 flex flex-column vocalid-white items-center")}>
                  <CircularProgress color={"inherit"} />
                </div> : <div className={classNames("flex-grow-1 flex flex-column items-center")}>
                  <div className={"flex flex-column items-center tc"}>
                    <Input
                      label={"Account Name*"}
                      placeholder={"Your Company Here"}
                      value={accountName}
                      onChange={setAccountName}
                      className={"mb4 w5"}
                    />
                    <PrimaryButton size={"small"} disabled={!accountName} onClick={async () => {
                      setCreatingAccount(true);
                      // await props.onCreateAccount(accountName);
                      const createAccountRes = await createAccount({ variables: {
                        name: accountName,
                        user: appContextReq.data!.viewer!.user.id,
                          subscription: pricingPlanInfo.plan !== NO_PLAN_ID ? {
                            pricingPlan: pricingPlanInfo.plan,
                            trial: pricingPlanInfo.trial,
                            cancelUrl: window.location.href,
                            successUrl: window.location.origin + "/enterprise"
                          } : null
                      } })
                      setCreatingAccount(false);
                      setCreatedAccount(true);

                      const redirectUrl = createAccountRes.data?.createAccount.pricingPlanPaymentUrl;
                      if (redirectUrl) {
                        setRedirectingToPayment(true)
                        setTimeout(() => {
                          window.location.href = redirectUrl;
                        }, 1000)
                      } else {
                        props.onAccountCreated?.()
                      }
                    }}>Finish Account Creation</PrimaryButton>
                  </div>
                </div>
            }
          </div>
        </Tile>
      </RegistrationTemplate></>
  );
};

export default CompanyAccountCreation;
