import React from 'react';
import classNames from 'classnames';
import { css } from 'emotion';
import {IEditorRef} from './editor/adjustments';
import Editor, {EditorMode} from './editor';
import {
  isEmptySlateValue,
  ISlateAlternatePronunciation,
  ISlateSelection,
  ISlateValue, slateValueAsText
} from "./editor/adjustments/internal-types";
import { useTheme } from '../../../theme';
import Icon, { EIconName } from '../../atoms/icon';
import Take from "./take";
import { TextButton } from "../../atoms/button";
import { CircularProgress } from '@material-ui/core';
import { useTakeRenderedListener } from '../../../graphql/subscriptions/TakeRendered';
import DeleteClipDialog from "../delete-clip-dialog";
import DeleteTakeDialog from "../delete-take-dialog";
import { usePreviousRef } from "../../shared/use-previous";
import { IEditorValue } from "../../../containers/pages/enterprise-project/editor-types";
import ssml from "./editor/ssml";
import { FeatureLock, StatelessFeatureLock } from "../../../utils/feature-locking";
import VoiceSelect from "../../../containers/organisms/voice-select";
import Tooltip from "../../atoms/tooltip";
import { MixType } from "../project-sidebar/tabs/mix";
import { useVoiceTalentQuery } from "../../../graphql/generated";
import { RevertableHiddenInput } from "../../atoms/input/hidden";

interface ITakeBase {
  type: string;
  id: string;
  number: number;
  createdAt: Date;
  audioUrl: string | null;
  status: 'unreviewed' | 'approved' | 'rejected';
  content: IEditorValue; // FIXME: this should be pulled up
  generatedSSML: string | null;
  vocal: {
    id: string;
    title: string;
    talent: boolean;
  };
}

export interface IGeneratedTake extends ITakeBase {
  type: 'GeneratedTake';
  renderedTakeId: string | null;
  rendering: boolean | null;
  error: string | null;
}

export interface ITalentRecordedTake extends ITakeBase {
  type: 'TalentRecordedTake';
  talent: {
    name: string;
  };
}

export type ITake = IGeneratedTake | ITalentRecordedTake;

export interface IClipData {
  id: string;
  createdAt: Date;
  title: string | null;
  takes: Array<ITake>;
  projectId: string;
  projectTypeId: string;
}

export enum ClipState {
  Inactive = 'inactive',
  Editing = 'editing'
}

export interface IClipProps {
  clip: IClipData;
  state: ClipState;
  alternatePronunciations: { [text: string]: ISlateAlternatePronunciation };
  ssml: string | null;
  preloadTakes?: boolean;

  content: ISlateValue;
  onContentChange(content: ISlateValue): void;
  onSelectionChange?(selection: ISlateSelection): void;
  selectedTakeId: string | null;

  defaultVocal: {
    id: string;
    talent: boolean;
  };

  currentRenderCount: number;
  maxRenders: number | null;
  maxCharactersPerRequest: number | null;
  mixType: MixType | undefined;

  onGenerate(vocalId: string): Promise<void>;
  onRequestTalentRecording(vocalId: string): Promise<void>;
  onEditing(): void;
  onDone(): void;
  onDelete(): void;
  onRequestSSML(): Promise<string>;
  setTitle(newTitle: string): void;

  onSelectedTake(takeId: string): void;
  onAddTakeToMix(takeId: string): void;
  onRejectTake(takeId: string): void;
  onApproveTake(takeId: string): void;
  onDeleteTake(takeId: string): void;
  onTakeRendered(takeId: string): void;
  onVocalSelected?(vocalId: string): void;

  className?: string;
}

export const Clip: React.FC<IClipProps> = (props) => {
  const editorRef = React.useRef<IEditorRef | null>(null)

  const previousSelectedTakeId = usePreviousRef(props.selectedTakeId);
  React.useEffect(() => {
    if (props.selectedTakeId && props.selectedTakeId !== previousSelectedTakeId) {
      props.onRequestSSML()
    }
  }, [props.selectedTakeId, props.onRequestSSML])

  const selectedTakeVocalId = props.clip.takes.find(take => take.id === props.selectedTakeId)?.vocal.id;
  const resolvedDefaultVocalId = selectedTakeVocalId ?? props.clip.takes[props.clip.takes.length - 1]?.vocal.id ?? props.defaultVocal.id;
  const [selectedVocalId, setSelectedVocalId] = React.useState(resolvedDefaultVocalId);
  React.useEffect(() => {
    setSelectedVocalId(resolvedDefaultVocalId);
    props.onVocalSelected?.(resolvedDefaultVocalId);
  }, [resolvedDefaultVocalId, props.state]);

  return (
    <div
      className={classNames(
        'bg-vocalid-tile',
        props.state !== 'inactive' && 'ba bw1 b--vocalid-icon-active',
        css({
          borderRadius: 6
        }),
        props.className
      )}
    >
      <div
        className={classNames(
          props.state !== 'editing' ? 'bg-vocalid-clip-inactive' : 'bg-vocalid-clip-active'
        )}
        style={{ borderRadius: 6 }}
        onClick={() => {
          if (props.state !== 'editing') {
            props.onEditing();
            setTimeout(() => {
              editorRef.current?.focus();
            }, 50);
          }
        }}
      >
        <ClipHeader {...props} selectedVocalId={selectedVocalId} selectVocalId={newVocalId => {
          setSelectedVocalId(newVocalId);
          props.onVocalSelected?.(newVocalId);
        }} />
        <ClipEditorBody {...props} editorRef={editorRef} selectedVocalId={selectedVocalId}/>
      </div>
      <ClipTakeList
        takes={props.clip.takes}
        mixType={props.mixType}
        selectedTakeId={props.selectedTakeId}
        state={props.state}
        preloadTakes={props.preloadTakes}
        onSelectedTake={props.onSelectedTake}
        onAddTakeToMix={props.onAddTakeToMix}
        onRejectTake={props.onRejectTake}
        onApproveTake={props.onApproveTake}
        onDeleteTake={props.onDeleteTake}
        onTakeRendered={props.onTakeRendered}
      />
    </div>
  )
}

interface ClipEditorBodyProps {
  editorRef: React.MutableRefObject<IEditorRef | null>;
  selectedVocalId: string;
}

const ClipEditorBody: React.FC<IClipProps & ClipEditorBodyProps> = (props) => {
  const { editorRef } = props
  const [editorMode, _setEditorMode] = React.useState<EditorMode>('adjustments');
  const [generating, setGenerating] = React.useState(false)

  const generate = async () => {
    setGenerating(true)
    await props.onGenerate(props.selectedVocalId);
    setGenerating(false)
  }

  const requestTalentRecording = async () => {
    setGenerating(true)
    await props.onRequestTalentRecording(props.selectedVocalId);
    setGenerating(false)
  }

  const setEditorMode = (setter: (current: EditorMode) => EditorMode) => {
    const newMode = setter(editorMode)
    _setEditorMode(newMode)
    if (newMode === "ssml") {
      props.onRequestSSML()
    }
  }

  // const firstNode = props.content.nodes[0]
  const generateDisabled = isEmptySlateValue(props.content) // props.content.nodes.length === 0 || (props.content.nodes.length === 1 && (firstNode?.type !== 'word' || firstNode.value === ""))

  const ssmlStr = (props.selectedTakeId && props.clip.takes.find(t => t.id === props.selectedTakeId)?.generatedSSML) ?? props.ssml;

  return (
    <>
      <div>
        <Editor
          ref={editorRef}
          value={props.content}
          mode={props.state !== ClipState.Editing ? 'text' : editorMode}
          ssml={ssmlStr}
          alternatePronunciations={props.alternatePronunciations}
          onChange={newValue => {
            // const blurbNumWords = 3
            //
            // // set title to stringified newValue (first couple words)
            // const lastValueBlurb = slateValueWords(props.content.slice(0, 10)).slice(0, blurbNumWords).join(" ")
            // const newValueBlurb = slateValueWords(newValue.slice(0, 10)).slice(0, blurbNumWords).join(" ")
            //
            // if (!props.clip.title || similarity(props.clip.title, lastValueBlurb) > 0.6) {
            //   // set generated title
            //   props.setTitle(newValueBlurb)
            // }

            props.onContentChange(newValue)
          }}
          onSelectionChange={props.onSelectionChange}
          readOnly={props.state !== ClipState.Editing}
          className={classNames(
            'vocalid-primary-text ph3 pt1 pb3',
            props.state !== 'editing'
              ? classNames(
                'bg-vocalid-clip-inactive pointer',
                css({
                  userSelect: 'none'
                })
              )
              : 'bg-vocalid-clip-active',
            props.state === 'inactive' && props.clip.takes.length === 0 && css({
              borderBottomLeftRadius: 6,
              borderBottomRightRadius: 6
            })
          )}
        />
      </div>
      {props.state === 'editing' && (
        <div className={'flex justify-between pa3'}>
          <div className={"flex"}>
            <span className={classNames('flex items-center pointer mr3', css({
              userSelect: 'none'
            }))} onClick={() => setEditorMode(o => o !== 'adjustments' ? 'adjustments' : 'text')}>
              <Icon name={EIconName.Eye} color={editorMode === 'adjustments' ? 'iconActive' : 'iconInactive'} size={14} className={'mr2'} />
              <span className={editorMode === 'adjustments' ? 'vocalid-icon-active' : 'vocalid-icon-inactive'}>View Adjustments</span>
            </span>
            <FeatureLock feature={"EditorSsml"} className={"flex items-center"}>
              <span className={classNames('flex items-center pointer mr2', css({
                userSelect: 'none'
              }))} onClick={() => setEditorMode(o => o !== 'ssml' ? 'ssml' : 'adjustments')}>
                <Icon name={EIconName.Eye} color={editorMode === 'ssml' ? 'iconActive' : 'iconInactive'} size={14} className={'mr2'} />
                <span className={editorMode === 'ssml' ? 'vocalid-icon-active' : 'vocalid-icon-inactive'}>View SSML</span>
              </span>
            </FeatureLock>
            <FeatureLock feature={"EditorSsml"} className={"flex items-center"}>
              <span className={classNames('flex items-center pointer', css({
                userSelect: 'none'
              }))} onClick={async () => {
                let resolvedSsmlStr = ssmlStr;
                if (!resolvedSsmlStr) resolvedSsmlStr = await props.onRequestSSML();

                const title = props.clip.title ?? "Unnamed clip"
                const take = props.selectedTakeId && props.clip.takes.find(t => t.id === props.selectedTakeId)?.number

                downloadSSML(resolvedSsmlStr, title + (take ? " " + take : ""));
              }}>
                <Icon name={EIconName.Download} size={18} />
              </span>
            </FeatureLock>
          </div>
          <span className={"inline-flex items-center"}>
            <StatelessFeatureLock lockedState={props.maxRenders != null && props.currentRenderCount >= props.maxRenders ? {
              lockedReason: `Generating a new take would exceed your max render limit of ${props.maxRenders}`
            } : props.maxCharactersPerRequest != null && slateValueAsText(props.content).length > props.maxCharactersPerRequest ? {
              lockedReason: `Character count exceeds maximum allowed per request (${props.maxCharactersPerRequest})`
            } : false}>
              <GenerateButton
                disabled={generateDisabled}
                selectedVocalId={props.selectedVocalId}
                onGenerate={() => generate()}
                onRequestTalentRecording={() => requestTalentRecording()}
                // onNewTake={() => { }}
                // onNewStarterTake={() => { }}
                isGenerating={generating}
              />
            </StatelessFeatureLock>
            <TextButton
              className={classNames('mr1', css({
                userSelect: 'none'
              }))}
              onClick={() => {
                editorRef.current?.blur();
                props.onDone();
              }}
            >
              Cancel
            </TextButton>
          </span>
          </div>
        )}
      </>
  )
}

function downloadSSML(ssml: string, title: string) {
  const blob = new Blob([ssml],
    { type: "text/plain;charset=utf-8" });

  const reader = new FileReader();
  reader.onload = e => {
    const dataUrl = e.target!.result as string;
    const a = document.createElement('a');
    a.href = dataUrl;
    a.download = `${title}.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  reader.readAsDataURL(blob);
}

const ClipHeader: React.FC<IClipProps & {
  selectedVocalId: string;
  selectVocalId(vocalId: string): void;
}> = (props) => {
  const resolvedPassedTitle = props.clip.title !== "" && props.clip.title != null ? props.clip.title : "Unnamed Clip"

  return (
    <table className={'w-100 vocalid-secondary-text pa3'} style={{ borderSpacing: 0 }}>
      <tbody>
        <tr>
          <td
            className={classNames(
              'v-top w-30 vocalid-h3',
              props.state === 'editing' && 'vocalid-primary-text'
            )}
          >
            <RevertableHiddenInput disabled={props.state !== 'editing'} initialValue={resolvedPassedTitle} onChange={async newTitle => await props.setTitle(newTitle)} className={"w-100"} />
          </td>
          <td className={'v-top pl4'}>{props.clip.createdAt.toLocaleDateString()}</td>
          <td className={'v-top tr'}>
            {props.state === ClipState.Editing && <div className={classNames("mr2 z-2 tl", css({
              display: 'inline-block',
              position: 'relative',
              top: -8
            }))}>
              <VoiceSelect selectedVocalId={props.selectedVocalId} selectedProjectTypeId={props.clip.projectTypeId} projectId={props.clip.projectId} onSelect={props.selectVocalId} />
            </div> }
            <ClipControls {...props}/>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const ClipControls: React.FC<IClipProps> = (props) => {
  const [isDeleting, setIsDeleting] = React.useState(false);

  return (
    <>
      <DeleteClipDialog isShown={isDeleting} onCancel={() => setIsDeleting(false)} onDelete={() => {
        props.onDelete();
        setIsDeleting(false);
      }} />
      <div className={"inline-flex justify-between items-center v-top"}>
        <div className={"flex items-center"}>
        </div>
        <div>
          {/* <Icon
            className={classNames('ph2 relative', css({top: 2}))}
            name={EIconName.AddToPlaylist}
            onClick={() => {}}
          />
          <Icon className={'ph2'} name={EIconName.Download} onClick={() => {}}/> */}
          <Icon className={"ph2"} name={EIconName.Delete} onClick={() => setIsDeleting(true)} />
        </div>
      </div>
    </>
  );
}

export interface IGenerateButtonProps {
  disabled?: boolean;
  selectedVocalId: string;

  onGenerate(): Promise<void>;
  onRequestTalentRecording(): Promise<void>;
  // onNewTake(): void;
  // onNewStarterTake(): void;
  className?: string;
  isGenerating: boolean;
}

const GenerateButton: React.FC<IGenerateButtonProps> = (props) => {
  const { data: voiceData } = useVoiceTalentQuery({ variables: { voice: props.selectedVocalId } });

  return (
    <GenerateButtonBase
      onGenerate={props.onGenerate}
      isGenerating={props.isGenerating}
      disabled={props.disabled}
      menu={voiceData?.voice.talent && voiceData.voice.talentRecordingRequestsEnabled ? [
        {
          label: 'Request Talent Recording', // Live Assist
          onClick: props.onRequestTalentRecording
        }
      ] : undefined}
      className={props.className}
    />
  );
};

export interface GenerateButtonBaseProps {
  onGenerate(): void;
  isGenerating: boolean;
  mini?: boolean;
  menu?: Array<GenerateButtonMenuItemProps>;
  disabled?: boolean;
  className?: string;
}

export interface GenerateButtonMenuItemProps {
  label: React.ReactNode;
  onClick(): void;
}

export const GenerateButtonBase: React.FC<GenerateButtonBaseProps> = props => {
  const [menuIsExpanded, setMenuExpanded] = React.useState(false);

  const { colors: themeColors } = useTheme();

  const btnColor = props.disabled ? themeColors.iconInactive : themeColors.blue;
  const textColor = props.disabled ? themeColors.secondaryText : 'white';

  const width = 185

  return (
    <div className={'dib relative z-1'}>
      <Tooltip
        visible={menuIsExpanded && props.menu !== undefined}
        interactive
        portalEl={document.getElementById("tooltip-container") ?? undefined}
        placement={'bottom-start'}
        tooltipElStyle={{
          padding: 0
        }}
        className={classNames("bg-vocalid-tile white shadow-4", css({
          // backgroundColor: btnColor,
          width,
          marginTop: -9
        }))}
        hideArrow
        tooltipContent={
          <div
            className={"w-100"}
            // className={classNames(
            //   'absolute left-0 right-0 bg-vocalid-tile white shadow-4',
            //   css({ top: '100%' })
            // )}
          >
            {props.menu && props.menu.map((menuItem, i) => (
              <div onClick={async () => {
                await menuItem.onClick();
                setMenuExpanded(false);
              }} className={'pointer pa2'}>
                {menuItem.label}
              </div>
            ))}
          </div>
        }
      >
        <span
          className={css({
            position: 'relative',
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'space-between',
            borderWidth: 0,
            borderStyle: 'solid',
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            borderBottomLeftRadius: !props.menu || !menuIsExpanded ? 4 : 0,
            borderBottomRightRadius: !props.menu || !menuIsExpanded ? 4 : 0,
            textTransform: 'uppercase',
            cursor: !props.disabled ? 'pointer' : 'not-allowed',
            backgroundColor: btnColor,
            color: textColor,
            width,

            fontFamily: '"Roboto", sans-serif',
            fontWeight: 500,
            fontSize: !props.mini ? 18 : 16,
            lineHeight: !props.mini ? '21px' : '14px'
          })}
          style={{ padding: 0 }}
        >
          <span
            onClick={() => !props.disabled && props.onGenerate()}
            className={css({
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'center',
              paddingTop: !props.mini ? 13.5 : 13.5,
              paddingBottom: !props.mini ? 13.5 : 13.5,
              // paddingLeft: !props.mini ? 40 : 20,
              // paddingRight: props.menu ? (!props.mini ? 40 : 10) : (!props.mini ? 40 : 20),
              // minWidth: "150px",
              textAlign: "center",
              overflowY: 'hidden'
            })}
          >
            { props.isGenerating ? <CircularProgress color={"inherit"} size={20} /> : <>Generate</> }
          </span>
          {props.menu &&
            <span
              className={css({ borderLeft: `1px solid ${textColor}` })}
              onClick={() => setMenuExpanded((old) => !old)}
            >
              <Icon
                name={EIconName.FilledArrowDown}
                color={textColor}
                size={40}
                className={css({
                  position: 'relative',
                  top: 4
                })}
              />
            </span>
          }
        </span>
      </Tooltip>
    </div>
  )
}

const ClipTakeList: React.FC<{
  takes: Array<IClipData['takes'][0]>;
  selectedTakeId: string | null;
  state: ClipState;
  preloadTakes?: boolean;
  mixType: MixType | undefined;

  onSelectedTake(takeId: string): void;
  onAddTakeToMix(takeId: string): void;
  onRejectTake(takeId: string): void;
  onApproveTake(takeId: string): void;
  onDeleteTake(takeId: string): void;
  onTakeRendered(takeId: string): void;
}> = (props) => {
  const takeHeight = 74
  const numberOfTakesToPreload = 5

  const [deletingTakeId, setDeletingTakeId] = React.useState<string | null>(null);

  return (
    <div className={css({
      maxHeight: takeHeight * 5,
      overflowY: 'auto'
    })}>
      <DeleteTakeDialog isShown={Boolean(deletingTakeId)} onCancel={() => setDeletingTakeId(null)} onDelete={() => {
        props.onDeleteTake(deletingTakeId!);
        setDeletingTakeId(null);
      }} />
      <div className={'w-100 flex flex-column'}>
        {props.takes.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime()).map((take, index) => {
          const selected = props.selectedTakeId ? props.selectedTakeId === take.id : index === 0

          return (
            <ClipTake
              // onClick={() => selectTakeForClip(props.clip.id, take.id)}
              onClick={() => props.onSelectedTake(take.id)}
              key={take.id}
              selected={selected && props.state !== 'inactive'}
              take={take}
              mixType={props.mixType}
              voiceName={take.vocal.title}
              onTakeRendered={() => props.onTakeRendered(take.id)}
              preload={selected || (index < numberOfTakesToPreload && props.preloadTakes)}
              onAddToMix={props.onAddTakeToMix}
              onApprove={() => props.onApproveTake(take.id)}
              onReject={() => props.onRejectTake(take.id)}
              onDelete={() => setDeletingTakeId(take.id)}
            />
          );
        })}
      </div>
    </div>
  )
}

const ClipTake: React.FC<{
  take: IClipData['takes'][0];
  selected: boolean;
  onClick(): void;
  onTakeRendered(): void;
  preload?: boolean;
  voiceName: string | null;
  mixType: MixType | undefined;

  onAddToMix(takeId: string): void;
  onReject(): void;
  onApprove(): void;
  onDelete(): void;
}> = (props) => {
  const take = props.take

  const { url, error } = useTakeRenderedListener(take.id, take.type === "TalentRecordedTake" || take.audioUrl != null || take.error !== null)

  const effectiveUrl = take.audioUrl ?? url
  const effectiveError = (take.type === "GeneratedTake" && take.error) || error

  return (
    <Take
      key={take.id}
      primary={props.selected}
      onClick={props.onClick}
      error={effectiveError ?? undefined}
      take={{
        ...take,
        status: "unreviewed",
        audioUrl: effectiveUrl,
        voiceName: props.voiceName ?? "Default Voice"
      }}
      mixType={props.mixType}
      onAddToMix={() => props.onAddToMix(take.id!)}
      onReject={props.onReject}
      onApprove={props.onApprove}
      onDelete={props.onDelete}
      preload={props.preload}
    />
  )
}

// https://stackoverflow.com/a/36566052
function similarity(s1: string, s2: string): number {
  let longer = s1;
  let shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  let longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }
  return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength as any);
}

function editDistance(s1: string, s2: string): number {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  const costs = [];
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i;
    for (let j = 0; j <= s2.length; j++) {
      if (i === 0)
        costs[j] = j;
      else {
        if (j > 0) {
          let newValue = costs[j - 1];
          if (s1.charAt(i - 1) !== s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue),
              costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0)
      costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}
