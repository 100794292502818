import React from "react";
import classNames from "classnames";
import { css } from "emotion";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import EditorErrorBoundary from "./adjustments/error-boundary";
import AdjustmentsEditor, { IEditorRef, IEditorProps as IAdjustmentsEditorProps } from "./adjustments";
import xml from "react-syntax-highlighter/dist/cjs/languages/hljs/xml";
import SSMLEditor from "./ssml";
import TextEditor from "./text";

SyntaxHighlighter.registerLanguage('xml', xml);

export type EditorMode = 'text' | 'adjustments' | 'ssml'

export interface IEditorProps extends IAdjustmentsEditorProps {
  mode: EditorMode;
  ssml: string | null;
}

const Editor = React.forwardRef<IEditorRef, IEditorProps>((props, ref) => {
  return (
    <div className={classNames(props.className)}>
      <div
        className={classNames(css({
          maxHeight: `${10 * 1.5}em`,
          lineHeight: 1.5,
          minHeight: '1.5em',
          overflowY: 'auto',
          transition: 'height 0.3s'
        }), "flex flex-column")}
      >
        {props.mode === 'text' ? (
          <TextEditor value={props.value} />
        ) : props.mode === 'adjustments' ? <AdjustmentsEditor ref={ref} {...props} /> :
          <SSMLEditor ssml={props.ssml} />
        }
      </div>
    </div>
  );
});

const EditorWithErrorBoundary = React.forwardRef<IEditorRef, IEditorProps>((props, ref) => (
  <EditorErrorBoundary>
    <Editor ref={ref} {...props} />
  </EditorErrorBoundary>
))

export default EditorWithErrorBoundary
