import React from 'react';
import classNames from 'classnames';
import Slider from '../../atoms/slider';
import Icon, { EIconName } from '../../atoms/icon';
import { useFocusedAudioPlayer } from './context';
import {css} from "emotion";
import { CircularProgress } from "@material-ui/core";
import { useTheme } from "../../../theme";

export interface IAudioPlayerRef {
  play(): void;
  pause(): void;
  stop(): void;
}

export interface IAudioPlayerProps {
  src: AudioPlayerSrc;
  buttonOnly?: boolean;
  buttonColor?: string;
  voiceName?: string;
  className?: string;
  preload?: boolean;
  disabled?: boolean;

  onPlay?(): void;
  onPause?(): void;
  onEnded?(): void;
}

type AudioPlayerSrc = string | File;

const AudioPlayer = React.forwardRef<IAudioPlayerRef, IAudioPlayerProps>((props, ref) => {
  const audioRef = React.useRef<HTMLAudioElement | null>(null);

  const [audioSrc, setAudioSrc] = React.useState<AudioPlayerSrc | null>(null);

  const [metadataLoaded, setMetadataLoaded] = React.useState<boolean>(false);
  const [durationSeconds, setDurationSeconds] = React.useState<number>(0);
  const [filesizeBytes, setFileSizeBytes] = React.useState<number | null>(null);

  const [canPlay, setCanPlay] = React.useState<boolean>(false);
  const [currentTimeSeconds, setCurrentTimeSeconds] = React.useState<number>(0);
  const [playing, setPlaying] = React.useState(false);

  const { colors: themeColors } = useTheme();
  const { isFocused, focus } = useFocusedAudioPlayer();

  const [loading, setLoading] = React.useState(false);
  const loadSrc = async () => {
    setLoading(true);

    let blob: Blob
    if (typeof props.src === "string") {
      const res = await fetch(props.src, {
        method: 'GET'
      });

      blob = await res.blob();
    } else {
      blob = props.src
    }
    setFileSizeBytes(blob.size);

    const blobUrl = window.URL.createObjectURL(blob);
    if (audioRef.current) {
      audioRef.current.src = blobUrl;
    }
    setAudioSrc(blobUrl);
    setLoading(false);
  }

  React.useEffect(() => {
    if (props.preload) {
      loadSrc();
    }
  }, []);

  const play = async () => {
    if (!audioSrc) {
      await loadSrc()
    }

    if (props.buttonOnly) {
      audioRef.current!.currentTime = 0;
    }
    audioRef.current?.play();
    focus();
    setPlaying(true);
    props.onPlay?.()
  }

  const pause = () => {
    audioRef.current?.pause();
    setPlaying(false);
    props.onPause?.()
  }

  React.useImperativeHandle(ref, () => ({
    play,
    pause,
    stop() {
      pause()
      audioRef.current!.currentTime = 0;
    }
  }));

  React.useEffect(() => {
    if (!isFocused) {
      pause();
    }
  }, [isFocused]);

  const playIconSize = !props.buttonOnly ? 20 : 25

  const playBtnJsx = loading || (playing && !canPlay) ? (
    <span className={css({
      color: props.buttonColor ?? themeColors.iconInactive
    })}><CircularProgress color={"inherit"} size={playIconSize} /></span>
  ) : (
    <Icon
      name={!playing ? EIconName.Play : !props.buttonOnly ? EIconName.Pause : EIconName.Stop}
      color={props.buttonColor}
      onClick={() => {
        if (props.disabled) return;

        if (!playing) {
          play()
        } else {
          pause()
        }
      }}
      style={{
        opacity: !props.disabled ? 1 : 0.5
      }}
      size={playIconSize}
    />
  );

  const audioTagJsx = (
    <audio
      ref={audioRef}
      // src={audioSrc ?? undefined}
      // src={props.src}
      controls={false}
      autoPlay={false}
      // preload={props.preload ? "auto" : "none"}
      onTimeUpdate={(e) => {
        const target = e.target as HTMLAudioElement;
        if (!isNaN(target.duration)) {
          setDurationSeconds(target.duration)
        }

        if (!isNaN(target.currentTime)) {
          setCurrentTimeSeconds(target.currentTime)
        }
      }}
      onLoadedMetadata={(e) => {
        const target = e.target as HTMLAudioElement;
        if (!isNaN(target.duration)) {
          setDurationSeconds(target.duration);
        }

        setMetadataLoaded(true);
      }}
      onCanPlay={(e) => {
        setCanPlay(true);
      }}
      onEnded={() => {
        setPlaying(false);
        audioRef.current!.currentTime = 0;
        props.onEnded?.();
      }}
      className={'absolute top-0 left-0 h-0 w-0'}
    />
  );

  return !props.buttonOnly ? (
    <div
      className={classNames('dib', props.className)}
      style={{
        minWidth: 200
      }}
    >
      <div>
        <div className={'flex relative items-start'}>
          <span className={classNames('db mr3', css({
            marginTop: 5
          }))}>{playBtnJsx}</span>
          <div className={'flex-grow-1'}>
            <div>
              <Slider
                min={0}
                max={durationSeconds}
                value={currentTimeSeconds}
                color={'green'}
                onChange={(newTime) => {
                  audioRef.current!.currentTime = newTime;
                }}
              />
            </div>
            <div className={classNames('flex justify-between mt1 vocalid-secondary-text', css({
              fontFamily: '"Roboto Condensed", sans-serif',
              fontWeight: 300,
              fontSize: '12px'
            }))}>
              <span className={css({
                position: 'relative',
                left: -10
              })}>{props.voiceName}</span>
              <span>
                <span>
                  <span>
                    {(metadataLoaded && canPlay)
                      ? new Date(currentTimeSeconds * 1000).toISOString().substr(14, 5)
                      : '--:--'}
                      {' '}/{' '}
                    {metadataLoaded
                      ? new Date(durationSeconds * 1000).toISOString().substr(14, 5)
                      : '--:--'}
                  </span>
                </span>
                <span>
                  {(metadataLoaded && filesizeBytes !== null && <span className={"ml2"}>{(filesizeBytes/1000).toFixed(0)} kB</span>)}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      {audioTagJsx}
    </div>
  ) : (
    <div className={classNames('dib', props.className)}>
      <div className={'relative'}>
        {playBtnJsx}
        {audioTagJsx}
      </div>
    </div>
  );
});

export default AudioPlayer;
