import React from 'react';
import EnterpriseDashboardPage from "../../components/pages/enterprise-dashboard";
import { useEnterpriseContextualState } from "../../components/templates/enterprise";
import { useHistory } from 'react-router-dom';
import GenericErrorBoundary from "../../components/molecules/generic-error-boundary";
import { useAppContextQuery, useEnterpriseDashboardQuery } from "../../graphql/generated";
import { useAnalyticsContextQuery } from "../../graphql/queries/AnalyticsContext";
import { BucketSize, ClipsQueryLatestGeneratedTakeFragment, useAnalyticsQuery } from "../../graphql/generated";

export interface IEnterpriseDashboardPageContainerProps { }

const EnterpriseDashboardPageContainer: React.FC<IEnterpriseDashboardPageContainerProps> = (props) => {
  const { state } = useEnterpriseContextualState()

  const dashboardQuery = useEnterpriseDashboardQuery({ variables: { account: state.selectedAccount! }, skip: !state.selectedAccount })
  const analyticsRanges = useAnalyticsRanges()
  const analytics = useAnalytics(analyticsRanges.selectedRange);
  const history = useHistory()

  const { data: appContextData } = useAppContextQuery()

  const account = appContextData?.viewer?.user.accounts.find(a => a.account.id === state.selectedAccount)?.account

  return (
    <GenericErrorBoundary>
      <EnterpriseDashboardPage
        data={
          {
            account: {
              name: account?.name ?? ""
            },
            pricingPlanSubscription: account?.pricingPlanSubscription ? {
              name: account.pricingPlanSubscription.pricingPlan.name,
              isTrial: account.pricingPlanSubscription.isTrial,
              isActive: account.pricingPlanSubscription.isActive
            } : null,
            projects: (dashboardQuery.data?.account?.projects?.results ?? []).map(project => ({
              ...project,
              updatedAt: new Date(project.updated),
              voice: project.defaultVoice.title
            })),
            clips: (dashboardQuery.data?.clips.results ?? []).filter(clip => clip.latestTake && clip.latestTake.__typename === "GeneratedTake" && clip.latestTake.rendered?.url !== undefined).map(clip => ({
              id: clip.id,
              title: clip.title,
              audioUrl: (clip.latestTake! as ClipsQueryLatestGeneratedTakeFragment).rendered!.url
            })),
            usage: {
              rangeOptions: analyticsRanges.rangeOptions,
              selectedRange: {
                key: analyticsRanges.selectedRange.label,
                from: analyticsRanges.selectedRange.startDate,
                to: analyticsRanges.selectedRange.endDate
              },
              chart: analytics
            }
          }}
        setSelectedUsageRange={analyticsRanges.setSelectedRange}
        onCreateProject={() => history.push("/enterprise/projects/new")}
        onCreateClip={() => { }}
      />
    </GenericErrorBoundary>
  );
};

export default EnterpriseDashboardPageContainer;

export function getCurrentMonthPeriod() {
  const currentDate = new Date()
  return {
    startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
    endDate: currentDate
  }
}

function useAnalyticsRanges() {
  const analyticsContext = useAnalyticsContextQuery()
  const subscription = analyticsContext.data?.account.pricingPlanSubscription

  const rangeOptions: Array<{ label: string; startDate: Date, endDate: Date }> = React.useMemo(() => {
    const currentDate = new Date()

    return [
      {
        label: "Billing Period",
        startDate: subscription ? new Date(subscription.currentPeriodStart) : getCurrentMonthPeriod().startDate,
        endDate: subscription ? new Date(subscription.currentPeriodEnd) : currentDate
      },
      {
        label: "Last Month",
        startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
        endDate: currentDate
      },
      {
        label: "Last Year",
        startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
        endDate: currentDate
      }
    ];
  }, [subscription]);

  const [selectedRangeLabel, setSelectedRangeLabel] = React.useState(rangeOptions[0].label);
  React.useEffect(() => {
    setSelectedRangeLabel(rangeOptions[0].label)
  }, [rangeOptions.length])
  const selectedRange = rangeOptions.find(r => r.label === selectedRangeLabel)!;

  return {
    rangeOptions: rangeOptions.map(r => r.label),
    selectedRange: selectedRange,
    setSelectedRange: setSelectedRangeLabel,
  }
}

export function useAnalytics(selectedRange: { startDate: Date, endDate: Date }) {
  let bucketSize: BucketSizeString = "Year"

  if (monthsBetween(selectedRange.startDate, selectedRange.endDate) <= 2) {
    bucketSize = "Day";
  } else if (yearsBetween(selectedRange.startDate, selectedRange.endDate) <= 3) {
    bucketSize = "Month"
  }

  const analytics = useConnectedAnalyticsQuery(selectedRange.startDate, selectedRange.endDate, bucketSize)

  const buckets = (analytics.data?.analytics.buckets ?? []).map(b => ({
    ...b,
    start: new Date(b.start)
  }))

  const points = React.useMemo(() => {
    if (bucketSize === "Day") {
      return new Array(daysBetween(selectedRange.startDate, selectedRange.endDate)).fill(null).map((_, dayI) => {
        const pointDate = new Date(new Date(selectedRange.startDate).setDate(selectedRange.startDate.getDate() + dayI))
        const pointBucket = buckets.find(b => b.start.getFullYear() === pointDate.getFullYear() && b.start.getMonth() === pointDate.getMonth() && b.start.getDate() === pointDate.getDate())

        return ({
          date: pointDate,
          renders: pointBucket?.renders ?? 0
        });
      });
    } else if (bucketSize === "Month") {
      return new Array(monthsBetween(selectedRange.startDate, selectedRange.endDate)).fill(null).map((_, monthI) => {
        const pointDate = new Date(new Date(selectedRange.startDate).setMonth(selectedRange.startDate.getMonth() + monthI))
        const pointBucket = buckets.find(b => b.start.getFullYear() === pointDate.getFullYear() && b.start.getMonth() === pointDate.getMonth())
        return ({
          date: pointDate,
          renders: pointBucket?.renders ?? 0
        });
      });
    } else if (bucketSize === "Year") {
      return new Array(yearsBetween(selectedRange.startDate, selectedRange.endDate)).fill(null).map((_, yearI) => {
        const pointDate = new Date(new Date(selectedRange.startDate).setFullYear(selectedRange.startDate.getFullYear() + yearI))
        const pointBucket = buckets.find(b => b.start.getFullYear() === pointDate.getFullYear())
        return ({
          date: pointDate,
          renders: pointBucket?.renders ?? 0
        });
      });
    }

    return []
  }, [selectedRange, buckets])

  return {
    points: points,
    totalRenders: buckets.reduce((acc, curr) => acc + curr.renders, 0),
    // totalSpend: buckets.reduce((acc, curr) => acc + curr.creditsConsumed, 0),
    pointInterval: bucketSize
  }
}

type BucketSizeString = "Day" | "Hour" | "Minute" | "Month" | "Week" | "Year";
const useConnectedAnalyticsQuery = (startDate: Date, endDate: Date, bucketSize: BucketSizeString) => {
  const castedBucketSize: BucketSize = bucketSize as any;
  const enterprise = useEnterpriseContextualState()

  return useAnalyticsQuery({
      variables: {
        input: {
          account: enterprise.state.selectedAccount!,
          bucketSize: castedBucketSize,
          dateStart: startDate.getTime(),
          dateEnd: endDate.getTime()
        }
      },
      skip: !enterprise.state.selectedAccount,
      fetchPolicy: "network-only"
    }
  )
}

function daysBetween(startDate: Date, endDate: Date) {
  return Math.round(Math.abs(((startDate as any) - (endDate as any)) / (24 * 60 * 60 * 1000))) + 1
}

function monthsBetween(startDate: Date, endDate: Date) {
    let months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
    months -= startDate.getMonth();
    months += endDate.getMonth();
    return months <= 0 ? 0 : months;
}

function yearsBetween(startDate: Date, endDate: Date) {
  let years = endDate.getFullYear() - startDate.getFullYear();
  return years <= 0 ? 0 : years;
}
