import React from 'react';
import classNames from 'classnames';
import {css} from "emotion";

export interface IHeaderProps {
  rightArea?: React.ReactNode;
  className?: string;
}

const Header: React.FC<IHeaderProps> = (props) => {
  return (
    <div
      className={classNames(
        'ph4 bg-vocalid-tile flex justify-between items-top',
        css({
          paddingTop: 30, paddingBottom: 30
        }),
        props.className
      )}
    >
      <div className={'vocalid-primary-text flex-grow-1'}>{props.children}</div>
      {props.rightArea && <div className={'flex-shrink-0'}>{props.rightArea}</div>}
    </div>
  );
};

export default Header;
