import React from 'react';
import { css } from 'emotion';
import classNames from 'classnames';
import { useTheme } from '../../../theme';

export interface IToggleProps {
  checked: boolean;
  onChange?(newValue: boolean): void;
  className?: string;
}

const Toggle: React.FC<IToggleProps> = (props) => {
  const { colors: themeColors } = useTheme();

  const uncheckedBGColor = themeColors.sliderGroove;
  const checkedBGColor = themeColors.green;

  // const disabledClassName = css({
  //   backgroundColor: labelColor,
  //   cursor: 'not-allowed',
  //   '&:before': {
  //     backgroundColor: labelColor,
  //     cursor: 'not-allowed'
  //   }
  // });

  const width = 60;
  const height = width * 0.4;
  const handleMargin = width * 0.025;

  return (
    <div
      className={classNames(
        css({
          display: 'inline-block'
        }),
        props.className
      )}
    >
      <div
        className={css({
          position: 'relative',
          width: width,
          display: 'inline-block',
          verticalAlign: 'middle',
          userSelect: 'none',
          textAlign: 'left'
        })}
      >
        <label
          className={css({
            display: 'block',
            overflow: 'hidden',
            cursor: 'pointer',
            borderRadius: 20,
            margin: 0
          })}
        >
          <input
            type="checkbox"
            className={css({
              display: 'none'
            })}
            checked={props.checked}
            onChange={(e) => props.onChange?.(e.target.checked)}
          />
          <span
            className={classNames(
              css({
                display: 'block',
                width: '200%',
                marginLeft: '-100%',
                // transition: 'margin 0.3s ease-in 0s',
                '&:before, &:after': {
                  display: 'block',
                  float: 'left',
                  width: '50%',
                  height: height,
                  padding: 0,
                  lineHeight: `${height}px`,
                  fontSize: 12,
                  fontWeight: 'bold',
                  boxSizing: 'border-box'
                },
                '&:before': {
                  content: '"On"',
                  textTransform: 'uppercase',
                  paddingLeft: 12,
                  backgroundColor: checkedBGColor,
                  color: themeColors.header
                },
                '&:after': {
                  content: '"Off"',
                  textTransform: 'uppercase',
                  paddingRight: 9,
                  backgroundColor: uncheckedBGColor,
                  color: themeColors.secondaryText,
                  textAlign: 'right'
                }
              }),
              props.checked &&
                css({
                  marginLeft: 0
                })
            )}
          />
          <span
            className={classNames(
              css({
                display: 'block',
                width: height - handleMargin * 2,
                margin: handleMargin,
                background: themeColors.sliderHandle,
                position: 'absolute',
                top: 0,
                bottom: 0,
                border: `7px solid ${themeColors.sliderHandleStroke}`,
                borderRadius: 20
                // transition: 'all 0.3s ease-in 0s'
              }),
              props.checked &&
                css({
                  right: 0
                })
            )}
          />
        </label>
      </div>
    </div>
  );
};

export default Toggle;
