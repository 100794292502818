import React from 'react';
import { useTheme } from '../../../theme';
import Tooltip from '../../atoms/tooltip';
import { Link } from 'react-router-dom';

export interface IProfileDropdownProps {
  profile: {
    firstName: string;
    lastName: string;
    emailAddress: string;
  };
  profileUrl?: string;
  onSignOut(): void;
}

const ProfileDropdown: React.FC<IProfileDropdownProps> = (props) => {
  const [isShown, setIsShown] = React.useState(false);
  const { colors: themeColors } = useTheme();

  return (
    <Tooltip
      visible={isShown}
      onClickOutside={() => setIsShown(false)}
      interactive
      tooltipContent={
        <>
          <h3 className={'mv0 white vocalid-list-title'}>
            {props.profile.firstName} {props.profile.lastName}
          </h3>
          <div className={'mt1 mb3'}>{props.profile.emailAddress}</div>
          { props.profileUrl && <div className={'mv2'}>
            <Link
              to={props.profileUrl}
              onClick={() => {
                setIsShown(false);
              }}
              className={'vocalid-link no-underline'}
            >
              Profile
            </Link>
          </div> }
          <div className={'mv2'}>
            <button
              onClick={() => {
                props.onSignOut();
                setIsShown(false);
              }}
              className={'vocalid-link no-underline bn bg-inherit pa0 pointer'}
            >
              Sign Out
            </button>
          </div>
        </>
      }
      color={themeColors.page}
      tooltipElStyle={{
        color: themeColors.secondaryText,
        minWidth: 180,
        borderRadius: 6,
        boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.2)'
      }}
    >
      <span className={'dib'} onClick={() => setIsShown((o) => !o)}>
        {props.children}
      </span>
    </Tooltip>
  );
};

export default ProfileDropdown;
