import React from 'react';
import { useParams } from 'react-router-dom';
import EnterpriseRecordingsReviewPage from '../../components/pages/enterprise-recordings-review';
import GenericErrorBoundary from "../../components/molecules/generic-error-boundary";
import FullPageLoader from "../shared/loader";
import { useRecordingReviewAssignmentDetailQuery } from "../../graphql/generated";

export interface IEnterpriseRecordingsReviewPageContainerProps {
  className?: string;
}

const EnterpriseRecordingsReviewPageContainer: React.FC<IEnterpriseRecordingsReviewPageContainerProps> = (
  props
) => {
  const { id } = useParams<{id: string}>()

  const { data, loading } = useRecordingReviewAssignmentDetailQuery({
    variables: {
      assignmentId: id
    }
  })

  if (!data || loading) return <FullPageLoader />
  if (!data.assignment.voice) return null

  return (
    <GenericErrorBoundary>
      <EnterpriseRecordingsReviewPage
        data={{
          assignment: {
            id: data.assignment.id,
            started: data.assignment.started ? new Date(data.assignment.started) : null,
            due: data.assignment.due ? new Date(data.assignment.due) : null,
            totalRecordingsCount: data.assignment.totalRecordingsCount,
            reviewedRecordingsCount: data.assignment.reviewedRecordingsCount,
            contentLines: data.assignment.contentLines,
            acceptedCount: data.assignment.reviewedAcceptedRecordingsCount,
            rejectedCount: data.assignment.reviewedRejectedRecordingsCount,
            recordedErrorCount: data.assignment.totalRecordingsCount - data.assignment.totalRecordingsWithoutErrors
          },
          vocal: {
            name: data.assignment.voice.title
          },
          talent: {
            id: data.assignment.voice.talent!.id,
            name: `${data.assignment.voice.talent!.firstName} ${data.assignment.voice.talent!.lastName}`
          },
          completedRecordingsCount: data.assignment.totalRecordingsCount
        }}
      />
    </GenericErrorBoundary>
  );
};

export default EnterpriseRecordingsReviewPageContainer;
