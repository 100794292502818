import React from 'react';
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { useProjectsQuery } from '../../graphql/queries/Projects';
import FullPageLoader from "../shared/loader";
import GenericErrorBoundary from "../../components/molecules/generic-error-boundary";
import { useDeleteProjectMutation } from "../../graphql/generated";
import CreateProjectDialog from "../organisms/create-project-dialog";
import DeleteProjectDialog from "../../components/organisms/delete-project-dialog";
import Header from "../../components/atoms/header";
import { StatelessFeatureLock } from "../../utils/feature-locking";
import { OutlineButton, PrimaryButton, TextButton } from "../../components/atoms/button";
import { css } from "emotion";
import classNames from "classnames";
import Tile from "../../components/atoms/tile";
import ClipCounter from "../../components/atoms/clip-counter";
import PaginationControls from "../../components/atoms/pagination-controls";

export interface IEnterpriseProjectsPageContainerProps {
  className?: string;
}

type EnterpriseProjectsPageContainerProps = IEnterpriseProjectsPageContainerProps

const EnterpriseProjectsPageContainer: React.FC<EnterpriseProjectsPageContainerProps> = (props) => {
  const perPage = 10
  const [page, setPage] = React.useState(0)

  const projectsQuery = useProjectsQuery({ page, perPage })
  const history = useHistory()

  const [deleteProjectMutation] = useDeleteProjectMutation()

  const onDeleteProject = async (projectId: string) => {
    const response = await deleteProjectMutation({
      variables: {
        projectId
      }
    })

    if (!response.data?.deleteProject.deleted) throw Error("unexpected - could not delete project")

    projectsQuery.refetch()
  }

  const onCreatedProject = async (projectId: string) => {
    await projectsQuery.refetch()
    history.push(`/enterprise/project/${projectId}`)
  }


  const [creatingProject, setCreatingProject] = React.useState(false);
  const [deletingProject, setDeletingProject] = React.useState<string | null>(null);
  const { params } = useRouteMatch<{ target?: string }>()

  React.useEffect(
    () => {
      if (params.target === "new") {
        setCreatingProject(true)
      }
    }, [params]
  )

  if (!projectsQuery.data) return <FullPageLoader />

  const maxProjectsCount = projectsQuery.data.account.allRoleRestrictions.maxProjects

  /*
  <EnterpriseProjectsPage
        data={{
          projectsCount: projectsQuery.data.account.projectsCount,
          maxProjectCount: projectsQuery.data.account.allRoleRestrictions.maxProjects,
          projects: projectsQuery.data.account.projects.map(project => ({
            ...project,
            voice: project.defaultVoice.title,
            numClips: project.countOfClips
          })),
          numProjectPages: 0
        }}
        onDeleteProject={(id) => onDeleteProject(id)}
        onCreatedProject={onCreatedProject}
        SearchBarComponent={SearchBarComponent}
      />
   */

  return (
    <GenericErrorBoundary>
      <CreateProjectDialog
        isShown={creatingProject}
        onCancel={() => setCreatingProject(false)}
        onCreated={async (projectId) => {
          await onCreatedProject(projectId)
          setCreatingProject(false);
        }}
      />
      <DeleteProjectDialog
        isShown={deletingProject !== null}
        onCancel={() => setDeletingProject(null)}
        onDelete={async () => {
          await onDeleteProject(deletingProject!!);
          setDeletingProject(null);
        }}
      />
      <div className={props.className}>
        <Header
          rightArea={
            <StatelessFeatureLock
              lockedState={maxProjectsCount != null && projectsQuery.data.account.projectsCount >= maxProjectsCount ? {
                lockedReason: `Creating an additional project would exceed your max project limit of ${maxProjectsCount}`
              } : false}
            >
              <PrimaryButton
                onClick={() => setCreatingProject(true)}
              >
                New Project
              </PrimaryButton>
            </StatelessFeatureLock>
          }
        >
          <h1 className={'db vocalid-h1 ma0'}>All Projects</h1>
          <div className={css({ marginTop: 14 })}>
            <span>{projectsQuery.data.account.projectsCount} Projects</span>
          </div>
        </Header>
        <div className={'mh4 mb4 mt3'}>
          {projectsQuery.data.account.projectsCount !== 0 ?
            <table className={'w-100'} style={{ borderSpacing: 0 }}>
              <tbody>
              {projectsQuery.data.account.projects.results.map((project) => (
                <React.Fragment key={project.id}>
                  <ProjectMini project={{
                    ...project,
                    voice: project.defaultVoice.title,
                    numClips: project.countOfClips
                  }} onDelete={() => setDeletingProject(project.id)} />
                  <tr className={'h1'}></tr>
                </React.Fragment>
              ))}
              </tbody>
            </table> : <div className={classNames("flex justify-center items-start vocalid-secondary-text", css({
              paddingTop: '15%'
            }))}>
              <Tile heading={<div className={'vocalid-h2 mh3'}>No Projects Found</div>}>
                <div className={"flex justify-center"}>
                  <PrimaryButton size={"small"} onClick={() => setCreatingProject(true)}>New Project</PrimaryButton>
                </div>
              </Tile>
            </div>}
        </div>
        <div className={"flex justify-center"}>
          <PaginationControls numPages={Math.ceil(projectsQuery.data.account.projects.totalResults / perPage)} page={page + 1} onChangePage={newPage => setPage(newPage - 1)} />
        </div>
      </div>
    </GenericErrorBoundary>
  );
};

export default EnterpriseProjectsPageContainer;

interface IProjectMiniProps {
  project: {
    id: string;
    title: string;
    numClips: number;
    voice: string;
  };
  onDelete(): void;
}

const ProjectMini: React.FC<IProjectMiniProps> = (props) => {
  return (
    <tr className={classNames('bg-vocalid-tile')}>
      <td
        className={classNames(
          'pl3 pv3 w-50 v-top',
          css({
            borderTopLeftRadius: 6,
            borderBottomLeftRadius: 6
          })
        )}
      >
        <Link
          to={`/enterprise/project/${props.project.id}`}
          className={'no-underline white vocalid-tile-title mr2 mt2 mb3 db'}
        >
          {props.project.title}
        </Link>
      </td>
      <td
        className={classNames(
          'ph2 pv3 v-top',
          css({
            borderTopRightRadius: 6,
            borderBottomRightRadius: 6
          })
        )}
      >
        <table className={'tl w-100'} style={{ borderSpacing: 0 }}>
          <thead className={'vocalid-secondary-text ttu'}>
          <tr>
            <th className={'vocalid-body w3 pt2 pl3'}>Clips</th>
            <th className={'vocalid-body w5 pt2'}>Vocal</th>
            <th className={'vocalid-body w3 pt2'}></th>
          </tr>
          </thead>
          <tbody
            className={classNames(
              'vocalid-icon-active',
              css({
                'td, td *': {
                  verticalAlign: 'top'
                }
              })
            )}
          >
          <tr>
            <td className={'pv3'}>
              <ClipCounter count={props.project.numClips} size={'large'} />
            </td>
            <td className={'pv0'}>
              <div className={'f5 b mv0'}>{props.project.voice}</div>
              {/*<div className={'mt4 pt3 vocalid-body vocalid-secondary-text ttu'}>Modified</div>
                <div className={'b'}></div>*/}
            </td>
            <td>
              <div className={'mt2 tc'}>
                <Link
                  to={`/enterprise/project/${props.project.id}`}
                >
                  <OutlineButton>View Project</OutlineButton>
                </Link>
                <TextButton
                  notUppercase
                  onClick={() => props.onDelete()}
                  className={'mt3'}
                >
                  Delete Project
                </TextButton>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
};
