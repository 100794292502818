import React from "react";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { irBlack } from "react-syntax-highlighter/dist/cjs/styles/hljs";
import xml from "react-syntax-highlighter/dist/cjs/languages/hljs/xml";
import format from "xml-formatter";

SyntaxHighlighter.registerLanguage('xml', xml);

export interface ISSMLEditorProps {
  ssml: string | null;
}

const SSMLEditor: React.FC<ISSMLEditorProps> = props => {
  const prettifiedSSML = React.useMemo(() => props.ssml && format(props.ssml), [props.ssml])

  return (
    <SyntaxHighlighter language="xml" style={irBlack} wrapLines codeTagProps={{
      style: {
        fontFamily: "'Roboto Mono', monospace"
      }
    }} customStyle={{
      backgroundColor: 'transparent',
      width: '100%',
      overflowX: 'unset'
    }}>
      {prettifiedSSML}
    </SyntaxHighlighter>
  );
};

export default SSMLEditor
