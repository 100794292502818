import React from 'react';
import Dialog from '../../molecules/dialog';
import { PrimaryButton, TextButton } from "../../atoms/button";
import { CircularProgress } from '@material-ui/core';
import { useDropzone } from "react-dropzone";
import classNames from "classnames";
import { css } from "emotion";
import parse from "csv-parse";
import { useTheme } from "../../../theme";

export interface IUploadClipSourcesDialogProps {
  isShown: boolean;
  onDismiss(): void;
  onCreate(clips: Array<{ title: string; text: string; }>): Promise<void>;
  className?: string;
}

const UploadClipSourcesDialog: React.FC<IUploadClipSourcesDialogProps> = (props) => {
  const { colors: themeColors } = useTheme();
  const [clipsToAdd, setClipsToAdd] = React.useState<Array<{ title: string; text: string; }>>([]);
  const [creating, setCreating] = React.useState(false)

  const [acceptedFiles, setAcceptedFiles] = React.useState<File[]>([])

  const clearState = () => {
    setAcceptedFiles([]);
    setClipsToAdd([]);
  };

  const onDrop = React.useCallback(newAcceptedFiles => {
    setAcceptedFiles(o => [...o, ...newAcceptedFiles])
  }, [])
  const {getRootProps, getInputProps} = useDropzone({ accept: ["text/csv", "text/plain"], multiple: false, onDrop });
  const acceptedFile: File | undefined = acceptedFiles[0];

  const onCreate = async () => {
    await props.onCreate(clipsToAdd!)
  }

   React.useEffect(() => {
     if (acceptedFile) {
       clipsFromFile(acceptedFile).then(clips => {
         setClipsToAdd(clips);
       });
     } else {
       setClipsToAdd([]);
     }
  }, [acceptedFile]);

  const selectedFile = acceptedFile ? (
    <div>
      <h4 className={"tc mb4"}>{clipsToAdd.length} Clips to add from {acceptedFile.name}</h4>
{/*      <ul>
        {clips.map((line, lineI) => (
          <li key={lineI}>{line}</li>
        ))}
      </ul>*/}
    </div>
  ) : undefined;

  return (
    <Dialog
      isShown={props.isShown}
      heading={<div className={'mr5'}>Upload Clip Content</div>}
      className={props.className}
    >
      {/*<div className={'vocalid-h3 mb2'}>Upload CSV:</div>*/}
      <div className={"mb3"}>
        <div {...getRootProps({className: classNames("br2 ba pa3 pointer", css({ borderColor: 'grey', outline: 'none' }), acceptedFile && css({
            backgroundColor: themeColors.tile,
            borderColor: themeColors.tile,
            color: themeColors.primaryText,
            fontWeight: 'bold'
          }))})}>
          <input {...getInputProps()} />
          <p>{!acceptedFile ? "Drag & drop your CSV/text file here, or click to select it" : `Selected: ${acceptedFile.name} (${acceptedFile.type})` }</p>
        </div>
        { selectedFile && <aside>
          {selectedFile}
        </aside> }
      </div>
      <div className={'flex justify-end'}>
        <TextButton
          dark
          onClick={() => {
            props.onDismiss();
            clearState();
          }}
          disabled={creating}
          className={'mr3'}
        >
          CANCEL
        </TextButton>
        <PrimaryButton
          disabled={clipsToAdd.length === 0}
          onClick={async () => {
            setCreating(true);
            await onCreate();
            setCreating(false);
            props.onDismiss();
            clearState();
          }}
        >
          {creating ? <CircularProgress size={18} color={"inherit"} /> : <span>CREATE</span>}
        </PrimaryButton>
      </div>
    </Dialog>
  );
};

export default UploadClipSourcesDialog;

function clipsFromFile(file: File): Promise<Array<{ title: string; text: string }>> {
  const defaultTitle = (num: number) => `${file.name} (${num})`

  const isOnlyWhitespace = (str: string) => str.replace(/\s/g, '').length === 0;

  return file.text().then(text => {
    if (file.type === "text/plain") {
      return text.split("\n").filter(line => !isOnlyWhitespace(line)).map((clipText, index) => ({
        title: defaultTitle(index + 1),
        text: clipText
      }));
    } else if (file.type === "text/csv") {
      return new Promise((resolve, reject) => {
        parse(text, {
          delimiter: ',',
          comment: '#' // TODO: make customizable?
        }, (err, output) => {
          if (err) {
            reject(err);
          } else {
            resolve((output as Array<Array<string>>)
              .reduce((acc, curr, index) => {
                if (curr.length === 1) {
                  // one column
                  return [...acc, {
                    title: defaultTitle(index + 1),
                    text: curr[0]
                  }];
                } else if (curr.length >= 2) {
                  // two columns ([title, text])
                  return [...acc, {
                    title: curr[0],
                    text: curr[1]
                  }];
                } else {
                  return []
                }
              }, [] as Array<{ title: string; text: string; }>))
          }
        })
      });
    } else {
      return [];
    }
  });
}
