import React from "react";
import classNames from "classnames";
import LogRocket from "logrocket";
import { encodeQueryParams, StringParam, useQueryParams } from "use-query-params";
import { useAccountPricingPlanQuery } from "../../../graphql/generated";
import { useSavedState } from "../../../utils/hooks/saved-state";
import queryString from "querystring";
import { useHistory } from "react-router-dom";
import { CreateUserAccountPageQueryParams } from "../registration/user-account-creation";
import { PricingPlansList } from "../../organisms/pricing-plan-selection/pricing-plans-list";

export const PricingPlansPageQueryParams = {
  flowCancellationReason: StringParam,
  origin: StringParam,
  mailing_list_addr: StringParam
} as const

export interface PublicPricingPlansPageProps {
  className?: string;
}

const PublicPricingPlansPage: React.FC<PublicPricingPlansPageProps> = props => {
  const history = useHistory();
  const [{
    flowCancellationReason,
    origin,
    mailing_list_addr
  }] = useQueryParams(PricingPlansPageQueryParams);

  React.useEffect(() => {
    LogRocket.track('PricingPlanOptionsShown', {
      ...(origin ? {
        origin
      } : {}),
      ...(mailing_list_addr ? {
        mailingListAddr: mailing_list_addr
      }: {}),
      ...(flowCancellationReason ? {
        flowCancellationReason
      } : {})
    });
  }, []);

  return (
    <div className={classNames('relative', props.className)}>
      <div className={'pa4'}>
        <div className={'flex flex-column items-center mb4'}>
          <h2 className={"vocalid-h2 vocalid-primary-text tc mh3"}>
            Select Your <img
              src={'/logo.png'}
              alt={'Parrot Studio'}
              height={22}
              className={'mh1'}
            /> Experience
          </h2>
          <PricingPlansList onPlanSelect={(id, trial) => {
              history.push(`/create-account?${queryString.stringify(encodeQueryParams(CreateUserAccountPageQueryParams, {
                plan: id,
                trial,
                origin,
                mailing_list_addr
              }) as any)}`)
          }} />
        </div>
      </div>
    </div>
  );
};

export default PublicPricingPlansPage;
