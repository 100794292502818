import React from 'react';
import Input from '../../../atoms/input';
import { PrimaryButton } from '../../../atoms/button';
import Separator from '../../../atoms/separator';
import { css } from 'emotion';
import classNames from 'classnames';
import { CircularProgress } from '@material-ui/core';
import Icon, { EIconName } from "../../../atoms/icon";
import _ from "lodash";
import AlternatePronunciationInput from "../../alternate-pronunciation-input";
import {
  isEmptyAlternatePronunciation,
  ISlateAlternatePronunciation, isPhoneticAlternatePronunciation,
  isTextAlternatePronunciation
} from "../../clip/editor/adjustments/internal-types";

export interface IProjectSubstitutionDictionaryData {
  dictionaryMembers: Array<{
    id: string;
    oldPronunciation: string;
    newPronunciation: ISlateAlternatePronunciation;
    global: boolean;
  }>;
}

export interface ISubstitutionDictionarySidebarMenuProps {
  data: IProjectSubstitutionDictionaryData;
  addSubstitutionDictionaryMember(oldPronunciation: string, newPronunciation: ISlateAlternatePronunciation): Promise<void>;
  setSubstitutionDictionaryMemberAsGlobal(id: string, global: boolean): Promise<void>;
  removeSubstitutionDictionaryMember(id: string): Promise<void>;
  className?: string;
}

const SubstitutionDictionarySidebarMenu: React.FC<ISubstitutionDictionarySidebarMenuProps> = (
  props
) => {
  const [oldPronunciation, setOldPronunciation] = React.useState('');
  const [newPronunciation, setNewPronunciation] = React.useState<ISlateAlternatePronunciation>({ text: "" });

  const [saving, setSaving] = React.useState(false)
  const [applyingListChange, setApplyingListChange] = React.useState(false)

  const save = async () => {
    if (oldPronunciation && newPronunciation) {
      setSaving(true)
      await props.addSubstitutionDictionaryMember(_.trim(oldPronunciation), isTextAlternatePronunciation(newPronunciation) ? { text: _.trim(newPronunciation.text) } : newPronunciation)
      setOldPronunciation('')
      setNewPronunciation({ text: "" })
      setSaving(false)
    }
  }

  return (
    <div className={props.className}>
      <div>
        <div className={'flex mb4'}>
          <div className={'w-40 pr2'}>
            <div className={'vocalid-dictionary-header vocalid-secondary-text tc mb3'}>Replace</div>
            <div>
              <Input
                value={oldPronunciation}
                onChange={(newValue) => setOldPronunciation(newValue ?? '')}
                placeholder={'Old Pronunciation'}
              />
            </div>
          </div>
          <div className={'w-60 pl2'}>
            <div className={'vocalid-dictionary-header vocalid-secondary-text tc mb3'}>With</div>
            <div>
              <AlternatePronunciationInput
                value={newPronunciation}
                onChange={(newValue) => {
                  setNewPronunciation(newValue ?? { text: "" });
                }}
              />
            </div>
          </div>
        </div>
        <div className={'flex justify-end'}>
          <PrimaryButton disabled={!oldPronunciation || isEmptyAlternatePronunciation(newPronunciation)} size={"small"} onClick={() => save()}>
            { saving ? <CircularProgress/> : <>Save</> }
          </PrimaryButton>
        </div>
      </div>
      { props.data.dictionaryMembers.length !== 0 ? ( <>
        <Separator className={'mv4'} />
        <div className={'vocalid-h3 vocalid-secondary-text tc mb3'}>Saved Substitutions</div>
        { !applyingListChange ? <table
          className={classNames(
            'w-100',
            css({
              borderSpacing: 0
            })
          )}
        >
          <thead>
            <tr>
              <td className={'vocalid-dictionary-header ph2 pv3 tc b w-40'}>Replace</td>
              <td className={'vocalid-dictionary-header ph2 pv3 tc b w-40'}>With</td>
              <td className={'ph2 pv3 tc b w-40'}></td>
            </tr>
          </thead>
          <tbody>
            {props.data.dictionaryMembers.map((substitution, i) => (
              <tr
                key={i}
                className={classNames(
                  'bg-vocalid-tile vocalid-primary-text',
                  css({
                    '&:nth-child(odd)': {
                      backgroundColor: '#27444f'
                    }
                  })
                )}
              >
                <td className={'pa2'}>{substitution.oldPronunciation}</td>
                <td className={'pa2 bl b--vocalid-clip-inactive'}>{isPhoneticAlternatePronunciation(substitution.newPronunciation) ? substitution.newPronunciation.phonetic.reduce((acc, curr) => acc + curr, "") : substitution.newPronunciation.text}</td>
                <td className={'pa2 bl tc b--vocalid-clip-inactive'}>
                  <Icon
                    name={EIconName.Delete}
                    onClick={async () => {
                      setApplyingListChange(true)
                      await props.removeSubstitutionDictionaryMember(substitution.id);
                      setTimeout(() => setApplyingListChange(false), 150)
                    }}
                  />
                  <Icon
                    name={EIconName.Star}
                    active={substitution.global}
                    onClick={async () => {
                      setApplyingListChange(true)
                      await props.setSubstitutionDictionaryMemberAsGlobal(substitution.id, !substitution.global);
                      setTimeout(() => setApplyingListChange(false), 150)
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table> : <div className={"pt4 tc"}><CircularProgress color={"inherit"} /></div>  }
        </>
      ) : null}
    </div>
  );
};

export default SubstitutionDictionarySidebarMenu;
