import React, { HTMLProps } from "react";
import classNames from "classnames";
import { css } from "emotion";
import Icon, { EIconName } from "../../../../../atoms/icon";
import NumberStepper from "../../../../../atoms/number-stepper";
import AudioPlayer from "../../../../../molecules/audio-player";
import { IPlaylistRenderedTakeItemData, PlaylistItemData, PlaylistMixContents } from "../index";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { isEqual } from "lodash";

export interface IPlaylistItemListProps {
  contents: PlaylistMixContents;
  onChange(contents: PlaylistMixContents): void;
}

export const PlaylistItemList: React.FC<IPlaylistItemListProps> = props => {
  const [items, _setItems] = React.useState<Array<PlaylistItemData>>(props.contents.items);
  React.useEffect(() => {
    if (!isEqual(props.contents.items, items)) {
      _setItems(props.contents.items);
    }
  }, [props.contents.items])

  const setItems = (newItems: Array<PlaylistItemData>) => {
    _setItems(newItems);
    props.onChange({
      ...props.contents,
      items: newItems
    });
  }

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(items, result.source.index, result.destination!.index);
    setItems(newItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={"sidebar-playlist"}>
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.draggableProps}>
                    <div className={"pv2"}>
                      {item.type === "take" ? (
                        <PlaylistTakeItem
                          take={item}
                          grabberProps={provided.dragHandleProps}
                          /*playDisabled={autoplay.autoplaying}*/
                          onRemove={() => {
                            setItems(items.filter(it => it.id !== item.id));
                          }} />
                      ) : (
                        <PlaylistPauseItem
                          durationMs={item.duration}
                          grabberProps={provided.dragHandleProps}
                          onChangeDuration={newPauseMs => {
                            setItems(items.map(i => i.id === item.id ? {
                              ...i,
                              duration: newPauseMs
                            } : i));
                          }}
                          onRemove={() => {
                            setItems(items.filter(it => it.id !== item.id));
                          }}
                        />
                      )}
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            { provided.placeholder }
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export interface IPlaylistPauseItemProps {
  durationMs: number;
  grabberProps?: HTMLProps<HTMLDivElement>;
  className?: string;
  onChangeDuration(newPauseMs: number): void;
  onRemove(): void;
}

export const PlaylistPauseItem: React.FC<IPlaylistPauseItemProps> = (props) => {
  return (
    <div
      className={classNames(
        'flex ph2 pv3 ba b--vocalid-tertiary-text bg-vocalid-header',
        css({ borderRadius: 4, borderWidth: 1 }),
        props.className
      )}
    >
      <div {...props.grabberProps}>
        <Icon
          name={EIconName.GrabDots}
          color={'tertiaryText'}
          size={18}
          className={classNames('dib o-60', css({ marginTop: 2 }))}
        />
      </div>
      <div className={'flex-grow-1 ml2'}>
        <div className={'flex justify-between'}>
          <div className={'flex items-start flex-wrap'}>
            <h3 className={'dib vocalid-h3 vocalid-tertiary-text mv0 mr4'}>Pause</h3>
            <NumberStepper
              value={props.durationMs/1000}
              step={0.05}
              fixed={2}
              suffix={'s'}
              inputEditable
              onChange={newValue => props.onChangeDuration(parseInt("" + newValue*1000))}
              className={css({ width: 120 })}
            />
          </div>
          <div>
            <Icon name={EIconName.X} className={classNames('mr1', css({
              position: 'relative',
              top: -1
            }))} onClick={props.onRemove} />
          </div>
        </div>
      </div>
    </div>
  );
};



export interface IPlaylistTakeItemProps {
  take: IPlaylistRenderedTakeItemData;
  grabberProps?: HTMLProps<HTMLDivElement>;
  className?: string;

  playDisabled?: boolean;
  onRemove(): void;
}

export const PlaylistTakeItem: React.FC<IPlaylistTakeItemProps> = (props) => {
  return (
    <div
      className={classNames(
        'flex bg-vocalid-tile ph2 pv3',
        css({ borderRadius: 4 }),
        props.className
      )}
    >
      <div {...props.grabberProps}>
        <Icon
          name={EIconName.GrabDots}
          color={'clipActive'}
          size={18}
          className={classNames('dib o-70', css({ marginTop: 2 }))}
        />
      </div>
      <div className={'flex-grow-1 ml2'}>
        <div className={'flex justify-between'}>
          <div>
            <h3 className={'dib vocalid-h3 vocalid-secondary-text mv0'}>{props.take.clipName} ({props.take.num})</h3>
          </div>
          <div>
            <Icon name={EIconName.X} onClick={props.onRemove} className={'mr1'} />
          </div>
        </div>
        <div className={"vocalid-body vocalid-tertiary-text mv0"}>{props.take.talentRecording ? <Icon name={EIconName.Star} className={classNames("relative v-mid mr1", css({ top: -3, marginBottom: -5 }))} /> : ""}{props.take.voiceName}</div>
        <div className={'mt2 pr4'}>
          <AudioPlayer
            src={props.take.audioUrl}
            disabled={props.playDisabled}
            className={'w-100'}
          />
        </div>
      </div>
    </div>
  );
};
