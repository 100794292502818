import { ESlateElementType, ISlateSelection, ISlateValue, ISlateWordNode } from "./internal-types";
import { Range } from "slate";

export function singleWordInSelection(content: ISlateValue, selection: NonNullable<ISlateSelection>): [ISlateWordNode, number] | null {
  const nodesInSelection = selectNodesForSelection(content, selection);
  // console.log("Nodes in selection: " + nodesInSelection.selection.length);

  if (nodesInSelection.selection.length !== 1) return null;
  const selectedNode = nodesInSelection.selection[0];
  // console.log(selectedNode);

  const [{ path: [_, startNodeIndex], offset: startOffset }] = Range.edges(selection);
  // console.log(`startNodeIndex: ${startNodeIndex}, startOffset: ${startOffset}`)

  if (selectedNode.type === ESlateElementType.Word) {
    return [selectedNode, startNodeIndex]
  } else if (selectedNode.type === ESlateElementType.Space) {
    // we need the word directly before the space, if it exists
    const nodeBeforeSelection = nodesInSelection.beforeSelection[nodesInSelection.beforeSelection.length - 1];

    if (nodeBeforeSelection?.type === ESlateElementType.Word) {
      return [nodeBeforeSelection, startNodeIndex - 1];
    }
  }

  return null;
}

export function selectNodesForSelection(nodes: ISlateValue, range: NonNullable<ISlateSelection>) {
  const [{ path: [_, startNodeIndex] }, { path: [__, endNodeIndex] }] = Range.edges(range);

  return {
    beforeSelection: nodes.slice(0, startNodeIndex),
    selection: nodes.slice(startNodeIndex, endNodeIndex + 1),
    afterSelection: nodes.slice(endNodeIndex + 1)
  }
}
