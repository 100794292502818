import React from "react";
import Input, { IInputProps } from "../../atoms/input";
import PhoneticKeyboard from "../../molecules/phonetic-keyboard";
import { useCombinedRefs } from "../../../utils/hooks/refs";

export interface PhoneticInputProps extends Omit<IInputProps, "type" | "value" | "mask" | "maskChar" | "onChange" | "placeholder"> {
  value: string[];
  placeholder?: string[];
  onChange?(newValue: string[]): void;
}

const PhoneticInput = React.forwardRef<HTMLInputElement, PhoneticInputProps>((props, ref) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const inputRef = useCombinedRefs<HTMLInputElement>(ref);
  const [phoneticKeyboardVisible, setPhoneticKeyboardVisible] = React.useState(false);
  useClickOutside(wrapperRef, () => {
    setPhoneticKeyboardVisible(false);
  });

  return (
    <div ref={wrapperRef}>
      <PhoneticKeyboard
        visible={phoneticKeyboardVisible && !props.disabled}
        lastKey={props.value[props.value.length - 1]}
        onPressKey={key => {
          props.onChange?.([...props.value, key]);
        }}
        onModifyLastKey={modifiedKey => {
          props.onChange?.([...props.value.slice(0, props.value.length - 1), modifiedKey]);
        }}
        onClickInside={() => {
          inputRef.current?.focus();
        }}
      >
        <Input
          {...props}
          ref={inputRef}
          value={props.value.reduce((acc, curr) => acc + curr, "")}
          placeholder={props.placeholder?.reduce((acc, curr) => acc + curr, "")}
          onFocus={() => {
            setPhoneticKeyboardVisible(true);
            props.onFocus?.();
          }}
          onKeyDown={key => {
            if (key === "Backspace") {
              props.onChange?.([...props.value.slice(0, props.value.length - 1)]);
            } else if (key === "Escape") {
              setPhoneticKeyboardVisible(false);
              inputRef.current?.blur();
            }
          }}
          onKeyPress={key => {
            props.onChange?.([...props.value, key]);
          }}
          onChange={e => {}}
          // onBlur={() => {
          //   setPhoneticKeyboardVisible(false);
          //   props.onBlur?.();
          // }}
        />
      </PhoneticKeyboard>
    </div>
  );
});

export default PhoneticInput;


/**
 * Hook that alerts clicks outside of the passed ref
 * source: https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 */
function useClickOutside(ref: React.RefObject<HTMLElement>, listener: () => void) {
  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        listener();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
