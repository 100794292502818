import React from 'react';
import Dialog from '../../molecules/dialog';
import { OutlineButton, PrimaryButton } from "../../atoms/button";

export interface IDeleteClipDialogProps {
  isShown: boolean;
  onCancel(): void;
  onDelete(): void;
  className?: string;
}

const DeleteClipDialog: React.FC<IDeleteClipDialogProps> = (props) => {
  return (
    <Dialog
      isShown={props.isShown}
      heading={'Are You Sure you want to delete this Clip?'}
      className={props.className}
    >
      <div className={'vocalid-h3 mb4'}>
        This action cannot be undone.
      </div>
      <div className={'flex justify-end'}>
        <OutlineButton
          dark
          size={'medium'}
          onClick={() => props.onDelete()}
          className={'mr4'}
        >
          CONTINUE
        </OutlineButton>
        <PrimaryButton onClick={() => props.onCancel()}>
          CANCEL
        </PrimaryButton>
      </div>
    </Dialog>
  );
};

export default DeleteClipDialog;
