import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'tachyons';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ThemeProvider from './theme';
import LogRocket from 'logrocket';

import GenericErrorBoundary from "./components/molecules/generic-error-boundary";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./store";

if (process.env.NODE_ENV !== 'development') {
  LogRocket.init('f9e1sy/parrot-enterprise-and-talent');
}

ReactDOM.render(
  <ReduxProvider store={store}>
    <ThemeProvider>
      <GenericErrorBoundary>
        <App />
      </GenericErrorBoundary>
    </ThemeProvider>
  </ReduxProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
