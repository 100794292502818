import React from 'react';
import Dialog from '../../molecules/dialog';
import {OutlineButton, PrimaryButton} from "../../atoms/button";

export interface IResetPasswordDialogProps {
  isShown: boolean;
  email: string;
  onCancel(): void;
  onReset(): void;
  className?: string;
}

const ResetPasswordDialog: React.FC<IResetPasswordDialogProps> = (props) => {
  return (
    <Dialog
      isShown={props.isShown}
      heading={'Reset User Password'}
      className={props.className}
    >
      <div className={'vocalid-h3 mb4'}>
        We will send a password reset link to the email {props.email}.
      </div>
      <div className={'flex justify-end'}>
        <OutlineButton
          dark
          size={'medium'}
          onClick={() => props.onReset()}
          className={'mr4'}
        >
          CONTINUE
        </OutlineButton>
        <PrimaryButton onClick={() => props.onCancel()}>
          CANCEL
        </PrimaryButton>
      </div>
    </Dialog>
  );
};

export default ResetPasswordDialog;
