import * as React from "react";
import ReconnectingWebSocket from "reconnecting-websocket";

/*const TAKE_RENDERED_SUBSCRIPTION = gql`
  subscription TakeRenderedSubscription($id: String!) {
    takeRendered(id: $id) {
      ... on TakeRenderingSucceeded {
        renderedTake {
          url
        }
      }
      ... on TakeRenderingFailed {
        error
      }
    }
  }
`


export const useTakeRenderedListener = (takeId: string, skip: boolean = false) => {
  const { data } = useSubscription<TakeRenderedSubscription, TakeRenderedSubscriptionVariables>(TAKE_RENDERED_SUBSCRIPTION, {
    variables: {
      id: takeId
    },
    skip: skip
  })

  return {
    url: data?.takeRendered.__typename === "TakeRenderingSucceeded" ? data.takeRendered.renderedTake.url : null,
    error: data?.takeRendered.__typename === "TakeRenderingFailed" ? data.takeRendered.error : null
  }
}*/

export const useTakeRenderedListener = (takeId: string, skip: boolean = false) => {
  const rwsRef = React.useRef<ReconnectingWebSocket>()
  const [url, setUrl] = React.useState<string | null>(null)
  const [error, setError] = React.useState<string | null>(null)

  React.useEffect(() => {
    if (!skip) {
      if (rwsRef.current) {
        rwsRef.current.close()
      }

      const rws = new ReconnectingWebSocket(`${process.env.REACT_APP_WS_BASE_URL}/render/take/${takeId}`);
      rwsRef.current = rws

      rws.addEventListener('message', (message) => {
        const json = JSON.parse(message.data)

        if (json.url) {
          setUrl(json.url)
          setError(null)
        }
        if (json.error) {
          setError(json.error)
          setUrl(null)
        }
      })
    }

    return () => {
      rwsRef.current?.close()
    }
  }, [skip])

  return {
    url,
    error
  }
}
