import React from 'react';
import Icon, { EIconName } from '../../atoms/icon';
import classNames from 'classnames';
import { css } from 'emotion';
import { useTheme } from "../../../theme";

export interface IBannerProps {
  contentClassName?: string;
  color?: string;
  onClose?(): void;
}

const Banner: React.FC<IBannerProps> = (props) => {
  const { colors: themeColors } = useTheme();

  return (
    <div className={classNames('pv2 vocalid-body vocalid-dark-text relative', css({
      backgroundColor: props.color ?? themeColors.banner
    }))}>
      <div className={classNames('ph3 tc', props.contentClassName)}>{props.children}</div>
      {props.onClose && (
        <div
          className={classNames(
            'flex items-center',
            css({
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 30
            })
          )}
        >
          <Icon
            name={EIconName.X}
            color={'darkText'}
            activeColor={'darkText'}
            onClick={props.onClose}
            className={'mr3'}
          />
        </div>
      )}
    </div>
  );
};

export default Banner;
