import React from "react";

export function useCombinedRefs<T>(...refs: Array<React.Ref<T>>): React.RefObject<T> {
  const targetRef = React.useRef<T>()

  React.useEffect(() => {
    refs.forEach(ref => {
      if (!ref) return

      if (typeof ref === 'function') {
        (ref as any)(targetRef.current as any)
      } else {
        // @ts-ignore
        (ref as any).current = targetRef.current
      }
    })
  }, [refs])

  return targetRef as any
}
