import React from 'react';
import { useVoicesQuery } from '../../graphql/queries/Voices';
import GenericErrorBoundary from "../../components/molecules/generic-error-boundary";
import Header from "../../components/atoms/header";
import { css } from "emotion";
import classNames from "classnames";
import { useTheme } from "../../theme";
import Icon, { EIconName } from "../../components/atoms/icon";
import { copyTextToClipboard } from "../../utils/clipboard";
import InfoTooltip from "../../components/molecules/info-tooltip";
import { API_DOCS_URL } from "../../graphql";
import ClipCounter from "../../components/atoms/clip-counter";
import FullPageLoader from "../shared/loader";
import { EnterpriseVoiceFragment, EnterpriseVoicesTalentFragment } from "../../graphql/generated";
import { labelForTalentGender, labelForTalentJobCategory } from "../shared/enums";
import PaginationControls from "../../components/atoms/pagination-controls";

export interface IEnterpriseVocalsPageContainerProps { }

const EnterpriseVocalsPageContainer: React.FC<IEnterpriseVocalsPageContainerProps> = (props) => {
  const perPage = 10
  const [page, setPage] = React.useState(0);
  const { data, loading } = useVoicesQuery({ page, perPage })

  if (!data || loading) return <FullPageLoader />

  const vocals = data.account.allVoiceEntitlements;

  return (
    <GenericErrorBoundary>
      <div>
        <Header>
          <h1 className={'db vocalid-h1 ma0'}>All VoiceDubbs</h1>
          <div className={css({ marginTop: 14 })}>
            <span>{vocals.totalResults} VoiceDubbs</span>
            {/*<span className={'mh3'}>|</span>*/}
            {/*<span>{props.data.numClips} Clips</span>*/}
          </div>
        </Header>
        <div className={'mh4 mb4 mt3'}>
          <table className={classNames('w-100', css({
            'td, td *': {
              verticalAlign: 'top'
            }
          }))} style={{ borderSpacing: 0 }}>
            <tbody>
            {vocals.results.map((vocal) => (
              <React.Fragment key={vocal.id}>
                <VocalMini vocal={vocal} talent={vocal.talent ?? null} apiAccessEnabled={data.account.allRoleRestrictions.synthesisApiAccess} />
                <tr className={'h1'}></tr>
              </React.Fragment>
            ))}
            </tbody>
          </table>
        </div>
        <div className={"flex justify-center"}>
          <PaginationControls numPages={Math.ceil(vocals.totalResults / perPage)} page={page + 1} onChangePage={newPage => setPage(newPage - 1)} />
        </div>
      </div>
    </GenericErrorBoundary>
  );
};

export default EnterpriseVocalsPageContainer;

export interface IVocalMiniProps {
  vocal: EnterpriseVoiceFragment;
  talent: EnterpriseVoicesTalentFragment | null;
  apiAccessEnabled: boolean;
  className?: string;
}

const VocalMini: React.FC<IVocalMiniProps> = ({ vocal, talent, ...props }) => {
  const [idCopiedToClipboard, setIdCopiedToClipboard] = React.useState(false);
  const { colors: themeColors } = useTheme();

  return (
    <>
      <tr className={classNames('bg-vocalid-tile', props.className)}>
        <td
          className={classNames(
            'pl3 pv3 w-50 v-top',
            css({
              borderTopLeftRadius: 6,
              borderBottomLeftRadius: 6
            })
          )}
        >
          <h2 className={'no-underline white vocalid-tile-title mr2 mt2 mb3 db'}>
            {vocal.title}
          </h2>
          <div className={"vocalid-secondary-text mb3"}>
            {[vocal.age, (vocal.ethnicIdentity ? vocal.ethnicIdentity + " " : "") + (vocal.gender ? labelForTalentGender(vocal.gender) : "")].filter(e => !!e).join(", ")}
          </div>
          { props.apiAccessEnabled && <h3 className={'vocalid-body vocalid-secondary-text flex items-center mb2 db'}>
            <span>{vocal.id}</span>
            <Icon name={EIconName.Copy} active={idCopiedToClipboard} color={themeColors.iconInactive} hoverColor={themeColors.iconActive} activeColor={themeColors.primaryButton} onClick={() => {
              copyTextToClipboard(vocal.id);
              setIdCopiedToClipboard(true);
            }} className={"flex-shrink-0 mh2"} size={18} />
            <InfoTooltip interactive className={"mt1"} tooltipContent={<>This VoiceDubb ID can be used when accessing the <a href={API_DOCS_URL} target={"_blank"} rel="noopener noreferrer" className={"no-underline vocalid-blue"}>Parrot API</a></>} />
          </h3> }
        </td>
        <td
          className={classNames(
            'ph2 pv3 v-top',
            css({
              borderTopRightRadius: 6,
              borderBottomRightRadius: 6
            })
          )}
        >
          <table className={'tl w-100'} style={{ borderSpacing: 0 }}>
            <thead className={'vocalid-tertiary-text ttu'}>
            <tr>
              <th className={'w-30 ph2 tc'}>Clips</th>
              <th className={'w-10 pr3'}>{ vocal.talent && "Talent" }</th>
              <th className={'w-30 pr3'}>Desired Project Categories</th>
              <th className={'w-30 pr3'}>Limitations</th>
            </tr>
            </thead>
            <tbody
              className={classNames(
                'vocalid-icon-active',
                css({
                  'td, td *': {
                    verticalAlign: 'top'
                  }
                })
              )}
            >
            <tr>
              <td className={'w-30 pv2'}>
                <div className={"flex justify-center"}>
                  <ClipCounter count={vocal.countOfClips} size={'large'} />
                </div>
              </td>
              <td className={'w-10 f5 b tl pr3 lh-title'}>{ talent && `${talent.firstName} ${talent.lastName}` } </td>
              <td className={"w-30 f5 b tl pr3 lh-title"}>{vocal.projectTypeWhitelist?.map(t => t.title).join(", ") ?? "None"}</td>
              <td className={"w-30 f5 b tl pr3 lh-title"}>{vocal.brandConflicts || vocal.limitations ? [vocal.brandConflicts && `Brand Conflicts: ${vocal.brandConflicts.join(", ")}`, vocal.limitations].filter(e => !!e).join(". ") : "None"}</td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </>
  );
};
