import React from 'react';
import Select, { ISelectOption, ISelectProps } from "../../../components/molecules/select";
import { PrimaryButton, TextButton, OutlineButton } from '../../../components/atoms/button';
import { useEnterpriseContextualState } from '../../../components/templates/enterprise';
import { css, keyframes } from 'emotion';
import classNames from 'classnames';
import {
  Gender,
  useAcceptTalentUsageMutation,
  useImplicitVoiceSelectionQuery,
  useVoiceSelectBrowseQuery,
  useVoiceSelectPreviewingQuery,
  useVoiceSelectProjectTypeQuery, useVoiceSelectProjectVoicesQuery,
  useVoiceSelectSelectedQuery,
  VoicePreviewDetailFragment
} from "../../../graphql/generated";
import Modal from '../../../components/atoms/modal';
import { useTheme } from '../../../theme';
import SearchBar from '../../../components/molecules/search-bar';
import { Loader } from '../../shared/loader';
import { labelForTalentGender } from '../../shared/enums';
import Tooltip from '../../../components/atoms/tooltip';
import Tag from '../../../components/atoms/tag';
import PaginationControls from '../../../components/atoms/pagination-controls';
import ProjectTypeTag from '../../../components/molecules/project-type-tag';
import Icon, { EIconName } from '../../../components/atoms/icon';
import NumberStepper from '../../../components/atoms/number-stepper';
import Slider, { RangeSlider } from "../../../components/atoms/slider";
import { isEqual, uniqBy } from "lodash";

export interface VoiceSelectProps {
  selectedVocalId?: string | null;
  selectedProjectTypeId?: string | null;
  projectId?: string | null;
  label?: ISelectProps['label'];
  color?: ISelectProps['color'];
  onSelect(vocalId: string): void;
  className?: string;
}

const VoiceSelect: React.FC<VoiceSelectProps> = ({
  selectedVocalId: passedVocalId,
  selectedProjectTypeId,
  projectId,
  onSelect: passedOnSelect,
  ...props
}) => {
  const {
    state: { selectedAccount }
  } = useEnterpriseContextualState();

  const implicitVoiceSelectionQuery = useImplicitVoiceSelectionQuery({
    variables: { account: selectedAccount! }, fetchPolicy: "no-cache"
  });

  const implicitVocalId = implicitVoiceSelectionQuery?.data?.account?.allVoiceEntitlements?.results[0]?.id
  const selectedVocalId = passedVocalId ?? implicitVocalId;

  React.useEffect(() => {
    if (passedVocalId === null && implicitVocalId) {
      passedOnSelect(implicitVocalId)
    }
  }, [implicitVocalId])

  const onSelect = (vocalId: string) => {
    passedOnSelect(vocalId);
  };

  if (!selectedVocalId) return null;

  return (
    <ControlledVoiceSelect
      selectedVocalId={selectedVocalId}
      selectedProjectTypeId={selectedProjectTypeId}
      projectId={projectId}
      account={selectedAccount!}
      onSelect={onSelect}
      {...props}
    />
  );
};

export default VoiceSelect;

interface ControlledVoiceSelectProps {
  selectedVocalId: string;
  selectedProjectTypeId?: string | null;
  projectId?: string | null;
  account: string;
  label?: ISelectProps['label'];
  color?: ISelectProps['color'];
  onSelect(vocalId: string): void;
  className?: string;
}

const ControlledVoiceSelect: React.FC<ControlledVoiceSelectProps> = ({
  selectedVocalId,
  projectId,
  account,
  ...props
}) => {
  const selectedQuery = useVoiceSelectSelectedQuery({ variables: { selected: selectedVocalId! } });

  const [showingModal, setShowingModal] = React.useState(false);

  const selectedVoice = selectedQuery.data?.selected;

  if (!selectedVoice) return null;

  return (
    <>
      <VocalSelectModal
        open={showingModal}
        selectedVocalId={selectedVocalId}
        account={account}
        projectId={projectId}
        onSelect={(voiceId) => {
          setShowingModal(false);
          props.onSelect(voiceId);
        }}
        onClose={() => setShowingModal(false)}
        selectedProjectTypeId={props.selectedProjectTypeId}
      />
      <div
        onClick={() => setShowingModal(true)}
        className={css({
          display: 'inline-block',
          cursor: 'pointer'
        })}
      >
        <label
          className={classNames(
            'db vocalid-h3 vocalid-secondary-text mb1',
            css({ margin: '0px 2px 5px 2px' })
          )}
        >
          {props.label}
        </label>
        <GradientButton
          colors={colorsForVoice(selectedVoice)}
          className={'db w-100 ba b--vocalid-dark-text'}
        >
          {selectedVoice.title}
        </GradientButton>
        {/*<Select label={props.label} color={props.color} value={selectedVoice.id} isSearchable={false} className={classNames(css({
          pointerEvents: 'none'
        }), props.className)}>
          {[{ value: selectedVoice.id, label: selectedVoice.title }]}
        </Select>*/}
      </div>
    </>
  );
};

interface VocalSelectModalProps {
  open: boolean;
  selectedVocalId: string;
  selectedProjectTypeId?: string | null;
  projectId?: string | null;
  account: string;

  onPreview?(id: string): void;
  onSelect(id: string): void;
  onClose(): void;
  className?: string;
}

type VoiceFilters = {
  age: number[] | null;
  gender: Gender | null;
}

const unsetFilters: VoiceFilters = {
  age: null,
  gender: null
};

export const VocalSelectModal: React.FC<VocalSelectModalProps> = (props) => {
  const { colors: themeColors } = useTheme();

  const [acceptTalentUsage] = useAcceptTalentUsageMutation();

  const perPage = 25;
  const [page, setPage] = React.useState(0);
  const [searchQuery, setSearchQuery] = React.useState<string | null>(null);

  const selectedProjectTypeQuery = useVoiceSelectProjectTypeQuery({
    variables: { selectedProjectTypeId: props.selectedProjectTypeId! },
    skip: !props.selectedProjectTypeId
  });

  const selectedProjectType = selectedProjectTypeQuery.data?.projectType;

  const [filtersShown, setFiltersShown] = React.useState(false);
  const [tempFilters, setTempFilters] = React.useState<VoiceFilters>(unsetFilters);
  const [filters, setFilters] = React.useState<VoiceFilters>(unsetFilters);
  const tempFiltersAreDifferent = !isEqual(tempFilters, filters);
  const filtersApplied = !isEqual(filters, unsetFilters);
  React.useEffect(() => {
    setPage(0)
  }, [filters])

  const close = () => {
    setTempFilters(unsetFilters);
    setFilters(unsetFilters);
    props.onClose();
  }

  const projectVoicesQuery = useVoiceSelectProjectVoicesQuery({ variables: { projectId: props.projectId! }, skip: !props.projectId });

  const allProjectVoices = projectVoicesQuery.data?.project.clips.flatMap(clip => clip.takes.flatMap(take => take.voice));
  const projectVoices = allProjectVoices ? uniqBy(allProjectVoices, v => v.id) : undefined;

  const browseQuery = useVoiceSelectBrowseQuery({
    variables: {
      where: {
        primaryVersion: { isNotNull: true },
        title: searchQuery ? { search: searchQuery } : null,
        projectTypeWhitelist: selectedProjectType ? { includes: [selectedProjectType.id] } : null,
        age: filters.age ? { within: { start: filters.age[0], endInclusive: filters.age[1] } } : null,
        gender: filters.gender ? { equals: filters.gender } : null
      },
      pagination: { page, perPage },
      account: props.account
    }
  });
  const voicePagination = browseQuery.data?.account
    .allVoiceEntitlements;
  const numVoices = voicePagination?.totalResults ?? 0;
  const numPages = Math.ceil(numVoices / perPage);
  const voices = voicePagination?.results ?? [];

  const implicitPreviewId =
    (voices.find((v) => v.id === props.selectedVocalId) && props.selectedVocalId) ?? voices[0]?.id;
  const [previewing, setPreviewing] = React.useState<string | null>(implicitPreviewId);

  React.useEffect(() => {
    if (!previewing) {
      setPreviewing(implicitPreviewId);
    }
  }, [voices]);

  React.useEffect(() => {
    setPreviewing(implicitPreviewId);
  }, [props.open, props.selectedVocalId]);

  const previewingQuery = useVoiceSelectPreviewingQuery({
    variables: { previewing: previewing!, account: props.account },
    skip: !previewing
  });

  const voice = (previewingQuery.data ?? previewingQuery.previousData)?.previewing;

  const [showAllVoiceProjectTypes, setShowAllVoiceProjectTypes] = React.useState(false);

  let previewVoiceUI = (
    <div className={'h-100 flex justify-center items-center'}>
      <Loader />
    </div>
  );

  if (voice) {
    const limitations =
      voice.brandConflicts || voice.limitations
        ? [
            voice.brandConflicts && `Brand Conflicts: ${voice.brandConflicts.join(', ')}`,
            voice.limitations
          ]
            .filter((e) => !!e)
            .join('. ')
        : null;

    const gradientIconColors = colorsForVoice(voice);

    const slicedVoiceProjectTypes = voice.projectTypeWhitelist.slice(0, 3)
    const shownProjectTypes = showAllVoiceProjectTypes ? voice.projectTypeWhitelist : slicedVoiceProjectTypes

    previewVoiceUI = (
      <>
        <GradientIcon
          id={`voice_${voice.id}`}
          animate
          colors={gradientIconColors}
          style={{
            height: 160,
            width: 160,
            marginBottom: -10
          }}
        />
        <h2 className={'vocalid-h2 tc mt0 mb2'}>{voice.title}</h2>
        <div className={'mb3'}>
          <div className={'vocalid-secondary-text mb2 tc'}>
            {[
              voice.age,
              voice.ethnicIdentity,
              voice.gender ? labelForTalentGender(voice.gender) : null
            ]
              .filter((e) => !!e)
              .join(', ')}
          </div>
          <div className={'flex justify-center flex-wrap ph4 mb2'}>
            {shownProjectTypes.map((projectType) => (
              <span key={projectType.id} className={'mh1 mv1'}>
                <ProjectTypeTag projectType={projectType} />
              </span>
            ))}
            {slicedVoiceProjectTypes.length !== voice.projectTypeWhitelist.length && (showAllVoiceProjectTypes ? <TextButton onClick={() => setShowAllVoiceProjectTypes(false)}>less</TextButton> : <TextButton onClick={() => setShowAllVoiceProjectTypes(true)}>+{voice.projectTypeWhitelist.length - slicedVoiceProjectTypes.length}</TextButton>)}
          </div>
        </div>
        {limitations && (
          <>
            <div className={'mb3 b--vocalid-red vocalid-primary-text flex pa3 ba br3 w-100'}>
              {limitations && (
                <div
                  className={classNames(
                    'ph2',
                    css({
                      flexBasis: '50%',
                      flexGrow: 1
                    })
                  )}
                >
                  <div className={'b'}>Limitations</div>
                  <div
                    className={css({
                      maxHeight: 150,
                      overflowY: 'auto'
                    })}
                  >
                    <div
                      className={css({
                        whiteSpace: 'pre-line',
                        maxWidth: 300
                      })}
                    >
                      {limitations}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  }

  // const agreedToUsage = talent != null && talent.latestUsageAcceptance != null && !talent.latestUsageAcceptance.isOutdated

  // if (talent === null || (
  //   (!desiredProjectCategories && !limitations) || agreedToUsage
  // )) return null

  return (
    <Modal
      isOpen={props.open}
      onClose={close}
      containerClassName={css({
        backgroundColor: themeColors.page,
        color: themeColors.primaryText,
        borderRadius: 6,
        boxShadow: '2px 2px 7px rgba(0, 0, 0, 0.5)'
      })}
      className={classNames(
        css({
          display: 'flex',
          flexDirection: 'column',
          paddingTop: 35,
          overflowY: 'hidden',
          // paddingLeft: 55,
          // paddingRight: 55,
          width: 650,
          height: 550
        }),
        props.className
      )}
    >
      <div
        className={classNames('flex justify-between items-center ph3', css({ marginBottom: 25 }))}
      >
        <h2 className={'flex-grow-1 mv0 vocalid-h1'}>Select VoiceDubb</h2>
        {selectedProjectType && <ProjectTypeTag projectType={selectedProjectType} />}
      </div>
      <div className={'flex flex-grow-1 overflow-y-hidden'}>
        <div className={classNames(css({ width: '35%' }), 'flex flex-column flex-grow-1')}>
          <div className={'pl3 pr2 pb3 flex justify-between items-center'}>
            <SearchBar searchQuery={searchQuery} onSearch={setSearchQuery} />
            <Icon
              name={EIconName.Filter}
              size={16}
              className={'ml3'}
              active={filtersShown}
              onClick={() => {
                if (filtersShown) {
                  setFiltersShown(false);
                  setTempFilters(filters);
                } else {
                  setFiltersShown(true);
                }
              }}
            />
            { filtersApplied && <Icon
              name={EIconName.X}
              size={20}
              className={'ml3'}
              onClick={() => setFilters(unsetFilters)}
            /> }
          </div>
          <div className={'flex-auto relative'}>
            <div className={'h-100 flex flex-column'}>
              {page === 0 && projectVoices && projectVoices?.length !== 0 && searchQuery === null &&
                <div>
                  <label className={"db ph3 vocalid-primary-text pv2"}>VoiceDubbs in Project</label>
                  <ul className={classNames('bg-vocalid-tile b--vocalid-header bt br bb list ph0 pv0 mv0 z-0 overflow-y-auto', css({ maxHeight: 100 }))}>
                    {projectVoices.map((voice, i, arr) => (
                      <li
                        key={voice.id}
                        onClick={() => {
                          setPreviewing(voice.id);
                          return props.onPreview?.(voice.id);
                        }}
                        className={classNames(
                          i < arr.length - 1 && 'bb',
                          'b--vocalid-secondary-text pv2 ph3 pointer',
                          css({
                            '&:hover': {
                              backgroundColor: themeColors.tileAlt
                            }
                          }),
                          previewing === voice.id &&
                          css({
                            backgroundColor: themeColors.tileAlt
                          })
                        )}
                      >
                        {voice.title}
                      </li>
                    ))}
                  </ul>
                  <div className={"pv2"} />
                </div>
              }
              <div className={"flex-auto flex flex-column"}>
                <label className={"db ph3 vocalid-primary-text pv2"}>{numVoices} { !filtersApplied && !searchQuery ? "VoiceDubbs Available" : "Results" }</label>
                <ul className={'bg-vocalid-tile b--vocalid-header bt br bb list ph0 pv0 mv0 z-0 flex-grow-1 flex-shrink-1 overflow-y-auto'}>
                  {voices.length !== 0 ? voices.map((voice, i, arr) => (
                    <li
                      key={voice.id}
                      onClick={() => {
                        setPreviewing(voice.id);
                        return props.onPreview?.(voice.id);
                      }}
                      className={classNames(
                        i < arr.length - 1 && 'bb',
                        'b--vocalid-secondary-text pv2 ph3 pointer',
                        css({
                          '&:hover': {
                            backgroundColor: themeColors.tileAlt
                          }
                        }),
                        previewing === voice.id &&
                          css({
                            backgroundColor: themeColors.tileAlt
                          })
                      )}
                    >
                      {voice.title}
                    </li>
                  )) : <div className={"vocalid-secondary-text tc mv3"}>No VoiceDubbs match query.</div>}
                </ul>
              </div>
            </div>
            <div
              className={classNames(
                css({
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: 0,
                  transition: 'height 300ms',
                  backgroundColor: themeColors.tileAlt,
                  overflowY: 'hidden',
                }),
                ...(filtersShown ?
                  [css({
                    height: '100%',
                    zIndex: 1
                  }), "b--vocalid-icon-active ba"] : [])
              )}
            >
              <div className={'flex flex-column h-100'}>
                <h3 className={'vocalid-body vocalid-icon-active tr mt2 ph3'}>Filter VoiceDubbs</h3>
                <div className={'flex-grow-1 pt3 ph3 overflow-x-hidden overflow-y-auto'}>
                  <div>
                    <RangeFilter name={"Age"} color={"iconActive"} min={0} max={100} step={10} value={tempFilters.age ?? [0, 100]} inactive={!tempFilters.age} onChange={value => setTempFilters(o => ({ ...o, age: value }))} onMakeInactive={() => setTempFilters(o => ({ ...o, age: null }))} className={"w-100 mb4"} />
                    <SelectFilter name={"Gender"} options={Object.keys(Gender).map(key =>
                      // @ts-ignore
                      ({ value: Gender[key], label: labelForTalentGender(Gender[key]) }))} value={tempFilters.gender ?? Gender.Male} inactive={!tempFilters.gender} onChange={value => setTempFilters(o => ({ ...o, gender: value }))} onMakeInactive={() => setTempFilters(o => ({ ...o, gender: null }))} className={"mb4"} />
                  </div>
                </div>
                <div className={'ph3 flex justify-center'}>
                  <OutlineButton color={themeColors.link} onClick={() => {
                    setFiltersShown(false)
                    setFilters(tempFilters);
                  }} disabled={!tempFiltersAreDifferent} className={"mb3"}>Apply</OutlineButton>
                </div>
              </div>
            </div>
          </div>
          <div className={'flex justify-left mh2 mv2'}>
            <PaginationControls
              numPages={numPages}
              page={page + 1}
              onChangePage={(newPage) => setPage(newPage - 1)}
            />
          </div>
        </div>
        <div
          className={classNames(
            css({ width: '65%', maxHeight: '100%' }),
            'flex flex-column items-center vocalid-h3 overflow-x-hidden overflow-y-hidden'
          )}
        >
          <div className={'w-100 flex flex-column flex-shrink-1 flex-grow-1 overflow-y-hidden'}>
            <div className={css({ height: '100%', overflowY: 'scroll' })}>
              <div className={'flex flex-column items-center ph4'}>{previewVoiceUI}</div>
            </div>
          </div>
          <div className={'flex-shrink-0 w-100 pv3 ph4'}>
            <div className={'flex justify-end items-center'}>
              <TextButton
                // size={'small'}
                onClick={() => {
                  close();
                  // setIsOpen(false)
                }}
                className={'mr3'}
              >
                CLOSE
              </TextButton>
              <PrimaryButton
                size={'medium'}
                disabled={!voice}
                onClick={async () => {
                  if (voice?.talent) {
                    await acceptTalentUsage({
                      variables: {
                        account: props.account,
                        talent: voice.talent.id
                      }
                    });
                  }
                  /*voiceDetails.refetch({
                    account: selectedAccount!,
                    voice: props.voiceId!
                  })*/
                  props.onSelect(voice!.id);
                }}
                // onClick={() => props.onDelete()}
              >
                CONFIRM
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

interface RangeFilterProps {
  name: React.ReactNode;
  min: number;
  max: number;
  step: number;
  value: number[];
  inactive: boolean;
  disabled?: boolean;
  color: string;
  onChange(newValue: number[]): void;
  onMakeInactive(): void;
  className?: string;
}

const RangeFilter: React.FC<RangeFilterProps> = props => {
  const { colors: themeColors } = useTheme();

  return (
    <div className={props.className}>
      <div className={'tc vocalid-h3 flex items-center justify-center'}><span>{props.name}</span>{props.inactive === false && <Icon name={EIconName.X} onClick={props.onMakeInactive} className={"ml2"} />}</div>
      <RangeSlider
        min={props.min}
        max={props.max}
        value={props.value}
        onChange={props.onChange}
        step={props.step}
        markers
        color={!props.inactive ? props.color : "iconInactive"}
        disabled={props.disabled /*|| props.fineGrainedValueUsed*/}
        trackOpacity={0.8}
        minLabel={<Icon name={EIconName.Minus} size={12} />}
        maxLabel={<Icon name={EIconName.Plus} size={12} />}
        style={{ marginTop: -5, marginBottom: -10 }}
        className={"ph3"}
      />
      <div className={classNames('tc vocalid-body', css({
        color: (themeColors as any)[!props.inactive ? props.color : "iconInactive"]
      }))}><span className={"mr3"}>{ props.value[0] }</span>-<span className={"ml3"}>{ props.value[1] }</span></div>
    </div>
  );
};

interface SelectFilterProps {
  name: React.ReactNode;
  value: string;
  options: Array<ISelectOption>;
  inactive: boolean;
  disabled?: boolean;
  onChange(newValue: string): void;
  onMakeInactive(): void;
  className?: string;
}

const SelectFilter: React.FC<SelectFilterProps> = props => {
  const { colors: themeColors } = useTheme()

  return (
    <div className={props.className}>
      <div className={'tc vocalid-h3 flex items-center justify-center'}><span>{props.name}</span>{props.inactive === false && <Icon name={EIconName.X} onClick={props.onMakeInactive} className={"ml2"} />}</div>
      <div className={"flex justify-center mt3"}>
        <Select value={props.value} onChange={props.onChange} disabled={props.disabled} color={!props.inactive ? undefined : (themeColors as any)["iconInactive"]}>
          {props.options}
        </Select>
      </div>
    </div>
  );
};

function colorsForVoice(voice: VoicePreviewDetailFragment): string[] {
  return [
    ...voice.projectTypeWhitelist.map((projectType) => colorForProjectType(projectType.title)),
    '#0094FF',
    '#8F7CFF',
    '#DDB073'
  ];
}

interface GradientButtonProps {
  colors: string[];
  onClick?(): void;
  className?: string;
}

const GradientButton: React.FC<GradientButtonProps> = (props) => {
  const { colors: themeColors } = useTheme();

  const gradientFlow = keyframes`
    0%{background-position:0% 0%}
    100%{background-position:100% 0%}
  `;
  //    50%{background-position:100% 0%}

  return (
    <button
      onClick={props.onClick}
      className={classNames(
        css({
          position: 'relative',
          // borderWidth: 0,
          padding: '8px 24px',
          fontSize: 14,
          lineHeight: '16px',

          borderStyle: 'solid',
          borderRadius: 4,
          cursor: props.onClick ? 'pointer' : 'unset',
          textTransform: 'capitalize',
          fontFamily: '"Roboto", sans-serif',
          fontWeight: 'bold',

          // borderColor: bgColor,
          background: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), linear-gradient(90deg, ${props.colors[0]} 0%, ${props.colors[1]} 20%, ${props.colors[2]} 40%, ${props.colors[0]} 60%, ${props.colors[1]} 80%, ${props.colors[2]} 100%)`,
          backgroundSize: '240% 100%',
          animation: `${gradientFlow} 20s linear infinite`,
          color: themeColors.header
        }),
        props.className
      )}
    >
      {props.children}
    </button>
  );
};

interface GradientIconProps {
  id: string;
  selected?: boolean;
  colors: string[];
  animate?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

const GradientIcon: React.FC<GradientIconProps> = (props) => {
  const { colors: themeColors } = useTheme();

  return (
    <svg
      width="220"
      height="220"
      viewBox="0 0 276 276"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={props.style}
      className={props.className}
    >
      <circle cx="138" cy="138" r="92" stroke="#1C2E36" strokeWidth="16" />
      <mask
        id="mask0_1378_15161"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="99"
        y="60"
        width="79"
        height="153"
      >
        <path
          d="M143.133 185.836V201.586C151.008 202.023 157.625 203.336 162.984 205.523C168.344 207.711 171.023 210.008 171.023 212.414C171.023 212.414 167.66 212.441 160.934 212.496C154.207 212.551 146.824 212.578 138.785 212.578C130.746 212.578 123.363 212.551 116.637 212.496C109.91 212.441 106.547 212.414 106.547 212.414C106.547 210.117 109.035 207.875 114.012 205.688C118.988 203.5 125.195 202.133 132.633 201.586V185.836C127.93 185.07 123.527 183.566 119.426 181.324C115.324 179.082 111.77 176.238 108.762 172.793C105.754 169.348 103.375 165.438 101.625 161.062C99.875 156.687 99 152.039 99 147.117V138.914H104.25V147.117C104.25 151.82 105.152 156.223 106.957 160.324C108.762 164.426 111.195 168.035 114.258 171.152C117.32 174.27 120.902 176.703 125.004 178.453C129.105 180.203 133.508 181.078 138.211 181.078C142.914 181.078 147.316 180.203 151.418 178.453C155.52 176.703 159.102 174.27 162.164 171.152C165.227 168.035 167.66 164.426 169.465 160.324C171.27 156.223 172.172 151.82 172.172 147.117V138.914H177.75V147.117C177.75 152.148 176.848 156.879 175.043 161.309C173.238 165.738 170.777 169.676 167.66 173.121C164.543 176.566 160.879 179.383 156.668 181.57C152.457 183.758 147.945 185.18 143.133 185.836ZM138.375 175.5C130.391 175.5 123.582 172.684 117.949 167.051C112.316 161.418 109.5 154.609 109.5 146.625V133.5H133.125V128.414H109.5V123.164H133.125V117.914H109.5V112.664H133.125V107.414H109.5V102.164H133.125V96.9141H109.5V88.5469C109.5 80.5625 112.316 73.8086 117.949 68.2852C123.582 62.7617 130.391 60 138.375 60C146.359 60 153.168 62.7617 158.801 68.2852C164.434 73.8086 167.25 80.5625 167.25 88.5469V96.9141H143.625V102.164H167.25V107.414H143.625V112.664H167.25V117.914H143.625V123.164H167.25V128.414H143.625V133.5H167.25V146.625C167.25 154.609 164.434 161.418 158.801 167.051C153.168 172.684 146.359 175.5 138.375 175.5Z"
          fill="black"
        />
      </mask>
      <mask
        id="mask0_1579_16049"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="50"
        y="50"
        width="176"
        height="176"
      >
        <circle cx="138" cy="138" r="88" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_1579_16049)">
        <g>
          <rect x="48" y="46" width="180" height="180" fill="white" />
          <rect
            x="48"
            y="46"
            width="180"
            height="180"
            fill={`url(#paint0_radial_1360_18328_${props.id})`}
          />
          <rect
            x="48"
            y="46"
            width="180"
            height="180"
            fill={`url(#paint1_radial_1360_18328_${props.id})`}
          />
          <rect
            x="48"
            y="46"
            width="180"
            height="180"
            fill={`url(#paint2_radial_1360_18328_${props.id})`}
          />
        </g>
      </g>
      <path
        d="M141.753 172.97V184.178C147.357 184.489 152.066 185.423 155.879 186.98C159.693 188.536 161.6 190.171 161.6 191.883C161.6 191.883 159.207 191.903 154.42 191.942C149.633 191.981 144.38 192 138.659 192C132.938 192 127.684 191.981 122.897 191.942C118.111 191.903 115.717 191.883 115.717 191.883C115.717 190.249 117.488 188.653 121.029 187.096C124.571 185.54 128.988 184.567 134.281 184.178V172.97C130.934 172.425 127.801 171.355 124.882 169.759C121.963 168.163 119.434 166.14 117.293 163.688C115.153 161.236 113.46 158.454 112.215 155.34C110.969 152.227 110.347 148.919 110.347 145.416V139.579H114.083V145.416C114.083 148.763 114.725 151.896 116.009 154.815C117.293 157.734 119.025 160.302 121.204 162.52C123.384 164.739 125.933 166.471 128.852 167.716C131.77 168.961 134.903 169.584 138.25 169.584C141.597 169.584 144.73 168.961 147.649 167.716C150.567 166.471 153.116 164.739 155.296 162.52C157.475 160.302 159.207 157.734 160.491 154.815C161.775 151.896 162.417 148.763 162.417 145.416V139.579H166.387V145.416C166.387 148.997 165.745 152.363 164.461 155.515C163.176 158.668 161.425 161.47 159.207 163.921C156.989 166.373 154.381 168.377 151.385 169.934C148.388 171.491 145.177 172.503 141.753 172.97ZM138.367 165.614C132.685 165.614 127.84 163.61 123.831 159.602C119.823 155.593 117.819 150.748 117.819 145.066V135.726H134.631V132.107H117.819V128.371H134.631V124.635H117.819V120.899H134.631V117.163H117.819V113.427H134.631V109.691H117.819V103.736C117.819 98.0546 119.823 93.2484 123.831 89.3178C127.84 85.3872 132.685 83.4219 138.367 83.4219C144.049 83.4219 148.894 85.3872 152.902 89.3178C156.911 93.2484 158.915 98.0546 158.915 103.736V109.691H142.103V113.427H158.915V117.163H142.103V120.899H158.915V124.635H142.103V128.371H158.915V132.107H142.103V135.726H158.915V145.066C158.915 150.748 156.911 155.593 152.902 159.602C148.894 163.61 144.049 165.614 138.367 165.614Z"
        fill="#1C2E36"
      />
      {props.children}
      <defs>
        <radialGradient
          id={`paint0_radial_1360_18328_${props.id}`}
          cx="50%"
          cy="100%"
          r={'75%'}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={props.colors[1]}>
            {props.animate && (
              <animate
                attributeName="stop-color"
                values={[props.colors[1], props.colors[2], props.colors[0], props.colors[1]].join(
                  '; '
                )}
                dur="10s"
                repeatCount="indefinite"
              />
            )}
          </stop>
          <stop offset="1" stopColor={props.colors[1]} stopOpacity="0">
            {props.animate && (
              <animate
                attributeName="stop-color"
                values={[props.colors[1], props.colors[2], props.colors[0], props.colors[1]].join(
                  '; '
                )}
                dur="10s"
                repeatCount="indefinite"
              />
            )}
          </stop>
        </radialGradient>
        <radialGradient
          id={`paint1_radial_1360_18328_${props.id}`}
          cx="100%"
          cy="0%"
          r="90%"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={props.colors[2]}>
            {props.animate && (
              <animate
                attributeName="stop-color"
                values={[props.colors[2], props.colors[0], props.colors[1], props.colors[2]].join(
                  '; '
                )}
                dur="10s"
                repeatCount="indefinite"
              />
            )}
          </stop>
          <stop offset="1" stopColor={props.colors[2]} stopOpacity="0">
            {props.animate && (
              <animate
                attributeName="stop-color"
                values={[props.colors[2], props.colors[0], props.colors[1], props.colors[2]].join(
                  '; '
                )}
                dur="10s"
                repeatCount="indefinite"
              />
            )}
          </stop>
        </radialGradient>
        <radialGradient
          id={`paint2_radial_1360_18328_${props.id}`}
          cx="0%"
          cy="0%"
          r="90%"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={props.colors[0]}>
            {props.animate && (
              <animate
                attributeName="stop-color"
                values={[props.colors[0], props.colors[1], props.colors[2], props.colors[0]].join(
                  '; '
                )}
                dur="10s"
                repeatCount="indefinite"
              />
            )}
          </stop>
          <stop offset="1" stopColor={props.colors[0]} stopOpacity="0">
            {props.animate && (
              <animate
                attributeName="stop-color"
                values={[props.colors[0], props.colors[1], props.colors[2], props.colors[0]].join(
                  '; '
                )}
                dur="10s"
                repeatCount="indefinite"
              />
            )}
          </stop>
        </radialGradient>
      </defs>
    </svg>
  );
};

export function colorForProjectType(type: string): string {
  const colors = [
    '#ea4d4d',
    '#FFC225',
    '#FF7628',
    '#5ac34f',
    '#49b9de',
    '#00FFC2',
    '#8F7CFF',
    '#de4bff'
  ];

  let typeIndex: number =
    type
      .split('')
      .map((x) => x.charCodeAt(0))
      .reduce((a, b) => a + b) %
    (colors.length - 1);

  return colors[typeIndex];
}
