import React from "react";
import Banner from "../../molecules/banner";
import { Link } from "react-router-dom";
import { useTheme } from "../../../theme";

export interface RenderCountWarningBannerProps {
  totalRenders: number;
  maxRenders: number | null;
  className?: string;
}

const RenderCountWarningBanner: React.FC<RenderCountWarningBannerProps> = props => {
  const { colors: themeColors } = useTheme();

  if (!props.maxRenders) return null;

  const managePlanLink = <Link to={"/enterprise/account"} className={"ml3 vocalid-dark-text b no-underline"}>Manage Your Plan</Link>;

  if (props.totalRenders >= props.maxRenders) {
    return <Banner>The maximum number of renders ({props.maxRenders}) have been used by this account. {managePlanLink}</Banner>;
  }

  if (props.totalRenders >= props.maxRenders*0.75) {
    return <Banner color={themeColors.popover}>Usage warning: This account has used more than 75% of its allotted renders. {managePlanLink}</Banner>;
  }

  return null;
};

export default RenderCountWarningBanner;
