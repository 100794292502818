import React from "react";
import RecordingsReviewTable from "../../../components/organisms/recordings-review-table";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  RecordingsReviewTableQueryVariables,
  useRecordingReviewTableBatchUpdateApprovalFlagMutation,
  useRecordingReviewTableReportLineMutation,
  useRecordingReviewTableUpdateLineMutation,
  useRecordingsReviewTableQuery
} from "../../../graphql/generated";
import FullPageLoader from "../../shared/loader";
import PaginationControls from "../../../components/atoms/pagination-controls";
import Dropdown, { DropdownOption } from "../../../components/molecules/dropdown";
import { css } from "emotion";
import Icon, { EIconName } from "../../../components/atoms/icon";
import { RecordingApprovalFlag } from "../../../graphql/generated";
import UploadTrainingDataDialog from "../upload-training-data-dialog";
import { OutlineButton } from "../../../components/atoms/button";

export interface RecordingReviewTableContainerProps {
  assignmentId: string;
  commentWritingAllowed?: boolean;
  recordingsShownInPage?: number;
  onPageChange?(newPage: number, oldPage: number): void;
  // talentId?: string;
  className?: string;
}

const RecordingsReviewTableContainer = React.forwardRef<HTMLDivElement, RecordingReviewTableContainerProps>((props, ref) => {
  const recordingsShownInPage = props.recordingsShownInPage ?? 50;
  const [page, _setPage] = React.useState(1) // maybe lift up?
  const setPage = (newPage: number) => {
    _setPage(newPage);
    props.onPageChange?.(newPage, page);
  };

  const [uploadDialogShown, setUploadDialogShown] = React.useState(false)

  const [filterApproval, setFilterApproval] = React.useState<RecordingApprovalFlag | false>(false);
  const toggleFilterApproval = (approvalFlag: RecordingApprovalFlag) => setFilterApproval(o => o === approvalFlag ? false : approvalFlag)

  const queryVars: RecordingsReviewTableQueryVariables = {
    assignmentId: props.assignmentId,
    where: {
      pagination: {
        page: page - 1,
        perPage: recordingsShownInPage,
      },
      filter: filterApproval ? {
        approvalFlag: filterApproval
      } : null
    }
  }

  const { data, loading, refetch: _refetch } =
    useRecordingsReviewTableQuery({
      variables: queryVars
    });

  const refetch = async () => _refetch(queryVars)

  const numPages = data && Math.ceil(data.assignment.recordings.totalResults / 50);

  // if page exceeds new pages count, set page to max new pages
  React.useEffect(() => {
    if (numPages && page > numPages) {
      setPage(numPages);
    }
  }, [numPages])

  const [_batchUpdateApproval] = useRecordingReviewTableBatchUpdateApprovalFlagMutation();
  const batchUpdateApproval = async (ids: string[], flag: RecordingApprovalFlag) => {
    await _batchUpdateApproval({
      variables: {
        ids,
        flag
      }
    });
    refetch();
  }

  const [_updateLine] = useRecordingReviewTableUpdateLineMutation()
  const updateComments = async (recordingId: string, comments: string | null) => {
    await _updateLine({ variables: { input: {
          recordingId: recordingId,
          enterpriseReviewComments: comments ? {
            comments
          } : null
    } } });
    refetch()
  }

  const updateSentence = async (recordingId: string, sentence: string) => {
    await _updateLine({ variables: { input: {
          recordingId: recordingId,
          recordedContentLineText: sentence
    } } });
    refetch()
  }

  const [_reportContentLine] = useRecordingReviewTableReportLineMutation()
  const reportContentLine = async (contentLineId: string, reason: string) => {
    await _reportContentLine({
      variables: {
        contentLineId,
        talentId: data!.assignment.voice!.talent!.id,
        reason
      }
    });
    refetch()
  }

  const shownRecordings = data?.assignment.recordings.results.map(recording => ({
    id: recording.id,
    audioUrl: recording.downloadUrl,
    num: recording.num, // (((totalPages - page) * 50) + (index)) + 1,
    sentence: recording.recordedContentLineText,
    contentLineId: recording.contentLine?.id ?? null,
    contentSentence: recording.contentLine?.text ?? null,
    error: recording.error ? {
      sentence: recording.error.text,
      message: recording.error.message
    } : null,
    assignment: recording.contentLine?.content.title || "",
    timestamp: new Date(recording.created),
    status: recording.clientApprovalFlag ?? RecordingApprovalFlag.Undecided,
    comments: recording.enterpriseReviewComments,
    session: {
      id: recording.recordingSession.id,
      startedAt: new Date(recording.recordingSession.created),
      recordingDeviceName: recording.recordingSession.recordingDeviceName,
      browser: recording.recordingSession.browser,
      os: recording.recordingSession.operatingSystem,
      userAgent: recording.recordingSession.userAgent
    },
    reported: recording.contentLine?.talentReports.find(r => r.reportedByTalent.id === data.assignment.voice!.talent!.id) !== undefined
  })) ?? [];

  const [selectedRecordingIds, setSelectedRecordingIds] = React.useState<string[]>([]); // maybe lift up?

  const bulkAcceptSelected = () => batchUpdateApproval(selectedRecordingIds, RecordingApprovalFlag.Approved)
  const bulkRejectSelected = () => batchUpdateApproval(selectedRecordingIds, RecordingApprovalFlag.Rejected)

  if (!data || loading) return <FullPageLoader className={"pt5"} />

  return (
    <>
      <UploadTrainingDataDialog isShown={uploadDialogShown} assignmentId={props.assignmentId} onDismiss={() => setUploadDialogShown(false)} onUploaded={() => {
        refetch()
      }} />
      <div className={'mh4 mb4 mt3'} ref={ref}>
        <div className={'mt2 mb4 flex justify-between'}>
          <div className={'flex justify-start'}>
            <div>
              <Dropdown
                label={'Bulk Action...'}
                className={'mr3'}
                menuColor={'iconInactive'}
                menuClassName={css({ minWidth: 200 })}
                roundedCorners
                disabled={selectedRecordingIds.length === 0}
              >
                {/* <DropdownOption onSelect={() => {}}>
                  <Icon name={EIconName.Download} color={'primaryText'} className={'mr2 v-mid'} /> Download
                </DropdownOption> */}
                <DropdownOption onSelect={() => bulkAcceptSelected()}>
                  <Icon name={EIconName.Approve} color={'green'} className={'mr2 v-mid'} /> Mark as Accepted
                </DropdownOption>
                <DropdownOption onSelect={() => bulkRejectSelected()}>
                  <Icon name={EIconName.Reject} color={'red'} className={'mr2 v-mid'} /> Mark as Rejected
                </DropdownOption>
              </Dropdown>
            </div>
            <div>
              <Dropdown
                label={'Filter'}
                className={'ml3'}
                menuColor={'iconInactive'}
                icon={EIconName.Filter}
                iconSize={12}
                noOutline
                menuClassName={css({ minWidth: 200 })}
                roundedCorners
              >
                <DropdownOption selected={filterApproval === RecordingApprovalFlag.Approved} onSelect={() => toggleFilterApproval(RecordingApprovalFlag.Approved)}>
                  <Icon name={EIconName.Approve} color={'green'} className={'mr2 v-mid'} /> Accepted
                </DropdownOption>
                <DropdownOption selected={filterApproval === RecordingApprovalFlag.Rejected} onSelect={() => toggleFilterApproval(RecordingApprovalFlag.Rejected)}>
                  <Icon name={EIconName.Reject} color={'red'} className={'mr2 v-mid'} /> Rejected
                </DropdownOption>
                <DropdownOption selected={filterApproval === RecordingApprovalFlag.Undecided} onSelect={() => toggleFilterApproval(RecordingApprovalFlag.Undecided)}>
                  <Icon name={EIconName.Approve} color={'primaryText'} className={'mr2 v-mid'} /> Not Reviewed
                </DropdownOption>
              </Dropdown>
            </div>
          </div>
          <div>
            <OutlineButton size={"medium"} onClick={() => {
              setUploadDialogShown(true);
            }} className={"mr2"}>
              Upload
            </OutlineButton>
          </div>
        </div>
        <div className={'ph4'}>
          <RecordingsReviewTable
            ref={ref}
            talentId={data.assignment.voice!.talent!.id}
            loadedRecordings={shownRecordings}
            commentWritingAllowed={props.commentWritingAllowed ?? false}

            selectedRecordingIds={selectedRecordingIds}
            onRecordingSelected={(recordingId, selected) => {
              const clonedIds = [...new Set(selectedRecordingIds)];
              if (selected) {
                clonedIds.push(recordingId);
              } else {
                const index = clonedIds.indexOf(recordingId);
                if (index !== -1) {
                  clonedIds.splice(index, 1);
                }
              }

              setSelectedRecordingIds([...new Set(clonedIds)]);
            }}
            // onClearedSelection={() => setSelectedRecordingIds([])}
            // onSelectedAllRecordings={() => setSelectedRecordingIds(shownRecordings.map((r) => r.id))}

            onRecordingStatusChange={(recordingId, newStatus) => batchUpdateApproval([recordingId], newStatus)}
            onUpdateRecordingComments={(recordingId, newComments) => updateComments(recordingId, newComments)}
            onRecordingSentenceChanged={(recordingId, newSentence) => updateSentence(recordingId, newSentence)}
            onReportContentLine={(contentLineId, reason) => reportContentLine(contentLineId, reason)}
          />
          <div className={'mh4 mt4 mb4 flex justify-center items-center'}>
            <PaginationControls numPages={numPages!} page={page} onChangePage={setPage}/>
          </div>
        </div>
      </div>
    </>
  );
});

export default RecordingsReviewTableContainer;
