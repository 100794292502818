import React from "react";
import classNames from "classnames";
import { css } from "emotion";
import Tooltip from "../../components/atoms/tooltip";
import { useEnterpriseContextualState } from "../../components/templates/enterprise";
import { RoleFeatureFlagKey } from "../../graphql/generated";

export type FeatureFlag = keyof typeof RoleFeatureFlagKey;

export interface FeatureLockContext {
  accounts: {
    [accountId: string]: {
      [feature: string]: FeatureLockedState
    }
  }
}

export type FeatureLockedState = boolean | 'hidden' | {
  lockedReason: string;
}

const FeatureLockContext = React.createContext<FeatureLockContext>({
  accounts: {}
});

export const FeatureLockProvider = FeatureLockContext.Provider;
export const FeatureLockConsumer = FeatureLockContext.Consumer;
export const useLockedFeatures = (accountId?: string | undefined) => {
  const { state } = useEnterpriseContextualState();
  const resolvedAccountId = accountId ?? state.selectedAccount;
  const context = React.useContext(FeatureLockContext)
  // console.log("Total context")
  // console.log(context)
  // console.log("resolved account id")
  // console.log(resolvedAccountId)
  const accountContext = resolvedAccountId ? context.accounts[resolvedAccountId] ?? {} : {};
  // console.log("Account context")
  // console.log(accountContext)
  return accountContext;
};

export const useLockedFeature = (feature: FeatureFlag | undefined, accountId?: string | undefined): FeatureLockedState => {
  const lockedFeatures = useLockedFeatures(accountId)
  return (feature ? lockedFeatures[feature] : false) ?? false;
};

export const FeatureLock: React.FC<{ account?: string | undefined; feature: FeatureFlag | undefined; noHidden?: boolean; disabled?: boolean; className?: string }> = props => {
  const lockedState = useLockedFeature(props.feature, props.account);
  return <StatelessFeatureLock lockedState={lockedState} noHidden={props.noHidden} disabled={props.disabled} children={props.children} className={props.className} />;
};

export const StatelessFeatureLock: React.FC<{ lockedState: FeatureLockedState; noHidden?: boolean; disabled?: boolean; className?: string; }> = props => {
  const lockedCss = React.useMemo(() => css({
    opacity: 0.2,
    cursor: 'not-allowed !important',
    userSelect: 'none',
    '& > *': {
      pointerEvents: 'none'
    }
  }), []);

  if (props.lockedState === false || props.disabled) {
    return (
      <div className={classNames(props.className ?? css({
        display: 'inline-block',
      }))}>
        {props.children}
      </div>
    );
  }

  if (props.lockedState === true || (props.lockedState === "hidden" && props.noHidden)) {
    return (
      <div className={classNames(lockedCss, props.className ?? css({
        display: 'inline-block'
      }))}>
        {props.children}
      </div>
    )
  }

  if (props.lockedState === 'hidden') {
    return null;
  }

  return (
    <Tooltip
      tooltipContent={<div className={classNames(css({
        textTransform: 'initial',
        textAlign: 'initial',
      }), "vocalid-body")}>
        {props.lockedState.lockedReason}
      </div>}
      containerClassName={classNames(lockedCss, props.className ?? css({
        display: 'inline-block',
      }))}>
      {props.children}
    </Tooltip>
  )
}
