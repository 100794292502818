import React from 'react';
import ReactSelect, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useTheme } from '../../../theme';
import classNames from 'classnames';
import Icon, { EIconName } from '../../atoms/icon';
import { css } from 'emotion';
import {
  InputValidationResult,
  InputValidationResultError,
  InputValidationResultWarning
} from '../../atoms/input';
import { Props as CreatableProps } from 'react-select/src/Creatable';

export interface ISelectOption<Value extends string = string> {
  value: Value;
  label: React.ReactNode;
  disabled?: boolean;
}

interface SharedSelectProps {
  label?: React.ReactNode;
  placeholder?: React.ReactNode;
  children: Array<ISelectOption>;
  validationResult?: InputValidationResult;

  color?: string;
  menuColor?: string;
  disabled?: boolean;

  isSearchable?: boolean;
  menuPortalTarget?: HTMLElement | null;
  styleOverrides?: Partial<{
    control: React.CSSProperties;
    menu: React.CSSProperties;
    multiValue: React.CSSProperties;
    multiValueLabel: React.CSSProperties;
    multiValueRemove: React.CSSProperties;
    valueContainer: React.CSSProperties;
    singleValue: React.CSSProperties;
  }>;
  menuPosition?: "absolute" | "fixed";
  multiValueRemoveIconColor?: string;
  creatable?: boolean;

  onBlur?(): void;
  onCreateValue?(newValue: string): void;
  className?: string;
}


interface BaseSelectProps extends SharedSelectProps {
  value: string | string[];
  isMulti?: boolean;
  onChange?(newValue: string | string[]): void;
}

const BaseSelect: React.FC<BaseSelectProps> = props => {
  const SelectComponent: React.ComponentType<CreatableProps<ISelectOption>> = React.useMemo(
    () => (props.creatable ? CreatableSelect : ReactSelect),
    [props.creatable]
  );

  const { colors: themeColors } = useTheme();
  const color = props.disabled
    ? themeColors.iconInactive
    : props.color
      ? (themeColors as any)[props.color] ?? props.color
      : themeColors.secondaryText;

  const menuColor = props.menuColor ?? themeColors.iconInactive;

  const validationResult = props.validationResult;

  const values = Array.isArray(props.value) ? props.value : [props.value];

  return (
    <div className={classNames('dib relative', props.className)}>
      {props.label && (
        <label
          className={classNames(
            'dib vocalid-h3 vocalid-secondary-text mb1',
            css({ margin: '0px 2px 5px 2px' })
          )}
        >
          {props.label}
        </label>
      )}
      <SelectComponent
        value={values
          .map((value) => props.children.find((o) => o.value === value))
          .filter((e) => e!)
          .map((e) => e!)}
        placeholder={props.placeholder ?? props.creatable ? (props.children.length === 0 ? 'Start typing to Add...' : 'Select or Add...') : 'Select...'}
        isMulti={props.isMulti}
        menuPosition={props.menuPosition ?? 'fixed'}
        menuPortalTarget={props.menuPortalTarget}
        components={{
          DropdownIndicator: () => (
            <Icon
              name={EIconName.DropdownArrowDown}
              color={color}
              hoverColor={color}
              size={25}
              className={classNames(
                'pl1 pr2',
                css({
                  '&:hover': {
                    fill: 'transparent !important'
                  }
                })
              )}
            />
          ),
          IndicatorSeparator: () => null,
          MultiValueRemove: ({ innerProps: { onClick } }) => (
            <div className={"flex flex-column justify-center"}>
              <Icon
                name={EIconName.X}
                color={props.multiValueRemoveIconColor ?? themeColors.tile}
                activeColor={props.multiValueRemoveIconColor ?? themeColors.tile}
                size={16}
                onClick={onClick}
                // className={css({
                //   paddingTop: 2
                // })}
              />
            </div>
          )
        }}
        styles={{
          control: (base) => ({
            ...base,
            fontFamily: "'Roboto', sans-serif",
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '16px',
            backgroundColor: 'transparent',
            color,
            borderRadius: 4,
            borderColor: color,
            appearance: 'none',
            borderStyle: 'solid',
            borderWidth: 1,
            minHeight: 40,
            minWidth: 140,
            // verticalAlign: 'top',
            paddingTop: 4,
            paddingBottom: 4,
            ...props.styleOverrides?.control
          }),
          valueContainer: (base) => ({
            ...base,
            // alignItems: 'flex-start',
            ...props.styleOverrides?.valueContainer
          }),
          input: (base) => ({
            ...base,
            color,
          }),
          placeholder: (base) => ({
            ...base,
            color,
            // transform: 'none',
            // top: 0,
            // bottom: 0,
            // position: 'relative',
            overflow: 'hidden',
            opacity: 0.6
          }),
          menu: (base) => ({
            ...base,
            backgroundColor: menuColor,
            color: themeColors.primaryText,
            borderRadius: 4,
            ...props.styleOverrides?.menu
          }),
          option: (base, state) => ({
            ...base,
            color: themeColors.primaryText,
            opacity: state.isDisabled ? 0.25 : 1,
            backgroundColor: menuColor,
            '&:hover': !state.isDisabled && {
              color: themeColors.primaryText,
              backgroundColor: themeColors.tile
            }
          }),
          noOptionsMessage: (base) => ({
            ...base,
            color: themeColors.primaryText
          }),
          singleValue: (base, state) => ({
            ...base,
            color,
            ...props.styleOverrides?.singleValue
          }),
          multiValue: (base) => ({
            ...base,
            backgroundColor: themeColors.secondaryText,
            color: themeColors.tile,
            marginTop: 3,
            marginBottom: 3,
            borderRadius: 4,
            paddingLeft: 5,
            paddingRight: 5,
            marginLeft: 3,
            marginRight: 3,
            ...props.styleOverrides?.multiValue
          }),
          multiValueLabel: (base) => ({
            ...base,
            fontSize: 14,
            ...props.styleOverrides?.multiValueLabel
          }),
          multiValueRemove: (base) => ({
            ...base,
            ...props.styleOverrides?.multiValueLabel
          })
        }}
        // className={props.className}
        isClearable={false}
        isSearchable={props.isSearchable ?? true}
        onChange={(v, { action, removedValue }) => {
          switch (action) {
            case 'remove-value':
            case 'pop-value':
              break;
            // case 'clear':
            //   v = []
            //   break;
          }

          if (props.isMulti) {
            const vA = v as ISelectOption[] | null;
            props.onChange?.(vA !== null ? vA.map((vO) => vO.value) : []);
          } else {
            const vO = v as ISelectOption | null;
            props.onChange?.(vO !== null ? vO.value : '');
          }
        }}
        options={props.children}
        isOptionDisabled={option => Boolean(option.disabled)}
        onCreateOption={
          props.onCreateValue &&
          ((inputValue) => {
            props.onCreateValue?.(inputValue);
          })
        }
        createOptionPosition={'last'}
      />
      {validationResult && validationResult !== true && (
        <div className={'mt1'}>
          {validationResult.hasOwnProperty('error') ? (
            <span className={'vocalid-red'}>
              {(validationResult as InputValidationResultError).error}
            </span>
          ) : (
            <span className={'vocalid-warning'}>
              {(validationResult as InputValidationResultWarning).warning}
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export interface ISelectProps extends SharedSelectProps {
  value: string;
  onChange?(newValue: string): void;

  className?: string;
}

const Select: React.FC<ISelectProps> = (props) => {
  // noinspection RequiredAttributes
  return (
    <BaseSelect isMulti={false} {...props} />
  )
};

export default Select;

export interface IMultiSelectProps extends SharedSelectProps {
  value: string[];
  onChange?(newValue: string[]): void;

  className?: string;
}

export const MultiSelect: React.FC<IMultiSelectProps> = (props) => {
  // noinspection RequiredAttributes
  return (
    <BaseSelect isMulti={true} {...props} />
  )
};
