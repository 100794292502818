import React, { useState } from "react";
import FullPageLoader, { Loader } from "../shared/loader";
import GenericErrorBoundary from "../../components/molecules/generic-error-boundary";
import { Auth0Config } from "../../auth";
import {
  Gender,
  TalentJobCategory,
  TalentDetailFragment,
  TalentProfileQuery,
  useTalentProfileQuery,
  useUpdateTalentMutation
} from "../../graphql/generated";
import { useTheme } from "../../theme";
import { useForm } from "../../components/shared/form";
import ResetPasswordDialog from "../../components/organisms/change-password-dialog";
import Header from "../../components/atoms/header";
import Avatar from "../../components/atoms/avatar";
import Tile from "../../components/atoms/tile";
import Input from "../../components/atoms/input";
import { PrimaryButton, TextButton } from "../../components/atoms/button";
import Select, { MultiSelect } from "../../components/molecules/select";
import TextArea from "../../components/atoms/textarea";
import classNames from "classnames";
import { css } from "emotion";
import Checkbox from "../../components/atoms/checkbox";
import { labelForTalentJobCategory, labelForTalentGender } from "../shared/enums";

export interface ITalentProfilePageContainerProps {
}

type TalentProfilePageContainerProps = ITalentProfilePageContainerProps

const TalentProfilePageContainer: React.FC<TalentProfilePageContainerProps> = (props) => {
  const { data, loading } = useTalentProfileQuery()

  if (!data || loading) return <FullPageLoader />

  if (!data.viewer?.user.talent?.talent) return null

  return <GenericErrorBoundary><LoadedTalentProfile data={data} /></GenericErrorBoundary>
};

interface LoadedTalentProfileProps {
  data: TalentProfileQuery;
}

const LoadedTalentProfile: React.FC<LoadedTalentProfileProps> = props => {
  const { colors: themeColors } = useTheme();
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [mutate] = useUpdateTalentMutation()
  const [saving, setSaving] = React.useState(false)

  const form = useForm({
    initialValues: props.data.viewer!.user.talent!.talent as TalentDetailFragment,
    async onSubmit(values, _form) {
      setSaving(true)
      await mutate({
        variables: {
          input: {
            id: props.data!.viewer!.user.talent!.talent.id,
            firstName: values.firstName,
            lastName: values.lastName,
            age: values.age,
            gender: values.gender,
            ethnicIdentity: values.ethnicIdentity,
            pronouns: values.pronouns,
            memberOfUnion: values.memberOfUnion,
            desiredProjectCategories: {
              list: values.desiredProjectCategories
            },
            hiredProjectCategories: {
              list: values.hiredProjectCategories
            },
            brandConflicts: values.brandConflicts,
            limitations: values.limitations,
            otherJobInfo: values.otherJobInfo,
            interestedInCharacterVoiceRecording: {
              list: values.interestedInCharacterVoiceRecording
            },
            showcaseVoicesOnVoice123: values.showcaseVoicesOnVoice123,
            interestedInRegionalAccentRecording: {
              list: values.interestedInRegionalAccentRecording
            },
            interestedInNonenglishLanguageRecording: {
              list: values.interestedInNonenglishLanguageRecording
            },
          }
        }
      })
      setSaving(false)
      // window.location.reload()
    },
    validate(values) {
      const errors: { [P in keyof typeof values]?: string } = {};

      if (!values.firstName) {
        errors.firstName = 'First Name is Required';
      }

      if (!values.lastName) {
        errors.lastName = 'Last Name is Required';
      }

      return errors;
    }
  });

  React.useEffect(() => {
    form.resetForm({
      values: props.data.viewer!.user.talent!.talent
    });
  }, [props.data]);

  const firstName = props.data.viewer!.user.talent!.talent.firstName ?? 'Johnny';
  const lastName = props.data.viewer!.user.talent!.talent.lastName ?? 'Appleseed';

  return (
    <>
      <ResetPasswordDialog
        isShown={isResettingPassword}
        email={props.data.viewer!.user.emailAddress}
        onCancel={() => setIsResettingPassword(false)}
        onReset={async () => {
          await fetch('https://vocalid.auth0.com/dbconnections/change_password', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              client_id: Auth0Config.clientId,
              email: props.data!.viewer!.user.emailAddress,
              connection: 'Username-Password-Authentication'
            })
          });
          setIsResettingPassword(false);
        }}
      />
      <div>
        <Header>
          <h1 className={'db vocalid-h1 mh0 mv2'}>PROFILE</h1>
        </Header>
        <div className={'mh4 white'}>
          <div className={'mt4 mb4 pt2 pb2'}>
            <Avatar size={120} className={'v-mid'} bgColor={themeColors.red}>
              {`${firstName.substring(0, 1)}${lastName.substring(0, 1)}`}
            </Avatar>
            <h1 className={'ml4 dib v-mid vocalid-h2'}>
              <span className={'f2'}>
                {firstName} {lastName}
              </span>
            </h1>
          </div>
          <Tile heading={<div className={'vocalid-h2'}>About You</div>} className={'mb4'}>
            <div className={'ph6 pv3'}>
              <div className={'mb3 flex flex-wrap'}>
                <Input
                  label={'First Name*'}
                  placeholder={'Johnny'}
                  {...form.inputProps('firstName')}
                  className={'w5 dib mr3 v-top'}
                />
                <Input
                  label={'Last Name*'}
                  placeholder={'Appleseed'}
                  {...form.inputProps('lastName')}
                  className={'w5 dib mr3 v-top'}
                />
                <Input
                  label={'Age'}
                  placeholder={'34'}
                  {...form.inputProps('age')}
                  type={"number"}
                  className={'w4 dib mr3 v-top'}
                />
                <Select
                  label={"Gender"}
                  {...form.selectProps('gender')}
                  className={'w5 dib mr3 v-top'}
                >
                  {Object.keys(Gender).map(key =>
                    // @ts-ignore
                    ({ value: Gender[key], label: labelForTalentGender(Gender[key]) }))}
                </Select>
                <Input
                  label={'Pronouns'}
                  placeholder={'They/Them'}
                  {...form.inputProps('pronouns')}
                  className={'w5 dib mr3 v-top'}
                />
                <Input
                  label={'Ethnic Identity'}
                  placeholder={"Latinx, White, AAPI, BIPOC..."}
                  {...form.inputProps('ethnicIdentity')}
                  className={'w5 dib mr3 v-top'}
                />
                <MultiSelect
                  label={"Language(s) of Interest"}
                  creatable
                  {...form.multiSelectProps('interestedInNonenglishLanguageRecording')}
                  className={'w-30 dib v-top mb3'}
                >
                  {form.values['interestedInNonenglishLanguageRecording']?.map(key =>
                    ({ value: key, label: key })) ?? []}
                </MultiSelect>
              </div>
              <h3 className={"vocalid-h3 ttu mb3"}>Work</h3>
              <div className={"mb3"}>
                <div className={'flex flex-wrap'}>
                  <MultiSelect
                    label={"Desired Project Categories"}
                    {...form.multiSelectProps('desiredProjectCategories')}
                    className={'w-30 dib mr3 v-top mb3'}
                  >
                    {Object.keys(TalentJobCategory).map(key =>
                      // @ts-ignore
                      ({ value: TalentJobCategory[key], label: labelForTalentJobCategory(TalentJobCategory[key]) }))}
                  </MultiSelect>
                  <MultiSelect
                    label={"Hired Project Categories"}
                    {...form.multiSelectProps('hiredProjectCategories')}
                    className={'w-30 dib mr3 v-top mb3'}
                  >
                    {Object.keys(TalentJobCategory).map(key =>
                      // @ts-ignore
                      ({ value: TalentJobCategory[key], label: labelForTalentJobCategory(TalentJobCategory[key]) }))}
                  </MultiSelect>
                  <MultiSelect
                    label={"Brand Conflicts"}
                    creatable
                    {...form.multiSelectProps('brandConflicts')}
                    className={'w-30 dib mr3 v-top mb3'}
                  >
                    {form.values['brandConflicts']?.map(key =>
                      ({ value: key, label: key })) ?? []}
                  </MultiSelect>
                  <TextArea
                    label={'Job Limitations'}
                    resize={"none"}
                    placeholder={"No cigarette-affiliated content, etc."}
                    {...form.inputProps('limitations')}
                    rows={8}
                    className={classNames('dib mr3 v-top', css({ minWidth: 400 }))}
                  />
                  <div className={"flex"}>
                    <TextArea
                      label={'Other Job Info'}
                      resize={"none"}
                      {...form.inputProps('otherJobInfo')}
                      rows={8}
                      className={classNames('dib mr3 v-top', css({ minWidth: 400 }))}
                    />
                    <span className={"flex flex-column mt4"}>
                      <Checkbox
                        label={'Member of Union'}
                        {...form.checkboxProps('memberOfUnion')}
                        className={"mb3"}
                      />
                      <Checkbox
                        label={'Showcase Vocals on Voice123'}
                        {...form.checkboxProps('showcaseVoicesOnVoice123')}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <h3 className={"vocalid-h3 ttu mb3"}>Voice Creation</h3>
              <div className={"mb3 flex"}>
                <MultiSelect
                  label={"Interest in Character Voice Recording"}
                  creatable
                  {...form.multiSelectProps('interestedInCharacterVoiceRecording')}
                  className={'w-30 dib mr3 v-top mb3'}
                >
                  {form.values['interestedInCharacterVoiceRecording']?.map(key =>
                    ({ value: key, label: key })) ?? []}
                </MultiSelect>
                <MultiSelect
                  label={"Interest in Regional Accent Recording"}
                  creatable
                  {...form.multiSelectProps('interestedInRegionalAccentRecording')}
                  className={'w-30 dib mr3 v-top mb3'}
                >
                  {form.values['interestedInRegionalAccentRecording']?.map(key =>
                    ({ value: key, label: key })) ?? []}
                </MultiSelect>
              </div>
              <div className={'flex justify-between'}>
                <PrimaryButton
                  disabled={!form.isValid || !form.dirty}
                  onClick={() => form.isValid && form.dirty && form.handleSubmit()}
                >
                  {!saving ? "Save" : <Loader />}
                </PrimaryButton>
              </div>
            </div>
          </Tile>
          <Tile heading={<div className={'vocalid-h2'}>Security</div>} className={'mb4'}>
            <div className={'ph6 pv3 flex'}>
              <Input
                type={'password'}
                label={'Password'}
                value={'**************'}
                className={'w-40 dib mr1'}
                disabled={true}
              />
              <TextButton className={"pv1"} onClick={() => {}}>
                <span className={'ttn'} onClick={() => setIsResettingPassword(true)}>Change Password</span>
              </TextButton>
            </div>
          </Tile>
        </div>
      </div>
    </>
  );
};

export default TalentProfilePageContainer;

