import React from 'react';
import Tooltip, { ITooltipProps } from '../../atoms/tooltip';
import Icon, { EIconName } from '../../atoms/icon';
import classNames from "classnames";

export interface IInfoTooltipProps extends Omit<ITooltipProps, 'containerClassName'> {
  tooltipClassName?: string;
  tooltipContainerClassName?: string;
}

const InfoTooltip: React.FC<IInfoTooltipProps> = ({
  tooltipClassName,
  tooltipContainerClassName,
  className,
  children,
  ...props
}) => {
  return (
    <Tooltip
      className={tooltipClassName}
      containerClassName={tooltipContainerClassName}
      placement={'top'}
      {...props}
    >
      {children ?? <span className={classNames("dib", className)}><Icon name={EIconName.Info} size={18} /></span>}
    </Tooltip>
  );
};

export default InfoTooltip;
