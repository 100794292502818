import React from "react";
import classNames from "classnames";
import { css } from "emotion";
import enterpriseImage from "../../../components/pages/landing/enterprise.jpg";
import { useTheme } from "../../../theme";

interface RegistrationTemplateProps {
  heading: React.ReactNode;
  subtitle?: React.ReactNode;
  className?: string;
}

const RegistrationTemplate: React.FC<RegistrationTemplateProps> = props => {
  const { colors: themeColors } = useTheme()

  return (
    <div className={classNames('relative h-100', props.className)}>
      <div className={'h-100'}>
        <div className={'flex flex-column items-center h-100 mb4'}>
          <div className={classNames("flex justify-center items-center flex-grow-1 h-100 w-100 relative", css({
            overflowX: 'hidden'
          }))}>
            <div className={css({
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${enterpriseImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: '80%',
              '&:after': {
                content: "''",
                top: 0,
                left: '100%',
                width: '100%',
                bottom: 0,
                position: 'absolute',
                transform: 'skew(20deg)',
                transformOrigin: 'bottom',
                background: themeColors.page
              }
            })} />
            <div className={classNames("flex justify-center", css({
              zIndex: 1
            }))}>
              <div className={classNames("flex-grow-1 pt2 mh5 flex flex-column items-end tr h-100", css({
                // paddingTop: "7%",
              }))}>
                <h1 className={"vocalid-h1 vocalid-white mb2 dn db-l"} style={{ fontSize: 64, lineHeight: '68px' }}>{props.heading}</h1>
                <h3 className={"vocalid-h3 vocalid-white pr2"}>{props.subtitle}</h3>
                {/*<Link to={"/pricing"} className={"vocalid-white mt4 ttu no-underline"}>Back to Pricing Plans</Link>*/}
              </div>
              <div>
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationTemplate;
