import React from 'react';
import classNames from 'classnames';
import { css } from 'emotion';
import Icon, { EIconName } from '../icon';
import { useTheme } from "../../../theme";

export interface ITagProps {
  color?: string;
  textColor?: string;
  onDelete?(): void;
  className?: string;
}

const Tag: React.FC<ITagProps> = (props) => {
  const { colors: themeColors } = useTheme();
  return (
    <div
      className={classNames(
        css({
          borderRadius: 12,
          paddingTop: 2,
          paddingBottom: 2,

          fontFamily: '"Roboto", sans-serif',
          fontWeight: 'normal',
          fontSize: 12,
          lineHeight: '14px',
          backgroundColor: props.color ?? themeColors.tag,
          color: props.textColor ?? themeColors.tagText
        }),
        'dib pl3',
        props.onDelete ? 'pr2' : 'pr3',
        props.className
      )}
    >
      <span className={'dib v-mid'}>{props.children}</span>
      {props.onDelete && (
        <span className={'dib v-mid ml1'}>
          <Icon
            name={EIconName.X}
            color={'tagText'}
            size={16}
            onClick={props.onDelete}
            className={'v-mid'}
          />
        </span>
      )}
    </div>
  );
};

export default Tag;
