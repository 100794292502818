import { EIconName } from "../../components/atoms/icon";

export function iconForProjectType(projectType: string): EIconName {
  const lcType = projectType.toLowerCase()

  switch (true) {
    case /tv/.test(lcType):
      return EIconName.TV;
    case /television/.test(lcType):
      return EIconName.TV;

    case /radio/.test(lcType):
      return EIconName.Radio;

    case /ivr/.test(lcType):
      return EIconName.Phone;
    case /message[\s-]on[\s-]hold/.test(lcType):
      return EIconName.Phone;

    case /audiobook/.test(lcType):
      return EIconName.Book;

    case /animation/.test(lcType):
      return EIconName.Animation;

    case /dubbing/.test(lcType):
      return EIconName.Dubbing;

    case /e[\s-]learning/.test(lcType):
      return EIconName.Dictionary;

    case /cinema/.test(lcType):
      return EIconName.Movie;
    case /movie/.test(lcType):
      return EIconName.Movie;

    case /promo/.test(lcType):
      return EIconName.Promo;

    case /video[\s-]?games/.test(lcType):
      return EIconName.VideoGames;
    case /toys/.test(lcType):
      return EIconName.Games;
    case /games/.test(lcType):
      return EIconName.Games;

    case /digital/.test(lcType):
      return EIconName.Digital;
  }

  return EIconName.Mix;
}
