import { SubstitutionDictionaryMemberFragment, useCreateSubstitutionDictionaryMemberMutation, useDeleteSubstitutionDictionaryMemberMutation, useUpdateSubstitutionDictionaryMemberMutation } from "../../../graphql/generated";
import {
  ISlateAlternatePronunciation,
  isTextAlternatePronunciation
} from "../../../components/organisms/clip/editor/adjustments/internal-types";

export function useManagedSubstitutionDictionary(accountDictionaryId: string, projectDictionaryId: string, accountMembersData: SubstitutionDictionaryMemberFragment[], projectMembersData: SubstitutionDictionaryMemberFragment[], refetchProjectQuery: () => Promise<any>) {
  const [createSubstitutionDictionaryMemberItemMutation] = useCreateSubstitutionDictionaryMemberMutation();
  const [updateSubstitutionDictionaryMemberItemMutation] = useUpdateSubstitutionDictionaryMemberMutation();
  const [deleteSubstitutionDictionaryMemberItemMutation] = useDeleteSubstitutionDictionaryMemberMutation();

  const createSubstitutionDictionaryMemberItem = async (original: string, replacement: ISlateAlternatePronunciation) => {
    if (isTextAlternatePronunciation(replacement)) {
      await createSubstitutionDictionaryMemberItemMutation({
        variables: {
          dictionary: projectDictionaryId,
          original,
          replacement: replacement.text
        }
      })
    } else {
      await createSubstitutionDictionaryMemberItemMutation({
        variables: {
          dictionary: projectDictionaryId,
          original,
          pronunciation: replacement.phonetic
        }
      })
    }

    await refetchProjectQuery()
  }

  const deleteSubstitutionDictionaryMemberItem = async (id: string) => {
    await deleteSubstitutionDictionaryMemberItemMutation({
      variables: {
        id
      }
    })
    await refetchProjectQuery()
  }

  const setSubstitutionDictionaryMemberItemGlobal = async (id: string, global: boolean) => {
    await updateSubstitutionDictionaryMemberItemMutation({
      variables: {
        id,
        dictionary: global ? accountDictionaryId : projectDictionaryId
      }
    });

    await refetchProjectQuery()
  }

  const memberMapper = (global: boolean) => (m: SubstitutionDictionaryMemberFragment) => ({
    id: m.id,
    created: m.created,
    oldPronunciation: m.original,
    newPronunciation: m.replacement !== null ? { text: m.replacement } : { phonetic: m.pronunciation! },
    global
  })

  return {
    members: [
      ...accountMembersData.map(memberMapper(true)),
      ...projectMembersData.map(memberMapper(false))
    ].sort((a, b) => a.created - b.created),
    addMember: async (oldPronunciation: string, newPronunciation: ISlateAlternatePronunciation) => {
      await createSubstitutionDictionaryMemberItem(oldPronunciation, newPronunciation)
    },
    setMemberGlobal: setSubstitutionDictionaryMemberItemGlobal,
    removeMember: deleteSubstitutionDictionaryMemberItem
  }
}
