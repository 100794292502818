import React from 'react';
import classNames from 'classnames';
import AgreementPdfView from "../agreement-pdf-view";
import Tile from "../../atoms/tile";

export interface IAgreementPdfBlockProps {
  file: string;
  header: React.ReactNode;
  headerText?: string;
  agreeText?: string;
  onAgree(): void;
  parentSelector?: () => HTMLElement;
  className?: string;
}

const AgreementPdfBlock: React.FC<IAgreementPdfBlockProps> = (props) => {
  return (
    <div className={classNames("flex justify-center items-start vocalid-secondary-text pt5")}>
      <Tile heading={<div className={'vocalid-h2 mr2'}>{props.header}</div>}>
        <AgreementPdfView file={props.file} headerText={props.headerText} agreeText={props.agreeText} onAgree={props.onAgree} />
      </Tile>
    </div>
  );
};

export default AgreementPdfBlock;
