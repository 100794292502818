import React from "react";
import Slider from "../../../atoms/slider";
import Separator from "../../../atoms/separator";
import Toggle from "../../../atoms/toggle";
import classNames from "classnames";
import InfoTooltip from "../../../molecules/info-tooltip";
import Icon, { EIconName } from "../../../atoms/icon";
import { EMagnitude } from "../../clip/editor/adjustments";
import { css } from "emotion";
import NumberStepper from "../../../atoms/number-stepper";
import { ISlateWordNode } from "../../clip/editor/adjustments/internal-types";
import AlternatePronunciationInput from "../../alternate-pronunciation-input";
import { TextButton } from "../../../atoms/button";
import { FeatureLock } from "../../../../utils/feature-locking";

export interface IVocalAdjustmentsSidebarMenuProps {
  active: boolean;
  clipData: IClipVoiceAdjustmentsData;
  singleSelectedWord: {
    text: string;
    defaultPhoneticPronunciation?: string[];
  } | null;
  onRequestDefaultPronunciation(text: string): void;
  onClipDataChange(newData: Partial<IClipVoiceAdjustmentsData>): void;

  className?: string;
}

export interface IClipVoiceAdjustmentsData {
  speed: EMagnitude | null;
  pitch: EMagnitude | null;
  loudness: EMagnitude | null;
  tone: 'excited' | 'terse' | 'disappointed' | 'none' | null;
  wordStress: EMagnitude | null;
  pause: 'long' | 'short' | 'none' | null;
  pauseInMilliseconds: number | 'none' | null;
  alternatePronunciation:
    | {
    phonetic: string[];
  }
    | {
    text: string;
  }
    | null;
}

const VocalAdjustmentsSidebarMenu: React.FC<IVocalAdjustmentsSidebarMenuProps> = (props) => {
  const convertedPauseMs = useFineGrainedPauseValueMs(props.clipData.pause);

  const featureLockCss = css({
    display: 'flex !important',
    flexDirection: 'column'
  })

  return (
    <div className={props.className}>
      <AdjustmentSlider
        name={'Speed'}
        color={'green'}
        value={magnitudeToNumber(props.clipData.speed ?? EMagnitude.Med)}
        onChange={(newValue) => props.onClipDataChange({ speed: numberToMagnitude(newValue) })}
        min={1}
        max={5}
        startPoint={3}
        step={1}
        disabled={!props.active}
        className={'pb3'}
      />
      <AdjustmentSlider
        name={'Pitch'}
        color={'orange'}
        value={magnitudeToNumber(props.clipData.pitch ?? EMagnitude.Med)}
        onChange={(newValue) => props.onClipDataChange({ pitch: numberToMagnitude(newValue) })}
        min={1}
        max={5}
        startPoint={3}
        step={1}
        disabled={!props.active}
        className={'pb3'}
      />
      <AdjustmentSlider
        name={'Loudness'}
        value={magnitudeToNumber(props.clipData.loudness ?? EMagnitude.Med)}
        onChange={(newValue) => props.onClipDataChange({ loudness: numberToMagnitude(newValue) })}
        min={1}
        max={5}
        color={'iconActive'}
        startPoint={3}
        step={1}
        disabled={!props.active}
        className={'pb3'}
      />
      <Separator />
      <FeatureLock feature={'EditorTone'} className={featureLockCss}>
        <div className={'tc vocalid-h3 mt4 mb4'}>
          Tone{' '}
          <InfoTooltip
            tooltipContent={
              'Apply a tonal effect to words. Select any part of sentence and choose tone. One tone can be applied per sentence.'
            }
            className={'ml2 v-mid'}
          />
        </div>
        <div className={'flex justify-between flex-wrap mb4'}>
          <ToneToggle
            onChange={(on) => props.onClipDataChange({ tone: on ? 'excited' : 'none' })}
            name={'Excited'}
            checked={props.clipData.tone === 'excited'}
            disabled={!props.active}
            className={'w-50 w-25-l pv2 pr1'}
          />
          <ToneToggle
            onChange={(on) => props.onClipDataChange({ tone: on ? 'terse' : 'none' })}
            name={'Terse'}
            checked={props.clipData.tone === 'terse'}
            disabled={!props.active}
            className={'w-50 w-25-l pv2 ph1'}
          />
          <ToneToggle
            onChange={(on) => props.onClipDataChange({ tone: on ? 'disappointed' : 'none' })}
            name={'Disappointed'}
            checked={props.clipData.tone === 'disappointed'}
            disabled={!props.active}
            className={'w-50 w-25-l pv2 ph1'}
          />
        </div>
        <Separator />
      </FeatureLock>
      <FeatureLock feature={'EditorInlinePronunciation'} className={featureLockCss}>
        <div className={'tc vocalid-h3 mt4 mb3'}>
          Say As <InfoTooltip tooltipContent={'Apply a local substitution to a word'} className={'ml2 v-mid'} />
        </div>
        <div className={classNames('flex justify-center mb4')}>
          <AlternatePronunciationInput
            value={props.clipData.alternatePronunciation}
            defaultPhonetics={props.singleSelectedWord?.defaultPhoneticPronunciation && {
              phonetic: props.singleSelectedWord.defaultPhoneticPronunciation
            }}
            defaultText={props.singleSelectedWord ? {
              text: props.singleSelectedWord.text.replace(/\W/g, "") // with removed punctuation
            } : undefined}
            disabled={!props.active || !props.singleSelectedWord}
            onChange={(alternatePronunciation) => props.onClipDataChange({ alternatePronunciation })}
            requestDefaultPhonetics={mode => {
              if (props.singleSelectedWord && mode === 'phonetic' && !props.singleSelectedWord?.defaultPhoneticPronunciation) {
                props.onRequestDefaultPronunciation(props.singleSelectedWord.text)
              }
            }}
          />
        </div>
        <Separator className={'mb4'} />
      </FeatureLock>
      <FeatureLock feature={'EditorWordStress'} className={featureLockCss}>
        <AdjustmentSlider
          name={
            <span>
              Word Stress{' '}
              <InfoTooltip
                tooltipContent={
                  'Increase or decrease stress on a single word. Place cursor in word and adjust slider.'
                }
                className={'ml2 v-mid'}
              />
            </span>
          }
          value={magnitudeToNumber(props.clipData.wordStress ?? EMagnitude.Med)}
          onChange={(on) => props.onClipDataChange({ wordStress: numberToMagnitude(on) })}
          min={1}
          max={5}
          startPoint={3}
          step={1}
          disabled={!props.active}
          className={'pb3'}
        />
      </FeatureLock>
      <FeatureLock feature={'EditorWordPause'} className={featureLockCss}>
        <AdjustmentSlider
          name={
            <span>
              Pause Between Words{' '}
              <InfoTooltip
                tooltipContent={
                  'Lengthen or shorten the pause between two words. Place cursor on a word and adjust slider, or for more precision, specify the exact number of seconds in the text box, e.g "0.2".   '
                }
                className={'ml2 v-mid'}
              />{' '}
            </span>
          }
          value={props.clipData.pause === 'long' ? 3 : props.clipData.pause === 'short' ? 1 : 2}
          onChange={(value) => {
            props.onClipDataChange({
              pause: value === 3 ? "long" : value === 1 ? "short" : "none",
              pauseInMilliseconds: 'none'
            });
          }}
          min={1}
          max={3}
          startPoint={2}
          step={1}
          disabled={!props.active || !props.singleSelectedWord}
          fineGrainedValue={
            (props.clipData.pauseInMilliseconds !== null && props.clipData.pauseInMilliseconds !== 'none'
              ? props.clipData.pauseInMilliseconds
              : convertedPauseMs !== undefined
              ? convertedPauseMs
              : 500) / 1000
          }
          fineGrainedValueUsed={props.clipData.pauseInMilliseconds !== null}
          fineGrainedValueStep={0.05}
          onChangeFineGrainedValue={(newPauseSec) => {
            props.onClipDataChange({ pauseInMilliseconds: newPauseSec * 1000 });
          }}
          onRemoveFineGrainedValue={() => {
            console.log("Remove fine grained pause")
            props.onClipDataChange({
              pause: 'none',
              pauseInMilliseconds: 'none'
            })
          }}
          className={'pb3'}
        />
      </FeatureLock>
    </div>
  );
};

export default VocalAdjustmentsSidebarMenu;

const useFineGrainedPauseValueMs = (
  pauseValue: IClipVoiceAdjustmentsData['pause']
): number | undefined => {
  const [cachedPauseValues, setCachedPauseValues] = React.useState<
    { [p in NonNullable<ISlateWordNode['pause']>]: number } | null
  >(null);

  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/reference/pause-duration`)
      .then((res) => res.json())
      .then((res) => {
        try {
          const newCachedPauseValues = Object.keys(res).reduce((acc, curr) => {
            return {
              ...acc,
              [curr]: parseFloat(res[curr].match(/((\d|.)+)s/)[1]) * 1000
            };
          }, {} as NonNullable<typeof cachedPauseValues>);
          setCachedPauseValues(newCachedPauseValues);
        } catch (e) {
          console.warn(e);
        }
      });
  }, []);

  return pauseValue !== 'none' && pauseValue !== null ? cachedPauseValues?.[pauseValue] : undefined;
};

export interface IAdjustmentSliderProps {
  name: React.ReactNode;
  color?: string;

  value: number;
  fineGrainedValue?: number;
  fineGrainedValueUsed?: boolean;
  fineGrainedValueStep?: number;
  onChangeFineGrainedValue?(newValue: number): void;
  onBlurFineGrainedValue?(): void;
  onRemoveFineGrainedValue?(): void;

  min: number;
  max: number;
  step: number;
  startPoint?: number;

  inactive?: boolean;
  disabled?: boolean;
  onChange?(newValue: number): void;
  onMakeInactive?(): void;

  className?: string;
}

export const AdjustmentSlider: React.FC<IAdjustmentSliderProps> = (props) => {
  return (
    <div className={props.className}>
      <div className={'tc vocalid-h3 flex items-center justify-center'}><span>{props.name}</span>{props.inactive === false && <Icon name={EIconName.X} onClick={props.onMakeInactive} className={"ml2"} />}</div>
      <Slider
        min={props.min}
        max={props.max}
        startPoint={props.startPoint}
        value={props.value}
        onChange={props.onChange}
        step={props.step}
        markers
        color={!props.inactive ? props.color : "iconInactive"}
        disabled={props.disabled /*|| props.fineGrainedValueUsed*/}
        trackOpacity={0.8}
        minLabel={<Icon name={EIconName.Minus} size={12} />}
        maxLabel={<Icon name={EIconName.Plus} size={12} />}
        style={{ marginTop: -5 }}
      />
      {props.fineGrainedValue !== undefined && (
        <div
          className={classNames(
            'relative',
            css({
              marginBottom: 60
            })
          )}
        >
          <div
            className={css({
              position: 'absolute',
              top: -27,
              left: 0,
              right: 0,
              display: 'flex',
              justifyContent: 'center',
              zIndex: 0
            })}
          >
            <div
              className={classNames(
                'bg-vocalid-header',
                css({
                  height: 20,
                  width: 20
                })
              )}
            />
          </div>
          <div
            className={css({
              position: 'absolute',
              top: -20,
              left: 0,
              right: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1,
              input: {
                color:
                  !props.fineGrainedValueUsed && props.value === props.startPoint
                    ? 'rgba(0, 0, 0, 0.0) !important'
                    : undefined,
                opacity: !props.fineGrainedValueUsed ? 0.5 : 1,
                '&:hover, &:focus': {
                  opacity: 1,
                  color: 'inherit !important'
                }
              }
            })}
          >
            {/* this icon is only for centering */}
            <div className={css({
              width: '32.5%'
            })} />
            <div className={classNames(
              css(({
                width: '35%'
              })),
              props.disabled && ['o-30', css({ pointerEvents: 'none' })]
            )}>
              <NumberStepper
                value={props.fineGrainedValue}
                inputEditable
                onChange={(newValue) => {
                  props.onChangeFineGrainedValue?.(newValue);
                }}
                step={props.fineGrainedValueStep ?? 0.5}
                fixed={2}
                suffix={'s'}
              />
            </div>
            <div className={css({
              opacity: !props.fineGrainedValueUsed ? 0.5 : 1,
              width: '32.5%'
            })}>
              <TextButton onClick={() => {
                props.onRemoveFineGrainedValue?.();
              }}>Remove</TextButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export interface IToneToggleProps {
  name: string;
  checked: boolean;
  disabled?: boolean;
  className?: string;
  onChange: (value: boolean) => void;
}

const ToneToggle: React.FC<IToneToggleProps> = (props) => {
  return (
    <div className={classNames('dib', props.className)}>
      <div className={'tc vocalid-h3 mb3'}>{props.name}</div>
      <div className={classNames('flex justify-center', props.disabled && 'o-30')}>
        <Toggle onChange={props.onChange} checked={props.checked} />
      </div>
    </div>
  );
};

const magnitudeToNumber = (magnitude: EMagnitude) => {
  switch (magnitude) {
    case EMagnitude.Low:
      return 1;
    case EMagnitude.MedLow:
      return 2;
    case EMagnitude.Med:
      return 3;
    case EMagnitude.MedHigh:
      return 4;
    case EMagnitude.High:
      return 5;
  }
};

const numberToMagnitude = (number: number) => {
  const _numberToMagnitudeMap: { [key: number]: EMagnitude } = [
    EMagnitude.Low,
    EMagnitude.MedLow,
    EMagnitude.Med,
    EMagnitude.MedHigh,
    EMagnitude.High
  ].reduce((acc, curr) => ({ ...acc, [magnitudeToNumber(curr)]: curr }), {});

  return _numberToMagnitudeMap[number];
};
