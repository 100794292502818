import React from 'react';
import Dialog from '../../molecules/dialog';
import {OutlineButton, PrimaryButton} from '../../atoms/button';
import { useAuth0 } from "@auth0/auth0-react";

export interface ISignOutDialogProps {
  isShown: boolean;
  onCancel(): void;
  onBeforeSignOut?(): void;
  onSignOutReturnTo: string;
  className?: string;
}

const SignOutDialog: React.FC<ISignOutDialogProps> = (props) => {
  const { logout } = useAuth0();

  return (
    <Dialog
      isShown={props.isShown}
      heading={'Are you sure you want to sign out?'}
      className={props.className}
    >
      <div className={'flex justify-center'}>
        <OutlineButton
          size={'medium'}
          onClick={() => props.onCancel()}
          className={'mr4'}
        >
          CANCEL
        </OutlineButton>
        <PrimaryButton onClick={async () => {
          await props.onBeforeSignOut?.();
          logout({
            returnTo: props.onSignOutReturnTo
          });
        }}>
          SIGN OUT
        </PrimaryButton>
      </div>
    </Dialog>
  );
};

export default SignOutDialog;
