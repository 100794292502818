import React from "react";
import AudioPlayer from "../../molecules/audio-player";
import Icon, { EIconName } from "../../atoms/icon";
import classNames from "classnames";
import { css } from "emotion";
import { ITake } from "./index";
import { useTheme } from "../../../theme";
import { CircularProgress } from "@material-ui/core";
import { FeatureLock } from "../../../utils/feature-locking";
import { MixType } from "../project-sidebar/tabs/mix";

export interface ITakeProps {
  take: Omit<ITake, "vocalId"> & { voiceName: string };
  primary?: boolean;
  error?: string;
  hovering?: boolean;
  className?: string;
  preload?: boolean;
  mixType: MixType | undefined;

  onClick?(): void;
  onAddToMix(): void;
  onDelete(): void;
  onReject(): void;
  onApprove(): void;
}

const Take: React.FC<ITakeProps> = (props) => {
  const { colors: themeColors } = useTheme();
  const [hovering, setHovering] = React.useState(false)


  const primaryCss = props.primary && css({
    background: themeColors.header,
    zIndex: 1
  })

  const hoveringCss = hovering && css({
    background: themeColors.header,
    zIndex: 1
  })

  const primaryShadowJsx = props.primary && <div className={css({
    background: themeColors.header,
  })}>
    <div>
      <div className={css({
        position: 'relative',
        pointerEvents: 'none',
        height: 0,
        zIndex: -100,
        '&:before': {
          content: '""',
          position: 'absolute',
          top: -50,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
          backgroundColor: 'transparent',
          boxShadow: `0px 14px 10px -10px ${themeColors.header}`
        }
      })} />
    </div>
  </div>

  const sectionCss = css({
    paddingTop: 8,
    paddingBottom: 6,
    paddingLeft: '.5rem',
    paddingRight: '.5rem'
  })

  const rendering = props.take.type === "GeneratedTake" && props.take.audioUrl === null && props.error === null

  return (
    <>
      <div className={classNames('ph2 flex items-center vocalid-tertiary-text', primaryCss, hoveringCss, props.className)} onClick={() => props.onClick && props.onClick()} onMouseOut={() => setHovering(false)} onMouseOver={() => setHovering(true)}>
        <div className={classNames(sectionCss, "flex-shrink-0")}>
          <strong className={'db ml2 mb1 mr3 vocalid-list-title'}>Take {props.take.number}{props.take.type === "TalentRecordedTake" ? <Icon name={EIconName.Star} className={classNames("relative v-mid ml1", css({ top: -3, marginBottom: -5 }))} /> : ""}</strong>
          <span className={classNames('db ml2 mb2 mr3', css({
            fontWeight: 300,
            fontSize: '12px'
          }))}>{props.take.createdAt.toLocaleString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            hour12: true,
            minute: "2-digit"
          })}</span>
        </div>
        <div className={classNames(sectionCss, "flex-shrink-1 flex-grow-1 overflow-x-hidden")}>
          <div className={css({
            paddingTop: 10,
            paddingBottom: 5
          })}>
            { (!props.error && !props.take.audioUrl && props.take.type !== "TalentRecordedTake") && <CircularProgress color={"inherit"}/> }
            { props.error && <div style={{
              whiteSpace: 'nowrap',
              paddingBottom: 5,
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }} title={props.error}>Error: {props.error}</div> }
            {props.take.type === "TalentRecordedTake" && !props.take.audioUrl && <div style={{
              paddingBottom: 5
            }} title={props.error}>Talent recording requested.</div> }
            {(!rendering && props.take.audioUrl) ? <AudioPlayer src={props.take.audioUrl} voiceName={props.take.voiceName} preload={props.preload} className={'w-100'} /> : null }
          </div>
        </div>
        <div className={classNames("flex-shrink-0 flex items-center ml4")}>
          { (!rendering && props.take.audioUrl) ? <_TakeActions { ...props } rendering={props.take.audioUrl === null} /> : null }
        </div>
      </div>
      {primaryShadowJsx}
    </>
  );
};

const _TakeActions: React.FC<ITakeProps & {
  rendering: boolean;
  className?: string;
}> = (props) => {
  const { colors: themeColors } = useTheme()

  const className = classNames(props.rendering ?
    classNames('ph2 pv3 tr mr2 o-40', css({ pointerEvents: 'none' })) :
    'tr mr2', props.className)

  return (
    <div className={className}>
      <FeatureLock feature={'ProjectMix'}>
        <FeatureLock feature={'ProjectMixAdMode'} noHidden disabled={props.mixType !== 'Ad'}>
          <Icon
            className={classNames('ph2')}
            name={EIconName.AddToMix}
            onClick={() => props.take.audioUrl ? props.onAddToMix() : {}}
          />
        </FeatureLock>
      </FeatureLock>
      <FeatureLock feature={'TakeDownload'}>
        <Icon className={'ph2'} name={EIconName.Download} onClick={() => { if (props.take.audioUrl) window.location.href = props.take.audioUrl } }/>
      </FeatureLock>
      <Icon className={'ph2'} name={EIconName.Delete} onClick={() => props.onDelete()} />
      {/*<Icon
        className={classNames(
          'ph2 relative',
          css({
            top: 2
          })
        )}
        name={EIconName.Reject}
        activeColor={themeColors.red}
        active={props.take.status === 'rejected'}
        onClick={() => props.onReject()}
      />
      <Icon
        className={classNames(
          'ph2 relative',
          css({
            top: -2,
            transform: 'scaleX(-1)'
          })
        )}
        name={EIconName.Approve}
        activeColor={themeColors.green}
        active={props.take.status === 'approved'}
        onClick={() => props.onApprove()}
      />*/}
    </div>
  )
}

export default Take;
